import React, { useState, useEffect } from "react"

import CloseIcon from "@mui/icons-material/Close"
import { IconButton, Grid, Typography, TextField, Button } from "@mui/material"
import { Formik } from "formik"
import * as Yup from "yup"
import SearchIcon from "@mui/icons-material/Search"
import InputFiltrarClienteProforma from "./InputFiltrarClientesProforma"

const ModalSearchClient = ({ initialValues, handleCloseModalCreateClient, setCabeceraProforma, cabeceraProforma }) => {
  const [formData, setFormData] = useState({}) // Estado para manejar los datos del formulario

  useEffect(() => {
    // Inicializar formData con los valores iniciales
    setFormData(initialValues)
  }, [initialValues])

  const handleSubmit = async (values, { setSubmitting }) => {
    setSubmitting(false)

    console.log(values, "aqui")
    console.log(values.nombre)

    console.log(cabeceraProforma, "aqui")
    console.log("cabeceradesdecliente")
    setCabeceraProforma({
      ...cabeceraProforma,
      clinombre: values.nombre,
      clicodigo: values.clicodigo,
    })
    handleCloseModalCreateClient()
    // Aquí se envia los datos del cliente al backend para procesarlos
    // const request = {
    //   clinombre: cliente.nombre,
    //   cliruc: cliente.cedula,
    //   cliemail: cliente.correo,
    //   clitelef1: cliente.telefono,
    //   clidirec: cliente.direccion,
    // };

    // try {
    //   const options = {
    //     method: "POST",
    //     body: JSON.stringify(request),
    //     headers: {
    //       "Content-Type": "application/json",
    //       "Access-Control-Allow-Origin": "*",
    //     },
    //   };
    //   await fetchwrapper("/proformas/createCliente", options);

    //   alert("Nuevo cliente creado");

    //   // Limpia el formulario después de enviar los datos
    //   clearModalCreateClient();

    //   //Cerrar el modal
    //   handleCloseModalCreateClient();
    // } catch (err) {
    //   alert("No se pudo crear el cliente");
    // }
  }

  const handleSearchInput = async (inputId, valuesForm, setValuesForm) => {
    // Fetch info existing cliente
    const searchText = valuesForm[inputId]
    console.log(searchText)
    console.log("eeeeeeeeeeeeeee111")

    const newFormValues = {
      nombre: "",
      cedulaRuc: "",
      correo: "",
      telefono: "",
      direccion: "",
    }
    await setValuesForm(newFormValues)
  }

  return (
    <div>
      <div style={{ marginLeft: "96%" }}>
        <IconButton onClick={handleCloseModalCreateClient}>
          <CloseIcon />
        </IconButton>
      </div>
      <Typography variant="h3" align="center" gutterBottom>
        Buscar Cliente
      </Typography>
      {/* .test(
              "longitud",
              "El número de teléfono debe tener 10 dígitos",
              (value) => {
                const length = value ? value.length : 0;
                return length === 10;
              }
            ) */}
      <Formik
        enableReinitialize={true}
        // initialValues={initialValues}
        initialValues={formData}
        validationSchema={Yup.object({
          nombre: Yup.string().max(100, "Debe ser máximo 100 carácteres o menos").required("Este campo es obligatorio"),
          cedulaRuc: Yup.string()
            .matches(/^[0-9]+$/, "Debe ser un valor numérico")
            .test("tipo", "El número debe ser de cédula (10 caracteres) o RUC (13 caracteres)", (value) => {
              const length = value ? value.length : 0
              return (length === 10 && /^[0-9]{10}$/.test(value)) || (length === 13 && /^[0-9]{13}$/.test(value))
            })
            .required("Este campo es obligatorio"),
          correo: Yup.string().email("Dirección de email inválida"),
          // .required("Este campo es obligatorio"),
          telefono: Yup.string()
            .matches(/^[0-9]+$/, "Debe ser un valor numérico")

            .required("Este campo es obligatorio"),
          direccion: Yup.string()
            .max(100, "Debe ser máximo 100 carácteres o menos")
            .required("Este campo es obligatorio"),
        })}
        onSubmit={handleSubmit}
      >
        {(formik) => (
          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12}>
                <InputFiltrarClienteProforma
                  setFormData={setFormData}
                  cabeceraProforma={cabeceraProforma}
                  setCabeceraProforma={setCabeceraProforma}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  disabled
                  id="nombre"
                  label="Nombre"
                  {...formik.getFieldProps("nombre")}
                  error={formik.touched.nombre && Boolean(formik.errors.nombre)}
                  helperText={formik.touched.nombre && formik.errors.nombre}
                  value={formik.values.nombre} // Usa value directamente de formik.values
                  InputLabelProps={{
                    shrink: true,
                  }}
                  /* InputProps={{
                    endAdornment: (
                      <IconButton
                        onClick={() =>
                          handleSearchInput(
                            "nombre",
                            formik.values,
                            formik.setValues
                          )
                        }
                      >
                        <SearchIcon />
                      </IconButton>
                    ),
                  }} */
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  disabled
                  fullWidth
                  id="cedulaRuc"
                  label="Cédula o Ruc"
                  {...formik.getFieldProps("cedulaRuc")}
                  error={formik.touched.cedulaRuc && Boolean(formik.errors.cedulaRuc)}
                  helperText={formik.touched.cedulaRuc && formik.errors.cedulaRuc}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  /* InputProps={{
                    endAdornment: (
                      <IconButton
                        onClick={() =>
                          handleSearchInput(
                            "cedulaRuc",
                            formik.values,
                            formik.setValues
                          )
                        }
                      >
                        <SearchIcon />
                      </IconButton>
                    ),
                  }} */
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="correo"
                  label="Correo"
                  {...formik.getFieldProps("correo")}
                  error={formik.touched.correo && Boolean(formik.errors.correo)}
                  helperText={formik.touched.correo && formik.errors.correo}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="telefono"
                  label="Teléfono"
                  {...formik.getFieldProps("telefono")}
                  error={formik.touched.telefono && Boolean(formik.errors.telefono)}
                  helperText={formik.touched.telefono && formik.errors.telefono}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="direccion"
                  label="Dirección"
                  {...formik.getFieldProps("direccion")}
                  error={formik.touched.direccion && Boolean(formik.errors.direccion)}
                  helperText={formik.touched.direccion && formik.errors.direccion}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  style={{ background: "#114B5E", color: "white" }}
                >
                  Asignar
                </Button>
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
    </div>
  )
}

export default ModalSearchClient
