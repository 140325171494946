import React, { useState, useEffect } from "react"
import Header from "../../../layouts/Header"
import { styled, createTheme, ThemeProvider } from "@mui/material/styles"
import {
  Box,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Button,
  Tooltip,
  Checkbox,
  FormControlLabel,
} from "@mui/material"
import Alert from "@mui/material/Alert"
import Swal from "sweetalert2"
import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import BackIcon from "../../../components/BackIcon"
import GrabarIcon from "../../../assets/iconos/Grabar.ico"
import fetchwrapper from "../../../services/interceptors/fetchwrapper"
import CustomBackdrop from "../../../components/CustomBackdrop"

const StyledRoot = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  marginTop: theme.spacing(8),
  flexGrow: 1,
  padding: "0 16px",
  height: "100vh",
}))
const theme = createTheme({
  palette: {
    primary: {
      main: "#196C87",
    },
    secondary: {
      main: "#196C87", // Cambia el color secundario a verde azulado
    },
  },
})
const CrearEmpaque = () => {
  const navigate = useNavigate()

  // Valores por defectos para los comboboxes y el check

  // Estados para manejaar el formulario de opciones
  // cuando llame a las funciones set, se actualizara el valor de los otros campos y se re renderiza
  const [tipo, setTipo] = useState("")
  const { mutateAsync: createEmpaque, isPending: isCreatingEmpaque } = useCreateEmpaque()

  function useCreateEmpaque() {
    const queryClient = useQueryClient()
    return useMutation({
      mutationFn: async (empaque) => {
        // send api update request here
        // send api update request here
        const options = {
          method: "POST",
          body: JSON.stringify(empaque),
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }
        console.log("json", options.body)
        let response = await fetchwrapper(`/Empaques/createEmpaque`, options)

        response = response.json()
        console.log(response)
        return response
      },
      // client side optimistic update
      onMutate: (newEmpaque) => {
        console.log(newEmpaque, "onmutate")
        queryClient.setQueryData(["Empaque", newEmpaque.id], (prevEmpaque) => ({
          ...newEmpaque,
        }))
      },
      // onSettled: () => queryClient.invalidateQueries({ queryKey: ['bancoDePreguntas'] }), //refetch bancoDePreguntas after mutation, disabled for demo
    })
  }
  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      const empaque = {
        tipoEmpaque: tipo,
      }

      await createEmpaque(empaque)
      Swal.fire({
        icon: "success",
        title: "Operación exitosa",
        text: "Se pudo crear el Empaque",
      }).then((result) => {
        if (result.isConfirmed) {
          navigate("/home/dashboard/Empaques") // Navegar a la ruta especificada
        }
      })
    } catch (error) {
      Swal.fire({
        title: "Error",
        text: "No se pudo crear el empaque",
        icon: "error",
        confirmButtonText: "OK",
      }).then(() => {
        navigate("/home/dashboard/Empaques") // Navega de vuelta cuando se cierre el SweetAlert
      })
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <Header />
      <div className="main main-app p-3 p-lg-4">
        <BackIcon />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "0 30px 30px 30px",
            fontSize: "25px",
          }}
        >
          <b>Tipos de Empaque</b>
        </div>
        <Box className={StyledRoot}>
          <form onSubmit={handleSubmit}>
            <Tooltip title="Grabar">
              <Button type="submit" color="primary" sx={{ marginTop: "15px" }}>
                <img src={GrabarIcon} alt="Grabar" style={{ width: "40px" }} />
              </Button>
            </Tooltip>
            <TextField
              label="Descripcción"
              margin="normal"
              fullWidth
              value={tipo}
              required
              onChange={(e) => setTipo(e.target.value)}
              inputProps={{ maxLength: 20 }}
            />
          </form>
        </Box>
        <CustomBackdrop isLoading={isCreatingEmpaque} />
      </div>
    </ThemeProvider>
  )
}

export default CrearEmpaque
