import React, { useState, useEffect } from "react"
import QRCodeScanner from "./QRCodeScanner"
import { Modal, Typography, Box } from "@mui/material"
import ModalProducto from "./ModalProducto"
import CloseIcon from "@mui/icons-material/Close"

const style = {
  position: "absolute",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
}

export default function ModalCameraQr({ isOpen, handleClose, codeType }) {
  const [result, setResult] = useState("No result")
  const [open, setOpen] = useState(false)

  useEffect(() => {
    setOpen(isOpen)
  }, [isOpen])

  const handleScan = (data) => {
    console.log(data)
    if (data) {
      console.log(data)
      setResult(data)
    }
  }

  const handleError = (err) => {
    console.error(err)
  }

  const handleOpen = () => {
    setOpen(true)
  }

  const handleCloseModal = () => {
    setResult("No result")
    setOpen(false)
    handleClose()
  }

  // Implementacion para cuando se detecte el picking del disposivo zebra
  // Recordar que el separador es *. ejemplo de link:
  //   /productos/get_producto_por_codigo-00003-005*
  //   filtrarProducto

  const [pick, setPick] = useState({
    current: "",
    isFinished: false,
  })

  // useEffect(() => {
  //   const handleKeyDown = (event) => {
  //     if (event.key !== "Unidentified" && event.key !== "Shift" && event.key !=="Enter") {
  //       setPick((prev) => {
  //         if (event.key == "*") {
  //           handleOpen();
  //           setResult(prev.current);
  //           console.log(prev.current, "esto es el *");

  //           return {
  //             current: prev.current,
  //             isFinished: true,
  //           };
  //         } else {
  //           setResult("No result");
  //           console.log("El estado general", pick);
  //           if (prev.isFinished) {
  //             return {
  //               current: event.key,
  //               isFinished: false,
  //             };
  //           } else {
  //             console.log("toda la palabra", prev);

  //             return {
  //               current: prev.current + event.key,
  //               isFinished: false,
  //             };
  //           }
  //         }
  //       });
  //     }
  //   };

  //   // Agregar el evento de escucha al documento

  //   document.addEventListener("keydown", handleKeyDown);

  //   // Limpiar el evento de escucha al desmontar el componente
  //   return () => {
  //     document.removeEventListener("keydown", handleKeyDown);
  //   };
  // }, []);

  return (
    <Modal
      open={open}
      onClose={handleCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      onBackdropClick={handleCloseModal}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box sx={style}>
        <CloseIcon onClick={handleCloseModal} style={{ cursor: "pointer" }} />
        <Typography id="modal-modal-title" variant="h6" component="h2" align="center" sx={{ mb: 2 }}>
          Escanea el {codeType}
        </Typography>
        <QRCodeScanner onScan={handleScan} onError={handleError} codeType={codeType} />
        <Typography id="modal-modal-description" variant="body1" component="p" align="center" sx={{ mt: 2 }}>
          {result === "No result" ? null : (
            <ModalProducto
              isOpen={true}
              handleClose={() => setResult("No result")}
              cproducto={result.charAt(result.length - 1) === "*" ? result.slice(0, -1) : result}
            />
          )}
        </Typography>
      </Box>
    </Modal>
  )
}
