/* eslint-disable eqeqeq */
import React, { useEffect, useState, useCallback, useContext, useRef } from "react"
import Header from "../../../layouts/Header"
import { styled, createTheme, ThemeProvider } from "@mui/material/styles"
import { Box, TextField, InputLabel, Button, Modal, IconButton, Alert } from "@mui/material"
import CustomSelect from "../../SolicitudDeIngreso/components/CustomSelect"
import CustomInputDetail from "../../SolicitudDeIngreso/components/CustomInputDetail"
import BackIcon from "../../../components/BackIcon"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"
import fetchwrapper from "../../../services/interceptors/fetchwrapper"
import { useQuery, useQueryClient } from "@tanstack/react-query"
import Select from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"
import { TimePicker } from "@mui/x-date-pickers/TimePicker"
import GrabarIcon from "../../../assets/iconos/Grabar.ico"
import ExcelImportar from "../../../assets/iconos/ExcelImportar.ico"
import Swal from "sweetalert2"
import ModalUploadFileEgreso from "./ModalUploadFileEgreso"
import DetalleImpresion from "./DetalleImpresion"
import { useNavigate, Navigate } from "react-router-dom"
import CustomAutoComplete from "../../SolicitudDeIngreso/components/CustomAutoComplete"
import { MaterialReactTable } from "material-react-table"
import EliminarIcon from "../../../assets/iconos/Eliminar.ico"
import DetalleIcon from "../../../assets/iconos/Detalle.ico"
import ProductoAddIcon from "../../../assets/iconos/ProductoAdd.ico"
import CustomTooltip from "../../../components/ToolTip"
import BuscarIcon from "../../../assets/iconos/Buscar.ico"
import { FaImage } from "react-icons/fa6"
import { SolicitudEntregaContext } from "../SolicitudDeEntregaContext"
import RegresarIcon from "../../../assets/iconos/Regresar.ico"

const theme = createTheme({
  palette: {
    primary: {
      main: "#196C87",
    },
    secondary: {
      main: "#196C87",
    },
  },
})

const Container = styled(Box)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "repeat(12, 1fr)",
  gridTemplateRows: "auto auto auto",
  gridTemplateAreas: `
    "Solicitud Solicitud Solicitud FechaI FechaI FechaI Cliente Cliente Cliente Cliente Cliente Cliente"
    "Motivo Motivo Motivo Estado Estado Estado Proveedor Proveedor Proveedor Proveedor Proveedor Proveedor"
    "FechaL FechaL FechaL HoraL HoraL HoraL Comentario Comentario Comentario Comentario Comentario Comentario"
    "Detalle Detalle Detalle Detalle Detalle Detalle Detalle Detalle Detalle Detalle Detalle Detalle"
    "AgenciaSol AgenciaSol AgenciaSol AgenciaSol AgenciaEnv AgenciaEnv AgenciaEnv AgenciaEnv Contacto Contacto Contacto Contacto"
  `,
  gap: "8px",
  alignItems: "center",

  [theme.breakpoints.down("sm")]: {
    gridTemplateColumns: "repeat(6, 1fr)",
    gridTemplateAreas: `
      "Solicitud Solicitud Solicitud FechaI FechaI FechaI"
      "Cliente Cliente Cliente Cliente Cliente Cliente"
      "Motivo Motivo Motivo Estado Estado Estado"
      "Proveedor Proveedor Proveedor Proveedor Proveedor Proveedor"
      "FechaL FechaL FechaL HoraL HoraL HoraL"
      "Comentario Comentario Comentario Comentario Comentario Comentario"
      "Detalle Detalle Detalle Detalle Detalle Detalle"
      "AgenciaSol AgenciaSol AgenciaEnv AgenciaEnv Contacto Contacto"
    `,
  },
}))

const Solicitud = styled(Box)({
  gridArea: "Solicitud",
})

const FechaI = styled(Box)({
  gridArea: "FechaI",
})

const FechaL = styled(Box)({
  gridArea: "FechaL",
})

const HoraL = styled(Box)({
  gridArea: "HoraL",
})

const Cliente = styled(Box)({
  gridArea: "Cliente",
})

const Motivo = styled(Box)({
  gridArea: "Motivo",
})

const Estado = styled(Box)({
  gridArea: "Estado",
})

const Proveedor = styled(Box)({
  gridArea: "Proveedor",
})

const Detalle = styled(Box)({
  gridArea: "Detalle",
})

const Comentario = styled(Box)({
  gridArea: "Comentario",
})

const AgenciaSol = styled(Box)({
  gridArea: "AgenciaSol",
})

const AgenciaEnv = styled(Box)({
  gridArea: "AgenciaEnv",
})

const Contacto = styled(Box)({
  gridArea: "Contacto",
})

const CrearSolicitudEntregaComponent = () => {
  const moment = require("moment")
  const dayjs = require("dayjs")
  const [inputValue, setInputValue] = useState("")

  // clientes
  const [cliente, setCliente] = useState("")
  const [clienteAutoComplete, setClienteAutoComplete] = useState("")
  const [clienteDescription, setclienteDescription] = useState("")

  // agencias
  const [agenciaEnvio, setAgenciaEnvio] = useState("")
  const [agenciaEnvioDescription, setAgenciaEnvioDescription] = useState("")

  const [agenciaSolicita, setAgenciaSolicita] = useState("")
  const [agenciaSolicitaDescription, setAgenciaSolicitaDescription] = useState("")

  const [agencias, setAgencias] = useState([])

  // contactos
  const [contactos, setContactos] = useState([])
  const [contacto, setContacto] = useState([])
  const [contactoDescription, setContactoDescription] = useState([])

  // productos
  const [articulo, setArticulo] = useState("")
  const [articuloDescription, setarticuloDescription] = useState("")
  const [productos, setProductos] = useState([])

  // proveedor
  const [proveedor, setProveedor] = useState("")
  const [proveedorDescription, setproveedorDescription] = useState("")
  const [proveedores, setProveedores] = useState([])

  const [motivo, setMotivo] = useState("")
  const [fechaInicial, setFechaInicial] = useState(dayjs())
  const [fechaFinal, setFechaFinal] = useState(dayjs())
  const [llegada, setLlegada] = useState(null)
  const [detalle, setDetalle] = useState("")
  const [comenLlegada, setcomenLllegada] = useState("")
  const [open, setOpen] = useState(false)
  const [horaLlegada, setHoraLlegada] = useState(dayjs())
  const { data, refetch } = useGenerarNumSecuencia()
  const [filtrosMotivo, setFiltrosMotivo] = useState([])
  const { allFiltrosMotivo } = useGetAllFiltros()
  const divRef = useRef(null)
  const [infoGenerada, setInfoGenerada] = useState(null)
  const [clienteSeleccionado, setClienteSeleccionado] = useState(false)
  const [agenciaSeleccionada, setAgenciaSeleccionada] = useState(false)
  const [productoSeleccionado, setProductoSeleccionado] = useState(false)
  const [openImpresion, setOpenImpresion] = useState(false)

  const {
    data: dataClientes = [],
    isFetchingClientes,
    errorCliente,
    refetch: refetchGetAllClientes,
  } = useGetAllClientes()

  // proveedores por cliente
  const {
    data: dataProveedores = [],
    isError: dataProveedoresError,
    isFetching: isFetchingProveedores,
    isLoading: isLoadingProveedores,
    refetch: refetchGetAllProveedores,
  } = useGetAllProveedores(cliente)

  // productos en bodega del cliente
  const {
    data: dataProductos = [],
    isError: dataProductosError,
    isFetching: isFetchingDataProductos,
    isLoading: isLoadingProductos,
    refetch: refetchGetProductos,
  } = useGetProductosXCliente(cliente)

  const {
    data: dataProductosSecondary = [],
    isError: dataProductosErrorSecondary,
    isFetching: isFetchingDataProductosSecondary,
    isLoading: isLoadingProductosSecondary,
    refetch: refetchGetProductosSecondary,
  } = useGetProductosXClienteSecondary(cliente)

  // agencias por cliente
  const {
    data: dataAgencias = [],
    isError: dataAgenciasError,
    isFetching: isFetchingDataAgencias,
    isLoading: isLoadingAgencias,
    refetch: refetchGetAgencias,
  } = useGetAgenciasXCliente(cliente)

  // contactos por agencia
  const {
    data: dataContactos = [],
    isError: dataContactosError,
    isFetching: isFetchingDataContactos,
    isLoading: isLoadingContactos,
    refetch: refetchGetContactos,
  } = useGetContactosXAgencia(agenciaEnvio, cliente)

  const handleOpenDetalleImpresion = (data) => {
    setInfoGenerada(data)
    setOpenImpresion(true)
  }

  const handleCloseDetalleImpresion = () => {
    setOpenImpresion(false)
    // navigate("/home/dashboard/SolicitudDeEntrega")
    resetFormValues()
  }

  useEffect(() => {
    const element = divRef.current
    if (element) {
      element.classList.remove("css-1oo34r5") // Eliminar la clase específica
    }
  }, [])

  function useGetAllFiltros() {
    return useQuery({
      queryKey: ["solicitudDeEgresoGetAllFiltros"],
      queryFn: async () => {
        let response = await fetchwrapper("/solicitudDeEgreso/getAllFiltros")
        response = await response.json()
        setFiltrosMotivo(response.data)
        return response.data
      },
      refetchOnWindowFocus: false,
      enabled: true,
    })
  }

  function useGetAllProveedores(cliente) {
    return useQuery({
      queryKey: ["solicitudDeEgresoProveedoresCreate", cliente],
      queryFn: async () => {
        const response = await fetchwrapper(`/solicitudDeIngreso/getAllProveedores`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ cliente }),
        })
        const result = await response.json()
        return result.proveedores
      },
      enabled: clienteSeleccionado,
      onError: (error) => {
        console.log("Error fetching data", error)
      },
    })
  }

  function useGetAgenciasXCliente(cliente) {
    return useQuery({
      queryKey: ["solicitudDeEgresoAgenciasCreate", cliente],
      queryFn: async () => {
        const response = await fetchwrapper(`/solicitudDeEgreso/getAgenciaXCliente`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ cliente }),
        })
        const result = await response.json()
        return result.agencias
      },
      enabled: clienteSeleccionado,
      onError: (error) => {
        console.log("Error fetching data", error)
      },
    })
  }

  function useGetContactosXAgencia(agencia, cliente) {
    return useQuery({
      queryKey: ["solicitudDeEgresoContactosCreate", agencia, cliente],
      queryFn: async () => {
        const response = await fetchwrapper(`/solicitudDeEgreso/getContactosXAgencia`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ agencia, cliente }),
        })
        const result = await response.json()
        return result.contactos
      },
      enabled: agenciaSeleccionada,
      onError: (error) => {
        console.log("Error fetching data", error)
      },
    })
  }

  function useGetProductosXCliente(cliente) {
    return useQuery({
      queryKey: ["solicitudDeEgresoProductosCreate", cliente],
      queryFn: async () => {
        const response = await fetchwrapper(`/solicitudDeIngreso/getProductosXCliente`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ cliente }),
        })
        const result = await response.json()
        return result.productos
      },
      enabled: clienteSeleccionado,
      onError: (error) => {
        console.log("Error fetching data", error)
      },
    })
  }

  function useGetProductosXClienteSecondary(cliente) {
    return useQuery({
      queryKey: ["solicitudDeEntregaProductosSecondaryCreate", cliente],
      queryFn: async () => {
        const response = await fetchwrapper(`/solicitudDeIngreso/getCod2ArticuloXCliente`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ cliente }),
        })
        const result = await response.json()
        return result.data
      },
      enabled: clienteSeleccionado,
      onError: (error) => {
        console.log("Error fetching data", error)
      },
    })
  }

  function useGetAllClientes() {
    return useQuery({
      queryKey: ["solicitudDeEgresoClientesCreate"],
      queryFn: async () => {
        const response = await fetchwrapper(`/solicitudDeIngreso/getAllClientes`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        })
        const result = await response.json()
        return result.clientes
      },
      keepPreviousData: true,
      enabled: true,
      onError: () => {
        console.log("Error fetching data")
      },
    })
  }

  const handleSelect = (selectedValue) => {
    const [codigo, descripcion] = selectedValue.split("-")
    setClienteSeleccionado(true)
    setCliente(codigo)
    setclienteDescription(descripcion)
    setClienteAutoComplete(selectedValue)
    refetchGetAllProveedores()
    refetchGetProductos()
    refetchGetAgencias()
  }

  const handleSelectAgenciaEnvio = (selectedValue) => {
    const [codigo, descripcion] = selectedValue.split("-")
    setAgenciaSeleccionada(true)
    setAgenciaEnvio(codigo)
    setAgenciaEnvioDescription(descripcion)
    refetchGetContactos()
  }

  const handleSelectAgenciaSolicita = (selectedValue) => {
    const [codigo, descripcion] = selectedValue.split("-")
    setAgenciaSolicita(codigo)
    setAgenciaSolicitaDescription(descripcion)
  }

  useEffect(() => {
    setProveedores(dataProveedores)
    setProductos(dataProductos)
  }, [cliente])

  useEffect(() => {
    setContactos(dataContactos)
  }, [agenciaEnvio])

  useEffect(() => {
    setCliente(cliente)
    setclienteDescription(clienteDescription)
  }, [cliente])

  const handleChange = (event) => {
    setInputValue(event.target.value)
  }

  const handleMotivoChange = (event) => {
    setMotivo(event.target.value)
  }

  useEffect(() => {
    setProveedor(proveedor)
    setproveedorDescription(proveedorDescription)
  }, [proveedor])

  useEffect(() => {
    setAgenciaEnvio(agenciaEnvio)
    setAgenciaEnvioDescription(agenciaEnvioDescription)
  }, [agenciaEnvio])

  useEffect(() => {
    setAgenciaSolicita(agenciaSolicita)
    setAgenciaSolicitaDescription(agenciaSolicitaDescription)
  }, [agenciaSolicita])

  useEffect(() => {
    setContacto(contacto)
    setContactoDescription(contactoDescription)
  }, [contacto])

  const handleProveedorChange = (selectedValue) => {
    const [codigo, descripcion] = selectedValue.split("-")
    setProveedor(codigo)
    setproveedorDescription(descripcion)
  }

  const handleContactoChange = (selectedValue) => {
    const [codigo, descripcion] = selectedValue.split("-")
    setContacto(codigo)
    setContactoDescription(descripcion)
  }

  const handleFechaInicial = (newFecha) => {
    setFechaInicial(newFecha)
  }

  const handleFechaFinal = (newFecha) => {
    setFechaFinal(newFecha)
  }

  const handleDetalle = (event) => {
    setDetalle(event.target.value)
  }

  const handleComenLlegada = (event) => {
    setcomenLllegada(event.target.value)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const changeHourFormat = (dateString) => {
    const date = new Date(dateString)
    const year = 1900
    const month = 1
    const day = 1
    const hour = date.getHours()
    const minute = date.getMinutes()
    const second = date.getSeconds()

    const newDate = new Date(year, month, day, hour, minute, second)
    return newDate
  }

  const navigate = useNavigate()
  const { productosEgreso, setProductosEgreso } = useContext(SolicitudEntregaContext)

  const useCrearSolicitudDeEgreso = () => {
    const crearSolicitudDeEgreso = useCallback(async () => {
      const options = {
        method: "POST",
        body: JSON.stringify({
          codigoSolicitud: data,
          motivo,
          descripcion: detalle,
          comenenvio: comenLlegada,
          clicodigo: cliente,
          procodigo: proveedor,
          sgafecenvio: fechaFinal,
          sgahorenvio: changeHourFormat(horaLlegada),
          agenciaSolicita,
          agenciaEnvia: agenciaEnvio,
          sgastatus: "PENDIENTE",
          condescrienv: contactoDescription,
          articulos: productosEgreso,
          concodrelextenv: contacto,
        }),
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }

      let response = await fetchwrapper("/solicitudDeEgreso/crearSolicitudDeEgreso", options)
      response = await response.json()
      return response
    }, [motivo, detalle, comenLlegada, cliente, proveedor, fechaInicial, horaLlegada, productosEgreso])

    return crearSolicitudDeEgreso
  }

  const crearSolicitudDeEgreso = useCrearSolicitudDeEgreso()

  const handleCrearSolicitudDeEgreso = async () => {
    // verificar si todos los productos pertenecen a la bodega del cliente
    const allProductosPertenecen = verificarProductosBodega()
    if (
      !cliente ||
      !motivo ||
      !fechaInicial ||
      !horaLlegada ||
      !detalle ||
      !fechaFinal ||
      !agenciaEnvio ||
      !agenciaSolicita ||
      !contacto
    ) {
      Swal.fire({
        icon: "error",
        title: "Campos incompletos",
        text: "Por favor, completa todos los campos obligatorios.",
      })
    } else if (productosEgreso.length == 0) {
      Swal.fire({
        icon: "error",
        title: "Campos incompletos",
        text: "Por favor, ingresa al menos un producto.",
      })
    } else if (allProductosPertenecen.resultado) {
      Swal.fire({
        icon: "error",
        title: "Productos incorrectos",
        text: `Los productos con código ${allProductosPertenecen.codigosNoAceptados} no pertenecen a la bodega del cliente`,
      })
    } else {
      const response = await crearSolicitudDeEgreso()
      if (response) {
        Swal.fire({
          icon: "success",
          title: "Operación exitosa",
          text: "Se creó una Solicitud de Egreso",
        }).then(() => {
          handleOpenDetalleImpresion(response.data)
        })
      }
    }
  }

  const resetFormValues = async () => {
    // Volver a generar el número de secuencia
    await refetch()

    // Resetear los valores del formulario a sus estados iniciales
    setCliente("")
    setclienteDescription("")
    setProveedor("")
    setproveedorDescription("")
    setAgenciaEnvio("")
    setAgenciaEnvioDescription("")
    setAgenciaSolicita("")
    setAgenciaSolicitaDescription("")
    setMotivo("")
    setFechaInicial(dayjs())
    setFechaFinal(dayjs())
    setHoraLlegada(dayjs())
    setDetalle("")
    setcomenLllegada("")
    setContacto("")
    setContactoDescription("")
    setProductosEgreso([]) // Limpiar los productos
    setClienteSeleccionado(false)
    setAgenciaSeleccionada(false)
    setClienteAutoComplete("")
  }

  // verificar que todos los productos sean de la bodega del cliente
  const verificarProductosBodega = () => {
    const codigosIngresados = productosEgreso.map((producto) => producto.artcodigo)
    const codigosAceptados = new Set(dataProductos.map((producto) => producto.split("-")[0]))
    const codigosNoAceptados = new Set() // Usar Set para evitar duplicados

    for (const codigo of codigosIngresados) {
      if (!codigosAceptados.has(codigo)) {
        codigosNoAceptados.add(codigo) // Agregar al Set
      }
    }

    // Convertir el Set a un array y unir los elementos en un string
    if (codigosNoAceptados.size > 0) {
      return {
        resultado: true,
        codigosNoAceptados: Array.from(codigosNoAceptados).join(", "), // Convierte el Set a un array y luego a un string
      }
    }

    // Si todos los códigos son aceptados, retorna false
    return { resultado: false, codigosNoAceptados: "" }
  }

  function useGenerarNumSecuencia() {
    return useQuery({
      queryKey: ["solicitudDeEgresoNumSecuencia"],
      queryFn: async () => {
        const options = {
          method: "POST",
          body: JSON.stringify({
            codDocumento: "SE",
          }),
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }
        let response = await fetchwrapper("/solicitudDeIngreso/generarNumSecuencia", options)
        response = await response.json()
        response = response?.data
        return response
      },
      refetchOnWindowFocus: false,
      enabled: false,
    })
  }

  useEffect(() => {
    refetch()
  }, [data])

  return (
    <ThemeProvider theme={theme}>
      <Header />
      <div className="main main-app p-3 p-lg-4">
        <BackIcon />
        <div style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
          <div style={{ paddingTop: "10px", paddingBottom: "10px" }}>
            <CustomTooltip title={"Buscar Solicitud de Egreso"}>
              <Button
                style={{ fontSize: "0.9vw" }}
                onClick={() => navigate("/home/dashboard/SolicitudDeEntrega/buscar")}
              >
                <img src={BuscarIcon} alt="Crear" style={{ width: "40px" }} />
              </Button>
            </CustomTooltip>
          </div>
          <div style={{ display: "flex", alignItems: "center", gap: "16px", padding: "5px" }}>
            <CustomTooltip title={"Crear Solicitud de Entrega"}>
              <Button onClick={handleCrearSolicitudDeEgreso}>
                <img src={GrabarIcon} alt="Grabar" style={{ width: "40px" }} />
              </Button>
            </CustomTooltip>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "0 30px 30px 30px",
            fontSize: "25px",
          }}
        >
          <b>Crear Solicitud de Entrega</b>
        </div>
        <Container>
          <Solicitud>
            <InputLabel htmlFor="solicitud">Solicitud</InputLabel>
            <TextField id="solicitud" value={data} onChange={handleChange} required fullWidth disabled />
          </Solicitud>
          <FechaI>
            <InputLabel>Fecha *</InputLabel>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                sx={{ width: "100%" }}
                disabled
                value={fechaInicial}
                dateFormat="yyyy-MM-dd hh:mm:ss.SSSSSS"
                timeFormat="HH:mm:ss.SSSSSS"
                onChange={handleFechaInicial}
                renderInput={(params) => <TextField required {...params} />}
              />
            </LocalizationProvider>
          </FechaI>
          <Cliente>
            <InputLabel>Cliente</InputLabel>
            <CustomAutoComplete
              value={clienteAutoComplete}
              items={dataClientes}
              onSelect={handleSelect}
              placeholder={"Buscar cliente"}
            />
          </Cliente>
          <Motivo>
            <CustomSelect
              label="Motivo"
              value={motivo}
              onChange={handleMotivoChange}
              options={filtrosMotivo}
              fullWidth
              disabled
            />
          </Motivo>
          <Estado>
            <InputLabel>Estado</InputLabel>
            <Select value="PENDIENTE" fullWidth disabled>
              <MenuItem value="PENDIENTE">PENDIENTE</MenuItem>
            </Select>
          </Estado>
          <Proveedor>
            <InputLabel>Proveedor</InputLabel>
            {clienteSeleccionado ? (
              dataProveedores.length === 0 ? (
                <CustomTooltip title={"Este cliente no tiene proveedores"}>
                  <span>
                    <CustomAutoComplete
                      items={dataProveedores}
                      disabled={true}
                      onSelect={handleProveedorChange}
                      placeholder={"Buscar proveedor"}
                    />
                  </span>
                </CustomTooltip>
              ) : (
                <CustomAutoComplete
                  items={dataProveedores}
                  disabled={dataProveedores.length === 0 || cliente === ""}
                  onSelect={handleProveedorChange}
                  placeholder={"Buscar proveedor"}
                />
              )
            ) : (
              <CustomTooltip title={"Primero debe seleccionar un cliente"}>
                <span>
                  <CustomAutoComplete
                    items={dataProveedores}
                    disabled={true}
                    onSelect={handleProveedorChange}
                    placeholder={"Buscar proveedor"}
                  />
                </span>
              </CustomTooltip>
            )}
          </Proveedor>
          <Comentario>
            <InputLabel>Comentario de Envío</InputLabel>
            <TextField value={comenLlegada} onChange={handleComenLlegada} placeholder="" fullWidth />
          </Comentario>
          <FechaL>
            <InputLabel>Envío *</InputLabel>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                sx={{ width: "100%" }}
                value={fechaFinal}
                dateFormat="yyyy-MM-dd hh:mm:ss.SSSSSS"
                timeFormat="HH:mm:ss.SSSSSS"
                onChange={handleFechaFinal}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </FechaL>
          <HoraL>
            <InputLabel>Hora de Envío *</InputLabel>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <TimePicker
                sx={{ width: "100%" }}
                value={horaLlegada}
                onChange={(newValue) => {
                  if (newValue) {
                    setHoraLlegada(newValue)
                    changeHourFormat(newValue)
                  }
                }}
              />
            </LocalizationProvider>
          </HoraL>
          <Detalle>
            <InputLabel>Detalle *</InputLabel>
            <TextField value={detalle} onChange={handleDetalle} placeholder="" fullWidth />
          </Detalle>
          <AgenciaSol>
            <InputLabel>Agencia Solicita*</InputLabel>
            {clienteSeleccionado ? (
              dataAgencias.length === 0 ? (
                <CustomTooltip title={"Este cliente no tiene agencias registradas"}>
                  <span>
                    <CustomAutoComplete
                      items={dataAgencias}
                      disabled={true}
                      onSelect={handleSelectAgenciaSolicita}
                      placeholder={"Buscar agencia"}
                    />
                  </span>
                </CustomTooltip>
              ) : (
                <CustomAutoComplete
                  items={dataAgencias}
                  disabled={dataAgencias.length === 0 || cliente === ""}
                  onSelect={handleSelectAgenciaSolicita}
                  placeholder={"Buscar agencia"}
                />
              )
            ) : (
              <CustomTooltip title={"Primero debe seleccionar un cliente"}>
                <span>
                  <CustomAutoComplete
                    items={dataAgencias}
                    disabled={true}
                    onSelect={handleSelectAgenciaSolicita}
                    placeholder={"Buscar agencia"}
                  />
                </span>
              </CustomTooltip>
            )}
          </AgenciaSol>
          <AgenciaEnv>
            <InputLabel>Agencia Envía*</InputLabel>
            {clienteSeleccionado ? (
              dataAgencias.length === 0 ? (
                <CustomTooltip title={"Este cliente no tiene agencias registradas"}>
                  <span>
                    <CustomAutoComplete
                      items={dataAgencias}
                      disabled={true}
                      onSelect={handleSelectAgenciaEnvio}
                      placeholder={"Buscar agencia"}
                    />
                  </span>
                </CustomTooltip>
              ) : (
                <CustomAutoComplete
                  items={dataAgencias}
                  disabled={dataAgencias.length === 0 || cliente === ""}
                  onSelect={handleSelectAgenciaEnvio}
                  placeholder={"Buscar agencia"}
                />
              )
            ) : (
              <CustomTooltip title={"Primero debe seleccionar un cliente"}>
                <span>
                  <CustomAutoComplete
                    items={dataAgencias}
                    disabled={true}
                    onSelect={handleSelectAgenciaEnvio}
                    placeholder={"Buscar agencia"}
                  />
                </span>
              </CustomTooltip>
            )}
          </AgenciaEnv>
          <Contacto>
            <InputLabel>Contacto*</InputLabel>
            {clienteSeleccionado ? (
              agenciaSeleccionada ? (
                dataContactos.length === 0 ? (
                  <CustomTooltip title={"Esta agencia no tiene contactos registrados"}>
                    <span>
                      <CustomAutoComplete
                        items={dataContactos}
                        disabled={true}
                        onSelect={handleContactoChange}
                        placeholder={"Buscar contacto"}
                      />
                    </span>
                  </CustomTooltip>
                ) : (
                  <CustomAutoComplete
                    items={dataContactos}
                    disabled={dataContactos.length === 0 || cliente === ""}
                    onSelect={handleContactoChange}
                    placeholder={"Buscar contacto"}
                  />
                )
              ) : (
                <CustomTooltip title={"Debe seleccionar una agencia de envío"}>
                  <span>
                    <CustomAutoComplete
                      items={dataContactos}
                      disabled={true}
                      onSelect={handleContactoChange}
                      placeholder={"Buscar contacto"}
                    />
                  </span>
                </CustomTooltip>
              )
            ) : (
              <CustomTooltip title={"Primero debe seleccionar un cliente"}>
                <span>
                  <CustomAutoComplete
                    items={dataContactos}
                    disabled={true}
                    onSelect={handleContactoChange}
                    placeholder={"Buscar contacto"}
                  />
                </span>
              </CustomTooltip>
            )}
          </Contacto>
        </Container>
        <div className="col-sm-12">
          <DatagridCrearSolIng
            clienteSeleccionado={clienteSeleccionado}
            dataProductos={dataProductos}
            dataProductosSecondary={dataProductosSecondary}
            productos={dataProductos}
            cliente={cliente}
            openImpresion={openImpresion}
            handleCloseDetalleImpresion={handleCloseDetalleImpresion}
            infoGenerada={infoGenerada}
          />
        </div>
      </div>
    </ThemeProvider>
  )
}

const DatagridCrearSolIng = ({
  clienteSeleccionado,
  dataProductos,
  dataProductosSecondary,
  productos,
  cliente,
  openImpresion,
  handleCloseDetalleImpresion,
  infoGenerada,
}) => {
  const [data, setData] = useState([])
  const [articulo, setArticulo] = useState("")
  const [articuloDescription, setArticuloDescription] = useState("")
  const [openAddProducto, setOpenAddProducto] = useState(false)
  const [cantidadSolicitada, setCantidadSolicitada] = useState("")
  const [estado, setEstado] = useState("PENDIENTE")
  const [isEditing, setIsEditing] = useState(false)
  const [editingProductId, setEditingProductId] = useState(null)
  const [autoCompleteValue, setAutoCompleteValue] = useState("")
  const [openModal, setOpenModal] = useState(false)
  // imagen de los productos
  const [openImageModal, setOpenImageModal] = useState(false)
  const [selectedImageUrl, setSelectedImageUrl] = useState("")
  const [imageHex, setImageHex] = useState("")

  const {
    productoSeleccionado,
    productoSeleccionadoDescri,
    articuloUsaDecimales,
    setProductosEgreso,
    productosEgreso,
  } = useContext(SolicitudEntregaContext)

  const useGetProductoImages = (artcodigo) => {
    return useQuery({
      queryKey: ["solicitudDeEntregaImagenes", artcodigo],
      queryFn: async () => {
        if (!artcodigo || artcodigo.trim() === "") {
          throw new Error("El código del artículo está vacío o es inválido.")
        }

        const response = await fetchwrapper(`/solicitudDeIngreso/getImagesxArtcodigo/${artcodigo}`)
        const result = await response.json()
        return result?.data
      },
      refetchOnWindowFocus: false,
      enabled: !!artcodigo,
    })
  }

  const { data: imagenProducto = [], refetch: refetchGetProductoImages, error } = useGetProductoImages(imageHex)

  const handleProductoChange = (selectedValue) => {
    const [codigo, descripcion] = selectedValue.split("-")
    setArticulo(codigo)
    setArticuloDescription(descripcion)
    setAutoCompleteValue(selectedValue)
  }

  useEffect(() => {
    // setArticulo(productoSeleccionado)
    // setArticuloDescription(productoSeleccionadoDescri)
    setProductosEgreso(productosEgreso)
  }, [productoSeleccionado, productoSeleccionadoDescri, data, productosEgreso])

  useEffect(() => {
    setArticulo(articulo)
    setArticuloDescription(articuloDescription)
    setAutoCompleteValue(autoCompleteValue)
  }, [articulo, articuloDescription])

  const handleChangeArticulo = (event) => {
    // setArticulo(event.target.value)
  }

  const handleCloseModal = () => {
    setOpenModal(false)
  }

  const handleEditClick = (row) => {
    handleOpenAddProducto(row.original)
  }

  const handleClickCloseAddProducto = () => {
    handleCloseAddProducto()
    setArticulo("")
    setArticuloDescription("")
    setCantidadSolicitada("")
  }

  const handleOpenImageModal = (hex) => {
    setImageHex(hex)
    setOpenImageModal(true)
  }

  useEffect(() => {
    setImageHex(imageHex)
  }, [imageHex])

  useEffect(() => {
    refetchGetProductoImages(imageHex)
    // console.log(imagenProducto)
  }, [refetchGetProductoImages, imageHex, imagenProducto])

  const handleCloseImageModal = () => {
    setOpenImageModal(false)
    setSelectedImageUrl("")
  }

  // para el insert en la grilla
  const handleAddProductoDirecto = () => {
    // Verificar si el producto seleccionado pertenece a la bodega del cliente
    const productoBodega = dataProductos.find((producto) => producto.split("-")[0] === articulo)
    let productoBodegaSecondary = true
    let descripcionProducto = articuloDescription
    let codProducto = articulo

    if (dataProductosSecondary.length > 0) {
      productoBodegaSecondary = dataProductosSecondary.find((producto) => producto.artcodigo2 === articulo)

      if (!articuloDescription && productoBodegaSecondary) {
        descripcionProducto = productoBodegaSecondary.artdescri
        codProducto = productoBodegaSecondary.artcodigo
      }
    } else {
      productoBodegaSecondary = false
    }

    if (!articulo || cantidadSolicitada <= 0 || (!productoBodega && !productoBodegaSecondary)) {
      // Mostrar error si el producto no pertenece a la bodega o si la cantidad es inválida
      Swal.fire({
        icon: "error",
        title: "Error",
        text: !articulo
          ? "Debe seleccionar un producto."
          : cantidadSolicitada <= 0
            ? "La cantidad solicitada debe ser mayor a 0."
            : `El producto con código ${articulo} no pertenece a la bodega del cliente.`,
      })
    } else {
      const newRow = {
        numSecuencia: editingProductId || productosEgreso.length + 1,
        artcodigo: codProducto,
        artdescri: descripcionProducto,
        cantSolicitada: cantidadSolicitada,
        estado,
      }

      setProductosEgreso((prevProductos) => [...prevProductos, { ...newRow, numSecuencia: productosEgreso.length + 1 }])
      setArticulo("")
      setArticuloDescription("")
      setCantidadSolicitada("")
      setAutoCompleteValue("")
      setEstado("PENDIENTE")
      setIsEditing(false)
      setEditingProductId(null)
    }
  }

  // para la edición
  const handleOpenAddProducto = (product = null) => {
    if (product) {
      setArticulo(product.artcodigo)
      setArticuloDescription(product.artdescri)
      setCantidadSolicitada(product.cantSolicitada)
      setEstado(product.estado)
      setEditingProductId(product.numSecuencia)
      setIsEditing(true)
    } else {
      setArticulo("")
      setArticuloDescription("")
      setCantidadSolicitada("")
      setEstado("PENDIENTE")
      setEditingProductId(null)
      setIsEditing(false)
    }
    setOpenAddProducto(true)
  }

  const handleCloseAddProducto = () => {
    setOpenAddProducto(false)
    setArticulo("")
    setArticuloDescription("")
    setCantidadSolicitada("")
    setEstado("PENDIENTE")
    setIsEditing(false)
    setEditingProductId(null)
  }

  const handleCantidadSolicitada = (e) => {
    const value = e.target.value
    if (articuloUsaDecimales == 0) {
      if (/^\d*\.?\d*$/.test(value)) {
        setCantidadSolicitada(value)
      }
    } else {
      if (/^\d*$/.test(value)) {
        setCantidadSolicitada(value)
      }
    }
  }

  const handleAddSubmit = () => {
    if (articulo && cantidadSolicitada > 0 && estado) {
      const newRow = {
        numSecuencia: editingProductId || productosEgreso.length + 1,
        artcodigo: articulo,
        artdescri: articuloDescription,
        cantSolicitada: cantidadSolicitada,
        estado,
      }

      if (isEditing) {
        setProductosEgreso((prevProductos) =>
          prevProductos.map((item) => (item.numSecuencia === editingProductId ? newRow : item)),
        )
      } else {
        const newId = productosEgreso.length > 0 ? Math.max(...productosEgreso.map((item) => item.numSecuencia)) + 1 : 1
        setProductosEgreso((prevProductos) => [...prevProductos, { ...newRow, numSecuencia: newId }])
      }
      setArticulo("")
      setArticuloDescription("")
      setCantidadSolicitada("")
      setEstado("PENDIENTE")
      setIsEditing(false)
      setEditingProductId(null)
      handleCloseAddProducto()
    } else if (cantidadSolicitada <= 0) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Cantidad debe ser mayor a 0",
        allowOutsideClick: false,
        backdrop: false,
        focusConfirm: false,
      })
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Llene todos los campos",
        allowOutsideClick: false,
        backdrop: false,
        focusConfirm: false,
      })
    }
  }

  const handleDeleteClick = (row) => {
    setProductosEgreso(productosEgreso.filter((item) => item.numSecuencia !== row.numSecuencia))
  }

  const handleOpenModal = () => {
    setOpenModal(true)
  }

  const columns = [
    {
      id: "actions",
      header: "Actions",
      Cell: ({ row }) => (
        <div>
          <CustomTooltip title={"Editar"}>
            <IconButton onClick={() => handleEditClick(row)}>
              <img src={DetalleIcon} alt="Detalle" style={{ width: "30px" }} />
            </IconButton>
          </CustomTooltip>
          <CustomTooltip title={"Eliminar"}>
            <IconButton onClick={() => handleDeleteClick(row.original)}>
              <img src={EliminarIcon} alt="Eliminar" style={{ width: "30px" }} />
            </IconButton>
          </CustomTooltip>
        </div>
      ),
    },
    {
      accessorKey: "artcodigo",
      header: "Código",
    },
    {
      accessorKey: "artdescri",
      header: "Artículo",
    },
    {
      accessorKey: "cantSolicitada",
      header: "Cantidad Solicitada",
    },

    {
      accessorKey: "estado",
      header: "Estado",
    },
    {
      id: "imagen",
      header: "Imagen",
      Cell: ({ row }) => {
        const { data: imagenProducto = [] } = useGetProductoImages(row.original.artcodigo)
        const tieneImagen = imagenProducto.length > 0 // Verificar si hay una imagen

        return (
          <CustomTooltip title={tieneImagen ? "Ver imagen" : "No hay imagen disponible"}>
            <span>
              <IconButton onClick={() => handleOpenImageModal(row.original.artcodigo)} disabled={!tieneImagen}>
                <FaImage />
              </IconButton>
            </span>
          </CustomTooltip>
        )
      },
    },
  ]

  return (
    <div>
      <div style={{ display: "flex", flexDirection: "row", gap: "10px", paddingTop: "10px", paddingBottom: "10px" }}>
        <Box>
          <CustomTooltip title={"Importar artículos desde Excel"}>
            <Button onClick={handleOpenModal}>
              <img src={ExcelImportar} alt="Importar" style={{ width: "40px" }} />
            </Button>
          </CustomTooltip>
          {openModal && (
            <ModalUploadFileEgreso
              openModal={openModal}
              onCloseModal={handleCloseModal}
              dataProductos={dataProductos}
              dataProductosSecondary={dataProductosSecondary}
            />
          )}
          {openImpresion && (
            <DetalleImpresion
              open={openImpresion}
              handleClose={handleCloseDetalleImpresion}
              infoGenerada={infoGenerada}
            />
          )}
        </Box>
        <Modal id="modalAddProducto" open={openAddProducto} onClose={handleCloseAddProducto}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: {
                xs: "90vw",
                sm: "70vw",
                md: 600,
              },
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
            }}
          >
            <div style={{ marginBottom: 16 }}>
              <CustomInputDetail
                label="Artículo*"
                placeholder1={"Código"}
                placeholder2={"Nombres"}
                values={[articulo, articuloDescription]}
                onChangeHandlers={[handleChangeArticulo]}
                // ayuda producto
                fullWidth
                isEditing={isEditing}
                disabled={isEditing}
              />
            </div>

            <div style={{ display: "flex", gap: "16px", marginBottom: 16 }}>
              <div style={{ flex: 1 }}>
                <InputLabel htmlFor="cantidadSolicitada">Cantidad Solicitada*</InputLabel>
                <TextField
                  id="cantidadSolicitada"
                  value={cantidadSolicitada}
                  onChange={handleCantidadSolicitada}
                  fullWidth
                />
              </div>

              <div style={{ flex: 1 }}>
                <InputLabel>Estado</InputLabel>
                <Select value="PENDIENTE" fullWidth disabled>
                  <MenuItem value="PENDIENTE">PENDIENTE</MenuItem>
                </Select>
              </div>
            </div>
            <CustomTooltip title={"Guardar"}>
              <Button variant="text" color="primary" onClick={handleAddSubmit} sx={{ mt: 2 }}>
                <img src={GrabarIcon} alt="Guardar" style={{ width: "40px" }} />
              </Button>
            </CustomTooltip>
            <CustomTooltip title={"Cerrar"}>
              <Button variant="text" color="primary" onClick={handleClickCloseAddProducto} sx={{ mt: 2 }}>
                <img src={RegresarIcon} alt="Cerrar" style={{ width: "40px" }} />
              </Button>
            </CustomTooltip>
          </Box>
        </Modal>
        {clienteSeleccionado ? (
          dataProductos.length === 0 ? (
            <CustomTooltip title={"Este cliente no tiene productos en bodega"}>
              <span>
                <CustomAutoComplete
                  items={dataProductos}
                  value={autoCompleteValue}
                  disabled={true}
                  onSelect={handleProductoChange}
                  placeholder={"Buscar producto"}
                />
              </span>
            </CustomTooltip>
          ) : (
            <CustomAutoComplete
              items={productos}
              value={autoCompleteValue}
              disabled={dataProductos.length === 0 || cliente === ""}
              onSelect={handleProductoChange}
              placeholder={"Buscar producto"}
            />
          )
        ) : (
          <CustomTooltip title={"Primero debe seleccionar un cliente"}>
            <span>
              <CustomAutoComplete
                items={productos}
                value={autoCompleteValue}
                disabled={true}
                onSelect={handleProductoChange}
                placeholder={"Buscar producto"}
              />
            </span>
          </CustomTooltip>
        )}
        <TextField
          id="cantidadSolicitada"
          value={cantidadSolicitada}
          onChange={handleCantidadSolicitada}
          fullWidth
          placeholder="Cantidad Solicitada"
        />
        <CustomTooltip title={"Agregar Artículo"}>
          <Button onClick={() => handleAddProductoDirecto()}>
            <img src={ProductoAddIcon} alt="Agregar" style={{ width: "40px" }} />
          </Button>
        </CustomTooltip>
        <Select value="PENDIENTE" fullWidth disabled>
          <MenuItem value="PENDIENTE">PENDIENTE</MenuItem>
        </Select>
      </div>
      <MaterialReactTable columns={columns} data={productosEgreso} />
      <Modal
        open={openImageModal}
        onClose={handleCloseImageModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            maxWidth: "90vw",
            maxHeight: "90vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {openImageModal && (
            <div>
              <div>
                <h3>Imagen del Producto</h3>
              </div>
              {imagenProducto.length > 0 ? (
                <img
                  src={`data:image/jpeg;base64,${imagenProducto[0].artimagen}`}
                  alt="Imagen del artículo"
                  style={{ maxWidth: "100%", maxHeight: "100%" }}
                />
              ) : (
                <p>No hay imagen disponible</p>
              )}
              <div>
                <Button onClick={handleCloseImageModal}>Cerrar</Button>
              </div>
            </div>
          )}
          ;
        </Box>
      </Modal>
    </div>
  )
}

export default CrearSolicitudEntregaComponent
