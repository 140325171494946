import React, { useState, useContext } from "react"
import { AprobacionContextProvider } from "../AprobacionContext"
import Header from "../../../layouts/Header"
import { styled, createTheme, ThemeProvider } from "@mui/material/styles"

import BuscarSolicitudEntregaAprobacion from "../components/BuscarSolicitudEntregaAprobacion"

const theme = createTheme({
  palette: {
    primary: {
      main: "#196C87",
    },
    secondary: {
      main: "#196C87", // Cambia el color secundario a verde azulado
    },
  },
})

const BuscarSolicitudDeEntrega = () => {
  return (
    <AprobacionContextProvider>
      <ThemeProvider theme={theme}>
        <Header />
        <BuscarSolicitudEntregaAprobacion />
      </ThemeProvider>
    </AprobacionContextProvider>
  )
}

export default BuscarSolicitudDeEntrega
