import React, { useEffect } from "react"
import { Box, Typography } from "@mui/material"
import { GridActionsCellItem, DataGrid } from "@mui/x-data-grid"
import DeleteIcon from "@mui/icons-material/DeleteOutlined"
import { styled } from "@mui/material/styles"
import formatItemCart from "../../../helpers/proforma/formatItemCart"
import math from "../../utils/math"
import { useCartStore } from "../../../stores/cartStore"

/* *****************************************************
                          STYLES
****************************************************** */

const StyledRoot = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  marginTop: theme.spacing(8),
  flexGrow: 1,
  padding: "0 16px",
}))

const StyledDataGridContainer = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  overflowX: "auto",
}))

export default function EditableTableDetalleProforma({ detallesProforma, setDetallesProforma, setSpecificQuantity }) {
  const { remove, add } = useCartStore() // Importa la función add del carrito

  const handleDeleteClick = (id) => () => {
    remove(id)
    setDetallesProforma(detallesProforma.filter((row) => row.codigo !== id))
  }

  // *****************************************************
  //                       COLUMNS
  // ******************************************************

  const columnas = [
    {
      headerName: "Código Item",
      field: "codigo",
      sort: "asc",
      width: 150,
    },
    {
      headerName: "Descripción",
      field: "descripcion",
      sort: "asc",
      width: 350,
    },
    {
      headerName: "Cantidad",
      field: "totalToBuy",
      sort: "asc",
      width: 150,
      type: "number",
      editable: true,
      valueFormatter: (params) => params.value,
      preProcessEditCellProps: (params) => {
        const value = parseFloat(params.props.value)
        const formattedValue = value.toFixed(2) // Limitar a dos decimales

        return { ...params.props, value: formattedValue }
      },
    },
    {
      headerName: "Valor unitario",
      field: "price",
      sort: "asc",
      width: 150,
      renderCell: (params) => <div>${math.round(params.value, 2)}</div>,
    },
    {
      headerName: "IVA",
      field: "sysiva",
      sort: "asc",
      width: 150,
      renderCell: (params) => <div>{math.round(params.value, 0)}%</div>,
    },
    {
      headerName: "Subotal Bruto",
      field: "totalPrice",
      sort: "asc",
      width: 150,
      renderCell: (params) => <div>${math.round(params.value, 2)}</div>,
    },
    {
      headerName: "Valor IVA",
      field: "totalIVA",
      sort: "asc",
      width: 150,
      renderCell: (params) => <div>${math.round(params.value, 2)}</div>,
    },
    {
      headerName: "Subtotal Bruto + IVA",
      field: "totalPriceWithIVA",
      sort: "asc",
      width: 150,
      renderCell: (params) => <div>${math.round(params.value, 2)}</div>,
    },

    {
      headerName: "Porcentaje Descuento (%)",
      field: "porcentajeDescuento",
      sort: "asc",
      width: 180,
      type: "number",
      editable: (params) => !params.row?.hasDescuento,
      renderCell: (params) => (
        <div
          style={{
            pointerEvents: !params.row.hasDescuento ? "auto" : "none",
            backgroundColor: !params.row.hasDescuento ? "" : "#f0f0f0",
            color: !params.row.hasDescuento ? "" : "#999",
          }}
        >
          {params.value}%
        </div>
      ),
      valueFormatter: (params) => params.value,
      preProcessEditCellProps: (params) => {
        const value = parseFloat(params.props.value)
        const formattedValue = value.toFixed(2) // Limitar a dos decimales
        console.log("cambio", params)
        if (params.row.hasDescuento) {
          alert("No puede modificar este descuento")
          return { ...params.props, value: formattedValue } // Bloquear edición si hasDescuento es verdadero
        }

        return { ...params.props, value: formattedValue }
      },
    },
    {
      headerName: "Descuento Total",
      field: "descuentoTotal",
      sort: "asc",
      width: 150,
      renderCell: (params) => {
        console.log("cambio", params.value)
        return <div>${math.round(params.value, 2)}</div>
      },
    },
    {
      headerName: "Subtotal Neto",
      field: "totalPriceWithDescuento",
      sort: "asc",
      width: 250,
      // editable:
      renderCell: (params) => {
        console.log(params, "pruebaa")
        return <div>${math.round(params.value, 2)}</div>
      },
    },
  ]

  useEffect(() => {
    console.log(detallesProforma)
    console.log("dentro de tabla")
  }, [detallesProforma])

  const handleRowEdit = (newRow, oldRow) => {
    // Limitar el número de decimales a dos
    const formattedQuantity = parseFloat(newRow.totalToBuy)
    let formmattedPorcentajeDescuento = 0

    if (oldRow.hasDescuento) {
      formmattedPorcentajeDescuento = oldRow.porcentajeDescuento
    } else {
      formmattedPorcentajeDescuento = newRow.porcentajeDescuento
    }

    newRow.totalToBuy = formattedQuantity
    newRow.porcentajeDescuento = formmattedPorcentajeDescuento

    // Actualiza el estado de la tabla
    const updatedDetallesProforma = detallesProforma.map((row) =>
      row.codigo === newRow.codigo
        ? {
            ...row,
            totalToBuy: formattedQuantity,
            porcentajeDescuento: formmattedPorcentajeDescuento,
          }
        : row,
    )
    setDetallesProforma(updatedDetallesProforma)

    // Crea un objeto de producto con la nueva cantidad
    const updatedProduct = formatItemCart({
      ...newRow,
      pedcantidad: formattedQuantity,
      porcentajeDescuento: formmattedPorcentajeDescuento,
    })

    // Actualiza el carrito
    add(updatedProduct)

    return newRow
  }

  return (
    <Box className={StyledRoot}>
      <Box className={StyledDataGridContainer} sx={{ width: "100%" }}>
        {detallesProforma && detallesProforma.length > 0 ? (
          <DataGrid
            rows={detallesProforma}
            columns={columnas}
            getRowId={(detalle) => detalle.codigo}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
            pageSizeOptions={[5, 10, 25]}
            localeText={{
              noRowsLabel: "Dale click a agregar para añadir productos a la proforma",
            }}
            disableSelectionOnClick
            // isCellEditable={(params) => !params.row.hasDescuento}
            processRowUpdate={handleRowEdit} // Usa la función para manejar la edición de la fila
            onProcessRowUpdateError={(err) => console.log(err)}
          />
        ) : (
          <Typography variant="body1">No hay datos para mostrar </Typography>
        )}
      </Box>
    </Box>
  )
}
