import React, { useState } from "react"
import { styled, createTheme, ThemeProvider } from "@mui/material/styles"

import { InputLabel, TextField, Box, Button } from "@mui/material"
const CustomInputDetail = ({ label, values, onChangeHandlers, placeholder1, placeholder2, button, isEditing }) => {
  const [open, setOpen] = useState(false)
  const handleOpenAyudaArticulo = () => {
    setOpen(true)
  }

  const MainItemContainer = styled("div")(({ theme }) => ({
    width: "10vw",
    [theme.breakpoints.down("sm")]: {
      width: "30vw",
    },
  }))

  const SecundaryItemContainer = styled("div")(({ theme }) => ({
    width: "27vw",
    [theme.breakpoints.down("sm")]: {
      width: "60vw",
    },
  }))

  return (
    <Box display="flex" flexDirection="column">
      <InputLabel>{label}</InputLabel>
      <Box display="flex" gap="8px">
        <MainItemContainer>
          <TextField
            value={values[0]}
            onChange={onChangeHandlers[0]}
            placeholder={placeholder1}
            fullWidth
            disabled={isEditing}
          />
        </MainItemContainer>
        <SecundaryItemContainer>
          <TextField value={values[1]} onChange={onChangeHandlers[1]} placeholder={placeholder2} fullWidth disabled />
        </SecundaryItemContainer>
        {button}
      </Box>
    </Box>
  )
}

export default CustomInputDetail
