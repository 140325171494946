import { useEffect, useState } from "react"
import { LineChart } from "@mui/x-charts/LineChart"
import { Autocomplete, TextField, Button } from "@mui/material"
import fetchwrapper from "../../../services/interceptors/fetchwrapper"
import LoadingComponent from "../../../components/loadingComponent"
import { MultiSelect } from "react-multi-select-component"

import months from "../assets/months"
import years from "../assets/years"
import BuscarIcon from "../../../assets/iconos/Buscar.ico"

export default function BasicLine({ lineData }) {
  // const [allLocalidades, setAllLocalidades] = useState([])
  // const [selectedLocalidades, setSelectedLocalidades] = useState([])

  // const [selectedMonth, setSelectedMonth] = useState(null)
  // const [selectedYear, setSelectedYear] = useState(null)

  // const [lineData, setLineData] = useState([])
  // const [isLoadingLine, setIsLoadingLine] = useState(false)

  // useEffect(() => {
  //   const getAllLocalidades = async () => {
  //     const cliciausu = localStorage.getItem("cliciausu")
  //     const company = JSON.parse(localStorage.getItem("jwt"))?.seleccion

  //     const options = {
  //       method: "POST",
  //       body: JSON.stringify({
  //         user: cliciausu,
  //         seleccion: company,
  //       }),
  //       headers: {
  //         "Content-Type": "application/json",
  //         "Access-Control-Allow-Origin": "*",
  //       },
  //     }
  //     try {
  //       let response = await fetchwrapper("/login/get_localidad", options)
  //       response = await response.json()
  //       const multiselectData = convertDataToMultiSelectData(response)
  //       setAllLocalidades(multiselectData)
  //     } catch (e) {
  //       alert("No se encontraron localidades")
  //     }
  //   }

  //   getAllLocalidades()
  // }, [])

  // const convertDataToMultiSelectData = (data) => {
  //   const result = data.map((d, index) => ({
  //     id: index,
  //     label: d?.locdescri,
  //     value: d?.loccodigo,
  //   }))

  //   return result
  // }

  // const getVtasDiasLocalidadLine = async () => {
  //   if (!selectedMonth || !selectedYear || !selectedLocalidades) {
  //     alert("Complete los campos")
  //     return
  //   }
  //   try {
  //     const options = {
  //       method: "POST",
  //       body: JSON.stringify({
  //         loccodigos: selectedLocalidades,
  //       }),
  //       headers: {
  //         "Content-Type": "application/json",
  //         "Access-Control-Allow-Origin": "*",
  //       },
  //     }
  //     setIsLoadingLine(true)
  //     let response = await fetchwrapper(
  //       `/facturas_ventas/getFacVentasBar/${selectedMonth.value}/${selectedYear.value}`,
  //       options,
  //     )
  //     response = await response.json()

  //     setLineData(response?.data)

  //     console.log(response?.data)
  //   } catch (error) {
  //     alert("No se pudo encontrar data")
  //   } finally {
  //     setIsLoadingLine(false)
  //   }
  // }

  const legendPlacement = {
    legend: {
      position: {
        vertical: "middle",
        horizontal: "right",
      },
      direction: "column",
    },
    margin: {
      top: 20,
      right: 100,
    },
  }

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          margin: "0 30px 30px 30px",
          fontSize: "20px",
        }}
      ></div>

      <div style={{ display: "flex", justifyContent: "center", marginTop: "40px" }}>
        {lineData.length === 0 ? (
          "No existen datos"
        ) : (
          <LineChart
            width={600}
            height={300}
            series={lineData.map((line) => line)}
            xAxis={[
              {
                scaleType: "pointer",
                data: new Array(31).fill().map((_, index) => index + 1),
                label: "Precio de ventas VS días",
                min: 0,
                max: 31,
              },
            ]}
            {...legendPlacement}
          />
        )}
      </div>
    </div>
  )
}
