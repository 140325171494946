import React from "react"
import { InputLabel, FormControl, Select, MenuItem, Box } from "@mui/material"
import { styled } from "@mui/material/styles"

const CustomSelect = ({ label, value, onChange, options, fullWidth = false }) => {
  const StyledBox = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      width: "43vw",
    },
  }))

  return (
    <div className="col-sm-16">
      <InputLabel>{label}</InputLabel>
      <FormControl fullWidth={fullWidth}>
        <Select value={value} onChange={onChange} size="sm" displayEmpty>
          <MenuItem value="" disabled></MenuItem>
          {options.map((option, index) => (
            <MenuItem key={index} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  )
}

export default CustomSelect
