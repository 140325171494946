import React, { useEffect, useState } from "react"
import Header from "../../../../layouts/Header"
import { styled, createTheme, ThemeProvider } from "@mui/material/styles"
import { Box, Grid, TextField } from "@mui/material"

import BackIcon from "../../../../components/BackIcon"

import { useLocation, useNavigate } from "react-router-dom"
import { useQuery } from "@tanstack/react-query"
import fetchwrapper from "../../../../services/interceptors/fetchwrapper"
import DatagridProductos from "../../components/DatagridProductos"
import CustomBackdrop from "../../../../components/CustomBackdrop"
import CustomSelectComponent from "../../../../components/CustomSelectComponent"
import PalletsManagment from "../../components/PalletsManagment"
import IngresoInventarioReadable from "../../components/IngresoInventarioReadable"

const StyledRoot = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  marginTop: theme.spacing(8),
  flexGrow: 1,
  padding: "0 16px",
  height: "100vh",
}))

const theme = createTheme({
  palette: {
    primary: {
      main: "#196C87",
    },
    secondary: {
      main: "#196C87", // Cambia el color secundario a verde azulado
    },
  },
})
const BuscarTodosIngresosReadableAsignacionUbicacion = () => {
  const location = useLocation()
  const navigate = useNavigate()

  // -----------------------------------------------------

  const {
    data: fetchedProductos = [],
    isError: isLoadingProductosError,
    isFetching: isFetchingProductos,
    isLoading: isLoadingProductos,
    error: isFetchingProductosError,
  } = useGetPProductos()

  // READ hook (get InProductos from api)
  function useGetPProductos() {
    return useQuery({
      queryKey: ["ProductosAsignacionUbicacionReadableBuscar"],
      queryFn: async () => {
        // send api request here
        let response = await fetchwrapper(
          `/asignacionUbicacion/getAllIngresosCabeceraConUbicacion/${location.state.sgasoling}`, // Esta es la misma api que se usa en cuarentena para traer todos las cabeceras de ingreso asociados a una solicitud
        )
        response = await response.json()
        response = response?.data.ingresos
        return response
      },
      refetchOnWindowFocus: false,
    })
  }

  return (
    <ThemeProvider theme={theme}>
      <Header />
      <div className="main main-app p-3 p-lg-4">
        <BackIcon />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "0 30px 30px 30px",
            fontSize: "25px",
          }}
        >
          <b>INGRESOS</b>
        </div>

        <div style={{ textAlign: "center" }}>
          <p>
            <strong>Código de Solicitud:</strong> {location.state.sgasoling}
          </p>
          <br />
          <br />
        </div>

        <CustomBackdrop isLoading={isLoadingProductos || isFetchingProductos} />

        {isLoadingProductosError || isFetchingProductosError ? (
          <div>Error al obtener la información</div>
        ) : (
          <Box className={StyledRoot}>
            <IngresoInventarioReadable
              fetchedInfo={fetchedProductos}
              isLoadingInfoError={isLoadingProductosError}
              isLoadingInfo={isLoadingProductos}
              isFetchingInfo={isFetchingProductos}
            />
          </Box>
        )}
      </div>
    </ThemeProvider>
  )
}

export default BuscarTodosIngresosReadableAsignacionUbicacion
