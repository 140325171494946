import React, { useState, useEffect } from "react"
import Header from "../../../layouts/Header"
import { styled, createTheme, ThemeProvider } from "@mui/material/styles"
import { Box, Grid, Card, CardContent, Typography, Button, TextField } from "@mui/material"

import BackIcon from "../../../components/BackIcon"
import CustomBackdrop from "../../../components/CustomBackdrop"
import { useNavigate } from "react-router-dom"
import Obturador from "../../../components/Obturador/Obturador"

import fetchwrapper from "../../../services/interceptors/fetchwrapper"

import DatagridPalletsCrear from "../components/DatagridPalletsCrear"
import { useQuery } from "@tanstack/react-query"

import CustomMultiselectComponent from "../../../components/CustomMultiselectComponent"
import CustomDateRangePickerComponent from "../../../components/CustomDateRangePickerComponent"
import CustomChips from "../../../components/CustomChips"

const StyledRoot = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  marginTop: theme.spacing(8),
  flexGrow: 1,
  padding: "0 16px",
  height: "100vh",
}))

const theme = createTheme({
  palette: {
    primary: {
      main: "#196C87",
    },
    secondary: {
      main: "#196C87", // Cambia el color secundario a verde azulado
    },
  },
})

const CrearVerificacionQuimico = () => {
  // Loaded info about filtros
  const [filtrosLoaded, setFiltrosLoaded] = useState({
    Cliente: [],
    Proveedor: [],
    NumeroSolicitud: [],
  })

  // State about values in each cb  in filtros
  const [clientesFiltroSelected, setClientesFiltroSelected] = useState([])
  const [selectAllClientes, setSelectAllClientes] = useState(false)
  const [proveedoresFiltroSelected, setProveedoresFiltroSelected] = useState([])
  const [selectAllProveedores, setSelectAllProveedores] = useState(false)

  const [numerosSolicitudesFiltroSelected, setNumerosSolicitudesFiltroSelected] = useState([])
  const [selectAllNumSolicitudes, setSelectAllNumSolicitudes] = useState(false)

  const [rangoFechasCreacionFiltroSelected, setRangoFechasCreacionFiltroSelected] = useState([])
  const [rangoFechasLlegadaFiltroSelected, setRangoFechasLlegadaFiltroSelected] = useState([new Date(), new Date()])

  const [palletCode, setPalletCode] = useState("")

  const [allNumPalletsFiltro, setAllNumPalletsFiltro] = useState([])

  // -----------------------------------------------------
  const {
    data: fetchedFiltrosCB = {},
    isError: isLoadingFiltrosCBError,
    isFetching: isFetchingFiltrosCB,
    isLoading: isLoadingFiltrosCB,
  } = useGetFiltrosCB()

  // READ hook (get FiltrosCB from api)
  function useGetFiltrosCB() {
    return useQuery({
      queryKey: ["FiltrosVerificacionQuimicoCB"],
      queryFn: async () => {
        // send api request here
        let response = await fetchwrapper("/verificacionDocumental/getFiltersSolicitudIngreso")
        response = await response.json()
        response = response?.data
        return response
      },
      refetchOnWindowFocus: false,
    })
  }
  // -----------------------------------------------------

  // -----------------------------------------------------
  const {
    data: fetchedSolicitudesIngresos = [],
    isError: isLoadingSolicitudesIngresosError,
    isFetching: isFetchingSolicitudesIngresos,
    isLoading: isLoadingSolicitudesIngresos,
    refetch: refetchSolicitudesIngresos,
  } = useGetSolicitudesIngresos()

  // READ hook (get SolicitudesIngresos from api)
  function useGetSolicitudesIngresos() {
    return useQuery({
      queryKey: ["PalletsCrearVerificacionQuimico"],
      queryFn: async () => {
        const options = {
          method: "POST",
          body: JSON.stringify({
            Cliente: clientesFiltroSelected,
            Proveedor: proveedoresFiltroSelected,
            NumeroSolicitud: numerosSolicitudesFiltroSelected,
            RangoFechasCreacion: rangoFechasCreacionFiltroSelected,
            RangoFechasLlegada: rangoFechasLlegadaFiltroSelected,
            Pallet: palletCode,
            NumerosPallets: allNumPalletsFiltro,
          }),
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }
        // send api request here
        let response = await fetchwrapper("/verificacionQuimico/getPalletsPorVerificar", options)
        response = await response.json()
        response = response?.data
        return response
      },
      refetchOnWindowFocus: false,
      // enabled: false,
    })
  }
  // -----------------------------------------------------

  // when all data of filtros loaded, set state in loaded filtros
  useEffect(() => {
    if (fetchedFiltrosCB.clientes && fetchedFiltrosCB.proveedores && fetchedFiltrosCB.codigosSolicitudes) {
      setFiltrosLoaded({
        Cliente: fetchedFiltrosCB.clientes,
        Proveedor: fetchedFiltrosCB.proveedores,
        NumeroSolicitud: fetchedFiltrosCB.codigosSolicitudes,
      })
    }
  }, [fetchedFiltrosCB])

  const isValidCodePallet = (code) => {
    const formatCode = String(code).trim()
    const codeChars = formatCode.substring(0, 3)
    const codeNums = formatCode.substring(3)

    return formatCode !== null && formatCode !== "" && codeChars === "PLA" && /^\d+$/.test(codeNums)
  }

  const searchPallet = async (e, palletCode, data = {}) => {
    if (e) e.preventDefault()
    const navigateData = {
      palletId: palletCode,
      ...data,
    }

    if (isValidCodePallet(navigateData?.palletId)) {
      setPalletCode(navigateData.palletId)

      await refetchSolicitudesIngresos()
    } else {
      alert("Código de pallet inválido")
    }
  }

  const textIsAlreadyDecodedPallet = (infoRead) => {
    const textDecoded = infoRead?.barcodeDataText

    if (textDecoded) {
      const data = {
        type: "barcodePallet",
        palletId: `PLA${textDecoded}`,
      }

      searchPallet(null, data.palletId, data)
    } else {
      throw new Error("")
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <Header />
      <div className="main main-app p-3 p-lg-4">
        <BackIcon />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "0 30px 30px 30px",
            fontSize: "25px",
          }}
        >
          <b>Verificación Químico</b>
        </div>

        <CustomBackdrop
          isLoading={
            isLoadingFiltrosCB || isFetchingFiltrosCB || isLoadingSolicitudesIngresos || isFetchingSolicitudesIngresos
          }
        />

        <Box className={StyledRoot}>
          <div>
            {/* <MultiSelect
              className="multiselect"
              options={filtrosLoaded.Cliente}
              value={clientesFiltroSelected}
              isLoading={isLoadingFiltrosCB || isFetchingFiltrosCB}
              onChange={setClientesFiltroSelected}
              labelledBy="Select"
              overrideStrings={{
                selectAll: "Seleccionar todo",
                selectSomeItems: "Selecciona los clientes",
                allItemsAreSelected: "Todas los items fueron seleccionados",
              }}
            /> */}

            <div
              style={{
                display: "grid",
                gap: "16px",
                maxWidth: "1200px",
                margin: "0 auto",
              }}
            >
              <CustomMultiselectComponent
                options={filtrosLoaded.Cliente}
                value={clientesFiltroSelected}
                setValue={setClientesFiltroSelected}
                selectAll={selectAllClientes}
                setSelectAll={setSelectAllClientes}
                placeholder="Selecciona los clientes"
              />

              <CustomChips
                value={allNumPalletsFiltro}
                setValue={setAllNumPalletsFiltro}
                placeholder="Digite las códigos de pallets"
              />

              {/* <CustomMultiselectComponent
                options={filtrosLoaded.NumeroSolicitud}
                value={numerosSolicitudesFiltroSelected}
                setValue={setNumerosSolicitudesFiltroSelected}
                selectAll={selectAllNumSolicitudes}
                setSelectAll={setSelectAllNumSolicitudes}
                placeholder="Seleccione las solicitudes"
              /> */}

              {/* <CustomMultiselectComponent
                options={filtrosLoaded.Proveedor}
                value={proveedoresFiltroSelected}
                setValue={setProveedoresFiltroSelected}
                selectAll={selectAllProveedores}
                setSelectAll={setSelectAllProveedores}
                placeholder="Seleccione los Proveedores"
              /> */}
            </div>

            <div
              style={{
                display: "grid",
                gap: "16px",
                maxWidth: "1200px",
                margin: "20px auto",
              }}
            >
              <CustomDateRangePickerComponent
                value={rangoFechasCreacionFiltroSelected}
                setValue={setRangoFechasCreacionFiltroSelected}
                placeholder="Rango de fechas de creación solicitud"
                size="lg"
              />

              {/* <CustomDateRangePickerComponent
                value={rangoFechasLlegadaFiltroSelected}
                setValue={setRangoFechasLlegadaFiltroSelected}
                placeholder="Rango de fechas de llegada solicitud"
                size="lg"
              /> */}
            </div>
          </div>

          {/* <div>
            <TextField
              fullWidth
              label="Código de pallet"
              id="codigoPallet"
              value={palletCode}
              onChange={(event) => {
                setPalletCode(event.target.value);
              }}
              style={{
                marginBottom: "10px",
              }}
            />

            <Obturador
              scannerParams={{
                msg: "Escanea el código de barras",
                codeType: "bar",
              }}
              executeFunction={textIsAlreadyDecodedPallet}
            />

            <br />
            <br />
          </div> */}

          <div>
            <Button variant="outlined" color="primary" onClick={refetchSolicitudesIngresos}>
              Filtrar
            </Button>
          </div>
          <DatagridPalletsCrear
            fetchedInfo={fetchedSolicitudesIngresos}
            isLoadingInfoError={isLoadingSolicitudesIngresosError}
            isLoadingInfo={isLoadingSolicitudesIngresos}
            isFetchingInfo={isFetchingSolicitudesIngresos}
          />
        </Box>
      </div>
    </ThemeProvider>
  )
}

export default CrearVerificacionQuimico
