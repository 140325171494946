import React, { useEffect, useState, useContext } from "react"
import { useNavigate } from "react-router-dom"

import Header from "../../../layouts/Header"
import { styled, createTheme, ThemeProvider } from "@mui/material/styles"
import {
  Box,
  TextField,
  InputLabel,
  Button,
  Typography,
  FormControl,
  Select,
  FormHelperText,
  MenuItem,
  Tooltip,
} from "@mui/material"
import ClientePdf from "./ClientePdf"
import DetalleImpresion from "../../Zonas/components/DetalleImpresion"
import Swal from "sweetalert2"
import ImprimirIcon from "../../../assets/iconos/Impresion.ico"
import BackIcon from "../../../components/BackIcon"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"
import fetchwrapper from "../../../services/interceptors/fetchwrapper"
import { useQuery, queryClient, useQueryClient, useMutation } from "@tanstack/react-query"
import CustomAutoComplete from "../../SolicitudDeIngreso/components/CustomAutoComplete"
import CrearIcon from "../../../assets/iconos/Crear.ico"
import BuscarIcon from "../../../assets/iconos/Buscar.ico"
import CustomTooltip from "../../../components/ToolTip"
import Accordion from "@mui/material/Accordion"
import AccordionDetails from "@mui/material/AccordionDetails"
import AccordionSummary from "@mui/material/AccordionSummary"
import ExpandMore from "@mui/icons-material/ExpandMore"
import { SelectAllSharp } from "@mui/icons-material"
import ClienteGrid from "./ClienteGrid"
import EjecutarAccionIcon from "../../../assets/iconos/EjecutarAccion.ico"
// Campos de busqueda
const ClienteBox = styled(Box)({
  gridArea: "Cliente",
})
const NombreBox = styled(Box)({
  gridArea: "Nombre",
})
const IdBox = styled(Box)({
  gridArea: "Id",
})
const EstadoBox = styled(Box)({
  gridArea: "Estado",
})
// Contenedor de campos de busqueda
const Container = styled(Box)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "repeat(12, 1fr)",
  gridTemplateRows: "auto auto auto",
  gridTemplateAreas: `
    "Cliente Cliente Nombre Nombre Nombre Nombre Id Id Id Id Estado Estado"
  `,
  gap: "8px",
  alignItems: "center",

  [theme.breakpoints.down("sm")]: {
    gridTemplateColumns: "repeat(6, 1fr)",
    gridTemplateAreas: `
      "Cliente Cliente Cliente Nombre Nombre Nombre"
      "Id Id Id Estado Estado Estado"
    
    `,
  },
}))
const BuscarClienteComponent = () => {
  const [expanded, setExpanded] = useState(false)
  // estados de los filtros
  const [error, setError] = useState(false)
  const [id, setId] = useState("")
  const [codigo, setCodigo] = useState("")
  const [nombre, setNombre] = useState("")
  const [searchAttempted, setSearchAttempted] = useState(false)
  const [clientes, setClientes] = useState([])
  const [isFirstTime, setFirstTime] = useState(true)
  const [openModal, setOpenModal] = useState(false)
  const [infoGenerada, setInfoGenerada] = useState([])

  const handleCodigoCliente = (event) => {
    setCodigo(event.target.value)
  }
  useEffect(() => {
    setCodigo(codigo)
    console.log(codigo)
  }, [codigo])
  const handleNombreCliente = (event) => {
    setNombre(event.target.value)
  }
  useEffect(() => {
    setNombre(nombre)
    console.log(nombre)
  }, [nombre])
  const handleExpandAccordion = () => {
    setExpanded((prevExpanded) => !prevExpanded)
  }

  const Estados = [
    { value: "A", description: "ACTIVO" },
    { value: "I", description: "INACTIVO" },
  ]

  const [estadoCliente, setEstadoCliente] = useState(Estados[0])
  function useGetClientesXFiltro() {
    return useQuery({
      queryKey: ["clientesXFiltro"],
      queryFn: async () => {
        const options = {
          method: "POST",
          body: JSON.stringify({
            codigoCliente: codigo,
            nombreCliente: nombre,
            numeroId: id,
            estadoCliente: estadoCliente.value,
          }),
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }
        console.log("json", options.body)
        // send api request here
        let response = await fetchwrapper("/AsociacionCliBod/getClientesFiltro", options)
        response = await response.json()
        console.log(response)
        return response.clientes
      },
      refetchOnWindowFocus: false,
      refetchOnReconnect: false, // Añade esta línea para evitar refetch al reconectar
      enabled: !searchAttempted && !isFirstTime,
    })
  }
  const resetAllFilters = () => {
    setId("")
    setCodigo("")
    setNombre("")
    setEstadoCliente(Estados[0])
  }
  const {
    data: fetchedClientes = [],
    isError: isLoadingClientesError,
    isFetching: isFetchingClientes,
    isLoading: isLoadingClientes,
    refetch: refetchClientes,
  } = useGetClientesXFiltro()
  const handleSearch = () => {
    setSearchAttempted(true)
    setFirstTime(false)
    setExpanded(false)
    refetchClientes()
    console.log("refetch", refetchClientes)
    resetAllFilters()
    console.log("fetchedClientes", fetchedClientes)
  }

  const navigate = useNavigate()
  const theme = createTheme({
    palette: {
      primary: {
        main: "#196C87",
      },
      secondary: {
        main: "#196C87",
      },
    },
  })

  // setea el valor de M3 y maneja que el valor sea numerico
  const handleNumericInput = (value, setValue) => {
    // Allow empty value, integers, or decimals
    if (/^\d*\.?\d*$/.test(value)) {
      setValue(value)
      setError(false) // No error if the input is valid
    } else {
      setError(true) // Show error if the input is invalid
    }
    console.log(id)
  }
  useEffect(() => {
    setId(id)
    console.log(id)
  }, [id])
  useEffect(() => {
    console.log(searchAttempted)
    // Actualiza el estado de clientes con los datos recibidos
    if (searchAttempted) {
      setClientes(fetchedClientes)
    }
  }, [searchAttempted, fetchedClientes])
  useEffect(() => {
    // Verifica si la búsqueda fue intentada y los datos ya no están cargándose
    if (searchAttempted && !isLoadingClientes && !isFetchingClientes && !isFirstTime) {
      console.log("filtros limpioss")
      resetAllFilters()
    }
  }, [isFetchingClientes, isLoadingClientes, searchAttempted])

  const handleOpenModal = () => {
    setInfoGenerada(fetchedClientes) // Asigna los datos de la tabla al estado
    setOpenModal(true)
  }
  console.log("info pdf", fetchedClientes)
  const handleCloseModal = () => {
    setOpenModal(false)
    navigate("/home/dashboard/AsociacionBodegasCliente")
  }
  return (
    <ThemeProvider theme={theme}>
      <Header />

      <div className="main main-app p-3 p-lg-4">
        <BackIcon />
        <div style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
          <div style={{ paddingTop: "10px", paddingBottom: "10px" }}>
            <CustomTooltip title={"Buscar Cliente"}>
              <Button style={{ fontSize: "0.9vw" }} onClick={handleSearch}>
                <img src={EjecutarAccionIcon} alt="Crear" style={{ width: "40px" }} />
              </Button>
            </CustomTooltip>
            <DetalleImpresion
              open={openModal}
              handleClose={handleCloseModal}
              infoGenerada={fetchedClientes}
              nombrePdf={"Clientes"}
              PdfComponent={ClientePdf}
            />
          </div>
        </div>
        <div style={{ paddingBottom: "10px" }}>
          <Accordion expanded={expanded} onChange={handleExpandAccordion} slotProps={{ heading: { component: "h2" } }}>
            <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1a-content" id="panel1a-header">
              <Typography variant="h6" style={{ width: "100%", textAlign: "center" }}>
                Criterios de búsqueda
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Container>
                <ClienteBox>
                  <InputLabel>Cliente</InputLabel>
                  <TextField fullWidth value={codigo} onChange={handleCodigoCliente} />
                </ClienteBox>
                <NombreBox>
                  <InputLabel>Nombre</InputLabel>
                  <TextField fullWidth value={nombre} onChange={handleNombreCliente} />
                </NombreBox>
                <IdBox>
                  <InputLabel>Identificacion</InputLabel>
                  <FormControl fullWidth error={error}>
                    <TextField
                      value={id}
                      onChange={(e) => handleNumericInput(e.target.value, setId)}
                      error={error} // Set error state in TextField
                      helperText={error ? "Por favor ingresa solo valores numéricos." : ""}
                    />
                    {error && <FormHelperText>El valor ingresado no es válido.</FormHelperText>}
                  </FormControl>
                </IdBox>
                <EstadoBox>
                  <InputLabel>Estado</InputLabel>
                  <Select
                    fullWidth
                    disabled
                    labelId="estadoClientesCB"
                    value={estadoCliente.value}
                    onChange={(e) => {
                      const selectedValue = e.target.value
                      const clienteestado = Estados.find((estado) => estado.value === selectedValue)
                      setEstadoCliente(clienteestado)
                      //   clearAll(tipoPregunta)
                    }}
                  >
                    {Estados.map((estado) => (
                      <MenuItem key={estado.value} value={estado.value}>
                        {estado.description}
                      </MenuItem>
                    ))}
                  </Select>
                </EstadoBox>
              </Container>
            </AccordionDetails>
          </Accordion>
        </div>
        <ClienteGrid
          fetchedInfo={clientes}
          isLoadingInfoError={isLoadingClientesError}
          isLoadingInfo={isLoadingClientes}
          isFetchingInfo={isFetchingClientes}
          searchAttempted={searchAttempted}
          setSolicitudesIngresos={setClientes}
          refetch={refetchClientes}
        ></ClienteGrid>
      </div>
    </ThemeProvider>
  )
}
export default BuscarClienteComponent
