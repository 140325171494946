import React, { useEffect, useState, useContext } from "react"
import Header from "../../../layouts/Header"
import { styled, createTheme, ThemeProvider } from "@mui/material/styles"

import CrearSolicitudEntregaComponent from "../components/CrearSolicitudEntregaComponent"
import { SolicitudDeEntregaContext, SolicitudEntregaContextProvider } from "../SolicitudDeEntregaContext"

const theme = createTheme({
  palette: {
    primary: {
      main: "#196C87",
    },
    secondary: {
      main: "#196C87", // Cambia el color secundario a verde azulado
    },
  },
})

const CrearSolicitudEntrega = () => {
  return (
    <SolicitudEntregaContextProvider>
      <ThemeProvider theme={theme}>
        <Header />
        <CrearSolicitudEntregaComponent />
      </ThemeProvider>
    </SolicitudEntregaContextProvider>
  )
}

export default CrearSolicitudEntrega
