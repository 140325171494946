/* eslint-disable camelcase */
import React, { useEffect, useState } from "react"
import { Box, Grid, TextField, Paper, Typography, Button, Modal, IconButton, Tooltip } from "@mui/material"
import { format } from "date-fns" // Importa la función de formato de fecha de date-fns

import { createTheme, ThemeProvider } from "@mui/material/styles"

import Header from "../../../layouts/Header"

import CrearProforma from "./CrearProforma"
import CreateProformaModal from "../../FiltrarProformas/components/CreateProformaModal"

import math from "../../utils/math"
import ModalAddProduct from "./ModalAddProduct"
import EditableTableDetalleProforma from "./EditableTableDetalleProforma"
import normalFormatDate from "../../utils/date/DDMMYYYFormatDate"
import BackIcon from "../../../components/BackIcon"
import { useQuery, useMutation } from "@tanstack/react-query"
import fetchwrapper from "../../../services/interceptors/fetchwrapper"
import CustomBackdrop from "../../../components/CustomBackdrop"
import AutocompleteClientes from "./AutocompleteClientes"
import ModalSearchClient from "./ModalSearchClient"
import ModalCreateClient from "./ModalCreateClient"
import InputSelectorFormaPago from "./InputSelectorFormaPago"
import InputSelectorVendedor from "./InputSelectorVendedor"

import { setQuantity, useCartStore } from "../../../stores/cartStore"
import { useNavigate } from "react-router-dom"
import formatItemCart from "../../../helpers/proforma/formatItemCart"
import { FcPlus, FcSearch } from "react-icons/fc"

// Estilos para Datagrid y botones de filtros
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  height: "90vh",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
}
const styleModalAddProduct = {
  position: "fixed",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "70vw",
  height: "70vh",
  maxHeight: "70vh", // Establece una altura máxima para el modal para asegurar que no se desborde del viewport
  overflowY: "auto", //
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
}

// Estilos para el color
const theme = createTheme({
  palette: {
    primary: {
      main: "#E8DFD1",
    },
    secondary: {
      main: "#196C87", // Cambia el color secundario a verde azulado
    },
  },
})

const styleModalCreateClient = {
  position: "fixed",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100vw",
  height: "100vh",
  maxHeight: "80vh",
  overflowY: "auto",
  backgroundColor: "white",
  border: "2px solid #000",
  boxShadow: 24,
  pt: 2,
  px: 0,
  pb: 3,
  [theme.breakpoints.up("md")]: {
    width: "70vw",
    height: "80vh",
    px: 4,
  },
}

const CuerpoProforma = (
  // eslint-disable-next-line no-empty-pattern
  {
    // idProforma,
    // cabeceraProforma,
    // setCabeceraProforma,
    // detallesProforma,
    // setDetallesProforma,
    // setSpecificQuantity,
    // getTotalsPrices,
  },
) => {
  const navigate = useNavigate()

  const { clearUseCartStoreStorage, setSpecificQuantity } = useCartStore()

  const [cabeceraProforma, setCabeceraProforma] = useState({})
  const [detalleProforma, setDetalleProforma] = useState([])
  // const [idProforma, setIdProforma] = useState("");
  const [crearProforma, setCrearProforma] = useState(false)
  const [isLoadingDetalle, setIsLoadingDetalle] = useState(true)

  const [isLoadingPreProforma, setIsLoadingPreProforma] = useState(false)

  /* const getTotalsPrices = (cart) => {
    const subTotal = cart.reduce(
      (partialSum, value) => partialSum + value.totalPrice,
      0
    );
    const IVA = cart.reduce(
      (partialSum, value) => partialSum + value.totalIVA,
      0
    );
    const total = cart.reduce(
      (partialSum, value) => partialSum + value.totalPriceWithIVA,
      0
    );

    return {
      subTotal,
      IVA,
      total,
    };
  }; */

  const setSpecificQuantityy = (idProduct, cart, quantity) => {
    setSpecificQuantity(idProduct, quantity)
    // const updatedCart = setQuantity(idProduct, cart, quantity);
    // console.log(updatedCart,".....final")
    // setDetalleProforma(updatedCart);
  }

  const clearProforma = () => {
    setCabeceraProforma({})
    setDetalleProforma([])
  }
  useEffect(() => {
    // clearProforma();
    // clearUseCartStoreStorage();
  }, [])

  const [totalPrices, setTotalPrices] = useState({
    subTotal: 0,
    IVA: 0,
    total: 0,
    subtotalNeto: 0,
  })

  const [fechaEmision, setFechaEmision] = useState(new Date())
  const [comment, setComment] = useState("")

  const [openModalAddProduct, setOpenModalAddProduct] = React.useState(false)
  const handleOpenModalAddProduct = () => {
    setOpenModalAddProduct(true)
  }
  const handleCloseModalAddProduct = () => {
    // clearUseCartStoreStorage();
    setOpenModalAddProduct(false)
  }

  const [openModalSearchClient, setOpenModalSearchClient] = useState(false)
  const handleOpenModalSearchClient = () => {
    setOpenModalSearchClient(true)
  }
  const handleCloseModalSearchClient = () => {
    console.log(clienteInfo)
    console.log(cabeceraProforma)

    setOpenModalSearchClient(false)
  }

  const [openModalCreateClient, setOpenModalCreateClient] = useState(false)
  const handleOpenModalCreateClient = () => setOpenModalCreateClient(true)
  const handleCloseModalCreateClient = () => setOpenModalCreateClient(false)

  /* Logica para crear la proforma */
  const {
    cart,
    idProforma,
    getTotalsPrices,
    // setIdProformaCart,
    setAllCart,
    enableProformaProcess,
  } = useCartStore()
  // const [isLoadingProforma, setIsLoadingProforma] = useState(false);

  useEffect(() => {
    setIsLoadingDetalle(true) // Cuando se completa la actualización

    const detalle = cart

    // Generate cabecera
    const totalProductsWithoutIVA = cart.reduce(
      (partialSum, value) => (value.sysiva === 0 ? partialSum + 1 : partialSum),
      0,
    )
    const totalProductsWithIVA = cart.length - totalProductsWithoutIVA

    const { subTotal, IVA, total, subtotalNeto, descuentoTotal } = getTotalsPrices(detalleProforma)
    const currentDate = new Date() // Obtener la fecha actual
    const formattedDate = currentDate.toUTCString() // Formatear la fecha como "Sun, 03 Mar 2024 00:00:00 GMT"

    const subtotalDeProductsWithoutIva = cart.reduce(
      (partialSum, value) => (value.sysiva === 0 ? value.totalPrice : partialSum),
      0,
    )

    const subtotalDeProductsWitIva = math.round(subTotal - subtotalDeProductsWithoutIva, 2)

    const cabecera = {
      // pedtivacer: totalProductsWithoutIVA,
      // pedtivapor: totalProductsWithIVA,
      pedtivacer: math.round(subtotalDeProductsWithoutIva, 2),
      pedtivapor: math.round(subtotalDeProductsWitIva, 2),
      pedsubtot: math.round(subTotal, 2),
      pediva: math.round(IVA, 2),
      pedtotal: math.round(total, 2),
      ped_detalle: comment,
      pedfecven: formattedDate,
      pedfecemi: formattedDate,
      subtotalNeto,
      subtotalBruto: subTotal,
      descuentoTotal,
    }

    setCabeceraProforma((prevCabecera) => ({
      ...prevCabecera, // Maintain previous values
      ...cabecera, // Update with new values
    }))

    setDetalleProforma(detalle)
    setIsLoadingDetalle(false) // Cuando se completa la actualización
  }, [cart])

  /** useEffect(() => {
    if (Object.keys(fetchedProforma).length !== 0) {
      const { cabecera, detalle } = fetchedProforma;
      const formatedDetalle = detalle.map((product) => formatItemCart(product));
      setCabeceraProforma(cabecera);
      setDetalleProforma(formatedDetalle);

      console.log(fetchedProforma, "aquii");
    }
  }, [fetchedProforma]); */

  useEffect(() => {
    const { subTotal, IVA, total, subtotalNeto } = getTotalsPrices(detalleProforma)
    setTotalPrices({
      subTotal,
      IVA,
      total,
      subtotalNeto,
    })
  }, [detalleProforma, detalleProforma.length])

  useEffect(() => {
    const { subTotal, IVA, total } = getTotalsPrices()
    setTotalPrices({
      subTotal,
      IVA,
      total,
    })
  }, [cart, cart.length])

  /* *****************************************************
                      Cliente QUERY
  ****************************************************** */
  // const [searchTextCliente, setSearchTextCliente] = useState(
  //   cabeceraProforma.clinombre
  // ); //Estado del textfield cliente en el ui

  // Estado del cliente usado tambien en el modal de agregar cliente

  const [clienteInfo, setClienteInfo] = useState({
    nombre: "",
    cedulaRuc: "",
    correo: "",
    telefono: "",
    direccion: "",
  })

  const {
    data: fetchedCliente = [],
    isError: isLoadingClienteError,
    isPending: isFetchingClientes,
    isLoading: isLoadingCliente,
    refetch: getCliente,
  } = useGetCliente()

  useEffect(() => {
    try {
      getCliente()
    } catch (error) {
      console.log(error)
    }
  }, [cabeceraProforma.clinombre])

  useEffect(() => {
    if (Object.keys(fetchedCliente) !== 0) {
      setClienteInfo({
        nombre: fetchedCliente.clinombre,
        cedulaRuc: fetchedCliente.cliruc,
        correo: fetchedCliente.cliemail,
        telefono: fetchedCliente.clitelef1,
        direccion: fetchedCliente.clidirec,
      })
    }
  }, [fetchedCliente])

  const getPreProforma = async () => {
    try {
      try {
        const preCart = {
          cabeceraProforma,
          detalleProforma,
        }
        const options = {
          method: "POST",
          body: JSON.stringify(preCart),
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }

        setIsLoadingPreProforma(true)
        const response = await fetchwrapper("/proformas/getPreProforma", options)
        const { cabecera, detalle } = await response.json()

        setCabeceraProforma(cabecera)
        setDetalleProforma(detalle)

        console.log(preCart, { cabecera, detalle }, "aquiiii")
        setAllCart(detalle)
      } catch (error) {
        console.log(error)
      }
    } catch {
      alert("No se puedo cargar la proforma")
      navigate("/home/dashboard/Creacion de Catalogos/Filtros")
    } finally {
      setIsLoadingPreProforma(false)
    }
  }

  // Solo se visualizara la proforma si se selecciona un cliente y una forma de pago
  useEffect(() => {
    if (cabeceraProforma && cabeceraProforma.factippag && cabeceraProforma.clicodigo) {
      getPreProforma() // Esto me va a cargar toda la proforma con sus respectivios descuentos
    }
  }, [cabeceraProforma.factippag, cabeceraProforma.clicodigo])

  if (isLoadingClienteError) {
    console.log("Error en cargar los clientes")
  }

  // READ hook (get Clientes from api)
  function useGetCliente() {
    return useQuery({
      queryKey: ["Cliente"],
      queryFn: async () => {
        // send api request here
        console.log(cabeceraProforma, "aquiantesss")
        if (cabeceraProforma.clinombre) {
          let response = await fetchwrapper(`/proformas/getCliente/${cabeceraProforma.clinombre}`)
          response = await response.json()
          console.log(response)
          setCabeceraProforma({
            ...cabeceraProforma,
            // clicodigo: response.data.clicodigo,
          })
          return response.data
        } else {
          return {
            clinombre: "",
            cliruc: "",
            cliemail: "",
            clitelef1: "",
            clidirec: "",
          }
        }
      },
      refetchOnWindowFocus: false,
      staleTime: Infinity,
    })
  }

  return (
    <>
      <ThemeProvider theme={theme}>
        <Header />
        <div className="main main-app p-3 p-lg-4">
          <BackIcon />
          <div className="d-md-flex align-items-center justify-content-between mb-4"></div>
          <CustomBackdrop isLoading={isLoadingCliente || isFetchingClientes || isLoadingPreProforma} />
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "30px",
            }}
          >
            <Paper
              style={{
                padding: "16px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              {/* *****************************************************
                                  CABECERA
              ****************************************************** */}
              <Typography style={{ margin: "10px" }} variant="h6">
                Emisión de Proforma
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={4}>
                  <TextField id="" label="PROFORMA" disabled type="text" fullWidth />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    disabled
                    id="fecha-emision"
                    label="Fecha de Emisión"
                    value={normalFormatDate(cabeceraProforma.pedfecemi)}
                    // value={format(fechaEmision, "dd/MM/yyyy")} // Formatea la fecha en el formato deseado

                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    id="fecha-vence"
                    label="Fecha de Vencimiento"
                    value={normalFormatDate(cabeceraProforma.pedfecven)}
                    // value={format(fechaEmision, "dd/MM/yyyy")} // Formatea la fecha en el formato deseado
                    InputProps={{
                      readOnly: true,
                    }}
                    disabled
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <InputSelectorFormaPago
                    cabeceraProforma={cabeceraProforma}
                    setCabeceraProforma={setCabeceraProforma}
                  ></InputSelectorFormaPago>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <InputSelectorVendedor
                    cabeceraProforma={cabeceraProforma}
                    setCabeceraProforma={setCabeceraProforma}
                  ></InputSelectorVendedor>
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    required
                    fullWidth
                    label="Cliente"
                    variant="outlined"
                    value={cabeceraProforma && cabeceraProforma.clinombre ? cabeceraProforma.clinombre : ""}
                    InputProps={{
                      readOnly: true,

                      endAdornment: (
                        <div style={{ display: "flex" }}>
                          <Tooltip title="Buscar">
                            <IconButton
                              onClick={() => {
                                handleOpenModalSearchClient()
                              }}
                            >
                              <FcSearch />
                            </IconButton>
                          </Tooltip>

                          <Tooltip title="Crear">
                            <IconButton
                              aria-label="delete"
                              onClick={() => {
                                handleOpenModalCreateClient()
                              }}
                            >
                              <FcPlus />
                            </IconButton>
                          </Tooltip>
                        </div>
                      ),
                    }}
                  />

                  {/* *****************************************************
                                   MODAL BUSCAR CLIENTE
                  ****************************************************** */}

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      width: "100%",
                    }}
                  >
                    <div style={{ textAlign: "left" }}>
                      <Modal
                        open={openModalSearchClient}
                        onClose={handleCloseModalSearchClient}
                        aria-labelledby="parent-modal-title"
                        aria-describedby="parent-modal-description"
                      >
                        <Box sx={{ ...styleModalCreateClient }}>
                          <ModalSearchClient
                            initialValues={clienteInfo}
                            handleCloseModalCreateClient={handleCloseModalSearchClient}
                            cabeceraProforma={cabeceraProforma}
                            setCabeceraProforma={setCabeceraProforma}
                          />
                        </Box>
                      </Modal>
                    </div>
                  </div>

                  {/* *****************************************************
                                   MODAL CREAR CLIENTE
                  ****************************************************** */}

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      width: "100%",
                    }}
                  >
                    <div style={{ textAlign: "left" }}>
                      <Modal
                        open={openModalCreateClient}
                        onClose={handleCloseModalCreateClient}
                        aria-labelledby="parent-modal-title"
                        aria-describedby="parent-modal-description"
                      >
                        <Box sx={{ ...styleModalCreateClient }}>
                          <ModalCreateClient
                            initialValues={clienteInfo}
                            handleCloseModalCreateClient={handleCloseModalCreateClient}
                            cabeceraProforma={cabeceraProforma}
                            setCabeceraProforma={setCabeceraProforma}
                          />
                        </Box>
                      </Modal>
                    </div>
                  </div>
                </Grid>

                {/* <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    id="outlined-read-only-input"
                    label="Empresa"
                    defaultValue={
                      JSON.parse(localStorage.getItem("jwt")).seleccion
                        .cliciacianombre
                    }
                    InputProps={{
                      readOnly: true,
                    }}
                    fullWidth
                  />
                </Grid> */}
                <Grid item xs={12} md={12}>
                  <TextField
                    id="outlined-multiline-static"
                    label="Observacion Cliente"
                    multiline
                    rows={3}
                    value={comment}
                    onChange={(e) => {
                      setComment(e.target.value)
                      setCabeceraProforma({
                        ...cabeceraProforma,
                        ped_detalle: e.target.value,
                      })
                    }}
                    fullWidth
                  />
                </Grid>

                {/* <CreateProformaModal /> Old create proforma modal */}

                {/* *****************************************************
                                   MODAL AGREGAR PRODUCTO
                  ****************************************************** */}
                {/* background: "#114B5E", */}
                <Grid container sx={{ marginTop: "15px" }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      width: "100%",
                    }}
                  >
                    <div style={{ textAlign: "left" }}>
                      {/* <Button
                        onClick={handleOpenModalAddProduct}
                        variant="contained"
                        style={{ background: "#114B5E", color: "white" }}
                      >
                        <span className="me-2">Agregar Productos</span>
                        <FcPlus size={"25px"} />
                      </Button> */}
                      <Modal
                        open={openModalAddProduct}
                        onClose={handleCloseModalAddProduct}
                        aria-labelledby="parent-modal-title"
                        aria-describedby="parent-modal-description"
                      >
                        <Box sx={{ ...styleModalAddProduct }}>
                          <ModalAddProduct
                            detallesProforma={detalleProforma}
                            setDetallesProforma={setDetalleProforma}
                            idProforma={idProforma}
                            handleCloseModalAddProduct={handleCloseModalAddProduct}
                          />

                          {/* <ChildModal /> */}
                        </Box>
                      </Modal>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Paper>
          </div>
          {/* *****************************************************
                                  DETALLE (TABLE)
              ****************************************************** */}
          <>
            {isLoadingDetalle || isLoadingPreProforma ? (
              <p>Cargando...</p>
            ) : (
              cabeceraProforma.factippag &&
              cabeceraProforma.clicodigo && (
                <EditableTableDetalleProforma
                  detallesProforma={detalleProforma || []}
                  setDetallesProforma={setDetalleProforma}
                  setSpecificQuantity={setSpecificQuantityy}
                />
              )
            )}
          </>
          {/* *****************************************************
                                  TOTAL PRICES
              ****************************************************** */}
          {cabeceraProforma.factippag && cabeceraProforma.clicodigo && (
            <Box sx={{ marginLeft: "15px", paddingTop: "25px" }}>
              <div>
                <p>
                  {" "}
                  <strong>Valor Bruto: </strong>
                  {/* ` $${math.round(totalPrices.subTotal, 2)}` */}$
                  {cabeceraProforma && cabeceraProforma.pedsubtot ? cabeceraProforma.subtotalBruto.toFixed(2) : 0}
                </p>
              </div>
              <div>
                <p>
                  {" "}
                  <strong>Descuento por Articulo: </strong>
                  {/* ` $${math.round(totalPrices.subTotal, 2)}` */}$
                  {cabeceraProforma && cabeceraProforma.descuentoTotal ? cabeceraProforma.descuentoTotal.toFixed(2) : 0}
                </p>
              </div>
              <div>
                <p>
                  <strong>Valor Neto: </strong>
                  {/* ` $${math.round(totalPrices.total, 2)}` */}$
                  {cabeceraProforma && cabeceraProforma.subtotalNeto ? cabeceraProforma.subtotalNeto.toFixed(2) : 0}
                </p>
              </div>
              <div>
                <p>
                  <strong>IVA: </strong>
                  {/* ` $${math.round(totalPrices.IVA, 2)}` */}$
                  {cabeceraProforma && cabeceraProforma.pediva ? cabeceraProforma.pediva.toFixed(2) : 0}
                </p>
              </div>

              <div>
                <p>
                  <strong>Total: </strong>$
                  {cabeceraProforma
                    ? parseFloat(cabeceraProforma.subtotalNeto.toFixed(2)) === 0
                      ? 0.0
                      : (
                          parseFloat(cabeceraProforma.subtotalNeto.toFixed(2)) +
                          parseFloat(cabeceraProforma.pediva.toFixed(2))
                        ).toFixed(2)
                    : 0}
                </p>
              </div>

              <div>
                <CrearProforma
                  crearProforma={true}
                  pedNum={idProforma}
                  detalleProforma={detalleProforma}
                  cabeceraProforma={cabeceraProforma}
                />
              </div>
            </Box>
          )}
        </div>
      </ThemeProvider>
    </>
  )
}

export default CuerpoProforma
