import React, { useEffect, useState, useContext } from "react"
import { SolicitudIngresoContext, SolicitudIngresoContextProvider } from "../SolicitudIngresoContext"
import Header from "../../../layouts/Header"
import { createTheme, ThemeProvider } from "@mui/material/styles"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import fetchwrapper from "../../../services/interceptors/fetchwrapper"
import { useQuery } from "@tanstack/react-query"

import CrearSolicitud from "../components/CrearSolicitud"

const theme = createTheme({
  palette: {
    primary: {
      main: "#196C87",
    },
    secondary: {
      main: "#196C87", // Cambia el color secundario a verde azulado
    },
  },
})

const CrearSolicitudDeIngreso = () => {
  const moment = require("moment")
  const dayjs = require("dayjs")
  const [inputValue, setInputValue] = useState("")
  const [cliente, setCliente] = useState("")
  const [clienteDescription, setclienteDescription] = useState("")
  const [proveedor, setProveedor] = useState("")
  const [proveedorDescription, setproveedorDescription] = useState("")
  const [motivo, setMotivo] = useState("")
  const [fecha, setFecha] = useState(null)
  const [llegada, setLlegada] = useState(null)
  const [detalle, setDetalle] = useState("")
  const [fetchedInfo, setFetchedInfo] = useState({})
  const [open, setOpen] = useState(false)
  const [estado, setEstado] = useState([])
  const [productoSeleccionadoStatus, setproductoSeleccionadoStatus] = useState()
  const productoSeleccionado = useContext(SolicitudIngresoContext)

  useEffect(() => {
    setInputValue(productoSeleccionado)
    console.log(productoSeleccionado)
  }, [productoSeleccionado, inputValue])

  const handleChange = (event) => {
    console.log(productoSeleccionado)
    setInputValue(event.target.value)
  }

  const handleMotivoChange = (event) => {
    setMotivo(event.target.value)
  }

  const handleEstadoChange = (event) => {
    setEstado(event.target.value) // The value will be an array when multiple selections are enabled
  }

  const handleClienteChange = (event) => {
    setCliente(event.target.value)
  }

  const handleClienteDescriptionChange = (event) => {
    setclienteDescription(event.target.value)
  }

  const handleProveedorChange = (event) => {
    setProveedor(event.target.value)
  }

  const handleEstado = (event) => {
    setEstado(event.target.value)
  }

  const handleFecha = (event) => {
    setFecha(event.target.value)
  }

  const handleDetalle = (event) => {
    setDetalle(event.target.value)
  }

  const handleProveedorDescriptionChange = (event) => {
    setproveedorDescription(event.target.value)
  }

  const handleOpenAyudaArticulo = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const motivoOptions = [
    { value: "motivo1", label: "Devolución de inventario inactivo" },
    { value: "motivo2", label: "Devoluciones a proveedores" },
    { value: "motivo3", label: "Devoluciones de las dependencias" },
    { value: "motivo4", label: "Estabilización J.Beneficencia y PORTRANS S.A" },
    { value: "motivo5", label: "Ingreso a bodega de clientes" },
    { value: "motivo6", label: "Plan Entrega Inactivo" },
  ]

  const estadoOptions = [
    { value: "motivo1", label: "Pendiente" },
    { value: "motivo2", label: "Aprobada" },
    { value: "motivo3", label: "Negada" },
    { value: "motivo4", label: "Cerrada" },
    { value: "motivo5", label: "Recibida Total" },
    { value: "motivo6", label: "Recibida Parcial" },
  ]

  const { refetch, data, isLoading, isError, isFetching } = useGetSolicitudDeIngreso()

  const handleSearchClick = () => {
    refetch()
  }

  const handleDoubleClick = (codigo) => {
    setInputValue(codigo)
  }

  function useGetSolicitudDeIngreso() {
    return useQuery({
      queryKey: ["solicitudDeIngresoCrear"],
      queryFn: async () => {
        // send api request here
        let response = await fetchwrapper(`/solicitudDeIngreso/getSolicitudDeIngreso/${inputValue}`)
        response = await response.json()
        response = response?.data
        console.log(response)
        setCliente(response.cliCodigo)
        setclienteDescription(response.nomCliente)
        setProveedor(response.proCodigo)
        setproveedorDescription(response.proNombre)
        setEstado(response.estado)
        setDetalle(response.descri)
        setFetchedInfo(response.arregloProductos)
        // setFecha(response['fecSolicitud']);
        console.log(moment(response.fecSolicitud, "ddd, DD MMM YYYY"))
        // setFecha(moment(response['fecSolicitud'], "ddd, DD MMM YYYY").toDate());
        return response
      },
      refetchOnWindowFocus: false,
      enabled: false,
    })
  }

  const adapter = new AdapterDayjs({
    locale: "en",
    format: "YYYY-MM-DD",
  })

  return (
    <SolicitudIngresoContextProvider>
      <ThemeProvider theme={theme}>
        <Header />
        <CrearSolicitud />
      </ThemeProvider>
    </SolicitudIngresoContextProvider>
  )
}

export default CrearSolicitudDeIngreso
