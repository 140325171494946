// Esta fn retorna un componente icono depediendo del
// tipo de libreria que pertenece el opcicono (nombre del icono)
// la libreria me lo dice el opcmenu
import React from "react"
import * as Icons from "react-icons/fc"
import { Icon } from "@iconify/react"

const getIconComponent = (opc) => {
  if (!opc?.icon) {
    return null
  }
  if (opc?.opcmenu === "iconify") {
    return <Icon icon={opc?.icon} width={30} height={30} />
  }

  if (opc?.opcmenu === "react-icons") {
    return React.createElement(Icons[opc?.icon], { size: 30 })
  }
  return React.createElement(Icons[opc?.icon], { size: 30 })
}

export default getIconComponent
