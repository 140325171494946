import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import { Box, Grid, TextField, Paper, Typography, Button, CircularProgress } from "@mui/material"
import { DataGrid } from "@mui/x-data-grid"
import { styled, createTheme, ThemeProvider } from "@mui/material/styles"
import Swal from "sweetalert2"
import InputMultivalueFiltrarProforma from "./components/InputMultivalueFiltrarProforma"
import DatePicker from "./components/DatePicker"
import InputFiltrarProforma from "./components/InputFiltrarProforma"
import Header from "../../layouts/Header"
import fetchwrapper from "../../services/interceptors/fetchwrapper"
import normalFormatDate from "../utils/date/DDMMYYYFormatDate"
// import ExamplePagination from "./Paginacion";
import CreateProformaModal from "./components/CreateProformaModal"
import BackIcon from "../../components/BackIcon"
import CuerpoProforma from "../Proforma/components/EmisionProforma"
import { FcPlus, FcSearch, FcUpload } from "react-icons/fc"

// Estilos para Datagrid y botones de filtros
const StyledRoot = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  marginTop: theme.spacing(8),
  flexGrow: 1,
  padding: "0 16px",
}))
const StyledDataGridContainer = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  overflowX: "auto",
}))

const StyledButtonContainerFiltro = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  marginTop: theme.spacing(2),
  textAlign: "left",
}))
const StyledButtonsFiltro = styled(Button)(({ theme }) => ({
  margin: "auto",
  marginLeft: theme.spacing(1),
  marginRight: theme.spacing(1),
}))

// const useStyles = makeStyles((theme) => ({
//   root: {
// display: "flex",
// flexDirection: "column",
// marginTop: theme.spacing(8),
// flexGrow: 1,
// padding: "0 16px",
//   },
//   dataGridContainer: {
//     flexGrow: 1,
//     overflowX: "auto",
//   },

//   buttonContainerFiltro: {
//     display: "flex",
//     flexDirection: "row",
//     justifyContent: "center",
//     marginTop: theme.spacing(2),
//   },
//   buttonsFiltro: {
//     margin: "auto",
//     marginLeft: theme.spacing(1),
//     marginRight: theme.spacing(1),
//   },

// }));

// Estilos para el color
const theme = createTheme({
  palette: {
    primary: {
      main: "#E8DFD1",
    },
    secondary: {
      main: "#196C87", // Cambia el color secundario a verde azulado
    },
  },
})

const FiltrarProforma = () => {
  const [productos, setProductos] = useState([])
  const [numPed, setNumped] = useState("")
  const [fechaInicio, setFechaInicio] = useState("")
  const [fechaFin, setFechaFin] = useState("")
  const [cliente, setCliente] = useState("")
  const [estadoFactura, setEstadoFactura] = useState([])
  const [btnFiltroloading, setBtnFiltroLoading] = useState(false)

  // Definir las columnas personalizadas de la tabla
  const columnas = [
    {
      headerName: "Num. pedido",
      field: "pednumped",
      sort: "asc",
      width: 150,
      renderCell: (params) => <Link to={`/home/dashboard/proforma/${params.value}`}>{params.value}</Link>,
    },

    {
      headerName: "Fecha Emisión",
      field: "pedfecisys",
      sort: "asc",
      width: 150,
      renderCell: (params) => <div>{normalFormatDate(params.value)}</div>,
    },
    {
      headerName: "Fecha Modificación",
      field: "pedfecmsys",
      sort: "asc",
      width: 180,
      renderCell: (params) => <div>{normalFormatDate(params.value)}</div>,
    },
    {
      headerName: "Vendedor",
      field: "vennombre",
      sort: "asc",
      width: 150,
    },
    {
      headerName: "Cliente",
      field: "clinombre",
      sort: "asc",
      width: 150,
    },
    {
      headerName: "Subtotal",
      field: "pedsubtot",
      sort: "asc",
      width: 150,
    },
    {
      headerName: "IVA",
      field: "pediva",
      sort: "asc",
      width: 150,
    },
    // {
    //   headerName: "Descuento",
    //   field: "c",
    //   sort: "asc",
    //   width: 250,
    // },
    {
      headerName: "Total",
      field: "pedtotal",
      sort: "asc",
      width: 150,
    },
    {
      headerName: "Estado",
      field: "pedstatus",
      sort: "asc",
      width: 150,
    },
    //     {
    //   headerName: "Vendedor",
    //   field: "vennombre",
    //   sort: "asc",
    //   width: 250,
    // },
    // {
    //   headerName: "Cliente",
    //   field: "clinombre",
    //   sort: "asc",
    //   width: 150,
    // },
  ]

  // Función que realiza la búsqueda de productos por filtro
  const filtros = async () => {
    // console.log(numPed, "numPed");
    console.log(fechaInicio, "fechaIincio")
    // console.log(fechaFin, "fechaFin");
    // console.log(cliente, "cliente");
    // console.log(estadoFactura, "estadosFactura");

    if (!numPed && !fechaInicio && !fechaFin && !cliente && estadoFactura) {
      Swal.fire({
        icon: "warning",
        title: "Atencion",
        text: "Use al menos un criterio de búsqueda para consultar los datos",
      })
      return
    }

    try {
      const request = {
        codigo: {
          numPed,
          fechaInicio,
          fechaFin,
          cliente,
          estado: estadoFactura,
        },
      }
      console.log(request)
      const options = {
        method: "POST",
        body: JSON.stringify(request),
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
      setBtnFiltroLoading(true)
      let response = await fetchwrapper("/proformas/make_proformas_filter", options)
      if (!response.ok) {
        console.error("Error")
      }
      response = await response.json()

      if (response.message) {
        setProductos([])

        throw new Error(response.message)
      }

      console.log(response)
      setProductos(response.data)
    } catch (err) {
      console.error(err)
    } finally {
      setBtnFiltroLoading(false)
    }
  }

  return (
    <>
      <ThemeProvider theme={theme}>
        <Header />
        <div className="main main-app p-3 p-lg-4">
          <BackIcon />
          <div className="d-md-flex align-items-center justify-content-between mb-4">
            {/* <div>
              <ol className="breadcrumb fs-sm mb-1">
                <li className="breadcrumb-item">
                  <Link to="#">Dashboard</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Proforma
                </li>
              </ol>
            </div> */}
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "30px",
            }}
          >
            <Paper
              style={{
                padding: "16px",
                display: "flex",

                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  textAlign: "left",
                  display: "flex",
                  justifyContent: "flex-end",
                  width: "100%",
                }}
              >
                <Link to="/home/dashboard/Proformas / EmisionProforma" style={{ textDecoration: "none" }}>
                  <Button
                    variant="contained"
                    color="secondary"
                    disabled={btnFiltroloading} // Deshabilitar el botón mientras se está cargando
                  >
                    {btnFiltroloading ? (
                      <CircularProgress size={24} />
                    ) : (
                      <>
                        <span className="me-2">Crear</span>
                        <FcPlus size={"25px"} />
                      </>
                    )}
                  </Button>
                </Link>
              </div>
              <Typography style={{ margin: "10px" }} variant="h6">
                Criterios de búsqueda
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    id="outlined-search"
                    label="NumPed"
                    type="search"
                    onChange={(e) => setNumped(e.target.value)}
                    fullWidth
                  />
                </Grid>

                <DatePicker label="Fecha Inicio" setDate={setFechaInicio} />
                <DatePicker label="Fecha Fin" setDate={setFechaFin} />

                {/* <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    id="outlined-search"
                    label="Cliente"
                    type="search"
                    onChange={(e) => setCliente(e.target.value)}
                    fullWidth
                  />
                </Grid> */}

                <InputMultivalueFiltrarProforma setEstadoFactura={setEstadoFactura} />
                <Grid item xs={12} sm={6} md={4}>
                  <InputFiltrarProforma setCliente={setCliente} />
                </Grid>
              </Grid>
              <Grid container sx={{ marginTop: "15px" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    width: "100%",
                    gap: "10px",
                  }}
                >
                  {/* <CreateProformaModal cliente= {cliente}/> */}

                  <div style={{ textAlign: "left" }}>
                    <Button
                      variant="contained"
                      onClick={filtros}
                      color="secondary"
                      disabled={btnFiltroloading} // Deshabilitar el botón mientras se está cargando
                    >
                      {btnFiltroloading ? (
                        <CircularProgress size={24} />
                      ) : (
                        <>
                          <span className="me-2">Filtrar</span>
                          <FcSearch size={"25px"} />
                        </>
                      )}
                    </Button>
                  </div>
                </div>
              </Grid>
            </Paper>
          </div>

          <Box className={StyledRoot}>
            <Box className={StyledDataGridContainer} sx={{ height: 800, width: "100%" }}>
              <DataGrid
                rows={productos}
                columns={columnas}
                getRowId={(productos) => productos.pednumped}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 10,
                    },
                  },
                }}
                pageSizeOptions={[10, 25]}
                localeText={{
                  noRowsLabel: "Dale click a Filtrar para mostrar los productos",
                }}
                disableSelectionOnClick
              />
            </Box>
          </Box>
        </div>
      </ThemeProvider>
      {/* <ExamplePagination/> */}
    </>
  )
}

export default FiltrarProforma
