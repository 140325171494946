import React, { useState } from "react"
import { Box, TextField, Snackbar, Alert, CircularProgress, Modal, Button } from "@mui/material"
import fetchwrapper from "../../../services/interceptors/fetchwrapper" // Assuming a custom service for API calls

function ModalNew({ openModal, onCloseModal, onSave }) {
  const [open, setOpen] = useState(openModal)
  const [snackbarMessage, setSnackbarMessage] = useState("")
  const [snackbarSeverity, setSnackbarSeverity] = useState("success") // Set default severity to 'success'
  const [openSnackbar, setOpenSnackbar] = useState(false)
  const [isLoading, setIsLoading] = useState(false) // Flag for loading spinner
  const [banfn, setBanfn] = useState("")
  const [ded, setDed] = useState("")
  const [bod, setBod] = useState("")

  const handleClose = () => {
    setOpen(false)
    onCloseModal() // Notify the parent component that the modal is closed
  }

  const handleConfirm = async () => {
    if (!banfn || !ded || !bod) {
      setSnackbarMessage("Por favor, complete todos los campos.")
      setOpenSnackbar(true)
      return
    }

    try {
      setIsLoading(true)
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          BANFN: banfn,
          DED: ded,
          BOD: bod,
        }),
      }

      const response = await fetchwrapper("/integracionjbg/insertsapCsolpedtras", requestOptions)
      if (response.ok) {
        setSnackbarSeverity("success") // Establecer severidad como éxito
        setSnackbarMessage("Elemento guardado") // Establecer mensaje de éxito
        setOpenSnackbar(true) // Mostrar el Snackbar
        onSave()
        handleClose()
      } else {
        // console.error('Error al guardar la solicitud:', error);
        setSnackbarSeverity("warning") // Establecer severidad como advertencia
        setSnackbarMessage(`Error al guardar la solicitud:`) // Establecer mensaje de error
        setOpenSnackbar(true) // Mostrar el Snackbar
      }
    } catch (error) {
      console.error("Error al guardar la solicitud:", error)
      setSnackbarSeverity("warning") // Establecer severidad como advertencia
      setSnackbarMessage(`Error al guardar la solicitud: ${error.message}`) // Establecer mensaje de error
      setOpenSnackbar(true) // Mostrar el Snackbar
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Modal open={open} onClose={handleClose} aria-labelledby="modal-new" aria-describedby="modal-new-description">
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "30vw",
          maxHeight: "70vh",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          overflow: "hidden", // Oculta el overflow
        }}
      >
        <TextField
          autoFocus
          margin="dense"
          label="Solp Traslado"
          type="text"
          fullWidth
          value={banfn}
          onChange={(e) => setBanfn(e.target.value)}
        />
        <TextField
          margin="dense"
          label="Centro Destino"
          type="text"
          fullWidth
          value={ded}
          onChange={(e) => setDed(e.target.value)}
        />
        <TextField
          margin="dense"
          label="Almacen"
          type="text"
          fullWidth
          value={bod}
          onChange={(e) => setBod(e.target.value)}
        />
        <Button variant="contained" onClick={handleConfirm} disabled={isLoading} sx={{ float: "left" }}>
          {isLoading ? <CircularProgress size={24} /> : "Guardar"}
        </Button>
        <Snackbar
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={() => setOpenSnackbar(false)}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <Alert onClose={() => setOpenSnackbar(false)} severity={snackbarSeverity}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Box>
    </Modal>
  )
}

export default ModalNew
