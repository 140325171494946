/* eslint-disable camelcase */
import { useEffect, useMemo, useState } from "react"
import {
  MaterialReactTable,
  // createRow,
  useMaterialReactTable,
} from "material-react-table"
import {
  createTheme,
  ThemeProvider,
  useTheme,
  Box,
  Button,
  IconButton,
  Tooltip,
  TextField,
  InputAdornment,
  Modal,
  FormControl,
  MenuItem,
  Select,
} from "@mui/material"
import { esES } from "@mui/material/locale"
import { MRT_Localization_ES } from "material-react-table/locales/es"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import TaskIcon from "@mui/icons-material/Task"
import AssignmentIcon from "@mui/icons-material/Assignment"
import fetchwrapper from "../../../services/interceptors/fetchwrapper"
import { useNavigate } from "react-router-dom"
import Accordion from "@mui/material/Accordion"
import AccordionSummary from "@mui/material/AccordionSummary"
import AccordionDetails from "@mui/material/AccordionDetails"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import SearchIcon from "@mui/icons-material/Search"
import CloseIcon from "@mui/icons-material/Close"
import Lotes from "./Lotes"
import normalFormatDate from "../../utils/date/DDMMYYYFormatDate"
import { styled } from "@mui/system"

const SingleRowSelectionTable = ({ columnsTable, dataTable, sgasoling, idProducto, setProducto, handleCloseModal }) => {
  const theme = useTheme()
  const [rowSelection, setRowSelection] = useState({})
  const columns = useMemo(() => columnsTable)
  const data = dataTable

  const table = useMaterialReactTable({
    localization: { ...MRT_Localization_ES },
    columns,
    data,
    enableRowSelection: true,
    enableMultiRowSelection: false, // use radio buttons instead of checkboxes
    getRowId: (row) => row.embcodigo, // give each row a more useful id
    muiTableBodyRowProps: ({ row }) => ({
      // add onClick to row to select upon clicking anywhere in the row
      onClick: row.getToggleSelectedHandler(),
      sx: { cursor: "pointer" },
    }),
    positionToolbarAlertBanner: "bottom", // move the alert banner to the bottom
    onRowSelectionChange: setRowSelection, // connect internal row selection state to your own
    state: { rowSelection }, // pass our managed row selection state to the table to use
  })

  const handleSelectSingleRow = () => {
    try {
      let rowSelected = table.getSelectedRowModel().rows[0]
      rowSelected = rowSelected.original
      setProducto(sgasoling, idProducto, rowSelected)
    } catch (error) {
      console.log(error)
    } finally {
      handleCloseModal()
    }
  }
  return (
    <ThemeProvider theme={createTheme(theme, esES)}>
      <MaterialReactTable table={table} />
      <div style={{ clear: "both" }}>
        <Button variant="text" color="primary" style={{ float: "right" }} onClick={handleSelectSingleRow}>
          Establecer
        </Button>
      </div>
    </ThemeProvider>
  )
}

const SearchTexfield = ({ sgasoling, idProducto, value, setProducto, columnsTable, dataTable }) => {
  const styles = {
    modalStyle: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: "90%",
      bgcolor: "background.paper",
      border: "2px solid #000",
      boxShadow: 24,
      p: 4,
    },
    closeStyle: {
      cursor: "pointer",
      marginLeft: "95%",
    },
  }
  const [open, setOpen] = useState(false) // modal
  const handleOpenModal = () => setOpen(true)
  const handleCloseModal = () => setOpen(false)
  return (
    <span style={{ flexGrow: 1 }}>
      <Modal
        open={open}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styles.modalStyle}>
          <IconButton style={styles.closeStyle} onClick={handleCloseModal}>
            <CloseIcon />
          </IconButton>
          <SingleRowSelectionTable
            columnsTable={columnsTable}
            dataTable={[...dataTable]}
            sgasoling={sgasoling}
            idProducto={idProducto}
            setProducto={setProducto}
            handleCloseModal={handleCloseModal}
          />
        </Box>
      </Modal>

      <TextField
        fullWidth
        id={`outlined-${sgasoling}-${idProducto}`}
        variant="standard"
        value={value ?? ""}
        disabled
        InputProps={{
          endAdornment: (
            <InputAdornment>
              <IconButton onClick={handleOpenModal}>
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </span>
  )
}

const DisabledTextField = ({ label = "", value }) => {
  return (
    <span style={{ flexGrow: 1 }}>
      <TextField fullWidth id={`outlined`} variant="standard" label={label} value={value ?? ""} disabled />
    </span>
  )
}

const IntegerInput = ({ value, setValue, min, max }) => {
  const handleChange = (event) => {
    const newValue = event.target.value.replace(/\D/g, "") // Eliminar todos los caracteres que no sean dígitos
    // Verificar si newValue es una cadena vacía después de eliminar caracteres no numéricos
    if (newValue === "") {
      setValue(0)
    } else {
      // Convertir newValue a un número entero
      const intValue = parseInt(newValue)

      // Verificar si intValue está dentro del rango (min, max)
      if (!isNaN(intValue) && intValue >= min && intValue <= max) {
        setValue(intValue)
      }
    }
  }
  return (
    <TextField
      fullWidth
      variant="standard"
      type="number"
      value={value.toString()}
      onChange={handleChange}
      InputProps={{
        inputProps: {
          min,
          max,
        },
      }}
    />
  )
}

const CustomAccordion = ({ product, setProducto, fetchedEmbalajes, fetchedTonos }) => {
  const styles = {
    accordionSummary: {
      backgroundColor: "rgb(25, 108, 135)",
      color: "white",
      fontWeight: "bolder",
    },
    row: {
      display: "flex",
      // justifyContent: "space-between",
      alignItems: "center",
      paddingBlock: "10px",
    },
    rowTitle: {
      alignSelf: "end",
      paddingRight: "20px",
      fontWeight: "bolder",
    },
  }

  const CustomSelect = styled(Select)(({ theme }) => ({
    "& .MuiSelect-selectMenu": {
      minWidth: 80,
      overflowX: "scroll",
    },
  }))

  useEffect(() => {
    console.log(product, "hereeeeeeeeee")
  }, [product]) // BORRAR

  return (
    <Accordion defaultExpanded>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`panel-${product.artcodigo}`}
        id={product.artcodigo}
        style={styles.accordionSummary}
      >
        {`${product.artcodigo} - ${product.artdescri}`}
      </AccordionSummary>
      <AccordionDetails>
        <div style={styles.row}>
          <span style={styles.rowTitle}>Código de Solicitud:</span>
          <DisabledTextField value={product.sgasoling} />
        </div>
        {/* EMBALAJES */}
        <div style={styles.row}>
          <span style={styles.rowTitle}>Código Embalaje:</span>
          <SearchTexfield
            sgasoling={product.sgasoling}
            idProducto={product.artcodigo}
            value={product.embcodigo}
            setProducto={setProducto}
            columnsTable={[
              {
                accessorKey: "embcodigo",
                header: "Código Embalaje",
                enableEditing: false,
                size: 200,
              },
              {
                accessorKey: "embdescripcion",
                header: "Descripción Embalaje",
                enableEditing: false,
                size: 200,
              },
              {
                accessorKey: "embmetros3",
                header: "Metros Cúbicos Embalaje",
                enableEditing: false,
                size: 200,
              },
              {
                accessorKey: "embpieza",
                header: "Conversión",
                enableEditing: false,
                size: 200,
              },
              {
                accessorKey: "embmetros2",
                header: "Metros Cuadrados",
                enableEditing: false,
                size: 200,
              },
            ]}
            dataTable={fetchedEmbalajes}
          />
        </div>

        <div style={styles.row}>
          <span style={styles.rowTitle}>Descripción Embalaje:</span>
          <DisabledTextField value={product.embdescripcion} />
        </div>

        <div style={styles.row}>
          <span style={styles.rowTitle}>Metros Cúbicos Embalaje:</span>
          <DisabledTextField value={product.embmetros3} />
        </div>

        <div style={styles.row}>
          <span style={styles.rowTitle}>Conversión:</span>
          <DisabledTextField value={product.embpieza} />
        </div>

        <div style={styles.row}>
          <span style={styles.rowTitle}>Metros Cuadrados:</span>
          <DisabledTextField value={product.embmetros2} />
        </div>

        {/* TONOS */}
        <div style={styles.row}>
          <span style={styles.rowTitle}>Código Tono:</span>
          <SearchTexfield
            sgasoling={product.sgasoling}
            idProducto={product.artcodigo}
            value={product.toncodigo}
            setProducto={setProducto}
            columnsTable={[
              {
                accessorKey: "toncodigo",
                header: "Código Tono",
                enableEditing: false,
                size: 200,
              },
              {
                accessorKey: "tondescripcion",
                header: "Descripción Tono",
                enableEditing: false,
                size: 200,
              },
            ]}
            dataTable={fetchedTonos}
          />
        </div>

        <div style={styles.row}>
          <span style={styles.rowTitle}>Descripción Tono:</span>
          <DisabledTextField value={product.tondescripcion} />
        </div>

        {/* UBICACION */}
        <div style={styles.row}>
          <span style={styles.rowTitle}>Código Bahía:</span>
          <DisabledTextField value={product.bahcodigo} />
        </div>
        <div style={styles.row}>
          <span style={styles.rowTitle}>Descripción Bahía:</span>
          <DisabledTextField value={product.bahdescripcion} />
        </div>
        <div style={styles.row}>
          <span style={styles.rowTitle}>Metros Cúbicos Bahía:</span>
          <DisabledTextField value={product.bahmetros3} />
        </div>
        <div style={styles.row}>
          <span style={styles.rowTitle}>Código Zona:</span>
          <DisabledTextField value={product.izoncodigo} />
        </div>
        <div style={styles.row}>
          <span style={styles.rowTitle}>Descripción Zona:</span>
          <DisabledTextField value={product.izondescripcion} />
        </div>
        <div style={styles.row}>
          <span style={styles.rowTitle}>Código Pasillo:</span>
          <DisabledTextField value={product.pascodigo} />
        </div>
        <div style={styles.row}>
          <span style={styles.rowTitle}>Descripción Pasillo:</span>
          <DisabledTextField value={product.pasdescripcion} />
        </div>
        <div style={styles.row}>
          <span style={styles.rowTitle}>Código Nivel:</span>
          <DisabledTextField value={product.nivcodigo} />
        </div>
        <div style={styles.row}>
          <span style={styles.rowTitle}>Código Posición:</span>
          <DisabledTextField value={product.poscodigo} />
        </div>

        {/* CALCULO DE CANTIDADES */}
        <div style={styles.row}>
          <span style={styles.rowTitle}>Cantidad Solicitada:</span>
          <DisabledTextField value={product.sgacansol} />
        </div>

        <div style={styles.row}>
          <span style={styles.rowTitle}>Cantidad Embalaje:</span>
          <IntegerInput
            value={Number(product.cantidadEmbalaje)}
            setValue={(newVal) =>
              setProducto(product.sgasoling, product.artcodigo, {
                cantidadEmbalaje: newVal,
              })
            }
            min={0}
            max={Number(product.sgacansol)}
          />
        </div>

        <div style={styles.row}>
          <span style={styles.rowTitle}>Cantidad Registrada:</span>
          <DisabledTextField value={Number(product.cantidadEmbalaje) ?? 0} />
        </div>

        {/* LOTES */}

        <div style={styles.row}>
          <span style={styles.rowTitle}>Series/Lote:</span>
          <Lotes
            maxCantidadEmbalaje={Number(product.cantidadEmbalaje)}
            setLotesInProducto={(newVal) =>
              setProducto(product.sgasoling, product.artcodigo, {
                lotes: newVal,
              })
            }
            artdiasseg={product.artdiasseg}
          />
        </div>

        <div style={styles.row}>
          <span style={styles.rowTitle}>Series/Lote Registrados:</span>
          <FormControl fullWidth variant="standard" sx={{ m: 1, minWidth: 80, overflowX: "scroll" }}>
            <CustomSelect
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              MenuProps={{
                PaperProps: { style: { maxHeight: 200, overflowX: "scroll" } },
              }}
            >
              {product.lotes.map((lote) => (
                // eslint-disable-next-line react/jsx-key
                <MenuItem value="">{`${lote.codigoLote} Cant:${lote.cantidadLote} Elab:${normalFormatDate(
                  lote.fechaElaboracionLote,
                )} Vence:${normalFormatDate(lote.fechaVencimientoLote)}`}</MenuItem>
              ))}
            </CustomSelect>
          </FormControl>
        </div>
      </AccordionDetails>
    </Accordion>
  )
}

const DatagridProductos = ({ productsCuarentena, setProductsCuarentena, ...params }) => {
  const setProducto = (sgasoling, artcodigo, newInfo) => {
    setProductsCuarentena((prev) => {
      const newProductosCuarentena = prev.map((product) => {
        if (product?.sgasoling === sgasoling && product?.artcodigo === artcodigo) {
          return { ...product, ...newInfo }
        }
        return product
      })

      return newProductosCuarentena
    })
  }
  return (
    <div>
      {productsCuarentena.map((product) => (
        <CustomAccordion
          key={product.sgasoling + product.artcodigo}
          product={product}
          setProducto={setProducto}
          {...params}
        />
      ))}
    </div>
  )
}

export default DatagridProductos
