import React, { useState, useEffect } from "react"
import Header from "../../../../../layouts/Header"
import { styled, createTheme, ThemeProvider } from "@mui/material/styles"
import {
  Box,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Button,
  Tooltip,
  Checkbox,
  FormControl,
  FormHelperText,
  FormControlLabel,
} from "@mui/material"
import ImprimirIcon from "../../../../../assets/iconos/Impresion.ico"
import DetalleImpresion from "../../../../Zonas/components/DetalleImpresion"
import BahiaPdf from "../../../components/BahiaPdf"
import Alert from "@mui/material/Alert"
import Swal from "sweetalert2"
import { useQuery, useQueryClient, useMutation, dataTagSymbol } from "@tanstack/react-query"
import CustomAutoComplete from "../../../../SolicitudDeIngreso/components/CustomAutoComplete"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import BackIcon from "../../../../../components/BackIcon"
import GrabarIcon from "../../../../../assets/iconos/Grabar.ico"
import fetchwrapper from "../../../../../services/interceptors/fetchwrapper"
import CustomBackdrop from "../../../../../components/CustomBackdrop"
import CustomTextFieldReadable from "../../../../../components/CustomTextFieldReadable"
//  crear funciona como un mantenedor normal y devuelve a la pagina de opciones
//  estilo del formulario
//  campos de creacion
const CodigoBox = styled(Box)({
  gridArea: "Codigo",
})
const DescripcionBox = styled(Box)({
  gridArea: "Descripcion",
})
const BodegaBox = styled(Box)({
  gridArea: "Bodega",
})
const ZonaBox = styled(Box)({
  gridArea: "Zona",
})
const PasilloBox = styled(Box)({
  gridArea: "Pasillo",
})
const M3Box = styled(Box)({
  gridArea: "M3",
})
const ClasificacionBox = styled(Box)({
  gridArea: "Clasificacion",
})
const EstadoBox = styled(Box)({
  gridArea: "Estado",
})
// Contenedor de campos de busqueda
const Container = styled(Box)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "repeat(12, 1fr)",
  gridTemplateRows: "auto auto auto",
  gridTemplateAreas: `
    "Codigo Codigo Codigo Codigo Descripcion Descripcion Descripcion Descripcion Descripcion Descripcion Descripcion Descripcion"
    "Bodega Bodega Bodega Bodega Zona Zona Zona Zona Pasillo Pasillo Pasillo Pasillo"
    "M3 M3 M3 M3 Estado Estado Estado Estado Clasificacion Clasificacion Clasificacion Clasificacion"
  `,
  gap: "8px",
  alignItems: "center",

  [theme.breakpoints.down("sm")]: {
    gridTemplateColumns: "repeat(6, 1fr)",
    gridTemplateAreas: `
      "Codigo Codigo Codigo Descripcion Descripcion Descripcion"
      "Bodega Bodega Bodega Zona Zona Zona"
      "Pasillo Pasillo Pasillo M3 M3 M3"
      "Estado Estado Estado Clasificacion Clasificacion Clasificacion"
    `,
  },
}))
const StyledRoot = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  marginTop: theme.spacing(8),
  flexGrow: 1,
  padding: "0 16px",
  height: "100vh",
}))
const theme = createTheme({
  palette: {
    primary: {
      main: "#196C87",
    },
    secondary: {
      main: "#196C87", // Cambia el color secundario a verde azulado
    },
  },
})
const EditarBahia = () => {
  const { id } = useParams()
  const location = useLocation()
  const datoSeleccionado = location.state
  const bahiaCodActual = datoSeleccionado.bahcodigo
  const bahiaDescriActual = datoSeleccionado.bahdescripcion
  const bodegaCodActual = datoSeleccionado.bodcodigo
  const bodegaDescriActual = datoSeleccionado.boddescri
  const zonaCodActual = datoSeleccionado.izoncodigo
  const zonaDescriActual = datoSeleccionado.izondescripcion
  const pasilloCodActual = datoSeleccionado.pascodigo
  const pasilloDescriActual = datoSeleccionado.pasdescripcion
  const m3Actual = datoSeleccionado.bahmetros3
  const bahiaEstadoActual = datoSeleccionado.bahstatus
  const bahiClasiActual = datoSeleccionado.bahclasi

  console.log(datoSeleccionado)
  const datosActuales = {
    bahcodigo: bahiaCodActual,
    bahdescripcion: bahiaDescriActual,
    bodcodigo: bodegaCodActual,
    boddescri: bodegaDescriActual,
    izoncodigo: zonaCodActual,
    izondescripcion: zonaDescriActual,
    pascodigo: pasilloCodActual,
    pasdescripcion: pasilloDescriActual,
    bahmetros3: m3Actual,
    bahstatus: bahiaEstadoActual,
    bahclasi: bahiClasiActual,
  }
  console.log("datosAcuales", datosActuales)
  const [openModal, setOpenModal] = useState(false)
  const [infoGenerada, setInfoGenerada] = useState([])
  const handleOpenModal = () => {
    const datos = [datosActuales]
    console.log("datos", datos)
    setInfoGenerada(datos) // Asigna los datos de la tabla al estado
    setOpenModal(true)
  }

  const handleCloseModal = () => {
    setOpenModal(false)
    navigate(`/home/dashboard/Bahias/buscar`)
  }

  const navigate = useNavigate()
  // estados de elementos del forrmulario
  const [error, setError] = useState(false)
  const [m3, setM3] = useState(m3Actual)
  const [codigo, setCodigo] = useState(bahiaCodActual)
  const [descripcion, setDescripcion] = useState(bahiaDescriActual)
  const [bodegas, setBodegas] = useState([])
  const [bodega, setBodega] = useState(bodegaCodActual)
  const [bodegaDescripcion, setBodegaDescripcion] = useState(bodegaDescriActual)
  const [zonas, setZonas] = useState([])
  const [zona, setZona] = useState(zonaCodActual)
  const [zonaDescripcion, setZonaDescripcion] = useState(zonaDescriActual)
  const [autoCompleteValueZona, setAutoCompleteValueZona] = useState(`${zona}-${zonaDescripcion}`)
  const [pasillos, setPasillos] = useState([])
  const [pasillo, setPasillo] = useState(pasilloCodActual)
  const [pasilloDescripcion, setPasilloDescripcion] = useState(pasilloDescriActual)
  const [autoCompleteValuePasillo, setAutoCompleteValuePasillo] = useState(`${pasillo} - ${pasilloDescripcion}`)
  const [clasificaciones, setClasificaciones] = useState([])
  const [clasificacion, setClasificacion] = useState(bahiClasiActual)
  const [autoCompleteValueClasificacion, setAutoCompleteValueClasificacion] = useState(clasificacion)
  const [bahias, setBahias] = useState([])
  const { mutateAsync: updateBahia, isPending: isUpdatingBahia } = useUpdateBahia()

  // estados de los campos
  const findEstado = (estadoCurrent) => {
    const result = Estados.find((estado) => estado.value === estadoCurrent)
    return result
  }
  // descripcion
  const handleDescripcionBahia = (event) => {
    setDescripcion(event.target.value)
  }
  useEffect(() => {
    setDescripcion(descripcion)
    console.log(descripcion)
  }, [descripcion])
  // bodegas
  const {
    data: dataBodegas = [],
    isError: dataBodegasError,
    isFetching: isFetchingBodegas,
    isLoading: isLoadingBodegas,
    refetch: refetchGetAllBodegas,
  } = useGetAllBodegas()
  function useGetAllBodegas() {
    return useQuery({
      queryKey: ["BahiasBodegas"],
      queryFn: async () => {
        const response = await fetchwrapper("/Bodegas/getBodegas", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        })
        const result = await response.json()
        return result.bodegas
      },
      keepPreviousData: true,
      enabled: true,
      onError: (error) => {
        console.log("Error fetching data", error)
      },
    })
  }
  // zonas
  const {
    data: dataZonas = [],
    isError: dataZonasError,
    isFetching: isFetchingZonas,
    isLoading: isLoadingZonas,
    refetch: refetchGetAllZonas,
  } = useGetAllZonas()
  function useGetAllZonas() {
    return useQuery({
      queryKey: ["BahiasZonas"],
      queryFn: async () => {
        const response = await fetchwrapper("/Bahias/getZonas", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        })
        const result = await response.json()
        return result.zonas
      },
      keepPreviousData: true,
      enabled: true,
      onError: (error) => {
        console.log("Error fetching data", error)
      },
    })
  }
  const handleZonaChange = (selectedValue) => {
    const [codigo, descripcion] = selectedValue.split("-")
    setZona(codigo)
    setZonaDescripcion(descripcion)
    setAutoCompleteValueZona(selectedValue)
    console.log(zona, zonaDescripcion)
  }
  useEffect(() => {
    setZona(zona)
    setZonaDescripcion(zonaDescripcion)
  }, [zona])

  // pasillo
  const {
    data: dataPasillos = [],
    isError: dataPasillosError,
    isFetching: isFetchingPasillos,
    isLoading: isLoadingPasillos,
    refetch: refetchGetAllPasillos,
  } = useGetAllPasillos()
  function useGetAllPasillos() {
    return useQuery({
      queryKey: ["BahiasPasillos"],
      queryFn: async () => {
        const response = await fetchwrapper("/Bahias/getPasillos", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        })
        const result = await response.json()
        return result.pasillos
      },
      keepPreviousData: true,
      enabled: true,
      onError: (error) => {
        console.log("Error fetching data", error)
      },
    })
  }
  const handlePasilloChange = (selectedValue) => {
    const [codigo, descripcion] = selectedValue.split("-")
    setPasillo(codigo)
    setPasilloDescripcion(descripcion)
    setAutoCompleteValuePasillo(selectedValue)
    console.log(pasillo, pasilloDescripcion)
  }
  useEffect(() => {
    setPasillo(pasillo)
    setPasilloDescripcion(pasilloDescripcion)
  }, [pasillo])
  // clasificaciones

  const {
    data: dataClasificaciones = [],
    isError: dataClasificacionesError,
    isFetching: isFetchingClasificaciones,
    isLoading: isLoadingClasificaciones,
    refetch: refetchGetAllClasificaciones,
  } = useGetAllClasificaciones()
  function useGetAllClasificaciones() {
    return useQuery({
      queryKey: ["BahiasClasificaciones"],
      queryFn: async () => {
        const response = await fetchwrapper("/Bahias/getClasificacion", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        })
        const result = await response.json()
        const clases = result.clasificaciones
        const clasificacionList = clases.map((item) => item.bahclasi)
        return clasificacionList
      },
      keepPreviousData: true,
      enabled: true,
      onError: (error) => {
        console.log("Error fetching data", error)
      },
    })
  }
  const handleClasificacionChange = (selectedValue) => {
    console.log(dataClasificaciones)
    setClasificacion(selectedValue)
    setAutoCompleteValueClasificacion(selectedValue)
    console.log(selectedValue)
  }
  useEffect(() => {
    setClasificacion(clasificacion)
  }, [clasificacion])
  // Estados
  const Estados = [
    { value: "A", description: "ACTIVO" },
    { value: "I", description: "INACTIVO" },
  ]

  const [estado, setEstado] = useState(findEstado(bahiaEstadoActual))
  useEffect(() => {
    setEstado(estado)
    console.log(estado)
  }, [estado])
  console.log("estado", estado)
  //  campos numericos
  const handleNumericInput = (value, setValue) => {
    // Allow empty value, integers, or decimals
    if (/^\d*\.?\d*$/.test(value)) {
      setM3(value)
      setError(false) // No error if the input is valid
    } else {
      setError(true) // Show error if the input is invalid
    }
    console.log(m3)
  }
  useEffect(() => {
    setM3(m3)
    console.log(m3)
  }, [m3])
  //  react query para creacion
  function useUpdateBahia() {
    const queryClient = useQueryClient()
    return useMutation({
      mutationFn: async (bahia) => {
        // send api update request here
        // send api update request here
        const options = {
          method: "PUT",
          body: JSON.stringify(bahia),
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }
        console.log("json", options.body)
        console.log(`/Bahias/updateBahia/${codigo}/${bodega}`)
        let response = await fetchwrapper(`/Bahias/updateBahia/${codigo}/${bodega}`, options)

        response = response.json()
        console.log(response)
        return response
      },
      // client side optimistic update
      onMutate: (newBahia) => {
        console.log(newBahia, "onmutate")
        queryClient.setQueryData(["Bahia", id], (prevBahia) => ({
          ...newBahia,
        }))
      },
      // onSettled: () => queryClient.invalidateQueries({ queryKey: ['bancoDePreguntas'] }), //refetch bancoDePreguntas after mutation, disabled for demo
    })
  }
  //  handle submit del forms
  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      const bahia = {
        descripcionBahia: descripcion,
        zonaBahia: zona,
        pasilloBahia: pasillo,
        metrosCubicos: m3,
        clasificacionBahia: clasificacion,
        estadoBahia: estado.value,
      }
      console.log("json", bahia)
      //    createBahia llama a useCreateBahia
      await updateBahia(bahia)

      Swal.fire({
        icon: "success",
        title: "Operación exitosa",
        text: "Se pudo editar la Bahia",
      }).then((result) => {
        if (result.isConfirmed) {
          navigate("/home/dashboard/Bahias/buscar") // Navegar a la ruta especificada
        }
      })
    } catch (error) {
      Swal.fire({
        title: "Error",
        text: "No se pudo editar la Bahia",
        icon: "error",
        confirmButtonText: "OK",
      }).then(() => {
        navigate("/home/dashboard/Bahias/buscar") // Navega de vuelta cuando se cierre el SweetAlert
      })
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <Header />
      <div className="main main-app p-3 p-lg-4">
        <BackIcon />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "0 30px 30px 30px",
            fontSize: "25px",
          }}
        >
          <b>Bahias o Perchas</b>
        </div>
        <Box className={StyledRoot}>
          <form onSubmit={handleSubmit}>
            <Tooltip title="Grabar">
              <Button type="submit" color="primary" sx={{ marginTop: "15px" }}>
                <img src={GrabarIcon} alt="Grabar" style={{ width: "40px" }} />
              </Button>
            </Tooltip>
            <Tooltip title="Imprimir">
              <Button color="primary" onClick={handleOpenModal} sx={{ marginTop: "15px" }}>
                <img src={ImprimirIcon} alt="Imprimir" style={{ width: "30px" }} />
              </Button>
            </Tooltip>
            <DetalleImpresion
              open={openModal}
              handleClose={handleCloseModal}
              infoGenerada={[datosActuales]}
              nombrePdf={"Bahia"}
              PdfComponent={BahiaPdf}
            />
            <Container>
              <CodigoBox>
                <CustomTextFieldReadable label="Codigo" value={codigo}></CustomTextFieldReadable>
              </CodigoBox>
              <DescripcionBox>
                <InputLabel>Descripcion</InputLabel>
                <TextField fullWidth value={descripcion} onChange={handleDescripcionBahia} />
              </DescripcionBox>
              <BodegaBox>
                <CustomTextFieldReadable
                  label="Bodega"
                  value={`${bodega}-${bodegaDescripcion}`}
                ></CustomTextFieldReadable>
              </BodegaBox>
              <ZonaBox>
                <InputLabel>Zona</InputLabel>
                <CustomAutoComplete
                  items={dataZonas}
                  onSelect={handleZonaChange}
                  placeholder={"Seleccionar zona"}
                  value={autoCompleteValueZona}
                />
              </ZonaBox>
              <PasilloBox>
                <InputLabel>Pasillo</InputLabel>
                <CustomAutoComplete
                  items={dataPasillos}
                  onSelect={handlePasilloChange}
                  placeholder={"Seleccionar pasillo"}
                  value={autoCompleteValuePasillo}
                />
              </PasilloBox>
              <M3Box>
                <InputLabel>M3</InputLabel>
                <FormControl fullWidth error={error}>
                  <TextField
                    value={m3}
                    onChange={(e) => handleNumericInput(e.target.value)}
                    error={error} // Set error state in TextField
                    helperText={error ? "Por favor ingresa solo valores numéricos." : ""}
                  />
                  {error && <FormHelperText>El valor ingresado no es válido.</FormHelperText>}
                </FormControl>
              </M3Box>
              <ClasificacionBox>
                <InputLabel>Clasificacion</InputLabel>
                <CustomAutoComplete
                  items={dataClasificaciones}
                  onSelect={handleClasificacionChange}
                  placeholder={`${clasificacion}`}
                  value={autoCompleteValueClasificacion}
                />
              </ClasificacionBox>
              <EstadoBox>
                <InputLabel>Estado</InputLabel>
                <Select
                  fullWidth
                  labelId="estadoBahiasCB"
                  value={estado.value}
                  onChange={(e) => {
                    const selectedValue = e.target.value
                    const bahiaestado = Estados.find((estado) => estado.value === selectedValue)
                    setEstado(bahiaestado)
                    //   clearAll(tipoPregunta)
                  }}
                >
                  {Estados.map((estado) => (
                    <MenuItem key={estado.value} value={estado.value}>
                      {estado.description}
                    </MenuItem>
                  ))}
                </Select>
              </EstadoBox>
            </Container>
          </form>
        </Box>
        <CustomBackdrop isLoading={isUpdatingBahia} />
      </div>
    </ThemeProvider>
  )
}

export default EditarBahia
