import React, { useEffect } from "react"
import { TextField, MenuItem } from "@mui/material"
import esPreguntaObligatoria from "../utils/esPreguntaObligatoria"

const ListaOpciones = ({
  description,
  setValue,
  pregcodigo,
  value,
  opciones,
  pregobligatoria,
  form,
  step,
  isReadable,
}) => {
  const handleTextFieldChange = (event) => {
    // Actualizar el estado con el valor escrito
    let nuevaRespuesta = event.target.value
    nuevaRespuesta = opciones.find((opcion) => opcion.opcion === nuevaRespuesta)

    const preguntaConRespuesta = form.preguntas.map((pregunta) => {
      if (pregunta?.pregcodigo === pregcodigo) {
        return {
          ...pregunta,
          respuestaSelected: nuevaRespuesta,
        }
      }
      return pregunta
    })

    const updatedForm = {
      cabecera: { ...form.cabecera },
      preguntas: preguntaConRespuesta,
    }

    setValue(step, updatedForm)
  }

  return (
    <div>
      <p style={{ paddingLeft: "5px", fontWeight: "bolder", fontSize: "1.1rem" }}>{description}</p>
      <TextField
        sx={{ marginTop: "0px" }}
        select
        label={`Lista de opciones ${esPreguntaObligatoria(pregobligatoria)}`}
        variant="outlined"
        fullWidth
        margin="normal"
        value={value}
        onChange={handleTextFieldChange}
        {...(isReadable && { InputProps: { readOnly: true } })}
      >
        {opciones.map((opcion, index) => (
          <MenuItem key={index} value={opcion.opcion}>
            {opcion.opcion}
          </MenuItem>
        ))}
      </TextField>
    </div>
  )
}

export default ListaOpciones
