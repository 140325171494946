import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Typography from "@mui/material/Typography"
import Modal from "@mui/material/Modal"

import { useState, useEffect } from "react"
import fetchwrapper from "../../../services/interceptors/fetchwrapper"
import CircularProgress from "@mui/material/CircularProgress"

import { useNavigate } from "react-router-dom"
import { useMutation } from "@tanstack/react-query"
import { useCartStore } from "../../../stores/cartStore"
import { FcCancel, FcUpload } from "react-icons/fc"
import PDFButton from "./FiltrarProductos/components/PDFButton"
import CustomBackdrop from "../../../components/CustomBackdrop"

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
}

// {
//   "user": "VENTAS2",
//   "pedusuisys": "zzuña",
//   "pedvalser": "01",
//   "mesacodigo": "01",
//   "pedtivacer" : 0,
//   "pedtivapor" : 1,
//   "pedsubtot" : 1,
//   "pediva" : 1,
//   "pedtotal" : 1,
//   "peddetalle" : "un detalle"
// }
const styles = {
  modalStyle: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    maxHeight: "80vh", // Limita la altura máxima del modal
    overflowY: "auto", // Habilita el desplazamiento vertical
  },
  closeStyle: {
    cursor: "pointer",
    marginLeft: "95%",
  },
}

const CrearProforma = ({ pedNum, crearProforma = false, detalleProforma, cabeceraProforma }) => {
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(true)
  const [loadFails, setLoadFails] = useState(false)
  const [numProforma, setNumProforma] = useState("")
  const [open, setOpen] = useState(false)

  const [proformaDatosOficiales, setProformaDatosOficiales] = useState({})
  const [openModalPdf, setOpenModalPdf] = useState(false)
  const handleOpenModalPdf = () => setOpenModalPdf(true)
  const handleCloseModalPdf = () => {
    setOpenModalPdf(false)
    navigate(`/home/dashboard/Creacion de Catalogos/Filtros`)
  }

  // //--------------------------------------------------------------

  //                     NEW !!!!!!
  // //--------------------------------------------------------------

  const { clearUseCartStoreStorage } = useCartStore()
  // const [isLoadingProforma, setIsLoadingProforma] = useState(false);

  // call UPDATE hook
  const { mutateAsync: createProforma, isPending: isCreatingProforma } = useCreateProforma()

  // UPDATE hook (put Proforma in api)
  function useCreateProforma() {
    return useMutation({
      mutationFn: async (proforma) => {
        // send api update request here
        const options = {
          method: "POST",
          body: JSON.stringify({
            ...proforma,
          }),
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }

        let response = await fetchwrapper("/proformas/createProforma", options)
        response = await response.json()

        return response
      },
    })
  }
  const handleCreateProforma = async () => {
    const proforma = {
      cabecera: cabeceraProforma,
      detalle: detalleProforma,
    }
    console.log("creandooo...")

    try {
      console.log(cabeceraProforma, cabeceraProforma.clicodigo, cabeceraProforma.factippag, cabeceraProforma.vencodigo)
      if (cabeceraProforma.clicodigo && cabeceraProforma.factippag && cabeceraProforma.vencodigo) {
        const { numPed: idProforma, cabecera, detalle } = await createProforma(proforma)
        setProformaDatosOficiales({
          cabecera,
          detalle,
        })

        handleOpenModalPdf()

        clearUseCartStoreStorage()
        alert(`Proforma ${idProforma} creada con exito`)
      } else {
        alert("Debe llenar todos los campos ")
      }
    } catch (error) {
      alert("No se pudo crear la proforma")
    }
  }

  const guardarProforma = async () => {
    // console.log(detalleProforma);
    // console.log(cabeceraProforma)
    const api = { cabecera: cabeceraProforma, detalle: detalleProforma }
    console.log(api, "aquiii")
    // const userGrupo = localStorage.getItem("cliciagrupo");
    // const user = localStorage.getItem("cliciausu");

    // const pednumped=JSON.parse(localStorage.getItem("proformaVolatil")).numPed;

    //     try {

    //       console.log("****************************************")
    //       //Actualizar cabecera
    // /**'numped', 'user', 'pedtivapor', 'pedsubtot', 'pediva',
    //         'pedtotal', 'pedusuisys', 'ciacodigo', 'loccodigo',
    //         'pedvalser', 'mesacodigo','pedstatus' */
    //       const optionsUpdateCabecera = {
    //         method: "POST",
    //         body: JSON.stringify({
    //           numped:pedNum,
    //           user:userGrupo,
    //           pedtivapor:cabeceraProforma.pedtivapor,
    //           pedsubtot:cabeceraProforma.pedsubtot,
    //           pediva:cabeceraProforma.pediva,
    //           pedtotal:cabeceraProforma.pedtotal,
    //           pedusuisys:user,
    //           ciacodigo:cabeceraProforma.ciacodigo,
    //           loccodigo:cabeceraProforma.loccodigo,
    //           pedvalser:"01",
    //           mesacodigo:"01",
    //           pedstatus:"C"

    //         }),
    //         headers: {
    //           "Content-Type": "application/json",
    //           "Access-Control-Allow-Origin": "*",
    //         },
    //       };
    //       console.log({
    //         numped:pedNum,
    //         user:userGrupo,
    //         pedtivapor:cabeceraProforma.pedtivapor,
    //         pedsubtot:cabeceraProforma.pedsubtot,
    //         pediva:cabeceraProforma.pediva,
    //         pedtotal:cabeceraProforma.pedtotal,
    //         pedusuisys:user,
    //         ciacodigo:cabeceraProforma.ciacodigo,
    //         loccodigo:cabeceraProforma.loccodigo,
    //         pedvalser:cabeceraProforma.pedvalser,
    //         mesacodigo:"01",
    //         pedstatus:"C"

    //       })
    //       console.log(optionsUpdateCabecera)

    //       let proformaUpdateCabecera = await fetchwrapper(
    //         "/proformas/updateCabecera",
    //         optionsUpdateCabecera
    //       );

    //       proformaUpdateCabecera = await proformaUpdateCabecera.json();
    //       console.log(proformaUpdateCabecera)
    //       if (proformaUpdateCabecera?.error) {
    //         alert("Error al guardar proforma");
    //         console.log(proformaUpdateCabecera)
    //         console.log(proformaUpdateCabecera.error)

    //         throw new Error("Error al guardar cabecera");

    //       }
    //       console.log(proformaUpdateCabecera)

    //       //Actualizar Detalles
    //       /**# {
    // #     "numped":"PRA2300007506",
    // #     "ciacodigo": "01",
    // #     "pedsecuen": 1,
    // #     "facnumfac":null,
    // #     "pedtipo":"PR",
    // #     "pedapliiva":-1,
    // #    "factippag":"CHQ",
    // #    "moncodigo":"D"

    // # }
    //       const optionsUpdateDetalles = {
    //         method: "POST",
    //         body: JSON.stringify({

    //           numped:pedNum,
    //           ciacodigo:cabeceraProforma.ciacodigo,
    //           pedsecuen:1,

    //          }),
    //         headers: {
    //           "Content-Type": "application/json",
    //           "Access-Control-Allow-Origin": "*",
    //         },
    //       };
    //       console.log(optionsUpdateDetalles)

    //       let proformaUpdateDetalle = await fetchwrapper(
    //         "/proformas/updateDetalle",
    //         optionsUpdateDetalles
    //       );
    //       proformaUpdateDetalle = await proformaUpdateDetalle.json();
    //       if (proformaUpdateDetalle?.error) {
    //         throw new Error("Error al guardar detalle");
    //       }*/

    //       setIsLoading(false);
    //       alert("Proforma guardada con éxito");
    //       navigate("/home/dashboard/Proformas / Pedidos");
    //       //localStorage.setItem("cart", JSON.stringify([]));
    //       //localStorage.setItem("proformaVolatil", JSON.stringify({}));
    //     } catch (error) {
    //       console.log(error);
    //       alert("Error al guardar proforma");
    //     }
  }

  const handleCancel = () => {
    // Vaciar el carrito y poner vacio la proforma volatil
    // navigate("/home/dashboard/Proformas / Pedidos");
    // localStorage.setItem("cart", JSON.stringify([]));
    navigate("/home/dashboard/Creacion de Catalogos/Filtros")
  }
  const handleClose = () => setOpen(false)

  const createProforma1 = async () => {
    const user = localStorage.getItem("cliciagrupo")
    console.log(user)
    try {
      const options = {
        method: "POST",
        body: JSON.stringify({
          user: "VENTAS2",
          pedusuisys: "zzuña",
          pedvalser: "00",
          mesacodigo: "00",
          pedtivacer: 1,
          pedtivapor: 1,
          pedsubtot: 1,
          pediva: 12,
          pedtotal: 1,
          peddetalle: "detalle",
        }),
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
      let proforma = await fetchwrapper("/proformas/nuevaCabecera", options)
      proforma = await proforma.json()
      if (proforma?.error) {
        throw new Error("se fue")
      }
      setNumProforma(proforma.numPed)
      localStorage.setItem(
        "proformaVolatil",
        JSON.stringify({
          numPed: proforma.numPed,
        }),
      )
    } catch (err) {
      setLoadFails(true)
    } finally {
      setIsLoading(false)
      navigate(`/home/dashboard/proforma/${JSON.parse(localStorage.getItem("proformaVolatil"))?.numPed}`)
    }
  }

  return (
    <div>
      <CustomBackdrop isLoading={isCreatingProforma} />
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          textAlign: "left",
          marginRight: "25px",
        }}
      >
        <Button
          variant="contained"
          sx={{ marginRight: "15px" }}
          onClick={() => {
            handleCancel()
          }}
          color="secondary"
        >
          <span className="me-2"> Cancelar Proforma</span>
          <FcCancel size={"25px"} />
        </Button>
        <Button
          hidden={!crearProforma}
          variant="contained"
          sx={{ marginRight: "15px" }}
          onClick={() => {
            // guardarProforma();
            handleCreateProforma()
          }}
          color="secondary"
        >
          <span className="me-2">Emitir Proforma</span>
          <FcUpload size={"25px"} />
        </Button>
        <Button
          hidden={crearProforma}
          variant="contained"
          sx={{ marginRight: "15px" }}
          onClick={() => {
            guardarProforma()
          }}
          color="secondary"
        >
          <span className="me-2">Procesar Proforma</span>
          <FcUpload size={"25px"} />
        </Button>
      </div>
      {/* <PDFButton
        proforma={{
          cabecera: cabeceraProforma,
          detalle: detalleProforma,
        }}
      /> */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {isLoading ? (
            <CircularProgress size={24} />
          ) : loadFails ? (
            <Typography id="modal-modal-title" variant="h6" component="h2">
              La creación de proforma tuvo un fallo
            </Typography>
          ) : (
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Proforma guardada con exito:
              <br />
              {numProforma}
            </Typography>
          )}
        </Box>
      </Modal>

      <Modal
        open={openModalPdf}
        onClose={handleCloseModalPdf}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styles.modalStyle}>
          <h2 style={{ textAlign: "center" }}>¿Desea descargar el PDF?</h2>
          <div style={{ textAlign: "center" }}>
            <Button
              variant="contained"
              color="error"
              onClick={() => {
                handleCloseModalPdf()
              }}
            >
              No
            </Button>

            <PDFButton proforma={proformaDatosOficiales} handleCloseModalPdf={handleCloseModalPdf} />
          </div>
        </Box>
      </Modal>
    </div>
  )
}

export default CrearProforma
