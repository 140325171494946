import React, { useState, useRef } from "react"
import AccordionFiltros from "./components/AccordionFiltros"
import Header from "../../layouts/Header"
import { styled, createTheme, ThemeProvider } from "@mui/material/styles"
import { Box, Button, Grid, Typography, CircularProgress } from "@mui/material"
import CardProducto from "./components/CardProducto"
import Pagination from "@mui/material/Pagination"

import FullRoute from "../../components/FullRoute"
import ScannerReaderButton from "./components/ScannerReaderButton"
import { NavLink } from "react-router-dom"
import BackIcon from "../../components/BackIcon"

const StyledRoot = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  marginTop: theme.spacing(8),
  flexGrow: 1,
  padding: "0 16px",
  height: "100vh",
}))

const theme = createTheme({
  palette: {
    primary: {
      main: "#196C87",
    },
    secondary: {
      main: "#196C87", // Cambia el color secundario a verde azulado
    },
  },
})

const CatalogoProductos = () => {
  return (
    <>
      <ThemeProvider theme={theme}>
        <Header />
        <div className="main main-app p-3 p-lg-4">
          <BackIcon />
          <div className="d-md-flex align-items-center justify-content-between mb-4"></div>
          {/* <FullRoute/> */}

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              width: "30%",
              margin: "0 auto",
            }}
          >
            <span>
              <ScannerReaderButton codeType="qrCode" />
            </span>

            <NavLink to={"Filtros"} style={{ marginTop: "15px" }}>
              <Button variant="contained" color="primary" fullWidth>
                Filtros
              </Button>
            </NavLink>
          </div>
        </div>
      </ThemeProvider>
    </>
  )
}

export default CatalogoProductos
