import { useMemo, useState } from "react"
import {
  MaterialReactTable,
  // createRow,
  useMaterialReactTable,
} from "material-react-table"
import DetalleImpresion from "../../Zonas/components/DetalleImpresion"
import CustomBackdrop from "../../../components/CustomBackdrop"
import { MRT_Localization_ES } from "material-react-table/locales/es"
import { createTheme, ThemeProvider, useTheme, Box, Button, IconButton, Tooltip } from "@mui/material"
import { esES } from "@mui/material/locale"
import ImprimirIcon from "../../../assets/iconos/Impresion.ico"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import EditIcon from "@mui/icons-material/Edit"
import EliminarIcon from "../../../assets/iconos/Eliminar.ico"
import DetalleIcon from "../../../assets/iconos/Detalle.ico"
import DeleteIcon from "@mui/icons-material/Delete"
import fetchwrapper from "../../../services/interceptors/fetchwrapper"
import { useNavigate } from "react-router-dom"
import CrearIcon from "../../../assets/iconos/Crear.ico"
import PasillosPdf from "./PasillosPdf"
import normalFormatDate from "../../utils/date/DDMMYYYFormatDate"
import normalFormatHour from "../../utils/date/HHMMSSFormatHour"

const CustomTable = () => {
  // para movernos a crear y editar
  const navigate = useNavigate()
  const columns = useMemo(
    () => [
      {
        accessorKey: "pascodigo",
        header: "Código",
        enableEditing: false,
        size: 80,
      },
      {
        accessorKey: "pasdescripcion",
        header: "Descripción",
        enableEditing: false,
        size: 80,
      },
      {
        accessorKey: "passtatus",
        header: "Estado",
        enableEditing: false,
        size: 80,
        Cell: ({ cell }) => {
          let cellValue = cell.getValue()
          if (cellValue === "A") {
            cellValue = "ACTIVO"
          } else {
            cellValue = "INACTIVO"
          }
          return cellValue
        },
      },
      {
        accessorKey: "pasusuisys",
        header: "Usuario Creacion",
        enableEditing: false,
        size: 80,
      },
      {
        accessorKey: "pasfecisys",
        header: "Fecha de Creación",
        enableEditing: false,
        size: 80,
        Cell: ({ cell }) => {
          const cellValue = cell.getValue()
          return normalFormatDate(cellValue)
        },
      },
      {
        accessorKey: "pashorisys",
        header: "Hora de Creación",
        enableEditing: false,
        size: 80,
        Cell: ({ cell }) => {
          const cellValue = cell.getValue()
          return new Date(cellValue).toLocaleTimeString()
        },
      },
      {
        accessorKey: "pasusumsys",
        header: "Usuario MOdifica",
        enableEditing: false,
        size: 80,
      },
      {
        accessorKey: "pasfecmsys",
        header: "Fecha Modifica",
        enableEditing: false,
        size: 80,
        Cell: ({ cell }) => {
          const cellValue = cell.getValue()
          return normalFormatDate(cellValue)
        },
      },
      {
        accessorKey: "pashormsys",
        header: "Hora Modifica",
        enableEditing: false,
        size: 80,
        Cell: ({ cell }) => {
          const cellValue = cell.getValue()
          return new Date(cellValue).toLocaleTimeString()
        },
      },
    ],
    [],
  )
  // useCreatePasillos crea las zonas
  // createPasillos funcion que se llama
  const { mutateAsync: createPasillos, isPending: isCreatingPasillos } = useCreatePasillos()

  const {
    data: fetchedPasillos = [],
    isError: isLoadingPasillosError,
    isFetching: isFetchingPasillos,
    isLoading: isLoadingPasillos,
  } = useGetPasillos()
  // useUpdateZona() hook de update, ,updateZona funcion que se ejecuta
  const { mutateAsync: updatePasillo, isPending: isUpdatingPasillo } = useUpdatePasillo()
  // useDeleteZona() hook de delete , usa la funcion deleteZona()
  const { mutateAsync: deletePasillo, isPending: isDeletingPasillo } = useDeletePasillo()
  // funcion que muestra el modal de eliminacion
  const openDeleteConfirmModal = async (row) => {
    if (window.confirm("¿Está seguro que quiere eliminar esta pasillo?")) {
      await deletePasillo(row.original.pascodigo)
    }
  }
  const table = useMaterialReactTable({
    columns,
    data: fetchedPasillos,
    enableEditing: true,
    initialState: {
      showColumnFilters: true,
    },
    enableRowActions: true,
    localization: { ...MRT_Localization_ES },
    getRowId: (row) => row.pascodigo,
    // si se cargar el error al importar los datos
    muiToolbarAlertBannerProps: isLoadingPasillosError
      ? {
          color: "error",
          children: "Error en cargar los pasillos",
        }
      : undefined,
    muiTableContainerProps: {
      sx: {
        minHeight: "500px",
      },
    },
    renderRowActions: ({ row, table }) => (
      <Box sx={{ display: "flex", gap: "1rem" }}>
        <Tooltip title="Editar">
          <IconButton
            onClick={() => {
              navigate(`editar/${row?.original.pascodigo}`, {
                state: row?.original,
              })
            }}
          >
            <img src={DetalleIcon} alt="Editar" style={{ width: "20px" }} />
          </IconButton>
        </Tooltip>
        <Tooltip title="Eliminar">
          <IconButton color="error" onClick={() => openDeleteConfirmModal(row)}>
            <img src={EliminarIcon} alt="Grabar" style={{ width: "20px" }} />
          </IconButton>
        </Tooltip>
      </Box>
    ),
    state: {
      isLoading: isLoadingPasillos,
      isSaving: isCreatingPasillos || isUpdatingPasillo || isDeletingPasillo,
      showAlertBanner: isLoadingPasillos,
      showProgressBars: isFetchingPasillos,
    },
  })
  return (
    <>
      <MaterialReactTable table={table} />
      <CustomBackdrop isLoading={table.getState().isSaving} />
    </>
  )
}
function useGetPasillos() {
  return useQuery({
    queryKey: ["Pasillos"],
    queryFn: async () => {
      // send api request here
      let response = await fetchwrapper("/Pasillos/getPasillos")
      response = await response.json()
      response = response?.data
      // await new Promise((resolve) => setTimeout(resolve, 1000)); //fake api call
      return response
    },
    refetchOnWindowFocus: false,
  })
}

// hooks a los que se les asigna una api y se encargan de actualizar el estado del componente
function useCreatePasillos() {
  // hook para crear la zona
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (pasillo) => {
      await new Promise((resolve) => setTimeout(resolve, 1000)) // fake api call
      return Promise.resolve()
    },
    onMutate: (newPasillo) => {
      queryClient.setQueryData(["Pasillos"], (prevPasillos) =>
        prevPasillos?.map((prevPasillos) =>
          prevPasillos.pascodigo === newPasillo.pascodigo ? newPasillo : prevPasillos,
        ),
      )
    },
  })
}
function useUpdatePasillo() {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (pasillo) => {
      // send api update request here
      await new Promise((resolve) => setTimeout(resolve, 1000)) // fake api call
      return Promise.resolve()
    },
    // client side optimistic update
    onMutate: (newPasilloInfo) => {
      queryClient.setQueryData(["Pasillos"], (prevPasillos) =>
        prevPasillos?.map((prevPasillos) =>
          prevPasillos.pascodigo === newPasilloInfo.pascodigo ? newPasilloInfo : prevPasillos,
        ),
      )
    },
    // onSettled: () => queryClient.invalidateQueries({ queryKey: ['bancoDePreguntas'] }), //refetch bancoDePreguntas after mutation, disabled for demo
  })
}
function useDeletePasillo() {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (codigoPasillo) => {
      const options = {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }

      const response = await fetchwrapper(`/Pasillos/deletePasillo/${codigoPasillo}`, options)
      return response
    },
    // actualizacion optimista
    onMutate: (codigoPasillo) => {
      queryClient.setQueryData(["Pasillos"], (prevPasillos) =>
        prevPasillos?.filter((pasillo) => pasillo.pascodigo !== codigoPasillo),
      )
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ["Pasillos"] })
    },
    onSuccess: () => {
      alert("Pasillo eliminado con exito")
    },
    onError: () => {
      alert("No se pudo eliminar el pasillo")
    },
  })
}

const DataGridPasillos = () => {
  const [openModal, setOpenModal] = useState(false)
  const [infoGenerada, setInfoGenerada] = useState([])
  const {
    data: fetchedPasillos = [],
    isError: isLoadingPasillosError,
    isFetching: isFetchingPasillos,
    isLoading: isLoadingPasillos,
  } = useGetPasillos()
  const handleOpenModal = () => {
    setInfoGenerada(fetchedPasillos) // Asigna los datos de la tabla al estado
    setOpenModal(true)
  }
  console.log("info pdf", fetchedPasillos)
  const handleCloseModal = () => {
    setOpenModal(false)
    navigate("/home/dashboard/Pasillos")
  }
  const theme = useTheme()
  const navigate = useNavigate()
  return (
    <div>
      <Tooltip title="Crear Pasillo">
        <Button color="primary" onClick={() => navigate("crear")} sx={{ marginBottom: "15px" }}>
          <img src={CrearIcon} alt="Crear" style={{ width: "30px" }} />
        </Button>
      </Tooltip>
      <Tooltip title="Imprimir">
        <Button color="primary" onClick={handleOpenModal} sx={{ marginBottom: "15px" }}>
          <img src={ImprimirIcon} alt="Imprimir" style={{ width: "30px" }} />
        </Button>
      </Tooltip>
      <DetalleImpresion
        open={openModal}
        handleClose={handleCloseModal}
        infoGenerada={fetchedPasillos}
        nombrePdf={"Pasillos"}
        PdfComponent={PasillosPdf}
      />
      <ThemeProvider theme={createTheme(theme, esES)}>
        <CustomTable />
      </ThemeProvider>
    </div>
  )
}
export default DataGridPasillos
