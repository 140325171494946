import React, { useEffect, useState } from "react"
import { Modal, Box, Button, Tooltip, IconButton } from "@mui/material"
import { PDFViewer, PDFDownloadLink, pdf } from "@react-pdf/renderer"
import RegresarIcon from "../../../assets/iconos/Regresar.ico"
import { FcDocument } from "react-icons/fc"

const DetalleImpresion = ({ open, handleClose, infoGenerada, nombrePdf, PdfComponent }) => {
  const [key, setKey] = useState(0)

  const handlePrint = async () => {
    const blob = await pdf(<PdfComponent data={infoGenerada} />).toBlob()
    const url = URL.createObjectURL(blob)
    const link = document.createElement("a")
    link.href = url
    link.download = `${nombrePdf}.pdf`
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    handleClose()
  }

  const handleRegenerate = () => {
    setKey((prevKey) => prevKey + 1)
  }

  useEffect(() => {
    console.log(JSON.stringify(infoGenerada), "web")
  }, [infoGenerada])

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 600,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
        }}
      >
        <Button
          onClick={handlePrint}
          style={{ marginTop: "20px" }}
          variant="contained"
          sx={{
            marginRight: "15px",
            marginTop: "20px",
            marginBottom: "10px",
          }}
          color="secondary"
        >
          <span className="me-2">Descargar {nombrePdf}</span>
          <FcDocument size={"25px"} />
        </Button>

        <PDFViewer key={key} style={{ width: "100%", height: "80vh" }}>
          <PdfComponent data={infoGenerada} />
        </PDFViewer>
        <Tooltip title="Cerrar">
          <IconButton color="error" onClick={handleClose}>
            <img src={RegresarIcon} alt="Grabar" style={{ width: "40px" }} />
          </IconButton>
        </Tooltip>
      </Box>
    </Modal>
  )
}

export default DetalleImpresion
