import React, { useState, useRef } from "react"
import AccordionFiltros from "./components/AccordionFiltros"
import Header from "../../../../layouts/Header"
import { styled, createTheme, ThemeProvider } from "@mui/material/styles"
import { Box, Button, Grid, Typography, CircularProgress } from "@mui/material"
import CardProducto from "./components/CardProducto"
import Pagination from "@mui/material/Pagination"
import ModalProducto from "./components/ModalProducto"
import BackIcon from "./../../../../components/BackIcon"

const StyledRoot = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  marginTop: theme.spacing(8),
  flexGrow: 1,
  padding: "0 16px",
  height: "100vh",
}))

const theme = createTheme({
  palette: {
    primary: {
      main: "#196C87",
    },
    secondary: {
      main: "#196C87", // Cambia el color secundario a verde azulado
    },
  },
})

const FiltrosCatalogosProductos = () => {
  const [productos, setProductos] = useState([])
  const [page, setPage] = useState(0)
  const [total, setTotal] = useState(0)
  const accordionRef = useRef(null)
  const [open, setOpen] = useState(false)
  const [selectedProduct, setSelectedProduct] = useState(null)
  const [filtersApplied, setFiltersApplied] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [filters, setFilters] = useState({})

  const productsPerPage = 10
  const limit = productsPerPage
  const offset = page * productsPerPage
  console.log(offset, "offset")
  console.log(page, "page")
  const handlePageChange = (newPage) => {
    setPage(newPage - 1)
    accordionRef.current.handleFiltros(newPage - 1)
    console.log(total, "totalaaaaaaaaaaaaa")
  }

  const handleFilteredProducts = async (data) => {
    setIsLoading(true)
    setProductos(data.data)
    setFiltersApplied(true)
    setTotal(data.total_count)
    // if data have only one page then set total to 1
    // if productos empty then set page to 1
    if (!data.data) {
      setPage(0)
    }
    /* if (offset > 0) {
            setPage(1);
        } */
    window.scrollTo(0, 0)
    setIsLoading(false)
    console.log(total, "total")
  }

  const handleOpenModal = (product) => {
    setSelectedProduct(product)
    setOpen(true)
  }

  // function to handle closing the Modal
  const handleCloseModal = () => {
    setSelectedProduct(null)
    setOpen(false)
  }

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          margin: "0 30px 30px 30px",
        }}
      >
        <AccordionFiltros
          apiFILTROS="/filter/make_filter_with_image"
          apiTODOS="/productos/get_productos_fichas"
          onFilteredProducts={handleFilteredProducts}
          page={page}
          limit={limit}
          ref={accordionRef}
          //   elem={<ScannerReaderButton codeType="qrCode" />}
        />
      </div>
      {isLoading ? (
        <Box sx={{ display: "flex", justifyContent: "center", my: 3 }}>
          <CircularProgress color="secondary" />
        </Box>
      ) : productos && productos.length > 0 ? (
        <Box className={StyledRoot}>
          <Grid container spacing={3} sx={{ alignItems: "stretch" }}>
            {productos.map((producto) => (
              // eslint-disable-next-line react/jsx-key
              <Grid item xs={12} sm={6}>
                <CardProducto producto={producto} sx={{ height: "100%" }} onClick={() => handleOpenModal(producto)} />
              </Grid>
            ))}
            {selectedProduct && (
              <ModalProducto
                isOpen={open}
                handleClose={handleCloseModal}
                cproducto={"/productos/get_producto_por_codigo-" + selectedProduct.codigo}
              />
            )}
          </Grid>
          <Box sx={{ display: "flex", justifyContent: "center", my: 3 }}>
            <Pagination
              count={Math.ceil(total / productsPerPage)}
              page={page + 1}
              onChange={(event, value) => handlePageChange(value)}
              color="primary"
            />
          </Box>
        </Box>
      ) : (
        <Typography variant="h6" align="center">
          {filtersApplied
            ? "No se encontraron productos que coincidan con los filtros aplicados"
            : "Dale click a Filtrar para mostrar los productos"}
        </Typography>
      )}
    </>
  )
}

export default FiltrosCatalogosProductos
