import React, { useEffect, useState, useContext } from "react"

import { SolicitudEntregaContext, SolicitudEntregaContextProvider } from "../../SolicitudDeEntregaContext"
import Header from "../../../../layouts/Header"
import { styled, createTheme, ThemeProvider } from "@mui/material/styles"
import { Box, TextField, InputLabel, Button } from "@mui/material"

import EditarSolicitudEntregaComponent from "../../components/EditarSolicitudEntregaComponent"

const theme = createTheme({
  palette: {
    primary: {
      main: "#196C87",
    },
    secondary: {
      main: "#196C87", // Cambia el color secundario a verde azulado
    },
  },
})

const StyledBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "16px",
  width: "100%",
}))

const StyledBoxTitle = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  margin: "0 30px 30px 30px",
  fontSize: "25px",
}))

const StyledBoxContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",
  gap: "16px",
  margin: "0 30px",
  width: "100%",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    alignItems: "center",
  },
}))

const StyledBoxGrid = styled(Box)(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",
  gap: "16px",
  margin: "0 30px",
  width: "100%",
}))

const StyledRoot = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  marginTop: theme.spacing(8),
  flexGrow: 1,
  padding: "0 16px",
  height: "100vh",
}))

const DivRow = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  gap: "8px",
  width: "100%",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
  },
}))

const ItemContainer = styled("div")(({ theme }) => ({
  flex: 1,
}))

const VerSolicitudDeEntrega = () => {
  const moment = require("moment")
  const dayjs = require("dayjs")
  const [inputValue, setInputValue] = useState("")
  const productosEgreso = useContext(SolicitudEntregaContext)

  const handleDoubleClick = (codigo) => {
    setInputValue(codigo)
  }

  return (
    <SolicitudEntregaContextProvider>
      <ThemeProvider theme={theme}>
        <Header />
        <EditarSolicitudEntregaComponent />
      </ThemeProvider>
    </SolicitudEntregaContextProvider>
  )
}

export default VerSolicitudDeEntrega
