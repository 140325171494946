import React, { useState, useEffect, useRef } from "react"
import {
  Autocomplete,
  Box,
  Alert,
  AlertTitle,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  FormLabel,
  Stack,
} from "@mui/material"
import { DataGrid } from "@mui/x-data-grid"
import PrintIcon from "@mui/icons-material/Print"
import LoadingButton from "@mui/lab/LoadingButton"
import { styled, createTheme, ThemeProvider } from "@mui/material/styles"
import Header from "../../layouts/Header"
import ZebraBrowserPrintWrapper from "../utils/zebra/index.js"
import AccordionFiltros from "./components/AccordionFiltros"
import FullRoute from "../../components/FullRoute"
// import InputNestedChildren from "./components/InputNestedChildren";
import fetchwrapper from "../../services/interceptors/fetchwrapper"
import TextField from "@mui/material/TextField"
import ScannerReaderButton from "./components/ScannerReaderButton"
import PrintButton from "./components/PrintButton"
import ModalProducto from "./components/ModalProducto"
import { Button } from "react-bootstrap"
import BackIcon from "../../components/BackIcon"

// Estilos para Datagrid y botones de filtros
const StyledRoot = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  marginTop: theme.spacing(8),
  flexGrow: 1,
  padding: "0 16px",
  height: "100vh",
}))
const StyledDataGridContainer = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  overflowX: "auto",
}))

// Estilos para el color
const theme = createTheme({
  palette: {
    primary: {
      main: "#196C87",
    },
    secondary: {
      main: "#196C87", // Cambia el color secundario a verde azulado
    },
  },
})

const FiltrarProductos = () => {
  const [productos, setProductos] = useState([])
  const [selectedRows, setSelectedRows] = useState([])
  const [isLoading, setIsLoading] = useState(false) // Para el boton de imprimir
  // multiples alertas
  const [alertas, setAlertas] = useState([])
  const [ipPrint, setIpPrint] = useState("")
  const [codeType, setCodeType] = useState("qrcode") // default value
  const [limit, setLimit] = useState(10)
  const [page, setPage] = useState(0)
  const accordionRef = useRef(null)
  const [rowCount, setRowCount] = useState(0)
  const [options, setOptions] = useState([])
  const [isOpenModal, setIsOpenModal] = useState(false)
  const handleCloseModal = () => setIsOpenModal(false)
  const handleOpenModal = () => setIsOpenModal(true)
  const [currentCodeProductoClicked, setcurrentCodeProductoClicked] = useState("")
  // Definir las columnas personalizadas de la tabla
  const openModalSpecificItemClicked = (code) => {
    setcurrentCodeProductoClicked(`/productos/get_producto_por_codigo-${code}`)
    handleOpenModal(true)
  }
  const columnas = [
    {
      headerName: "Código",
      field: "codigo",
      sort: "asc",
      width: 150,
      renderCell: (params) => {
        return (
          <Button variant="contained" onClick={() => openModalSpecificItemClicked(params.value)}>
            {params.value}
          </Button>
        )
      },
    },
    {
      headerName: "Cnt Etiquetas",
      field: "artcantactual",
      sort: "asc",
      width: 100,
      type: "number",
      editable: true,
      valueGetter: (params) => {
        return `${Math.floor(params.value)}`
      },
      /* valueFormatter: (value) => {
        /*const parsedValue = parseInt(value, 10);
        if (isNaN(parsedValue) || parsedValue < 0) {
          return 0;
        }
       // return parseFloat(value).toFixed(2);
      } */
      // format the value to show 2 decimals
      valueFormatter: (params) => {
        return `${Math.floor(params.value)}`
        // return `${parseFloat(params.value).toFixed(2)}`;
      },
    },
    {
      headerName: "Descripción",
      field: "descripcion",
      sort: "asc",
      width: 270,
    },
    {
      headerName: "Precio",
      field: "precio",
      sort: "asc",
      width: 150,
      // parse value to number
      valueFormatter: (params) => {
        return `$${parseFloat(params.value).toFixed(2)}`
      },
    },
    {
      headerName: "Medida",
      field: "medida",
      sort: "asc",
      width: 250,
    },
    {
      headerName: "Presentación",
      field: "presentacion",
      sort: "asc",
      width: 250,
    },
    {
      headerName: "Marca",
      field: "marca",
      sort: "asc",
      width: 250,
    },
    {
      headerName: "Linea",
      field: "linea",
      sort: "asc",
      width: 250,
    },
  ]

  const handlePrintTypeChange = (event) => {
    setCodeType(event.target.value)
  }

  const handleFilteredProducts = (data) => {
    // see if the data is esctructured as data.data or just data
    if (data.data) {
      setProductos(data.data)
      setRowCount(data.total_count)
    } else {
      setProductos(data)
      setRowCount(data.length)
    }
  }

  const handleRowSelected = (params) => {
    // put the array of selected rows in the state
    setSelectedRows(params)
  }

  const handleCantidadEtiquetasChange = (params) => {
    // Busca el producto en el arreglo de datos y actualiza la propiedad "artcantactual"
    const productosActualizados = productos.map((producto) => {
      if (producto.codigo === params.codigo) {
        return { ...producto, artcantactual: params.artcantactual }
      }
      return producto
    })

    setProductos(productosActualizados)
  }

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow }
    // console.log(updatedRow);
    // handle send data to api
    handleCantidadEtiquetasChange(updatedRow)
    return updatedRow
  }

  // Función para cargar los datos de los productos desde API
  useEffect(() => {
    // cargarProductos();
  }, [selectedRows])

  useEffect(() => {
    const checkIpPrinter = async () => {
      try {
        const options = {
          method: "POST",
          body: JSON.stringify({
            printproceso: "ZEBRA",
          }),
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }
        let response = await fetchwrapper("/externos/get_impresoras", options)
        response = await response.json()

        console.log("esta es la api")
        console.log(response)
        setOptions(response)
        // setIpPrint(prev=>response[0].printip)
        console.log(ipPrint)

        // return response[0].printip
      } catch (err) {
        console.error("Erro impresora", err)
      }
    }

    checkIpPrinter()
  }, [])

  const getProductsPrintable = () => {
    if (selectedRows.length === 0) {
      alert("No hay productos seleccionados")
      return []
    }

    // const listaProductos = productos.filter(
    //   (producto) => selectedRows.includes(producto.codigo) && Number(producto.artcantactual) > 0
    // );
    const listaProductos = productos.filter((producto) => selectedRows.includes(producto.codigo))

    return listaProductos
  }

  const conectarImpresora = async (qrCode, i) => {
    let zpl = ""
    if (codeType === "qrcode") {
      const qrLink = "/productos/get_producto_por_codigo-" + qrCode
      zpl = `^XA
      ^FO120,120^BQN,2,10
      ^FDMA,${qrLink}^FS
      ^XZ`
    } else {
      const qrLink = "/productos-" + qrCode
      zpl = `^XA
        ^BY5,2,270
        ^FO100,550^BC^FD${qrLink}^FS
        ^XZ
        `
    }
    try {
      const browserPrint = new ZebraBrowserPrintWrapper("http://" + ipPrint + ":9100/")
      // Select default printer
      const defaultPrinter = await browserPrint.getDefaultPrinter()
      // Set the printer
      browserPrint.setPrinter(defaultPrinter)
      // Check printer status
      const printerStatus = await browserPrint.getPrinterStatus()
      // Check if printer is ready

      console.log(zpl)
      if (printerStatus.isReadyToPrint) {
        console.log("Printer is ready to print")
        // if barcode or qqrcode

        browserPrint.print(zpl)
      } else {
        console.log("Printer is not ready to print")
      }
    } catch (error) {
      console.log(zpl)
      return -1
    }
  }

  const mostrarAlertas = (codigo) => {
    console.log("No se conecta a la impresora 10.10.10 para el producto", codigo)
    return (
      <Alert
        key={codigo}
        severity="warning"
        onClose={() => setAlertas((prevAlertas) => prevAlertas.filter((a) => a.key !== codigo))}
      >
        <AlertTitle>QR code</AlertTitle>
        No se conecta a la impresora {ipPrint} para el producto {codigo}
      </Alert>
    )
  }

  const addAlerta = (alerta) => {
    console.log("New alerta:", alerta)
    const alertaEnLista = alertas.some((a) => Number(a.key) === Number(alerta.key))
    console.log("alertaEnLista:", alertaEnLista)
    if (alertaEnLista) {
      console.log("Alerta already exists")
      return
    }
    setAlertas((prevAlertas) => [...prevAlertas, alerta])
  }

  const imprimirEtiquetas = async (listaProductos) => {
    if (!Array.isArray(listaProductos) || listaProductos.length === 0) {
      return
    }
    let f
    for (const producto of listaProductos) {
      const qrCodeValue = producto.codigo
      for (let i = 0; i < producto.artcantactual; i++) {
        // is loading
        setIsLoading(true)
        f = await conectarImpresora(qrCodeValue, i)
        if (f === -1) {
          break
        }
      }
      if (f === -1) {
        addAlerta(mostrarAlertas(producto.codigo))
      }
    }
    setIsLoading(false)
  }

  const handlePageChange = (newPage) => {
    console.log("newPage:", newPage)
    setPage(newPage.page)
    setLimit(newPage.pageSize)
    console.log("accordionRef.current:", accordionRef.current)
    console.log(page)
    console.log(limit)
    accordionRef.current.handleFiltros(newPage.page)
  }

  const handleAutocompleteChange = (event, value) => {
    value !== null ? setIpPrint(value.printip) : setIpPrint("")
    // setIpPrint(selectedObject.printip); // Asigna el valor de 'ip' al estado 'ipPrint'
  }

  return (
    <>
      <ThemeProvider theme={theme}>
        <Header />
        <div className="main main-app p-3 p-lg-4">
          <BackIcon />
          <div className="d-md-flex align-items-center justify-content-between mb-4">
            {/* <div>
              <ol className="breadcrumb fs-sm mb-1">
                <li className="breadcrumb-item">
                  <Link to="#">Dashboard</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Producto
                </li>
              </ol>
            </div> */}
          </div>
          <Stack>{alertas}</Stack>
          {/* <FullRoute /> */}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "0 30px 30px 30px",
            }}
          >
            <AccordionFiltros
              apiTODOS="/productos/obtener_viewProductos_x_pagina"
              apiFILTROS="/filter/make_filter"
              onFilteredProducts={handleFilteredProducts}
              limit={limit}
              page={page}
              ref={accordionRef}
            ></AccordionFiltros>
          </div>
          <Box alignItems="center">
            {/* <LoadingButton
              variant="contained"
              endIcon={<PrintIcon />}
              // onClick={imprimirProductos}
              color="secondary"
              loading={isLoading}
              style={{ marginRight: "16px" }} // added margin right
            >
              Imprimir
            </LoadingButton> */}
            {/* Lector de qr y de barra */}
            <PrintButton getProductsPrintable={getProductsPrintable} printers={options} />

            {/* <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={codeType}
                onChange={handlePrintTypeChange}
              >
                <FormControlLabel value="qrcode" control={<Radio />} label="QR Code" />
                <FormControlLabel value="barcode" control={<Radio />} label="Bar code" />
              </RadioGroup>
            </FormControl> */}

            {/* <Autocomplete
            options={options}
            getOptionLabel={(option) => option.lincodigo}
            style={{width : '20%'}}
            onChange={handleAutocompleteChange} 
            renderInput={(params) => (
              <TextField {...params} label="Seleccionar impresora" variant="outlined" />
            )}

            /> */}
          </Box>

          <StyledRoot>
            <StyledDataGridContainer
              sx={{
                height: 400,
                width: "100%",
                marginTop: "20px",
                marginBottom: "0",
              }}
            >
              <DataGrid
                rows={productos}
                columns={columnas}
                checkboxSelection
                isRowSelectable={(params) => params.row.artcantactual > 0}
                getRowId={(productos) => productos.codigo}
                initialState={{
                  pagination: {
                    paginationModel: {
                      page,
                      pageSize: 10,
                    },
                  },
                }}
                pageSizeOptions={[10, 25]}
                onEditCellCommit={handleCantidadEtiquetasChange}
                processRowUpdate={processRowUpdate}
                onRowSelectionModelChange={(params) => {
                  handleRowSelected(params)
                }}
                onProcessRowUpdateError={(params) => {
                  console.log("Error")
                }}
                // onEditCellChange={handleCantidadEtiquetasChange}
                disableSelectionOnClick
                localeText={{
                  noRowsLabel: "Dale click a Filtrar para mostrar los productos",
                  footerRowSelected: (params) => `${params} productos(s) seleccionado(s)`,
                }}
                onPaginationModelChange={(params) => {
                  console.log("params:", params)
                  handlePageChange(params)
                }}
                // change total count
                pagination
                paginationMode="server"
                rowCount={rowCount}
              />
            </StyledDataGridContainer>
          </StyledRoot>
        </div>
        <ModalProducto isOpen={isOpenModal} handleClose={handleCloseModal} cproducto={currentCodeProductoClicked} />
      </ThemeProvider>
    </>
  )
}

export default FiltrarProductos
