import * as React from "react"
import { Button, TextField, Box, Alert } from "@mui/material"
import { useCartStore } from "../../../stores/cartStore"
import math from "../../utils/math/index"

export default function PriceSelector({ producto, handleClose, selectedItem }) {
  const MIN_CANTIDAD = 0.1
  const [currentCantidad, setcurrentCantidad] = React.useState(1)
  const [warning, setWarning] = React.useState(false)
  const MAX_CANTIDAD = parseFloat(producto.cabecera.cantidad)
  const { add } = useCartStore()

  const handleInputChange = (event) => {
    const value = event.target.value

    // Permitir que el usuario borre la entrada o ingrese un solo punto decimal
    if (value === "" || value === ".") {
      setcurrentCantidad(value)
      return
    }

    // Limita el número de decimales a 2
    const regex = /^\d*\.?\d{0,2}$/
    if (regex.test(value)) {
      const numericValue = parseFloat(value)

      // Restringe la entrada a los límites de cantidad
      if (!isNaN(numericValue)) {
        if (numericValue > MAX_CANTIDAD) {
          setcurrentCantidad(MAX_CANTIDAD.toFixed(2))
        } else if (numericValue < MIN_CANTIDAD) {
          setcurrentCantidad(MIN_CANTIDAD)
        } else {
          setcurrentCantidad(value)
        }
      } else {
        setcurrentCantidad(value)
      }
    }
  }

  const handleIncrease = () => {
    const newCantidad = parseFloat((parseFloat(currentCantidad) + 0.1).toFixed(2))
    if (newCantidad >= MAX_CANTIDAD) {
      setcurrentCantidad(MAX_CANTIDAD.toFixed(2))
    } else {
      setcurrentCantidad(newCantidad.toFixed(2))
    }
  }

  const handleDecrease = () => {
    const newCantidad = parseFloat((parseFloat(currentCantidad) - 0.1).toFixed(2))
    if (newCantidad < MIN_CANTIDAD) {
      setcurrentCantidad(MIN_CANTIDAD.toFixed(2))
    } else {
      setcurrentCantidad(newCantidad.toFixed(2))
    }
  }

  const pushToCart = () => {
    if (!selectedItem || !selectedItem.bodcodigo) {
      setWarning(true)
      return
    }

    if (currentCantidad === "" || parseFloat(currentCantidad) < MIN_CANTIDAD) return

    const price = math.number(producto?.cabecera?.precio)
    const priceIVA = price * (producto?.cabecera.sysiva / 100)
    const totalIVA = priceIVA * currentCantidad
    const totalPrice = price * currentCantidad
    const totalPriceWithIVA = totalPrice + totalIVA

    const product = {
      ...producto?.cabecera,
      bodcodigo: selectedItem.bodcodigo,
      priceIVA,
      price,
      totalToBuy: parseFloat(currentCantidad),
      maxTotalStockToBuy: MAX_CANTIDAD,
      totalPrice,
      totalIVA,
      totalPriceWithIVA,
    }

    add(product)
    handleClose()
  }

  return (
    <>
      {warning && (
        <Alert severity="warning" onClose={() => setWarning(false)}>
          No puedes dejar el campo registro de bodega vacío
        </Alert>
      )}

      {producto.cabecera.cantidad >= MIN_CANTIDAD && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          // eslint-disable-next-line react/no-unknown-property
          sx={{ width: "100%" }}
        >
          <Button variant="outlined" onClick={handleDecrease}>
            -
          </Button>
          <TextField
            type="text"
            value={currentCantidad}
            onChange={handleInputChange}
            sx={{ mx: 2, width: "6rem" }}
            inputProps={{
              inputMode: "decimal",
              pattern: "[0-9]*",
              step: 0.1,
            }}
          />
          <Button variant="outlined" onClick={handleIncrease}>
            +
          </Button>
        </div>
      )}

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-evenly",
          marginTop: "1.5rem",
        }}
      >
        <Button onClick={handleClose} sx={{ textAlign: "center" }}>
          Cerrar
        </Button>
        {producto.cabecera.cantidad >= MIN_CANTIDAD && (
          <Button
            variant="contained"
            onClick={pushToCart}
            sx={{ textAlign: "center" }}
            style={{ backgroundColor: "#196C87" }}
          >
            Agregar
          </Button>
        )}
      </Box>
    </>
  )
}
