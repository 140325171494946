/* eslint-disable camelcase */
import { useMemo, useState } from "react"
import {
  MaterialReactTable,
  // createRow,
  useMaterialReactTable,
} from "material-react-table"
import { createTheme, ThemeProvider, useTheme, Box, Button, IconButton, Tooltip } from "@mui/material"
import { esES } from "@mui/material/locale"
import { MRT_Localization_ES } from "material-react-table/locales/es"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import EditIcon from "@mui/icons-material/Edit"
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye"
import fetchwrapper from "../../../services/interceptors/fetchwrapper"
import { useNavigate } from "react-router-dom"
import CrearIcon from "../../../assets/iconos/Crear.ico"
import normalFormatDate from "../../utils/date/DDMMYYYFormatDate"

const CustomTable = ({ fetchedInfo, isLoadingInfoError, isLoadingInfo, isFetchingInfo }) => {
  const navigate = useNavigate()

  const columns = useMemo(
    () => [
      {
        accessorKey: "sgasoling",
        header: "Código de Solicitud de Ingreso",
        enableEditing: false,
        size: 200,
      },
      {
        accessorKey: "sgastatus",
        header: "Estado de la Solicitud Ingreso",
        enableEditing: false,
        size: 200,
      },
      {
        accessorKey: "sgadescri",
        header: "Descripción",
        enableEditing: false,
        size: 200,
      },
      {
        accessorKey: "clinombre",
        header: "Cliente",
        enableEditing: false,
        size: 120,
      },
      {
        accessorKey: "pronombre",
        header: "Proveedor",
        enableEditing: false,
        size: 120,
      },
      {
        accessorKey: "motdescripcion",
        header: "Motivo",
        enableEditing: false,
        size: 120,
      },
      {
        accessorKey: "sgafecsol",
        header: "Fecha de Emisión",
        enableEditing: false,
        size: 120,
        Cell: ({ value }) => normalFormatDate(value),
      },
      {
        accessorKey: "sgafecllegada",
        header: "Fecha de Llegada",
        enableEditing: false,
        size: 120,
        Cell: ({ value }) => normalFormatDate(value),
      },
      {
        accessorKey: "sgacomenllegada",
        header: "Comentario de Llegada",
        enableEditing: false,
        size: 120,
      },
    ],
    [],
  )

  const table = useMaterialReactTable({
    columns,
    data: fetchedInfo,
    enableEditing: true,
    enableRowActions: true,
    localization: { ...MRT_Localization_ES },
    getRowId: (row) => row.formcodigo,
    muiToolbarAlertBannerProps: isLoadingInfoError
      ? {
          color: "error",
          children: "Error en cargar las Solicitudes Ingresos",
        }
      : undefined,
    muiTableContainerProps: {
      sx: {
        minHeight: "500px",
      },
    },

    renderRowActions: ({ row, table }) => (
      <Box sx={{ display: "flex", gap: "1rem" }}>
        <Tooltip title="Ver">
          <IconButton
            onClick={() => {
              navigate(`ver`, {
                state: row?.original,
              })
            }}
          >
            <RemoveRedEyeIcon />
          </IconButton>
        </Tooltip>
      </Box>
    ),
    state: {
      isLoading: isLoadingInfo,
      showAlertBanner: isLoadingInfoError,
      showProgressBars: isFetchingInfo,
    },
  })

  return <MaterialReactTable table={table} />
}

// DELETE hook (delete SolicitudesIngresos in api)
function useDeleteSolicitudesIngresos() {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (formcodigo) => {
      // send api update request here
      const options = {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }

      const response = await fetchwrapper(`/FormularioProcesos/deleteFormulario/${formcodigo}`, options)
      return response
    },
    // client side optimistic update
    onMutate: (formcodigo) => {
      queryClient.setQueryData(["SolicitudesIngresos"], (prevSolicitudesIngresos) =>
        prevSolicitudesIngresos?.filter((SolicitudesIngresos) => SolicitudesIngresos.formcodigo !== formcodigo),
      )
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ["SolicitudesIngresos"] })
    },

    onSuccess: () => {
      alert("Formulario eliminado con éxito")
    },

    onError: () => {
      alert("No se pudo eliminar este formulario")
    },
  })
}

const DatagridSolicitudesIngresos = (params) => {
  const theme = useTheme()
  return (
    <div>
      <ThemeProvider theme={createTheme(theme, esES)}>
        <CustomTable {...params} />
      </ThemeProvider>
    </div>
  )
}

export default DatagridSolicitudesIngresos
