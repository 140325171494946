import { useState, useCallback, useContext, useEffect } from "react"
import Header from "../../../layouts/Header"
import { styled, createTheme, ThemeProvider } from "@mui/material/styles"
import { useNavigate, useLocation } from "react-router-dom"
import {
  Box,
  Button,
  InputLabel,
  TextField,
  MenuItem,
  Select,
  Tooltip,
  IconButton,
  Modal,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
} from "@mui/material"
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"
import { IoCloseCircleSharp } from "react-icons/io5"
import CustomBackIcon from "../../SolicitudDeIngreso/components/CustomBackIcon"
import CustomSelect from "../../SolicitudDeIngreso/components/CustomSelect"
import CustomTooltip from "../../../components/ToolTip"
import BuscarIcon from "../../../assets/iconos/Buscar.ico"
import ImpresionIcon from "../../../assets/iconos/Impresion.ico"
import DetalleImpresion from "../../SolicitudDeEntrega/components/DetalleImpresion"
import SolicitudDeEgresoPDF from "../../SolicitudDeEntrega/components/SolicitudDeEgresoPDF"
import { useQuery, useQueryClient } from "@tanstack/react-query"
import fetchwrapper from "../../../services/interceptors/fetchwrapper"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import GrabarIcon from "../../../assets/iconos/Grabar.ico"
import Swal from "sweetalert2"

import { MaterialReactTable } from "material-react-table"
import { AprobacionContext } from "../AprobacionContext"
import { FaImage } from "react-icons/fa6"
// import { Flex } from "@mantine/core"
const theme = createTheme({
  palette: {
    primary: {
      main: "#196C87",
    },
    secondary: {
      main: "#196C87",
    },
  },
})

const Container = styled(Box)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "repeat(12, 1fr)",
  gridTemplateRows: "auto auto auto",
  gridTemplateAreas: `
    "Solicitud Solicitud Solicitud FechaI FechaI FechaI Cliente Cliente Cliente ClienteDescri ClienteDescri ClienteDescri"
    "Motivo Motivo Motivo Estado Estado Estado Proveedor Proveedor Proveedor ProveedorDescri ProveedorDescri ProveedorDescri"
    "FechaL FechaL FechaL HoraL HoraL HoraL Comentario Comentario Comentario Comentario Comentario Comentario"
    "Detalle Detalle Detalle Detalle Detalle Detalle AgenciaSol AgenciaSol AgenciaSol AgenciaSolDescri AgenciaSolDescri AgenciaSolDescri"
    "AgenciaEnv AgenciaEnv AgenciaEnv AgenciaEnvDescri AgenciaEnvDescri AgenciaEnvDescri Contacto Contacto Contacto ContactoDescri ContactoDescri ContactoDescri"
    "Check Check Check Check Check Check Check Check Check Check Check Check"
    "CheckCierre CheckCierre CheckCierre CheckCierre CheckCierre CheckCierre CheckCierre CheckCierre CheckCierre CheckCierre CheckCierre CheckCierre"
  `,
  gap: "8px",
  alignItems: "center",

  [theme.breakpoints.down("sm")]: {
    gridTemplateColumns: "repeat(6, 1fr)",
    gridTemplateAreas: `
      "Solicitud Solicitud Solicitud FechaI FechaI FechaI"
      "Cliente Cliente Cliente ClienteDescri ClienteDescri ClienteDescri"
      "Motivo Motivo Motivo Estado Estado Estado"
      "Proveedor Proveedor Proveedor ProveedorDescri ProveedorDescri ProveedorDescri"
      "FechaL FechaL FechaL HoraL HoraL HoraL"
      "Comentario Comentario Comentario Comentario Comentario Comentario"
      "Detalle Detalle Detalle Detalle Detalle Detalle"
      "AgenciaSol AgenciaSol AgenciaSol AgenciaSolDescri AgenciaSolDescri AgenciaSolDescri"
      "AgenciaEnv AgenciaEnv AgenciaEnv AgenciaEnvDescri AgenciaEnvDescri AgenciaEnvDescri "
      "Contacto Contacto Contacto ContactoDescri ContactoDescri ContactoDescri"
      "Check Check Check Check Check Check"
      "CheckCierre CheckCierre CheckCierre CheckCierre CheckCierre CheckCierre"
      
    `,
  },
}))

const Solicitud = styled(Box)({
  gridArea: "Solicitud",
})

const FechaI = styled(Box)({
  gridArea: "FechaI",
})

const FechaL = styled(Box)({
  gridArea: "FechaL",
})

const HoraL = styled(Box)({
  gridArea: "HoraL",
})

const Cliente = styled(Box)({
  gridArea: "Cliente",
})

const ClienteDescri = styled(Box)({
  gridArea: "ClienteDescri",
})

const Motivo = styled(Box)({
  gridArea: "Motivo",
})

const Estado = styled(Box)({
  gridArea: "Estado",
})

const Proveedor = styled(Box)({
  gridArea: "Proveedor",
})

const ProveedorDescri = styled(Box)({
  gridArea: "ProveedorDescri",
})

const Detalle = styled(Box)({
  gridArea: "Detalle",
})

const Comentario = styled(Box)({
  gridArea: "Comentario",
})

const AgenciaSol = styled(Box)({
  gridArea: "AgenciaSol",
})

const AgenciaSolDescri = styled(Box)({
  gridArea: "AgenciaSolDescri",
})

const AgenciaEnv = styled(Box)({
  gridArea: "AgenciaEnv",
})

const AgenciaEnvDescri = styled(Box)({
  gridArea: "AgenciaEnvDescri",
})

const Contacto = styled(Box)({
  gridArea: "Contacto",
})

const ContactoDescri = styled(Box)({
  gridArea: "ContactoDescri",
})

const Check = styled(Box)({
  gridArea: "Check",
  width: "100%",
})

const CheckCierre = styled(Box)({
  gridArea: "CheckCierre",
  width: "100%",
})

const AprobacionSolicitudEntrega = () => {
  const location = useLocation()
  const dayjs = require("dayjs")
  const [cliente, setCliente] = useState("")
  const [clienteDescription, setclienteDescription] = useState("")
  const [detalle, setDetalle] = useState(location.state.sgadescri)
  const [open, setOpen] = useState(false)
  const [filtrosMotivo, setFiltrosMotivo] = useState([])
  const { allFiltrosMotivo } = useGetAllFiltros()
  const fechaEnvio = dayjs(location.state.sgafecenvio).utc() // "DD/MM/YYYY"
  const fechaSolicitud = dayjs(location.state.sgafecsol).utc() // "DD/MM/YYYY"
  const horaEnvio = dayjs(location.state.sgahorenvio).utc()
  const [openModal, setOpenModal] = useState(false)
  const [solicitudImpresion, setSolicitudImpresion] = useState({})
  const navigate = useNavigate()
  const [estado, setEstado] = useState(location.state.sgastatus)

  // APROBAR / NEGAR
  const [checkValue, setCheckValue] = useState(location.state.sgastatus)
  const handleCheckValue = (event) => {
    setCheckValue(event.target.value)
  }

  const [isAllowCerrar, setIsAllowCerrar] = useState(estado === "APROBADA" || estado === "NEGADA")
  const [isAllowChecks, setIsAllowChecks] = useState(estado === "CERRADA")
  const [comenAprobacion, setComenAprobacion] = useState("")
  const handleComenAprobacion = (event) => {
    setComenAprobacion(event.target.value)
  }

  // CERRAR
  const [checkValueCierre, setCheckValueCierre] = useState(location.state.sgastatus)
  const handleCheckValueCierre = (event) => {
    setCheckValueCierre(event.target.value)
  }
  const [comenCierre, setComenCierre] = useState("")
  const handleComenCierre = (event) => {
    setComenCierre(event.target.value)
  }

  const [codigoCliente, setCodigoCliente] = useState(null)
  const [codigoProveedor, setCodigoProveedor] = useState(null)
  const { productosEgreso, setProductosEgreso } = useContext(AprobacionContext)

  // agencias
  const [agenDescriEnv, setAgenDescriEnv] = useState(null)
  const [agenDescriSol, setAgenDescriSol] = useState(null)

  const [infoGenerada, setInfoGenerada] = useState("")
  const handleCloseModal = () => {
    setOpenModal(false)
  }

  // impresion
  const {
    data: fetchedSolicitudIngreso = {},
    isError: isLoadingSolicitudIngresoError,
    isFetching: isFetchingSolicitudIngreso,
    isLoading: isLoadingSolicitudIngreso,
    refetch: refetchSolicitudIngreso,
  } = useGetSolicitud()

  function useGetSolicitud() {
    return useQuery({
      queryKey: ["solicitudDeEgresoImpresion"],
      queryFn: async () => {
        const options = {
          method: "POST",
          body: JSON.stringify({
            codSolicitud: location.state.sgasolegr,
          }),
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }
        // send api request here
        let response = await fetchwrapper("/solicitudDeEgreso/getSolicitudDeEgreso", options)
        response = await response.json()
        return response.data
      },
      refetchOnWindowFocus: false,
      enabled: true,
    })
  }

  const handleOpenModal = () => {
    setOpenModal(true)
  }

  const imprimir = async () => {
    await refetchSolicitudIngreso()
    handleOpenModal()
  }

  useEffect(() => {
    if (fetchedSolicitudIngreso) {
      setSolicitudImpresion(fetchedSolicitudIngreso)
      setInfoGenerada(fetchedSolicitudIngreso)
    }
  }, [fetchedSolicitudIngreso])

  // productos de bodega
  const [datoDelHijo, setDatoDelHijo] = useState([])
  // Función para obtener el código del cliente
  const obtenerCodigoCliente = async (clinombre) => {
    const clienteOptions = {
      method: "POST",
      body: JSON.stringify({ clinombre }),
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    }
    const clienteResponse = await fetchwrapper("/solicitudDeIngreso/getCodCliente", clienteOptions)
    const clienteData = await clienteResponse.json()
    return clienteData.codigo
  }

  // Función para obtener el código del proveedor
  const obtenerCodigoProveedor = async (pronombre) => {
    const proveedorOptions = {
      method: "POST",
      body: JSON.stringify({ pronombre }),
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    }
    const proveedorResponse = await fetchwrapper("/solicitudDeIngreso/getCodProveedor", proveedorOptions)
    const proveedorData = await proveedorResponse.json()
    return proveedorData.codigo
  }

  // obtener codigo agencias
  const obtenerCodigoAgencias = async (agenCodigo) => {
    const agenciaOptions = {
      method: "POST",
      body: JSON.stringify({ agenCodigo }),
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    }
    const agenciaResponse = await fetchwrapper("/AprobacionSolicitudEntrega/getCodAgencia", agenciaOptions)
    const agenciaData = await agenciaResponse.json()
    return agenciaData.descripcion
  }

  useEffect(() => {
    setCliente(cliente)
  }, [cliente])

  useEffect(() => {
    setCheckValue(checkValue)
  }, [checkValue])

  useEffect(() => {
    setEstado(estado)
  }, [estado])

  useEffect(() => {
    setIsAllowCerrar(isAllowCerrar)
  }, [isAllowCerrar])

  useEffect(() => {
    setIsAllowChecks(isAllowChecks)
  }, [isAllowChecks])

  useEffect(() => {
    const cargarCodigos = async () => {
      try {
        const clinombre = location.state.clinombre
        const pronombre = location.state.pronombre
        const agenCodSol = location.state.agencodigosol
        const agencodigoenv = location.state.agencodigoenv

        const clienteCodigo = await obtenerCodigoCliente(clinombre)
        const proveedorCodigo = await obtenerCodigoProveedor(pronombre)
        const agenSolDescri = await obtenerCodigoAgencias(agenCodSol)
        const agenEnvDescri = await obtenerCodigoAgencias(agencodigoenv)

        setCodigoCliente(clienteCodigo)
        setCodigoProveedor(proveedorCodigo)
        setAgenDescriEnv(agenEnvDescri)
        setAgenDescriSol(agenSolDescri)
      } catch (error) {
        console.error("Error al cargar los códigos:", error)
      }
    }

    cargarCodigos()
  }, [])

  const manejarDatosHijo = (dato) => {
    setDatoDelHijo(dato)
  }

  const handleClienteChange = (event) => {
    setCliente(event.target.value)
  }

  const handleClienteDescriptionChange = (event) => {
    setclienteDescription(event.target.value)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleDetalle = (event) => {
    setDetalle(event.target.value)
  }

  const aprobarSolEgr = useCallback(async () => {
    if (checkValueCierre === "CERRADA" && comenCierre === "") {
      Swal.fire({
        icon: "warning",
        title: "Comentario requerido",
        text: "Debes ingresar un comentario para el cierre.",
      })
      return
    } else if (comenAprobacion === "" && !isAllowCerrar) {
      Swal.fire({
        icon: "warning",
        title: "Comentario requerido",
        text: "Debes ingresar un comentario para continuar.",
      })
      return
    }

    // Mostrar alerta de confirmación antes de proceder
    Swal.fire({
      title: "¿Estás seguro?",
      text: "Confirma que deseas actualizar el estado de la solicitud.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sí, actualizar",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        // Si el usuario confirma, proceder con la actualización
        let comentario
        let estado
        if (checkValueCierre === "CERRADA") {
          comentario = comenCierre
          estado = checkValueCierre
          setEstado(checkValueCierre)
          setIsAllowChecks(true)
        } else if (checkValue === "APROBADA" || checkValue === "NEGADA") {
          comentario = comenAprobacion
          estado = checkValue
          setEstado(checkValue)
          setIsAllowCerrar(true)
        }

        const options = {
          method: "POST",
          body: JSON.stringify({
            codigoSolicitud: location.state.sgasolegr,
            comentario,
            articulos: productosEgreso,
            estado,
          }),
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }

        fetchwrapper("/AprobacionSolicitudEntrega/aprobarSolicitudEgreso", options)
          .then((response) => response.json())
          .then((response) => {
            // Mostrar mensaje de éxito si la operación es exitosa
            Swal.fire({
              icon: "success",
              title: "Éxito",
              text: "La solicitud ha sido actualizada correctamente.",
            })
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: "Error",
              text: "Hubo un problema al actualizar la solicitud. Por favor intenta de nuevo.",
            })
            console.error("Error actualizando productos:", error)
          })
      }
    })
  }, [productosEgreso, checkValue, checkValueCierre, comenAprobacion, comenCierre, location.state.sgasolegr])

  // verificar que todos los productos sean de la bodega del cliente
  const verificarProductosBodega = (datoDelHijo) => {
    const codigosIngresados = productosEgreso.map((producto) => producto.artcodigo)
    const codigosAceptados = new Set(datoDelHijo.map((producto) => producto.split("-")[0]))
    const codigosNoAceptados = new Set() // Usar Set para evitar duplicados

    for (const codigo of codigosIngresados) {
      if (!codigosAceptados.has(codigo)) {
        codigosNoAceptados.add(codigo) // Agregar al Set
      }
    }

    // Convertir el Set a un array y unir los elementos en un string
    if (codigosNoAceptados.size > 0) {
      return {
        resultado: true,
        codigosNoAceptados: Array.from(codigosNoAceptados).join(", "), // Convierte el Set a un array y luego a un string
      }
    }

    // Si todos los códigos son aceptados, retorna false
    return { resultado: false, codigosNoAceptados: "" }
  }

  const handleUpdateSolicitudDeEgreso = async () => {
    // verificar si todos los productos pertenecen a la bodega del cliente
    const allProductosPertenecen = verificarProductosBodega(datoDelHijo)
    if (allProductosPertenecen.resultado) {
      Swal.fire({
        icon: "error",
        title: "Productos incorrectos",
        text: `Los productos con código ${allProductosPertenecen.codigosNoAceptados} no pertenecen a la bodega del cliente`,
      })
    } else {
      await aprobarSolEgr()
      Swal.fire({
        icon: "success",
        title: "Operación exitosa",
        text: "Se editó la Solicitud de Egreso",
      }).then((result) => {
        if (result.isConfirmed) {
          navigate("/home/dashboard/SolicitudDeEntrega") // Navegar a la ruta especificada
        }
      })
    }
  }

  function useGetAllFiltros() {
    return useQuery({
      queryKey: ["solicitudDeEgresoGetAllFiltros"],
      queryFn: async () => {
        let response = await fetchwrapper("/solicitudDeEgreso/getAllFiltros")
        response = await response.json()
        setFiltrosMotivo(response.data)
        return response.data
      },
      refetchOnWindowFocus: false,
      enabled: true,
    })
  }

  // -----------------------------------------------------

  return (
    <ThemeProvider theme={theme}>
      <Header />
      <div className="main main-app p-3 p-lg-4">
        <CustomBackIcon />
        <div style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
          <div style={{ display: "flex", alignItems: "center", gap: "16px", padding: "5px" }}>
            <CustomTooltip title={"Guardar"}>
              <Button onClick={aprobarSolEgr}>
                <img src={GrabarIcon} alt="Grabar" style={{ width: "50px" }} />
              </Button>
            </CustomTooltip>
          </div>
          <div style={{ display: "flex", alignItems: "center", gap: "16px", padding: "5px" }}>
            <CustomTooltip title={"Imprimir"}>
              <Button onClick={imprimir}>
                <img src={ImpresionIcon} alt="Imprimir" style={{ width: "40px" }} />
              </Button>
            </CustomTooltip>
            <DetalleImpresion open={openModal} handleClose={handleCloseModal} infoGenerada={infoGenerada} />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "0 30px 30px 30px",
            fontSize: "25px",
          }}
        >
          <b>Aprobación de Solicitud de Entrega</b>
        </div>
        <Container>
          <Solicitud>
            <InputLabel htmlFor="solicitud">Solicitud</InputLabel>
            <TextField id="solicitud" value={location.state.sgasolegr} disabled fullWidth />
          </Solicitud>
          <FechaI>
            <InputLabel>Fecha</InputLabel>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                sx={{ width: "100%" }}
                disabled
                format="DD/MM/YYYY"
                defaultValue={dayjs("2022-04-17")}
                value={fechaSolicitud}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </FechaI>
          <Cliente>
            <InputLabel>Cliente</InputLabel>
            <TextField value={codigoCliente} onChange={handleClienteChange} fullWidth disabled />
          </Cliente>
          <ClienteDescri>
            <InputLabel>Nombre</InputLabel>
            <TextField value={location.state.clinombre} onChange={handleClienteDescriptionChange} fullWidth disabled />
          </ClienteDescri>
          <Motivo>
            <InputLabel>Motivo</InputLabel>
            <TextField disabled id="estado" value={location.state.motdescripcion} onChange={() => {}} fullWidth />
          </Motivo>
          <Estado>
            <InputLabel>Estado</InputLabel>
            <TextField disabled id="estado" value={estado} onChange={() => {}} fullWidth />
          </Estado>
          <Proveedor>
            <InputLabel>Proveedor</InputLabel>
            <TextField value={codigoProveedor} onChange={handleClienteChange} fullWidth disabled />
          </Proveedor>
          <ProveedorDescri>
            <InputLabel>Descripción</InputLabel>
            <TextField value={location.state.pronombre} onChange={handleClienteDescriptionChange} fullWidth disabled />
          </ProveedorDescri>

          <Comentario>
            <InputLabel>Comentario de Envío</InputLabel>
            <TextField value={location.state.sgacomenenvio} fullWidth disabled />
          </Comentario>
          <FechaL>
            <InputLabel>Envío</InputLabel>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                disabled
                sx={{ width: "100%" }}
                value={fechaEnvio}
                dateFormat="yyyy-MM-dd hh:mm:ss.SSSSSS"
                timeFormat="HH:mm:ss.SSSSSS"
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </FechaL>
          <HoraL>
            <InputLabel>Hora de Envío</InputLabel>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <TimePicker value={horaEnvio} sx={{ width: "100%" }} disabled />
            </LocalizationProvider>
          </HoraL>
          <Detalle>
            <InputLabel>Detalle</InputLabel>
            <TextField value={detalle} onChange={handleDetalle} placeholder="" fullWidth disabled />
          </Detalle>
          <AgenciaSol>
            <InputLabel>Agencia Solicita</InputLabel>
            <TextField value={location.state.agencodigosol} onChange={handleClienteChange} fullWidth disabled />
          </AgenciaSol>
          <AgenciaSolDescri>
            <InputLabel>Descripción</InputLabel>
            <TextField value={agenDescriSol} onChange={handleClienteChange} fullWidth disabled />
          </AgenciaSolDescri>
          <AgenciaEnv>
            <InputLabel>Agencia Envía</InputLabel>
            <TextField
              value={location.state.agencodigoenv}
              onChange={handleClienteDescriptionChange}
              fullWidth
              disabled
            />
          </AgenciaEnv>
          <AgenciaEnvDescri>
            <InputLabel>Descripción</InputLabel>
            <TextField value={agenDescriEnv} onChange={handleClienteDescriptionChange} fullWidth disabled />
          </AgenciaEnvDescri>
          <Contacto>
            <InputLabel>Contacto</InputLabel>
            <TextField value={location.state.concodrelextenv} onChange={handleClienteChange} fullWidth disabled />
          </Contacto>
          <ContactoDescri>
            <InputLabel>Descripción</InputLabel>
            <TextField
              value={location.state.condescrienv}
              onChange={handleClienteDescriptionChange}
              fullWidth
              disabled
            />
          </ContactoDescri>
          <Check>
            <FormControl>
              <FormLabel id="demo-controlled-radio-buttons-group">APROBAR / NEGAR / CERRAR</FormLabel>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={checkValue}
                onChange={handleCheckValue}
                row
              >
                <FormControlLabel
                  value="APROBADA"
                  control={<Radio />}
                  label="APROBAR"
                  disabled={isAllowCerrar || isAllowChecks}
                />
                <FormControlLabel
                  value="NEGADA"
                  control={<Radio />}
                  label="NEGAR"
                  disabled={isAllowCerrar || isAllowChecks}
                />
              </RadioGroup>
            </FormControl>
            <InputLabel>Comentario *</InputLabel>
            <TextField
              fullWidth
              value={comenAprobacion}
              onChange={handleComenAprobacion}
              disabled={isAllowCerrar || isAllowChecks}
            ></TextField>
          </Check>
          <CheckCierre>
            <FormControl>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={checkValueCierre}
                onChange={handleCheckValueCierre}
                row
              >
                <FormControlLabel
                  value="CERRADA"
                  control={<Radio />}
                  label="CIERRE"
                  disabled={!isAllowCerrar || isAllowChecks}
                />
              </RadioGroup>
            </FormControl>
            <InputLabel>Comentario *</InputLabel>
            <TextField
              fullWidth
              value={comenCierre}
              onChange={handleComenCierre}
              disabled={!isAllowCerrar || isAllowChecks}
            ></TextField>
          </CheckCierre>
        </Container>

        <div className="col-sm-12">
          <DatagridEditSolIng
            estado={estado}
            sgasolegr={location.state.sgasolegr}
            cliente={codigoCliente}
            onEnviarDatos={manejarDatosHijo}
          />
        </div>
      </div>
    </ThemeProvider>
  )
}

const DatagridEditSolIng = ({ sgasolegr, cliente, onEnviarDatos, estado }) => {
  const [data, setData] = useState([])
  const [articulo, setArticulo] = useState("")
  const [articuloDescription, setArticuloDescription] = useState("")
  const { data: fetchedProductosSoling = [], refetch: refetchGetProductosSolIng } = useGetProductosSolIng(sgasolegr)
  const [clienteSeleccionado, setClienteSeleccionado] = useState(true)
  const [productos, setProductos] = useState([])
  // imagen de los productos
  const [openImageModal, setOpenImageModal] = useState(false)
  const [selectedImageUrl, setSelectedImageUrl] = useState("")
  const [imageHex, setImageHex] = useState("")

  const enviarDato = () => {
    onEnviarDatos(dataProductos) // Llama a la función del padre y le pasa el dato
  }

  const useGetProductoImages = (artcodigo) => {
    return useQuery({
      queryKey: ["solicitudDeEgresoImagenes", artcodigo],
      queryFn: async () => {
        if (!artcodigo || artcodigo.trim() === "") {
          throw new Error("El código del artículo está vacío o es inválido.")
        }

        const response = await fetchwrapper(`/solicitudDeIngreso/getImagesxArtcodigo/${artcodigo}`)
        const result = await response.json()
        return result?.data
      },
      refetchOnWindowFocus: false,
      enabled: !!artcodigo,
    })
  }

  const { data: imagenProducto = [], refetch: refetchGetProductoImages, error } = useGetProductoImages(imageHex)

  const { productoSeleccionado, productoSeleccionadoDescri, setProductosEgreso, productosEgreso } =
    useContext(AprobacionContext)

  // imagenes
  const handleOpenImageModal = (hex) => {
    setImageHex(hex)
    setOpenImageModal(true)
  }

  useEffect(() => {
    setImageHex(imageHex)
  }, [imageHex])

  useEffect(() => {
    refetchGetProductoImages(imageHex)
  }, [refetchGetProductoImages, imageHex, imagenProducto])

  const handleCloseImageModal = () => {
    setOpenImageModal(false)
    setSelectedImageUrl("")
  }

  useEffect(() => {
    setArticulo(productoSeleccionado)
    setArticuloDescription(productoSeleccionadoDescri)
  }, [productoSeleccionadoDescri, data, productosEgreso])

  useEffect(() => {
    if (fetchedProductosSoling && fetchedProductosSoling.length > 0) {
      setProductosEgreso(
        fetchedProductosSoling.map((producto) => ({
          ...producto,
          prevNumSecuencia: producto.numSecuencia,
        })),
      )
    }
  }, [fetchedProductosSoling])

  function useGetProductosSolIng(sgasolegr) {
    return useQuery({
      queryKey: ["solicitudDeEgresoPorProducto", sgasolegr],
      queryFn: async () => {
        const options = {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }
        let response = await fetchwrapper(`/solicitudDeEgreso/getArticulosPorSolEgr/${sgasolegr}`, options)
        response = await response.json()
        response = response?.productos
        return response
      },
      enabled: !!sgasolegr,
      refetchOnWindowFocus: true,
      refetchOnMount: true,
      refetchOnReconnect: true,
      onError: (error) => {
        console.log("Error fetching data", error)
      },
    })
  }

  useEffect(() => {
    refetchGetProductosSolIng()
  }, [sgasolegr, refetchGetProductosSolIng])

  useEffect(() => {
    console.log("El estado se actualizó a:", estado)
    refetchGetProductosSolIng()
    setProductosEgreso((prevProductos) =>
      prevProductos.map((producto) => ({
        ...producto,
        estado,
      })),
    )
  }, [estado, refetchGetProductosSolIng])

  const handleDeleteClick = (row) => {
    const productIdToCierre = row.numSecuencia
    const productCodToCierre = row.artcodigo

    Swal.fire({
      title: "¿Estás seguro?",
      text: "¿Desea cerrar este Producto?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sí, cerrar",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        // Llamada a la API para cerrar el producto en el servidor
        const options = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
          body: JSON.stringify({
            codigoSolicitud: sgasolegr,
            artcodigo: productCodToCierre,
            sgasecuen: productIdToCierre,
          }),
        }

        fetchwrapper("/AprobacionSolicitudEntrega/cerrarItemSolEgr", options)
          .then((response) => {
            if (!response.ok) {
              throw new Error("Error al cerrar el producto")
            }
            return response.json()
          })
          .then(() => {
            Swal.fire("Éxito!", "El producto ha sido cerrado.", "success")

            // Actualizar el estado del producto en la grilla sin eliminarlo
            setProductosEgreso((prevProductos) =>
              prevProductos.map((producto) =>
                producto.numSecuencia === productIdToCierre
                  ? { ...producto, estado: "CERRADA" } // Aquí se actualiza el estado a "CERRADA"
                  : producto,
              ),
            )
          })
          .catch((error) => {
            console.error("Error:", error)
            Swal.fire("Error!", "No se pudo cerrar el producto.", "error")
          })
      }
    })
  }

  const {
    data: dataProductos = [],
    isError: dataProductosError,
    isFetching: isFetchingDataProductos,
    isLoading: isLoadingProductos,
    refetch: refetchGetProductos,
  } = useGetProductosXCliente(cliente)

  useEffect(() => {
    setProductos(dataProductos)
  }, [dataProductos])

  useEffect(() => {
    enviarDato()
  }, [dataProductos])

  function useGetProductosXCliente(cliente) {
    return useQuery({
      queryKey: ["solicitudDeEgresoProductosEdit", cliente],
      queryFn: async () => {
        const response = await fetchwrapper(`/solicitudDeIngreso/getProductosXCliente`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ cliente }),
        })
        const result = await response.json()
        return result.productos
      },
      enabled: clienteSeleccionado,
      onError: (error) => {
        console.log("Error fetching data", error)
      },
    })
  }

  const columns = [
    {
      id: "actions",
      header: "Cerrar item",
      Cell: ({ row }) => {
        const isEditable = row.original.estado !== "CERRADA"
        return (
          <div>
            <Tooltip title={isEditable ? "" : "Artículo Cerrado"}>
              <span>
                <IconButton onClick={() => handleDeleteClick(row.original)} disabled={!isEditable}>
                  <IoCloseCircleSharp />
                </IconButton>
              </span>
            </Tooltip>
          </div>
        )
      },
    },
    {
      accessorKey: "artcodigo",
      header: "Código",
    },
    {
      accessorKey: "artdescri",
      header: "Artículo",
    },
    {
      accessorKey: "cantSolicitada",
      header: "Cantidad Solicitada",
    },
    {
      accessorKey: "cantEgresada",
      header: "Cantidad Egresada",
    },
    {
      accessorKey: "estado",
      header: "Estado",
    },
    {
      id: "imagen",
      header: "Imagen",
      Cell: ({ row }) => {
        const { data: imagenProducto = [] } = useGetProductoImages(row.original.artcodigo)
        const tieneImagen = imagenProducto.length > 0 // Verificar si hay una imagen

        return (
          <CustomTooltip title={tieneImagen ? "Ver imagen" : "No hay imagen disponible"}>
            <span>
              <IconButton onClick={() => handleOpenImageModal(row.original.artcodigo)} disabled={!tieneImagen}>
                <FaImage />
              </IconButton>
            </span>
          </CustomTooltip>
        )
      },
    },
  ]

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        gap: "10px",
        paddingTop: "10px",
        paddingBottom: "10px",
        width: "100%",
      }}
    >
      <div style={{ width: "100%", overflowX: "auto" }}>
        <MaterialReactTable columns={columns} data={productosEgreso || []} />
      </div>
      <Modal
        open={openImageModal}
        onClose={handleCloseImageModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            maxWidth: "90vw",
            maxHeight: "90vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {openImageModal && (
            <div>
              <div>
                <h3>Imagen del Producto</h3>
              </div>
              {imagenProducto.length > 0 ? (
                <img
                  src={`data:image/jpeg;base64,${imagenProducto[0].artimagen}`}
                  alt="Imagen del artículo"
                  style={{ maxWidth: "100%", maxHeight: "100%" }}
                />
              ) : (
                <p>No hay imagen disponible</p>
              )}
              <div>
                <Button onClick={handleCloseImageModal}>Cerrar</Button>
              </div>
            </div>
          )}
          ;
        </Box>
      </Modal>
    </div>
  )
}

export default AprobacionSolicitudEntrega
