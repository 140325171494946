import { useEffect, useMemo, useState } from "react"
import { MaterialReactTable, useMaterialReactTable } from "material-react-table"
import { MRT_Localization_ES } from "material-react-table/locales/es"

const DatagridPickingSugerido = ({ columnsTable, data, onSetSelectedColumns }) => {
  const columns = useMemo(
    () =>
      columnsTable.map((column) => ({
        ...column,
      })),
    [],
  )

  // optionally, you can manage the row selection state yourself
  const [rowSelection, setRowSelection] = useState({})

  const table = useMaterialReactTable({
    columns,
    data,
    enableRowSelection: true,
    onRowSelectionChange: setRowSelection, // connect internal row selection state to your own
    state: { rowSelection }, // pass our managed row selection state to the table to use
    localization: { ...MRT_Localization_ES },
  })

  const getRowsSelected = (rows) => {
    return table.getSelectedRowModel().rows.map((r) => r.original)
  }

  // do something when the row selection changes...
  useEffect(() => {
    onSetSelectedColumns(getRowsSelected())
  }, [rowSelection])

  return <MaterialReactTable table={table} />
}

export default DatagridPickingSugerido
