import React, { useEffect, useState } from "react"
import Header from "../../../../../layouts/Header"
import { styled, createTheme, ThemeProvider } from "@mui/material/styles"
import { Box, Grid, TextField, Button } from "@mui/material"

import BackIcon from "../../../../../components/BackIcon"

import { useLocation, useNavigate } from "react-router-dom"
import { useQuery, useMutation } from "@tanstack/react-query"
import fetchwrapper from "../../../../../services/interceptors/fetchwrapper"
import CustomBackdrop from "../../../../../components/CustomBackdrop"
import ProductsInventarioReadable from "../../../components/ProductsInventarioReadable"
import ZebraPrinter from "../../../../utils/zebraPrinter"
import SearchTexfield from "../../../components/SearchTextField"

const StyledRoot = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  marginTop: theme.spacing(8),
  flexGrow: 1,
  padding: "0 16px",
  height: "100vh",
}))

const theme = createTheme({
  palette: {
    primary: {
      main: "#196C87",
    },
    secondary: {
      main: "#196C87", // Cambia el color secundario a verde azulado
    },
  },
})

const styles = {
  accordionSummary: {
    backgroundColor: "rgb(25, 108, 135)",
    color: "white",
    fontWeight: "bolder",
  },
  row: {
    display: "flex",
    // justifyContent: "space-between",
    alignItems: "center",
    paddingBlock: "10px",
  },
  rowTitle: {
    alignSelf: "end",
    paddingRight: "20px",
    fontWeight: "bolder",
  },
}
const DisabledTextField = ({ label = "", value }) => {
  return (
    <span style={{ flexGrow: 1 }}>
      <TextField fullWidth id={`outlined`} variant="standard" label={label} value={value ?? ""} disabled />
    </span>
  )
}

const ActualizarTodosArticulosIngresoAsignacionUbicacion = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const [bahiaSelected, setBahiaSelected] = useState(null)
  const [posicionSelected, setPosicionSelected] = useState(null)
  const [nivelSelected, setNivelSelected] = useState(null)

  // -----------------------------------------------------

  const {
    data: fetchedProductos = [],
    isError: isLoadingProductosError,
    isFetching: isFetchingProductos,
    isLoading: isLoadingProductos,
    error: isFetchingProductosError,
  } = useGetPProductos()

  // READ hook (get InProductos from api)
  function useGetPProductos() {
    return useQuery({
      queryKey: ["ProductosAsignacionUbicacionReadableActualizar"],
      queryFn: async () => {
        // send api request here
        let response = await fetchwrapper(
          `/asignacionUbicacion/getAllIngresosPalletsDetalle/${location.state?.cabecera?.palletid}`, // Esta es la misma api que se usa en cuarentena para traer todos los articulos asociados a un ingreso
        )
        response = await response.json()
        response = response?.data
        return response
      },
      refetchOnWindowFocus: false,
    })
  }

  const {
    data: fetchedUbicacionDestino = {},
    isFetching: isFetchingUbicacionDestino,
    isLoading: isLoadingUbicacionDestino,
    isError: isErrorUbicacionDestino,
  } = useGetPUbicacionDestino()

  // READ hook (get InProductos from api)
  function useGetPUbicacionDestino() {
    return useQuery({
      queryKey: ["UbicacionDestinoBuscado"],
      queryFn: async () => {
        // send api request here
        let response = await fetchwrapper(
          `/asignacionUbicacion/getUbicacionDestino/${location.state?.cabecera?.palletid}`, // Esta es la misma api que se usa en cuarentena para traer todos los articulos asociados a un ingreso
        )
        response = await response.json()
        response = response?.data
        return response
      },
      refetchOnWindowFocus: false,
      onerror: () => {
        alert("No se pudo obtener la ubicación destino de este ingreso. Intente con recargar esta página otra vez")
      },
    })
  }

  const {
    data: fetchedBahias = [],
    isError: isLoadingBahiasError,
    isFetching: isFetchingBahias,
    isLoading: isLoadingBahias,
  } = useGetBahias()

  function useGetBahias() {
    return useQuery({
      queryKey: ["UbicacionesAsignacionBahiasReadableActualizar"],
      queryFn: async () => {
        // send api request here
        let response = await fetchwrapper(
          `/asignacionUbicacion/getAllBahias/${location.state?.cabecera?.invcodigo}/${location.state?.cabecera?.bodcodigo}`,
        )
        response = await response.json()
        response = response?.data
        return response
      },
      refetchOnWindowFocus: false,
    })
  }

  const {
    data: fetchedNiveles = [],
    isError: isLoadingNivelesError,
    isFetching: isFetchingNiveles,
    isLoading: isLoadingNiveles,
  } = useGetNiveles()

  function useGetNiveles() {
    return useQuery({
      queryKey: ["UbicacionesAsignacionNivelesReadableActualizar"],
      queryFn: async () => {
        // send api request here
        let response = await fetchwrapper(`/asignacionUbicacion/getAllNiveles`)
        response = await response.json()
        response = response?.data
        return response
      },
      refetchOnWindowFocus: false,
    })
  }

  const {
    data: fetchedPosiciones = [],
    isError: isLoadingPosicionesError,
    isFetching: isFetchingPosiciones,
    isLoading: isLoadingPosiciones,
  } = useGetPosiciones()

  function useGetPosiciones() {
    return useQuery({
      queryKey: ["UbicacionesAsignacionPosicionesReadableActualizar"],
      queryFn: async () => {
        // send api request here
        let response = await fetchwrapper(`/asignacionUbicacion/getAllPosiciones`)
        response = await response.json()
        response = response?.data
        return response
      },
      refetchOnWindowFocus: false,
    })
  }
  const { mutateAsync: upddateUbicacion, isPending: isUpdadingteUbicacion } = useUpdateUbicacion()

  // CREATE hook (post new bancoDePregunta to api)
  function useUpdateUbicacion() {
    return useMutation({
      mutationFn: async (ubicacion) => {
        // send api update request here
        const options = {
          method: "POST",
          body: JSON.stringify(ubicacion),
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }

        const response = await fetchwrapper(`/asignacionUbicacion/updateUbicacionDestino`, options)
        return response
      },
      // eslint-disable-next-line n/handle-callback-err
      onError: (error, variables, context) => {
        // An error happened!
        alert("Error al actualizar la ubicación. Intente otra vez")
      },
      onSuccess: () => {
        alert("Ubicación actualizada con éxito")
        navigate(-1)
      },

      // onSettled: () => queryClient.invalidateQueries({ queryKey: ['BancoDePreguntas'] }), //refetch bancoDePreguntas after mutation, disabled for demo
    })
  }

  // When ubicacion destino is loaded, complete de inputboxes
  useEffect(() => {
    if (Object.keys(fetchedUbicacionDestino).length !== 0) {
      setBahiaSelected({
        bahcodigo: fetchedUbicacionDestino.bahcodigo,
        bahdescripcion: fetchedUbicacionDestino.bahdescripcion,
        pascodigo: fetchedUbicacionDestino.pascodigo,
        izoncodigo: fetchedUbicacionDestino.izoncodigo,
      })

      setPosicionSelected({
        poscodigo: fetchedUbicacionDestino.poscodigo,
        posdescripcion: fetchedUbicacionDestino.posdescripcion,
      })

      setNivelSelected({
        nivcodigo: fetchedUbicacionDestino.nivcodigo,
        nivdescripcion: fetchedUbicacionDestino.nivdescripcion,
      })
    }
  }, [fetchedUbicacionDestino])

  const prepareUpdateUbicacion = () => {
    if (
      !bahiaSelected?.izoncodigo ||
      !bahiaSelected?.pascodigo ||
      !bahiaSelected?.bahcodigo ||
      !posicionSelected?.poscodigo ||
      !nivelSelected?.nivcodigo
    ) {
      alert("Complete todos los campos de la ubicación")
      return
    }

    const newUbicacionDestino = {
      ubicacion: {
        izoncodigo: bahiaSelected.izoncodigo,
        pascodigo: bahiaSelected.pascodigo,
        bahcodigo: bahiaSelected.bahcodigo,
        poscodigo: posicionSelected.poscodigo,
        nivcodigo: nivelSelected.nivcodigo,
      },
      palletid: location.state?.cabecera?.palletid,
    }

    console.log(newUbicacionDestino)

    upddateUbicacion(newUbicacionDestino)
  }

  return (
    <ThemeProvider theme={theme}>
      <Header />
      <div className="main main-app p-3 p-lg-4">
        {/* {fetchedBahias} */}
        <BackIcon />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "0 30px 30px 30px",
            fontSize: "25px",
          }}
        >
          <b>ARTICULOS ASOCIADOS AL PALET</b>
        </div>
        <div style={{ textAlign: "center" }}>
          <p>
            <strong>Código de Palet:</strong> {location.state?.cabecera?.palletid}
          </p>
          <p>
            <strong>Observación:</strong> {location.state?.cabecera?.tradescripcion}
          </p>
          <br />
          <br />
        </div>
        <CustomBackdrop
          isLoading={
            isLoadingProductos ||
            isFetchingProductos ||
            isUpdadingteUbicacion ||
            isFetchingUbicacionDestino ||
            isLoadingUbicacionDestino ||
            isFetchingBahias ||
            isLoadingBahias ||
            isLoadingNiveles ||
            isFetchingNiveles ||
            isFetchingPosiciones ||
            isLoadingPosiciones
          }
        />

        {isLoadingProductosError ||
        isFetchingProductosError ||
        isErrorUbicacionDestino ||
        isLoadingBahiasError ||
        isLoadingNivelesError ||
        isLoadingPosicionesError ? (
          <div>Error al obtener la información</div>
        ) : (
          <Box className={StyledRoot}>
            <div style={styles.row}>
              <span style={styles.rowTitle}>Código Bahía de Ingreso:</span>
              <SearchTexfield
                idProducto="bahias"
                idEachRow="bahcodigo"
                value={bahiaSelected?.bahcodigo}
                setProducto={setBahiaSelected}
                columnsTable={[
                  {
                    accessorKey: "bahcodigo",
                    header: "Código Embalaje",
                    enableEditing: false,
                    size: 200,
                  },
                  {
                    accessorKey: "bahdescripcion",
                    header: "Descripción Embalaje",
                    enableEditing: false,
                    size: 200,
                  },
                  {
                    accessorKey: "izoncodigo",
                    header: "Código Zona",
                    enableEditing: false,
                    size: 200,
                  },
                  {
                    accessorKey: "izondescripcion",
                    header: "Descripción Zona",
                    enableEditing: false,
                    size: 200,
                  },
                  {
                    accessorKey: "pascodigo",
                    header: "Código Pasillo",
                    enableEditing: false,
                    size: 200,
                  },
                  {
                    accessorKey: "pasdescripcion",
                    header: "Descripción Pasillo",
                    enableEditing: false,
                    size: 200,
                  },
                  {
                    accessorKey: "bahmetros3",
                    header: "Metros Cúbicos",
                    enableEditing: false,
                    size: 200,
                  },
                ]}
                dataTable={fetchedBahias}
              />
            </div>
            <div style={styles.row}>
              <span style={styles.rowTitle}>Descripción Bahía de Ingreso:</span>
              <DisabledTextField value={bahiaSelected?.bahdescripcion} />
            </div>

            <div style={styles.row}>
              <span style={styles.rowTitle}>Código Posición de Ingreso:</span>
              <SearchTexfield
                idProducto="posiciones"
                idEachRow="poscodigo"
                value={posicionSelected?.poscodigo}
                setProducto={setPosicionSelected}
                columnsTable={[
                  {
                    accessorKey: "poscodigo",
                    header: "Código",
                    enableEditing: false,
                    size: 200,
                  },
                  {
                    accessorKey: "posdescripcion",
                    header: "Descripción",
                    enableEditing: false,
                    size: 200,
                  },
                ]}
                dataTable={fetchedPosiciones}
              />
            </div>
            <div style={styles.row}>
              <span style={styles.rowTitle}>Descripción Posición de Ingreso:</span>
              <DisabledTextField value={posicionSelected?.posdescripcion} />
            </div>

            <div style={styles.row}>
              <span style={styles.rowTitle}>Código Nivel de Ingreso:</span>
              <SearchTexfield
                idProducto="niveles"
                idEachRow="nivcodigo"
                value={nivelSelected?.nivcodigo}
                setProducto={setNivelSelected}
                columnsTable={[
                  {
                    accessorKey: "nivcodigo",
                    header: "Código",
                    enableEditing: false,
                    size: 200,
                  },
                  {
                    accessorKey: "nivdescripcion",
                    header: "Descripción",
                    enableEditing: false,
                    size: 200,
                  },
                ]}
                dataTable={fetchedNiveles}
              />
            </div>
            <div style={styles.row}>
              <span style={styles.rowTitle}>Descripción Nivel de Ingreso:</span>
              <DisabledTextField value={nivelSelected?.nivdescripcion} />
            </div>

            <div style={styles.row}>
              <span style={styles.rowTitle}>Zona de Ingreso:</span>
              <DisabledTextField value={bahiaSelected?.izoncodigo} />
            </div>

            <div style={styles.row}>
              <span style={styles.rowTitle}>Pasillo de Ingreso:</span>
              <DisabledTextField value={bahiaSelected?.pascodigo} />
            </div>

            <Button onClick={prepareUpdateUbicacion} variant="contained" color="primary" fullWidth>
              Actualizar Ubicación
            </Button>
            <br />
            <br />
            <ProductsInventarioReadable productsCuarentena={fetchedProductos?.productosIngreso} />
          </Box>
        )}
      </div>
    </ThemeProvider>
  )
}

export default ActualizarTodosArticulosIngresoAsignacionUbicacion
