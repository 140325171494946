import React, { useState, useEffect } from "react"
import Header from "../../../../layouts/Header"
import { styled, createTheme, ThemeProvider } from "@mui/material/styles"
import {
  Box,
  Grid,
  Card,
  CardContent,
  Typography,
  Button,
  TextField,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material"
import { useMutation, useQuery } from "@tanstack/react-query"
import BackIcon from "../../../../components/BackIcon"
import fetchwrapper from "../../../../services/interceptors/fetchwrapper"
import CustomBackdrop from "../../../../components/CustomBackdrop"
import { useNavigate, useLocation } from "react-router-dom"
import ProductsReadable from "../../components/ProductsReadable"
import GrabarIcon from "../../../../assets/iconos/Grabar.ico"
import CustomSelectComponent from "../../../../components/CustomSelectComponent"

const StyledRoot = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  marginTop: theme.spacing(8),
  flexGrow: 1,
  padding: "0 16px",
  height: "100vh",
}))

const theme = createTheme({
  palette: {
    primary: {
      main: "#196C87",
    },
    secondary: {
      main: "#196C87", // Cambia el color secundario a verde azulado
    },
  },
})
const VerificarVerificacionQuimico = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [productosPorVerificarCabecera, setProductosPorVerificarCabecera] = useState({})
  const [productosPorVerificar, setProductosPorVerificar] = useState([])

  const [motivos, setMotivos] = useState([])
  const [selectedMotivo, setSelectedMotivo] = useState(null)

  const opcionesVerificacion = [
    {
      id: 1,
      descrip: "Sí",
    },
    {
      id: 2,
      descrip: "No",
    },
  ]
  const [selectedOpcionVerificacion, setSelectedOpcionVerificacion] = useState(null) // Id selected in custom select component (1 | 2)

  const handleSelectedOpcionVerificacion = (event) => {
    setSelectedOpcionVerificacion(event.target.value)
  }

  const {
    data: fetchedProductos = [],
    isError: isLoadingProductosError,
    isFetching: isFetchingProductos,
    isLoading: isLoadingProductos,
  } = useGetProductos()

  // READ hook (get Productos from api)
  function useGetProductos() {
    return useQuery({
      queryKey: ["FiltrosVerificacionQuimProductosssssss"],
      queryFn: async () => {
        // send api request here
        let response = await fetchwrapper(`/verificacionQuimico/getAllProductsPorVerificar/${location.state.palletid}`)
        response = await response.json()

        const motivos = response?.data?.motivos
        setMotivos(motivos)

        const cabeceraResponse = response?.data?.cabecera
        setProductosPorVerificarCabecera(cabeceraResponse)

        const productosResponse = response?.data?.productos
        return productosResponse
      },
    })
  }

  const { mutateAsync: saveVerifiacionQuimico, isPending: isSavingVerifiacionQuimico } = useSaveVerifiacionQuimico()

  function useSaveVerifiacionQuimico() {
    return useMutation({
      mutationFn: async (data) => {
        const options = {
          method: "POST",
          body: JSON.stringify(data),
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }

        let response = await fetchwrapper(`/verificacionQuimico/saveProductsPorVerificar`, options)

        response = response.json()
        return response
      },
      onError: () => {
        alert("No se pudo hacer la verificación químico. Intente otra vez")
      },
      onSuccess: () => {
        alert("Verificación químico realizada con éxito")
        navigate(-1)
      },
    })
  }
  const handleSaveProductsPorVerificar = async () => {
    if (!selectedOpcionVerificacion) {
      alert("Seleccione un opción para aprobado")
      return
    }
    if (!selectedMotivo) {
      alert("Seleccione un opción para motivo")
      return
    }

    const data = {
      cabecera: productosPorVerificarCabecera,
      isAprobadoQuimicoWeb: Number(selectedOpcionVerificacion) === 1, // Si es uno es aprobado(true) y sino es rechazado(false)
      motivo: selectedMotivo,
    }

    await saveVerifiacionQuimico(data)
  }

  useEffect(() => {
    if (fetchedProductos.length !== 0) {
      const productsPorVerificarFormat = fetchedProductos.map((product) => ({
        sgasoling: product.sgasoling,
        artcodigo: product.artcodigo,
        artdescri: product.artdescri,

        embcodigo: product.embcodigo,
        embdescripcion: product.embdescripcion,
        embmetros3: product.embmetros3,
        embpieza: product.embpieza,
        embmetros2: product.embmetros2,

        tondescripcion: product.tondescripcion,
        toncodigo: product.toncodigo,

        cantidadEmbalada: product.cantidadEmbalada,

        lotes: product.lotes,

        isAprobado: null,
      }))

      setProductosPorVerificar(productsPorVerificarFormat)
    } else {
      setProductosPorVerificar([])
    }
  }, [fetchedProductos])

  const styles = {
    accordionSummary: {
      backgroundColor: "rgb(25, 108, 135)",
      color: "white",
      fontWeight: "bolder",
    },
    row: {
      display: "flex",
      // justifyContent: "space-between",
      alignItems: "center",
      paddingBlock: "10px",
    },
    rowTitle: {
      alignSelf: "end",
      paddingRight: "20px",
      fontWeight: "bolder",
    },
  }
  return (
    <ThemeProvider theme={theme}>
      <Header />
      <div className="main main-app p-3 p-lg-4">
        <BackIcon />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "0 30px 30px 30px",
            fontSize: "25px",
          }}
        >
          <b>Verificación Químico</b>
        </div>

        <CustomBackdrop isLoading={isLoadingProductos || isFetchingProductos || isSavingVerifiacionQuimico} />

        <Box className={StyledRoot}>
          <div style={{ textAlign: "center" }}>
            <p>
              <strong>Código de pallet:</strong> {location.state.palletid}
            </p>
            {/* <p>
              <strong>Código de Solicitud:</strong>{" "}
              {productosPorVerificarCabecera?.sgasoling}
            </p>
            <p>
              <strong>Código de Ingreso:</strong>{" "}
              {productosPorVerificarCabecera?.tranumero}
            </p> */}
            <p>
              <strong>Inventario:</strong> {productosPorVerificarCabecera?.invdescri}
            </p>
            <p>
              <strong>Bodega:</strong> {productosPorVerificarCabecera?.boddescri}
            </p>
            <p>
              <strong>Bahía:</strong> {productosPorVerificarCabecera?.bahcodigo}
            </p>
            <p>
              <strong>Zona:</strong> {productosPorVerificarCabecera?.izondescripcion}
            </p>
            <p>
              <strong>Pasillo:</strong> {productosPorVerificarCabecera?.pascodigo}
            </p>
            <p>
              <strong>Posición:</strong> {productosPorVerificarCabecera?.poscodigo}
            </p>
            <p>
              <strong>Nivel:</strong> {productosPorVerificarCabecera?.nivcodigo}
            </p>

            <div style={styles.row}>
              <span style={{ ...styles.rowTitle, paddingBottom: "10px" }}>Aprobado:</span>
              <FormControl fullWidth>
                <RadioGroup
                  row
                  aria-label="aprobacion"
                  name="aprobacion"
                  value={selectedOpcionVerificacion}
                  onChange={handleSelectedOpcionVerificacion}
                >
                  {opcionesVerificacion.map((opcion) => (
                    <FormControlLabel key={opcion.id} value={opcion.id} control={<Radio />} label={opcion.descrip} />
                  ))}
                </RadioGroup>
              </FormControl>
            </div>

            <div style={styles.row}>
              <span style={{ ...styles.rowTitle, paddingBottom: "15px" }}>Motivo:</span>

              <div style={{ width: "100%" }}>
                <CustomSelectComponent
                  id="bodegas"
                  floatLabel={null}
                  optionLabel="accion"
                  values={motivos}
                  selectedValue={selectedMotivo}
                  setSelectedValue={setSelectedMotivo}
                  placeholder={null}
                />
              </div>
            </div>

            <br />
            <br />
          </div>

          <div>
            {isLoadingProductosError ? (
              <div>No se pudo ejecutar la búsqueda.Intente otra vez</div>
            ) : (
              <>
                {productosPorVerificar.length === 0 ? (
                  <div>Este pallet no contiene ningún artículo que necesite verificarse</div>
                ) : (
                  <>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={handleSaveProductsPorVerificar}
                      sx={{ marginBottom: "20px" }}
                    >
                      Guardar
                      <span style={{ marginLeft: "8px" }}>
                        <img src={GrabarIcon} alt="guardar" style={{ width: "40px" }} />
                      </span>
                    </Button>
                    <ProductsReadable products={productosPorVerificar} />
                  </>
                )}
              </>
            )}
          </div>
        </Box>
      </div>
    </ThemeProvider>
  )
}

export default VerificarVerificacionQuimico
