/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import { useMemo, useState } from "react"
import {
  MaterialReactTable,
  // createRow,
  useMaterialReactTable,
} from "material-react-table"
import { createTheme, ThemeProvider, useTheme, Box, Button, IconButton, Tooltip } from "@mui/material"
import { esES } from "@mui/material/locale"
import { MRT_Localization_ES } from "material-react-table/locales/es"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import EditIcon from "@mui/icons-material/Edit"
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye"
import fetchwrapper from "../../../services/interceptors/fetchwrapper"
import { useNavigate } from "react-router-dom"
import CrearIcon from "../../../assets/iconos/Crear.ico"
import normalFormatDate from "../../utils/date/DDMMYYYFormatDate"

const CustomTable = ({ fetchedInfo, isLoadingInfoError, isLoadingInfo, isFetchingInfo }) => {
  const navigate = useNavigate()

  const columns = useMemo(
    () => [
      // {
      //   accessorKey: "sgasoling",
      //   header: "Código de Solicitud de Ingreso",
      //   enableEditing: false,
      //   size: 200,
      // },
      // {
      //   accessorKey: "tranumero",
      //   header: "Código Ingreso",
      //   enableEditing: false,
      //   size: 200,
      // },
      {
        accessorKey: "palletid",
        header: "Código de Pallet",
        enableEditing: false,
        size: 200,
      },
      {
        accessorKey: "tradescripcion",
        header: "Observación",
        enableEditing: false,
        size: 200,
      },
      // {
      //   accessorKey: "sgastatus",
      //   header: "Estado de la Solicitud Ingreso",
      //   enableEditing: false,
      //   size: 200,
      // },
      // {
      //   accessorKey: "sgadescri",
      //   header: "Descripción",
      //   enableEditing: false,
      //   size: 200,
      // },
      {
        accessorKey: "clinombre",
        header: "Cliente",
        enableEditing: false,
        size: 120,
      },
      // {
      //   accessorKey: "pronombre",
      //   header: "Proveedor",
      //   enableEditing: false,
      //   size: 120,
      // },
      // {
      //   accessorKey: "motdescripcion",
      //   header: "Motivo",
      //   enableEditing: false,
      //   size: 120,
      // },
      {
        accessorKey: "trafecisys",
        header: "Fecha de Creación",
        enableEditing: false,
        size: 120,
        Cell: ({ cell }) => normalFormatDate(cell.getValue()),
      },
      // {
      //   accessorKey: "trahorisys",
      //   header: "Fecha de Llegada",
      //   enableEditing: false,
      //   size: 120,
      //   Cell: ({ cell }) => normalFormatDate(cell.getValue()),
      // },
      // {
      //   accessorKey: "sgacomenllegada",
      //   header: "Comentario de Llegada",
      //   enableEditing: false,
      //   size: 120,
      // },
    ],
    [],
  )

  const table = useMaterialReactTable({
    columns,
    data: fetchedInfo,
    enableEditing: true,
    enableRowActions: true,
    localization: { ...MRT_Localization_ES },
    getRowId: (row) => row.formcodigo,
    muiToolbarAlertBannerProps: isLoadingInfoError
      ? {
          color: "error",
          children: "Error en cargar las Solicitudes Ingresos",
        }
      : undefined,
    muiTableContainerProps: {
      sx: {
        minHeight: "500px",
      },
    },

    renderRowActions: ({ row, table }) => (
      <Box sx={{ display: "flex", gap: "1rem" }}>
        <Tooltip title="Ver">
          <IconButton
            onClick={() => {
              navigate(`verificar`, {
                state: row?.original,
              })
            }}
          >
            <RemoveRedEyeIcon />
          </IconButton>
        </Tooltip>
      </Box>
    ),
    state: {
      isLoading: isLoadingInfo,
      showAlertBanner: isLoadingInfoError,
      showProgressBars: isFetchingInfo,
    },
  })

  return <MaterialReactTable table={table} />
}

const DatagridPalletsCrear = (params) => {
  const theme = useTheme()
  return (
    <div>
      <ThemeProvider theme={createTheme(theme, esES)}>
        <CustomTable {...params} />
      </ThemeProvider>
    </div>
  )
}

export default DatagridPalletsCrear
