import React, { useState, useEffect } from "react"
import Header from "../../../layouts/Header"
import { styled, createTheme, ThemeProvider } from "@mui/material/styles"
import {
  Box,
  Grid,
  Card,
  CardContent,
  Typography,
  InputLabel,
  MenuItem,
  Select,
  Autocomplete,
  TextField,
  Button,
} from "@mui/material"
import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query"
import { useNavigate, useParams } from "react-router-dom"

import BackIcon from "../../../components/BackIcon"
import CrearIcon from "../../../assets/iconos/Crear.ico"
import fetchwrapper from "../../../services/interceptors/fetchwrapper"
import CustomBackdrop from "../../../components/CustomBackdrop"
import DatagridDragDrop from "../components/DatagridDragDrop"

const StyledRoot = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  marginTop: theme.spacing(8),
  flexGrow: 1,
  padding: "0 16px",
  height: "100vh",
}))

const theme = createTheme({
  palette: {
    primary: {
      main: "#196C87",
    },
    secondary: {
      main: "#196C87", // Cambia el color secundario a verde azulado
    },
  },
})

const EditarFormulario = () => {
  const navigate = useNavigate()
  const { formularioID } = useParams()

  const Estados = [
    { value: "A", description: "Activo" },
    { value: "I", description: "Inactivo" },
  ]
  const [codigo, setCodigo] = useState("")
  const [descripcion, setDescripcion] = useState("")
  const [estado, setEstado] = useState(Estados[0])
  const [proceso, setProceso] = useState("")
  // State for drag and drop component
  const [allPreguntas, setAllPreguntas] = useState([])
  const [preguntasSelectedFormulario, setPreguntasSelectedFormulario] = useState([])

  const {
    data: fetchedFormulario = [],
    isError: isLoadingFormularioError,
    isFetching: isFetchFormulario,
    isLoading: isLoadingFormulario,
    refetch: refetchFormulario,
  } = useGetFormulario()

  // READ hook (get formulario from api)
  function useGetFormulario() {
    return useQuery({
      queryKey: ["FormularioProceso", formularioID],
      queryFn: async () => {
        // send api request here
        let response = await fetchwrapper(`/FormularioProcesos/getFormulario/${formularioID}`)
        response = await response.json()
        return response
      },
      refetchOnWindowFocus: false,
    })
  }

  if (isLoadingFormularioError) {
    alert("Error al obtener el formulario")
    navigate(-1)
  }

  // When all formulario load set in state
  useEffect(() => {
    if (
      fetchedFormulario?.cabecera?.formcodigo &&
      fetchedFormulario?.cabecera?.procesocod &&
      fetchedFormulario?.cabecera?.formdescri &&
      fetchedFormulario?.cabecera?.formstatus &&
      fetchedFormulario?.detalle?.data1 &&
      fetchedFormulario?.detalle?.data2
    ) {
      const cabecera = fetchedFormulario?.cabecera
      const data1 = fetchedFormulario?.detalle?.data1
      const data2 = fetchedFormulario?.detalle?.data2

      // Asignacion de datos de cabecera
      setCodigo(cabecera?.formcodigo)
      setProceso(cabecera?.procesocod)
      setDescripcion(cabecera?.formdescri)
      const estado = Estados.find((estado) => estado.value === cabecera?.formstatus)
      setEstado(estado)

      // Asignacion de datos del detalle en los drag drop componentes
      setAllPreguntas(data1)
      setPreguntasSelectedFormulario(data2)
    }
  }, [fetchedFormulario])

  const { mutateAsync: updateFormulario, isPending: isUpdatingFormulario } = useUpdateFormulario()

  // UPDATE hook (put specific bancoDePregunta in api)
  function useUpdateFormulario() {
    const queryClient = useQueryClient()
    return useMutation({
      mutationFn: async (formulario) => {
        // send api update request here
        // send api update request here
        const options = {
          method: "PUT",
          body: JSON.stringify(formulario),
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }

        let response = await fetchwrapper(`/FormularioProcesos/editarFormulario/${formularioID}`, options)

        response = response.json()
        return response
      },
      // client side optimistic update
      onMutate: (newFormulario) => {
        queryClient.setQueryData(["FormularioProceso", formularioID], (prevFormulario) => ({
          ...newFormulario,
        }))
      },
      onSettled: () =>
        queryClient.invalidateQueries({
          queryKey: ["FormularioProceso", formularioID],
        }), // refetch formulario after mutation, disabled for demo
    })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    if (!descripcion || !estado || !proceso || preguntasSelectedFormulario.length === 0) {
      alert("Complete todos los campos")
      return
    }

    const upadateFormulario = {
      cabecera: {
        descripcion,
        estado: estado.value,
        procesocod: proceso,
      },
      detalle: preguntasSelectedFormulario,
    }

    try {
      await updateFormulario(upadateFormulario)
      alert("Formulario actualizado con éxito")
    } catch {
      alert("Error al actualizar el formulario ")
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <Header />
      <div className="main main-app p-3 p-lg-4">
        <BackIcon />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "0 30px 30px 30px",
            fontSize: "25px",
          }}
        >
          <b>Formulario</b>
        </div>
        <CustomBackdrop isLoading={isLoadingFormulario || isFetchFormulario || isUpdatingFormulario} />

        <Box className={StyledRoot}>
          <form onSubmit={handleSubmit}>
            <TextField
              label="Código"
              margin="normal"
              fullWidth
              value={codigo}
              onChange={(e) => setCodigo(e.target.value)}
              required
              disabled
              sx={{
                backgroundColor: "#e2dbd8",
              }}
            />
            <TextField
              label="Proceso"
              margin="normal"
              fullWidth
              value={proceso}
              onChange={(e) => setProceso(e.target.value)}
              required
              disabled
              sx={{
                backgroundColor: "#e2dbd8",
              }}
            />

            <TextField
              label="Descripción"
              margin="normal"
              fullWidth
              value={descripcion}
              onChange={(e) => setDescripcion(e.target.value)}
              required
            />

            <div>
              <InputLabel id="estadosCB" sx={{ paddingBlock: "10px", paddingLeft: "5px" }}>
                Estado
              </InputLabel>
              <Select
                fullWidth
                labelId="estadosCB"
                value={estado.value}
                onChange={(e) => {
                  const selectedValue = e.target.value
                  setEstado(Estados.find((estado) => estado.value === selectedValue))
                }}
              >
                {Estados.map((estado) => (
                  <MenuItem key={estado.value} value={estado.value}>
                    {estado.description}
                  </MenuItem>
                ))}
              </Select>
            </div>

            <Button type="submit" variant="outlined" color="primary" sx={{ marginTop: "20px" }}>
              <img src={CrearIcon} alt="Crear" style={{ width: "40px" }} />
              Editar
            </Button>
          </form>

          <div>
            <DatagridDragDrop
              data1={allPreguntas}
              setData1={setAllPreguntas}
              data2={preguntasSelectedFormulario}
              setData2={setPreguntasSelectedFormulario}
            />
          </div>
        </Box>
      </div>
    </ThemeProvider>
  )
}

export default EditarFormulario
