import React, { useState, useEffect } from "react"
import Header from "../../../layouts/Header"
import { styled, createTheme, ThemeProvider } from "@mui/material/styles"
import {
  Box,
  Grid,
  Card,
  CardContent,
  Typography,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  FormGroup,
  FormControlLabel,
  TextField,
  Button,
} from "@mui/material"

import BackIcon from "../../../components/BackIcon"
import CrearIcon from "../../../assets/iconos/Crear.ico"
import OpcionesMultiples from "../components/OpcionesMultiples"
import fetchwrapper from "../../../services/interceptors/fetchwrapper"
import CustomBackdrop from "../../../components/CustomBackdrop"
import TextoLibre from "../components/TextoLibre"
import ListaOpciones from "../components/ListaOpciones"

const StyledRoot = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  marginTop: theme.spacing(8),
  flexGrow: 1,
  padding: "0 16px",
  height: "100vh",
}))

const theme = createTheme({
  palette: {
    primary: {
      main: "#196C87",
    },
    secondary: {
      main: "#196C87", // Cambia el color secundario a verde azulado
    },
  },
})

const CrearBancosDePreguntas = () => {
  // Valores por defectos para los comboboxes y el check
  const Estados = [
    { value: "A", description: "Activo" },
    { value: "I", description: "Inactivo" },
  ]
  const TiposPregunta = [
    { value: "U", description: "Texto Libre" },
    { value: "L", description: "Lista de Opciones" },
    { value: "M", description: "Opciones Múltiples" },
  ]

  const PreguntaObligatoria = [
    { value: -1, description: " Es obligatoria", checked: true },
    { value: 0, description: "No es obligatoría", checked: false },
  ]

  // Estados para manejaar el formulario de opciones
  const [descripcion, setDescripcion] = useState("")
  const [estado, setEstado] = useState(Estados[0])
  const [tipoPregunta, setTipoPregunta] = useState(TiposPregunta[0])
  const [preguntaObligatoria, setPreguntaObligatoria] = useState(PreguntaObligatoria[0])

  // Estado para manejar el tipo de pregunta seleccionado
  const [allPreguntas, setallPreguntas] = useState([])

  // Manejar el loading al momento de crear el banco de preguntas en el db
  const [isLoadingCrearBancoDePregunta, setIsLoadingCrearBancoDePregunta] = useState(false)

  // // Cada vez que se cambie el tipo de pregunta se borraran todos los datos
  useEffect(() => {
    clearAll(tipoPregunta)
  }, [tipoPregunta.value])

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (tipoPregunta.value !== "U") {
      if (!(descripcion && estado && tipoPregunta && preguntaObligatoria) || allPreguntas.length === 0) {
        alert("Complete los campos")
        return
      }
    }

    try {
      const options = {
        method: "POST",
        body: JSON.stringify({
          cabecera: {
            descripcion,
            estado: estado.value,
            tipoPregunta: tipoPregunta.value,
            preguntaObligatoria: preguntaObligatoria.value,
          },
          detalle: allPreguntas,
        }),
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }

      setIsLoadingCrearBancoDePregunta(true)

      let response = await fetchwrapper("/BancoDePreguntas/createBancoDePregunta", options)
      response = await response.json()
      clearAll()
      alert(`Banco de pregunta creado con código ${response.pregcodigoGenerated}`)
    } catch (error) {
      alert("No se puedo crea el banco de preguntas")
    } finally {
      setIsLoadingCrearBancoDePregunta(false)
    }
  }

  const clearAll = (tipoPregunta = TiposPregunta[0]) => {
    setDescripcion("")
    setEstado(Estados[0])
    setTipoPregunta(tipoPregunta)
    setPreguntaObligatoria(PreguntaObligatoria[0])
    setallPreguntas([])
  }

  return (
    <ThemeProvider theme={theme}>
      <Header />
      <div className="main main-app p-3 p-lg-4">
        <BackIcon />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "0 30px 30px 30px",
            fontSize: "25px",
          }}
        >
          <b>Banco de Preguntas</b>
        </div>
        <CustomBackdrop isLoading={isLoadingCrearBancoDePregunta} />

        <Box className={StyledRoot}>
          <form onSubmit={handleSubmit}>
            <div>
              <InputLabel id="tiposPreguntasCB" sx={{ paddingBlock: "15px", paddingLeft: "5px" }}>
                Tipo de Pregunta
              </InputLabel>
              <Select
                fullWidth
                labelId="tiposPreguntasCB"
                value={tipoPregunta.value}
                onChange={(e) => {
                  const selectedValue = e.target.value
                  setTipoPregunta(TiposPregunta.find((tipoPregunta) => tipoPregunta.value === selectedValue))
                }}
              >
                {TiposPregunta.map((tipo) => (
                  <MenuItem key={tipo.value} value={tipo.value}>
                    {tipo.description}
                  </MenuItem>
                ))}
              </Select>
            </div>

            <TextField
              label="Descripción"
              margin="normal"
              fullWidth
              value={descripcion}
              onChange={(e) => setDescripcion(e.target.value)}
              required
            />

            <div>
              <InputLabel id="estadosCB" sx={{ paddingBlock: "10px", paddingLeft: "5px" }}>
                Estado
              </InputLabel>
              <Select
                fullWidth
                labelId="estadosCB"
                value={estado.value}
                onChange={(e) => {
                  const selectedValue = e.target.value
                  setEstado(Estados.find((estado) => estado.value === selectedValue))
                }}
              >
                {Estados.map((estado) => (
                  <MenuItem key={estado.value} value={estado.value}>
                    {estado.description}
                  </MenuItem>
                ))}
              </Select>
            </div>

            <FormGroup sx={{ paddingBlock: "15px", paddingLeft: "5px" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={preguntaObligatoria.checked}
                    onChange={(e) => {
                      const selectedValue = e.target.checked
                      setPreguntaObligatoria(
                        PreguntaObligatoria.find(
                          (preguntaObligatoria) => preguntaObligatoria.checked === selectedValue,
                        ),
                      )
                    }}
                  />
                }
                label="Pregunta Obligatoria"
              />
            </FormGroup>

            <Button type="submit" variant="outlined" color="primary">
              <img src={CrearIcon} alt="Crear" style={{ width: "40px" }} />
              Crear
            </Button>
          </form>

          {/* {tipoPregunta.value === "U" && (
            <div
              style={{
                boxSizing: "borderBox",
                marginTop: "20px",
                border: "5px solid #5a9996",
                padding: "10px",
                paddingTop: "15px",
              }}
            >
              <div>
                <p
                  style={{
                    fontSize: "18px",
                    color: "black",
                    marginLeft: "5px",
                  }}
                >
                  Opciones
                </p>

                <TextoLibre
                  allPreguntas={allPreguntas}
                  setallPreguntas={setallPreguntas}
                />
              </div>
            </div>
          )} */}

          {tipoPregunta.value === "L" && (
            <div
              style={{
                boxSizing: "borderBox",
                marginTop: "20px",
                border: "5px solid #5a9996",
                padding: "10px",
                paddingTop: "15px",
              }}
            >
              <div>
                <p
                  style={{
                    fontSize: "18px",
                    color: "black",
                    marginLeft: "5px",
                  }}
                >
                  Opciones
                </p>

                <ListaOpciones allPreguntas={allPreguntas} setallPreguntas={setallPreguntas} />
              </div>
            </div>
          )}

          {tipoPregunta.value === "M" && (
            <div
              style={{
                boxSizing: "borderBox",
                marginTop: "20px",
                border: "5px solid #5a9996",
                padding: "10px",
                paddingTop: "15px",
              }}
            >
              <div>
                <p
                  style={{
                    fontSize: "18px",
                    color: "black",
                    marginLeft: "5px",
                  }}
                >
                  Opciones
                </p>

                <OpcionesMultiples allPreguntas={allPreguntas} setallPreguntas={setallPreguntas} />
              </div>
            </div>
          )}
        </Box>
      </div>
    </ThemeProvider>
  )
}

export default CrearBancosDePreguntas
