import React, { useEffect } from "react"
import { FormControlLabel, Checkbox } from "@mui/material"
import esPreguntaObligatoria from "../utils/esPreguntaObligatoria"

const OpcionesMultiples = ({
  description,
  setValue,
  pregcodigo,
  values,
  opciones,
  pregobligatoria,
  form,
  step,
  isReadable,
}) => {
  const handleTextFieldChange = (objSelected) => {
    // Actualizar el estado con los valores del check
    const nuevaRespuesta = [...values]

    const indice = values.findIndex((value) => value.opcion === objSelected.opcion)

    if (indice !== -1) {
      // El objeto ya existe, así que lo eliminamos
      nuevaRespuesta.splice(indice, 1)
    } else {
      // El objeto no existe, así que lo agregamos
      nuevaRespuesta.push(objSelected)
    }

    // Ordenar las opciones seleccionadas por el pregsecuen
    nuevaRespuesta.sort((a, b) => a.pregsecuen - b.pregsecuen)

    const preguntaConRespuesta = form.preguntas.map((pregunta) => {
      if (pregunta?.pregcodigo === pregcodigo) {
        return {
          ...pregunta,
          respuestaSelected: nuevaRespuesta,
        }
      }
      return pregunta
    })
    //   console.log(preguntaConRespuesta);

    const updatedForm = {
      cabecera: { ...form.cabecera },
      preguntas: preguntaConRespuesta,
    }
    setValue(step, updatedForm)
  }

  return (
    <div>
      <p style={{ paddingLeft: "1px", fontWeight: "bolder", fontSize: "1.1rem" }}>
        {`${description} ${esPreguntaObligatoria(pregobligatoria)}`}
      </p>
      <div>
        {opciones.map((option, index) => (
          <FormControlLabel
            key={index}
            control={
              <Checkbox
                checked={values.some((value) => value?.opcion === option.opcion)}
                onChange={() => handleTextFieldChange(option)}
                disabled={!!isReadable}
              />
            }
            label={option.opcion}
          />
        ))}
      </div>
    </div>
  )
}

export default OpcionesMultiples
