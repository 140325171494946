import { useRef, useState } from "react"
import { useReactToPrint } from "react-to-print"
import Button from "@mui/material/Button"
import { Box } from "@mui/material"

import html2pdf from "html2pdf.js"
import ReactDOMServer from "react-dom/server"

const SaveAndPrintButton = ({ onAction, disabled, Component, nameDoc = "document" }) => {
  const componentRef = useRef()
  const [isError, setIsError] = useState(false)

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: nameDoc,
    onAfterPrint: async () => {
      await handleSave()
    },
  })

  const handleSave = () => {
    return new Promise((resolve, reject) => {
      const htmlContent = ReactDOMServer.renderToStaticMarkup(Component)

      const opt = {
        margin: 10,
        filename: "CheckListTR.pdf",
        image: {
          type: "png",
          quality: 0.98,
        },
        html2canvas: {
          scale: 3,
          logging: true,
          dpi: 192,
          letterRendering: true,
        },
        pagebreak: { mode: "avoid-all" },

        jsPDF: {
          unit: "mm",
          format: "a4",
          orientation: "portrait",
        },
      }

      html2pdf()
        .from(htmlContent)
        .set(opt)
        .toPdf()
        .get("pdf")
        .then(function (pdf) {
          const totalPages = pdf.internal.getNumberOfPages()

          for (let i = 1; i <= totalPages; i++) {
            pdf.setPage(i)
            pdf.setFontSize(10)
            pdf.setTextColor(100)
            // Obtener dimensiones de la página
            const pageWidth = pdf.internal.pageSize.getWidth()
            // Calcular posición central y vertical
            const textWidth =
              (pdf.getStringUnitWidth("Page " + i + " of " + totalPages) * pdf.internal.getFontSize()) /
              pdf.internal.scaleFactor
            const xPos = (pageWidth - textWidth) / 2
            const yPos = 8

            // Colocar el texto en la posición calculada
            pdf.text("Page " + i + " of " + totalPages, xPos, yPos)
          }
          // Imprimir el PDF directamente
          pdf.save("CheckListTR.pdf")
          // imprimirPDF(pdf);

          // pdf.autoPrint();

          // Abre una nueva ventana para imprimir el PDF
          // window.open(pdf.output("bloburl"), "_blank");
          resolve()
          // ...
        })
    })
  }
  const handleActions = async () => {
    const existedError = await onAction()
    setIsError(existedError)
    if (!existedError) {
      handlePrint()
    }
  }
  return (
    <>
      {/* <div ref={componentRef} >
      {Component}
    </div> */}
      <Button onClick={handleActions} disabled={disabled}>
        Guardar
      </Button>
      {!isError && (
        <Box displayPrint="block" ref={componentRef} display="block" position="absolute" marginTop={10}>
          {Component}
        </Box>
      )}
    </>
  )
}

export default SaveAndPrintButton
