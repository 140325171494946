import React, { useState, useEffect } from "react"
import Header from "../../../layouts/Header"
import { styled, createTheme, ThemeProvider } from "@mui/material/styles"
import { Box, Grid, Card, CardContent, Typography, Button } from "@mui/material"

import BackIcon from "../../../components/BackIcon"
import fetchwrapper from "../../../services/interceptors/fetchwrapper"
import CustomBackdrop from "../../../components/CustomBackdrop"

import DatagridSolicitudIngreso from "../components/DatagridSolicitudIngreso"
// import { MultiSelect } from "react-multi-select-component";
import { useQuery } from "@tanstack/react-query"

import CustomMultiselectComponent from "../../../components/CustomMultiselectComponent"
import CustomDateRangePickerComponent from "../../../components/CustomDateRangePickerComponent"

const StyledRoot = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  marginTop: theme.spacing(8),
  flexGrow: 1,
  padding: "0 16px",
  height: "100vh",
}))

const theme = createTheme({
  palette: {
    primary: {
      main: "#196C87",
    },
    secondary: {
      main: "#196C87", // Cambia el color secundario a verde azulado
    },
  },
})
const BuscarAsignacionUbicacion = () => {
  // Loaded info about filtros
  const [filtrosLoaded, setFiltrosLoaded] = useState({
    Cliente: [],
    Proveedor: [],
    NumeroSolicitud: [],
  })

  // State about values in each cb  in filtros
  const [clientesFiltroSelected, setClientesFiltroSelected] = useState([])
  const [selectAllClientes, setSelectAllClientes] = useState(false)
  const [proveedoresFiltroSelected, setProveedoresFiltroSelected] = useState([])
  const [selectAllProveedores, setSelectAllProveedores] = useState(false)

  const [numerosSolicitudesFiltroSelected, setNumerosSolicitudesFiltroSelected] = useState([])
  const [selectAllNumSolicitudes, setSelectAllNumSolicitudes] = useState(false)

  const [rangoFechasCreacionFiltroSelected, setRangoFechasCreacionFiltroSelected] = useState([])
  const [rangoFechasLlegadaFiltroSelected, setRangoFechasLlegadaFiltroSelected] = useState([new Date(), new Date()])

  // -----------------------------------------------------
  const {
    data: fetchedFiltrosCB = {},
    isError: isLoadingFiltrosCBError,
    isFetching: isFetchingFiltrosCB,
    isLoading: isLoadingFiltrosCB,
  } = useGetFiltrosCB()

  // READ hook (get FiltrosCB from api)
  function useGetFiltrosCB() {
    return useQuery({
      queryKey: ["FiltrosAsignacionUbicacionCB"],
      queryFn: async () => {
        // send api request here
        let response = await fetchwrapper("/verificacionDocumental/getFiltersSolicitudIngreso")
        response = await response.json()
        response = response?.data
        return response
      },
      refetchOnWindowFocus: false,
    })
  }
  // -----------------------------------------------------

  // -----------------------------------------------------
  const {
    data: fetchedSolicitudesIngresos = [],
    isError: isLoadingSolicitudesIngresosError,
    isFetching: isFetchingSolicitudesIngresos,
    isLoading: isLoadingSolicitudesIngresos,
    refetch: refetchSolicitudesIngresos,
  } = useGetSolicitudesIngresos()

  // READ hook (get SolicitudesIngresos from api)
  function useGetSolicitudesIngresos() {
    return useQuery({
      queryKey: ["SolicitudesIngresosBuscarAsignacionUbicacion"],
      queryFn: async () => {
        const options = {
          method: "POST",
          body: JSON.stringify({
            Cliente: clientesFiltroSelected,
            Proveedor: proveedoresFiltroSelected,
            Status: ["RECIBIDA TOTAL", "RECIBIDA PARCIAL"],
            NumeroSolicitud: numerosSolicitudesFiltroSelected,
            RangoFechasCreacion: rangoFechasCreacionFiltroSelected,
            RangoFechasLlegada: rangoFechasLlegadaFiltroSelected,
          }),
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }
        // send api request here
        let response = await fetchwrapper("/asignacionUbicacion/obtenerSolicitudesIngresoBuscar", options)
        response = await response.json()
        response = response?.data
        return response
      },
      refetchOnWindowFocus: false,
      enabled: false,
    })
  }
  // -----------------------------------------------------

  // when all data of filtros loaded, set state in loaded filtros
  useEffect(() => {
    if (fetchedFiltrosCB.clientes && fetchedFiltrosCB.proveedores && fetchedFiltrosCB.codigosSolicitudes) {
      setFiltrosLoaded({
        Cliente: fetchedFiltrosCB.clientes,
        Proveedor: fetchedFiltrosCB.proveedores,
        NumeroSolicitud: fetchedFiltrosCB.codigosSolicitudes,
      })
    }
  }, [fetchedFiltrosCB])

  useEffect(() => {
    console.log(
      numerosSolicitudesFiltroSelected,
      clientesFiltroSelected,
      proveedoresFiltroSelected,
      rangoFechasCreacionFiltroSelected,
      rangoFechasLlegadaFiltroSelected,
    )
  }, [
    numerosSolicitudesFiltroSelected,
    clientesFiltroSelected,
    proveedoresFiltroSelected,
    rangoFechasCreacionFiltroSelected,
    rangoFechasLlegadaFiltroSelected,
  ])

  return (
    <ThemeProvider theme={theme}>
      <Header />
      <div className="main main-app p-3 p-lg-4">
        <BackIcon />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "0 30px 30px 30px",
            fontSize: "25px",
          }}
        >
          <b>Descarga en cuarentena</b>
        </div>

        <CustomBackdrop
          isLoading={
            isLoadingFiltrosCB || isFetchingFiltrosCB || isLoadingSolicitudesIngresos || isFetchingSolicitudesIngresos
          }
        />

        <Box className={StyledRoot}>
          <div>
            {/* <MultiSelect
              className="multiselect"
              options={filtrosLoaded.Cliente}
              value={clientesFiltroSelected}
              isLoading={isLoadingFiltrosCB || isFetchingFiltrosCB}
              onChange={setClientesFiltroSelected}
              labelledBy="Select"
              overrideStrings={{
                selectAll: "Seleccionar todo",
                selectSomeItems: "Selecciona los clientes",
                allItemsAreSelected: "Todas los items fueron seleccionados",
              }}
            /> */}

            <div
              style={{
                display: "grid",
                gap: "16px",
                maxWidth: "1200px",
                margin: "0 auto",
              }}
            >
              <CustomMultiselectComponent
                options={filtrosLoaded.Cliente}
                value={clientesFiltroSelected}
                setValue={setClientesFiltroSelected}
                selectAll={selectAllClientes}
                setSelectAll={setSelectAllClientes}
                placeholder="Selecciona los clientes"
              />

              <CustomMultiselectComponent
                options={filtrosLoaded.NumeroSolicitud}
                value={numerosSolicitudesFiltroSelected}
                setValue={setNumerosSolicitudesFiltroSelected}
                selectAll={selectAllNumSolicitudes}
                setSelectAll={setSelectAllNumSolicitudes}
                placeholder="Seleccione las solicitudes"
              />

              <CustomMultiselectComponent
                options={filtrosLoaded.Proveedor}
                value={proveedoresFiltroSelected}
                setValue={setProveedoresFiltroSelected}
                selectAll={selectAllProveedores}
                setSelectAll={setSelectAllProveedores}
                placeholder="Seleccione los Proveedores"
              />
            </div>

            <div
              style={{
                display: "grid",
                gap: "16px",
                maxWidth: "1200px",
                margin: "20px auto",
              }}
            >
              <CustomDateRangePickerComponent
                value={rangoFechasCreacionFiltroSelected}
                setValue={setRangoFechasCreacionFiltroSelected}
                placeholder="Rango de fechas de creación solicitud"
                size="lg"
              />

              {/* <CustomDateRangePickerComponent
                value={rangoFechasLlegadaFiltroSelected}
                setValue={setRangoFechasLlegadaFiltroSelected}
                placeholder="Rango de fechas de llegada solicitud"
                size="lg"
              /> */}
            </div>
          </div>

          <div>
            <Button variant="outlined" color="primary" onClick={refetchSolicitudesIngresos}>
              Filtrar
            </Button>
          </div>
          <DatagridSolicitudIngreso
            fetchedInfo={fetchedSolicitudesIngresos}
            isLoadingInfoError={isLoadingSolicitudesIngresosError}
            isLoadingInfo={isLoadingSolicitudesIngresos}
            isFetchingInfo={isFetchingSolicitudesIngresos}
          />
        </Box>
      </div>
    </ThemeProvider>
  )
}

export default BuscarAsignacionUbicacion
