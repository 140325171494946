/* eslint-disable array-callback-return */
// PDFContent.js

import React from "react"

const FormCheckListTransporteRecepcion = ({ data = {} }) => {
  const styles = {
    container: {
      width: "80%",
      margin: "0 auto",
      borderCollapse: "collapse",
      border: "1px solid #000",
    },
    mainTable: {
      width: "100%",
      borderCollapse: "collapse",
      border: "1px solid #000",
    },
    logoCelda: {
      backgroundColor: "#f2f2f2",
      border: "1px solid #000",
    },
    registroCelda: {
      backgroundColor: "#f2f2f2",
      border: "1px solid #000",
    },
    celda: {
      backgroundColor: "#f2f2f2",
      border: "1px solid #000",
      textAlign: "center",
      fontWeight: "bolder",
    },
    encabezadoCelda: {
      backgroundColor: "#f2f2f2",
      border: "1px solid #000",
    },
    dataCelda: {
      border: "1px solid #000",
    },
    textCenter: {
      textAlign: "center",
    },
  }

  return (
    <div
      id="content"
      style={{
        width: "80%",
        margin: "10px auto",
      }}
    >
      <table
        style={{
          width: "100%",
          borderCollapse: "collapse",
          border: "1px solid #000",
        }}
      >
        <tbody>
          <tr>
            <td rowSpan={3} style={styles.celda}>
              Logo
            </td>
            <td rowSpan={3} style={styles.celda}>
              REGISTRO
            </td>
            {/* <td style={styles.celda}> {data.cabecera?.formcodigo}</td> */}
            <td style={styles.celda}>OPE-REG-SC-33</td>
          </tr>
          <tr>
            <td style={styles.celda}>Versión: 08</td>
          </tr>
          <tr>
            <td style={styles.celda}>Fecha de vigencia: {new Date().toLocaleDateString()}</td>
          </tr>

          <tr>
            <td colSpan={2} style={styles.celda}>
              MERCATTI S.A. MERCATTISA
            </td>
            <td colSpan={2} style={{ ...styles.celda, textTransform: "uppercase" }}>
              {data.cabecera?.procesocod}
            </td>
          </tr>
        </tbody>
      </table>
      <br />
      <hr />
      <div style={{ marginBottom: "10px" }}>
        <p>
          <span>{}</span>
        </p>
      </div>
      <div>
        {data.preguntas?.map((pregunta) => (
          <div key={pregunta.formsecuen} style={{ marginBottom: "10px" }}>
            <p>
              {pregunta.pregtipo === "U" && pregunta.pregdescri === "PLACAS" && (
                <span>
                  <span style={{ fontWeight: "bolder" }}>
                    VEHÍCULO PLACAS
                    {/* {pregunta.pregdescri} */}
                  </span>
                  : {pregunta.respuestaSelected}
                </span>
              )}

              {pregunta.pregtipo === "M" && (
                <span>
                  {/* {pregunta.pregdescri}:{" "} */}
                  {pregunta.opciones?.map((opcion, index) => (
                    <span key={opcion.pregsecuen}>
                      {index > 0 && Array(6).fill("\u00A0").join("")}
                      <span style={{ fontWeight: "bolder" }}>{opcion.opcion}: </span>
                      {pregunta?.respuestaSelected?.some((opc) => {
                        return opc?.opcion === opcion.opcion && opc?.pregsecuen === opcion.pregsecuen
                      })
                        ? "_X_"
                        : "__"}
                    </span>
                  ))}
                </span>
              )}
            </p>
          </div>
        ))}
        <span>
          <span style={{ fontWeight: "bolder" }}>Fecha:</span> {new Date().toLocaleDateString()}
        </span>

        <table
          style={{
            width: "100%",
            borderCollapse: "collapse",
            border: "1px solid #000",
          }}
        >
          <thead>
            <tr>
              <th
                colSpan="1"
                style={{
                  border: "1px solid #000",
                  padding: "8px",
                  textAlign: "center",
                }}
              >
                LIMPIEZA GENERAL
              </th>
              <th colSpan="1" style={{ border: "1px solid #000", padding: "8px" }}>
                SI
              </th>
              <th colSpan="1" style={{ border: "1px solid #000", padding: "8px" }}>
                NO
              </th>
            </tr>
          </thead>
          <tbody>
            {data.preguntas?.map((pregunta, index) => (
              <>
                {index === 5 && (
                  <tr style={{ textAlign: "center" }}>
                    <td
                      style={{
                        padding: "8px",
                        fontWeight: "bolder",
                        border: "1px solid #000",
                      }}
                    >
                      CONTAMINACIÓN CRUZADA
                    </td>
                  </tr>
                )}
                {index === 6 && (
                  <tr style={{ textAlign: "center" }}>
                    <td
                      style={{
                        padding: "8px",
                        fontWeight: "bolder",
                        border: "1px solid #000",
                      }}
                    >
                      SEGURIDAD Y CARGA
                    </td>
                  </tr>
                )}
                {pregunta.pregtipo === "L" && (
                  <tr key={pregunta.formsecuen}>
                    <td style={{ border: "1px solid #000", padding: "8px" }}>{pregunta.pregdescri}</td>
                    <td style={{ border: "1px solid #000", padding: "8px" }}>
                      {pregunta.respuestaSelected && pregunta.respuestaSelected.opcion.toUpperCase() === "SI" && "X"}
                    </td>
                    <td style={{ border: "1px solid #000", padding: "8px" }}>
                      {pregunta.respuestaSelected && pregunta.respuestaSelected.opcion.toUpperCase() === "NO" && "X"}
                    </td>
                  </tr>
                )}
              </>
            ))}
          </tbody>
        </table>
      </div>

      {data.preguntas?.map((pregunta) => {
        if (pregunta.pregdescri !== "PLACAS") {
          return (
            <div key={pregunta.formsecuen} style={{ marginBottom: "10px" }}>
              <p>
                {pregunta.pregtipo === "U" && (
                  <span>
                    <span style={{ fontWeight: "bolder" }}>{pregunta.pregdescri}</span>: {pregunta.respuestaSelected}
                  </span>
                )}
              </p>
            </div>
          )
        }
      })}
      <div style={{ marginBottom: "10px" }}>
        <span style={{ fontWeight: "bolder" }}>FIRMA RESPONSABLE DEL CHECK LIST:</span>
      </div>

      {/* <table
        style={{
          width: "100%",
          borderCollapse: "collapse",
          border: "1px solid #000",
        }}
      >
        <thead>
          <tr>
            <th colSpan="2" style={styles.celda}>
              Constancia de recepción:
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th style={styles.celda}>
              Recibe: Bodega MERCATTI S.A. MERCATTISA
            </th>
            <th style={styles.celda}>Entrega:Operador</th>
          </tr>
          <tr>
            <th style={styles.celda}>Firma:</th>
            <th style={styles.celda}>Firma:</th>
          </tr>
        </tbody>
      </table>
      <p>
        <strong>Control De Cambios</strong>
      </p>
      <table
        style={{
          width: "100%",
          borderCollapse: "collapse",
          border: "1px solid #000",
        }}
      >
        <tbody>
          <tr>
            <th style={styles.celda}>Versión No</th>
            <th style={styles.celda}>Cambios Realizados</th>
            <th style={styles.celda}>Fecha:</th>
            <th style={styles.celda}>Fecha Próxima Revisión</th>
          </tr>
          <tr>
            <td style={styles.celda}>01</td>
            <td style={styles.celda}>Versión original</td>
            <td style={styles.celda}>06 de Julio de 2017</td>
            <td style={styles.celda}>06 de Julio de 2019</td>
          </tr>
          
         
        </tbody>
      </table>
      <br />
      <table
        style={{
          width: "100%",
          borderCollapse: "collapse",
          border: "1px solid #000",
        }}
      >
        <thead></thead>
        <tbody>
          <tr>
            <th style={styles.celda}>Elaborado por:</th>
            <th style={styles.celda}>Revisado y Aprobado por:</th>
            <th style={styles.celda}>Autorizado por:</th>
          </tr>
          <tr>
            <td style={styles.celda}>
              Nombre: Eduardo Alvarado Cargo: Jefe de Operaciones y Logística.
              Firma:
            </td>
            <td style={styles.celda}>
              Nombre: Q.F. Francis Lange Cargo: Responsable Técnico. Firma:
            </td>
            <td style={styles.celda}>
              Nombre: Christian Ulich Cargo: Gerente General. Firma:
            </td>
          </tr>
          <tr>
            <td style={styles.celda}>Fecha: 04/05/2023</td>
            <td style={styles.celda}>Fecha: 04/05/2023</td>
            <td style={styles.celda}>Fecha: 04/05/2023</td>
          </tr>
        </tbody>
      </table>{" "} */}
    </div>
  )
}

export default FormCheckListTransporteRecepcion
