import { useState, useCallback, useContext, useEffect } from "react"
import Header from "../../../layouts/Header"
import { styled, createTheme, ThemeProvider } from "@mui/material/styles"
import { useNavigate, useLocation } from "react-router-dom"
import { Box, Button, InputLabel, TextField, MenuItem, Select, Tooltip, IconButton, Modal } from "@mui/material"
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"
import ModalUploadFileEgreso from "../components/ModalUploadFileEgreso"
import CustomBackIcon from "../../SolicitudDeIngreso/components/CustomBackIcon"
import RegresarIcon from "../../../assets/iconos/Regresar.ico"
import CustomTooltip from "../../../components/ToolTip"
import EliminarIcon from "../../../assets/iconos/Eliminar.ico"
import DetalleIcon from "../../../assets/iconos/Detalle.ico"
import CrearIcon from "../../../assets/iconos/Crear.ico"
import { useQuery, useQueryClient } from "@tanstack/react-query"
import fetchwrapper from "../../../services/interceptors/fetchwrapper"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import GrabarIcon from "../../../assets/iconos/Grabar.ico"
import Swal from "sweetalert2"
import { MaterialReactTable } from "material-react-table"
import { SolicitudEntregaContext } from "../SolicitudDeEntregaContext"
import ExcelImportar from "../../../assets/iconos/ExcelImportar.ico"
import CustomInputDetail from "../../SolicitudDeIngreso/components/CustomInputDetail"
import CustomAutoComplete from "../../SolicitudDeIngreso/components/CustomAutoComplete"
import { FaImage } from "react-icons/fa6"
import DetalleImpresion from "./DetalleImpresion"
import ImpresionIcon from "../../../assets/iconos/Impresion.ico"
import ProductoAddIcon from "../../../assets/iconos/ProductoAdd.ico"

const theme = createTheme({
  palette: {
    primary: {
      main: "#196C87",
    },
    secondary: {
      main: "#196C87",
    },
  },
})

const Container = styled(Box)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "repeat(12, 1fr)",
  gridTemplateRows: "auto auto auto",
  gridTemplateAreas: `
    "Solicitud Solicitud Solicitud FechaI FechaI FechaI Cliente Cliente Cliente ClienteDescri ClienteDescri ClienteDescri"
    "Motivo Motivo Motivo Estado Estado Estado Proveedor Proveedor Proveedor ProveedorDescri ProveedorDescri ProveedorDescri"
    "FechaL FechaL FechaL HoraL HoraL HoraL Comentario Comentario Comentario Comentario Comentario Comentario"
    "Detalle Detalle Detalle Detalle Detalle Detalle AgenciaSol AgenciaSol AgenciaSol AgenciaSolDescri AgenciaSolDescri AgenciaSolDescri"
    "AgenciaEnv AgenciaEnv AgenciaEnv AgenciaEnvDescri AgenciaEnvDescri AgenciaEnvDescri Contacto Contacto Contacto ContactoDescri ContactoDescri ContactoDescri"
  `,
  gap: "8px",
  alignItems: "center",

  [theme.breakpoints.down("sm")]: {
    gridTemplateColumns: "repeat(6, 1fr)",
    gridTemplateAreas: `
      "Solicitud Solicitud Solicitud FechaI FechaI FechaI"
      "Cliente Cliente Cliente ClienteDescri ClienteDescri ClienteDescri"
      "Motivo Motivo Motivo Estado Estado Estado"
      "Proveedor Proveedor Proveedor ProveedorDescri ProveedorDescri ProveedorDescri"
      "FechaL FechaL FechaL HoraL HoraL HoraL"
      "Comentario Comentario Comentario Comentario Comentario Comentario"
      "Detalle Detalle Detalle Detalle Detalle Detalle"
      "AgenciaSol AgenciaSol AgenciaSol AgenciaSolDescri AgenciaSolDescri AgenciaSolDescri"
      "AgenciaEnv AgenciaEnv AgenciaEnv AgenciaEnvDescri AgenciaEnvDescri AgenciaEnvDescri "
      "Contacto Contacto Contacto ContactoDescri ContactoDescri ContactoDescri"
      
    `,
  },
}))

const Solicitud = styled(Box)({
  gridArea: "Solicitud",
})

const FechaI = styled(Box)({
  gridArea: "FechaI",
})

const FechaL = styled(Box)({
  gridArea: "FechaL",
})

const HoraL = styled(Box)({
  gridArea: "HoraL",
})

const Cliente = styled(Box)({
  gridArea: "Cliente",
})

const ClienteDescri = styled(Box)({
  gridArea: "ClienteDescri",
})

const Motivo = styled(Box)({
  gridArea: "Motivo",
})

const Estado = styled(Box)({
  gridArea: "Estado",
})

const Proveedor = styled(Box)({
  gridArea: "Proveedor",
})

const ProveedorDescri = styled(Box)({
  gridArea: "ProveedorDescri",
})

const Detalle = styled(Box)({
  gridArea: "Detalle",
})

const Comentario = styled(Box)({
  gridArea: "Comentario",
})

const AgenciaSol = styled(Box)({
  gridArea: "AgenciaSol",
})

const AgenciaSolDescri = styled(Box)({
  gridArea: "AgenciaSolDescri",
})

const AgenciaEnv = styled(Box)({
  gridArea: "AgenciaEnv",
})

const AgenciaEnvDescri = styled(Box)({
  gridArea: "AgenciaEnvDescri",
})

const Contacto = styled(Box)({
  gridArea: "Contacto",
})

const ContactoDescri = styled(Box)({
  gridArea: "ContactoDescri",
})

const Check = styled(Box)({
  gridArea: "Check",
  width: "100%",
})

const EditarSolicitudEntregaComponent = () => {
  const location = useLocation()
  const dayjs = require("dayjs")
  const [cliente, setCliente] = useState("")
  const [clienteDescription, setclienteDescription] = useState("")
  const [detalle, setDetalle] = useState(location.state.sgadescri)
  const [open, setOpen] = useState(false)
  const [filtrosMotivo, setFiltrosMotivo] = useState([])
  const { allFiltrosMotivo } = useGetAllFiltros()
  const fechaEnvio = dayjs(location.state.sgafecenvio).utc() // "DD/MM/YYYY"
  const fechaSolicitud = dayjs(location.state.sgafecsol).utc() // "DD/MM/YYYY"
  const horaEnvio = dayjs(location.state.sgahorenvio).utc()
  const navigate = useNavigate()

  const [codigoCliente, setCodigoCliente] = useState(null)
  const [codigoProveedor, setCodigoProveedor] = useState(null)
  const { productosEgreso, setProductosEgreso } = useContext(SolicitudEntregaContext)

  // impresion
  const {
    data: fetchedSolicitudIngreso = {},
    isError: isLoadingSolicitudIngresoError,
    isFetching: isFetchingSolicitudIngreso,
    isLoading: isLoadingSolicitudIngreso,
    refetch: refetchSolicitudIngreso,
  } = useGetSolicitud()

  const [solicitudImpresion, setSolicitudImpresion] = useState({})
  const [openModal, setOpenModal] = useState(false)
  const [infoGenerada, setInfoGenerada] = useState("")

  // agencias
  const [agenDescriEnv, setAgenDescriEnv] = useState(null)
  const [agenDescriSol, setAgenDescriSol] = useState(null)

  const handleOpenModal = () => {
    setOpenModal(true)
  }

  const imprimir = async () => {
    await refetchSolicitudIngreso()
    handleOpenModal()
  }

  useEffect(() => {
    if (fetchedSolicitudIngreso) {
      setSolicitudImpresion(fetchedSolicitudIngreso)
      setInfoGenerada(fetchedSolicitudIngreso)
    }
  }, [fetchedSolicitudIngreso])

  const handleCloseModal = () => {
    setOpenModal(false)
  }

  // productos de bodega
  const [datoDelHijo, setDatoDelHijo] = useState([])
  // Función para obtener el código del cliente
  const obtenerCodigoCliente = async (clinombre) => {
    const clienteOptions = {
      method: "POST",
      body: JSON.stringify({ clinombre }),
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    }
    const clienteResponse = await fetchwrapper("/solicitudDeIngreso/getCodCliente", clienteOptions)
    const clienteData = await clienteResponse.json()
    return clienteData.codigo
  }

  function useGetSolicitud() {
    return useQuery({
      queryKey: ["solicitudDeEgresoImpresion"],
      queryFn: async () => {
        const options = {
          method: "POST",
          body: JSON.stringify({
            codSolicitud: location.state.sgasolegr,
          }),
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }
        // send api request here
        let response = await fetchwrapper("/solicitudDeEgreso/getSolicitudDeEgreso", options)
        response = await response.json()
        return response.data
      },
      refetchOnWindowFocus: false,
      enabled: true,
    })
  }

  // Función para obtener el código del proveedor
  const obtenerCodigoProveedor = async (pronombre) => {
    const proveedorOptions = {
      method: "POST",
      body: JSON.stringify({ pronombre }),
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    }
    const proveedorResponse = await fetchwrapper("/solicitudDeIngreso/getCodProveedor", proveedorOptions)
    const proveedorData = await proveedorResponse.json()
    return proveedorData.codigo
  }

  useEffect(() => {
    setCliente(cliente)
  }, [cliente])

  useEffect(() => {
    const cargarCodigos = async () => {
      const clinombre = location.state.clinombre
      const pronombre = location.state.pronombre
      const agenCodSol = location.state.agencodigosol
      const agencodigoenv = location.state.agencodigoenv

      try {
        const clienteCodigo = await obtenerCodigoCliente(clinombre)
        setCodigoCliente(clienteCodigo)
      } catch (error) {
        console.error("Error al cargar el código del cliente:", error)
      }

      try {
        const proveedorCodigo = await obtenerCodigoProveedor(pronombre)
        setCodigoProveedor(proveedorCodigo)
      } catch (error) {
        console.error("Error al cargar el código del proveedor:", error)
      }

      try {
        const agenSolDescri = await obtenerCodigoAgencias(agenCodSol)
        setAgenDescriSol(agenSolDescri)
      } catch (error) {
        console.error("Error al cargar la descripción de la agencia de solicitud:", error)
      }

      try {
        const agenEnvDescri = await obtenerCodigoAgencias(agencodigoenv)
        setAgenDescriEnv(agenEnvDescri)
      } catch (error) {
        console.error("Error al cargar la descripción de la agencia de envío:", error)
      }
    }

    cargarCodigos()
  }, [])

  const manejarDatosHijo = (dato) => {
    setDatoDelHijo(dato)
  }

  const handleClienteChange = (event) => {
    setCliente(event.target.value)
  }

  const handleClienteDescriptionChange = (event) => {
    setclienteDescription(event.target.value)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleDetalle = (event) => {
    setDetalle(event.target.value)
  }

  const updateProductosSolEgr = useCallback(async () => {
    try {
      // Obtengo el código del cliente
      const clienteOptions = {
        method: "POST",
        body: JSON.stringify({ clinombre: location.state.clinombre }),
        headers: {
          "Content-Type": "application/json",
        },
      }
      const clienteResponse = await fetchwrapper("/solicitudDeIngreso/getCodCliente", clienteOptions)
      const clienteData = await clienteResponse.json()
      const descripcionCliente = clienteData.codigo

      // Inicializo el código del proveedor como nulo o vacío
      let descripcionProveedor = null

      // Solo obtengo el código del proveedor si existe pronombre
      if (location.state.pronombre) {
        const proveedorOptions = {
          method: "POST",
          body: JSON.stringify({ pronombre: location.state.pronombre }),
          headers: {
            "Content-Type": "application/json",
          },
        }
        const proveedorResponse = await fetchwrapper("/solicitudDeIngreso/getCodProveedor", proveedorOptions)
        const proveedorData = await proveedorResponse.json()
        descripcionProveedor = proveedorData.codigo
      }

      // Construyo los datos para enviar
      const options = {
        method: "POST",
        body: JSON.stringify({
          codigoSolicitud: location.state.sgasolegr,
          motivo: location.state.motivo,
          descripcion: detalle,
          comenenvio: location.state.motdescripcion,
          clicodigo: descripcionCliente,
          procodigo: descripcionProveedor, // Si no hay proveedor, esto será null
          sgafecenvio: fechaEnvio,
          sgahorenvio: horaEnvio,
          sgastatus: location.state.sgastatus,
          articulos: productosEgreso,
        }),
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }

      // Envío la solicitud de actualización
      let response = await fetchwrapper("/solicitudDeEgreso/updateProductosSolEgr", options)
      response = await response.json()
      return response
    } catch (error) {
      console.error("Error al actualizar los productos de solicitud de egreso:", error)
    }
  }, [detalle, productosEgreso])

  // verificar que todos los productos sean de la bodega del cliente
  const verificarProductosBodega = (datoDelHijo) => {
    const codigosIngresados = productosEgreso.map((producto) => producto.artcodigo)
    const codigosAceptados = new Set(datoDelHijo.map((producto) => producto.split("-")[0]))
    const codigosNoAceptados = new Set() // Usar Set para evitar duplicados

    for (const codigo of codigosIngresados) {
      if (!codigosAceptados.has(codigo)) {
        codigosNoAceptados.add(codigo) // Agregar al Set
      }
    }

    // Convertir el Set a un array y unir los elementos en un string
    if (codigosNoAceptados.size > 0) {
      return {
        resultado: true,
        codigosNoAceptados: Array.from(codigosNoAceptados).join(", "), // Convierte el Set a un array y luego a un string
      }
    }

    // Si todos los códigos son aceptados, retorna false
    return { resultado: false, codigosNoAceptados: "" }
  }

  const handleUpdateSolicitudDeEgreso = async () => {
    // verificar si todos los productos pertenecen a la bodega del cliente
    const allProductosPertenecen = verificarProductosBodega(datoDelHijo)
    if (allProductosPertenecen.resultado) {
      Swal.fire({
        icon: "error",
        title: "Productos incorrectos",
        text: `Los productos con código ${allProductosPertenecen.codigosNoAceptados} no pertenecen a la bodega del cliente`,
      })
    } else {
      await updateProductosSolEgr()
      Swal.fire({
        icon: "success",
        title: "Operación exitosa",
        text: "Se editó la Solicitud de Egreso",
      }).then((result) => {
        /* if (result.isConfirmed) {
          navigate("/home/dashboard/SolicitudDeEntrega") // Navegar a la ruta especificada
        } */
      })
    }
  }

  function useGetAllFiltros() {
    return useQuery({
      queryKey: ["solicitudDeEgresoGetAllFiltros"],
      queryFn: async () => {
        let response = await fetchwrapper("/solicitudDeEgreso/getAllFiltros")
        response = await response.json()
        setFiltrosMotivo(response.data)
        return response.data
      },
      refetchOnWindowFocus: false,
      enabled: true,
    })
  }

  // obtener codigo agencias
  const obtenerCodigoAgencias = async (agenCodigo) => {
    const agenciaOptions = {
      method: "POST",
      body: JSON.stringify({ agenCodigo }),
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    }
    const agenciaResponse = await fetchwrapper("/AprobacionSolicitudEntrega/getCodAgencia", agenciaOptions)
    const agenciaData = await agenciaResponse.json()
    return agenciaData.descripcion
  }

  // -----------------------------------------------------

  return (
    <ThemeProvider theme={theme}>
      <Header />
      <div className="main main-app p-3 p-lg-4">
        <CustomBackIcon />
        <div style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
          <div style={{ display: "flex", alignItems: "center", gap: "16px", padding: "5px" }}>
            <CustomTooltip title={"Crear Solicitud de Egreso"}>
              <Button onClick={() => navigate("/home/dashboard/SolicitudDeEntrega/crear")}>
                <img src={CrearIcon} alt="Crear" style={{ width: "40px" }} />
              </Button>
            </CustomTooltip>
          </div>
          <div style={{ display: "flex", alignItems: "center", gap: "16px", padding: "5px" }}>
            <CustomTooltip title={"Editar Solicitud de Egreso"}>
              <Button onClick={handleUpdateSolicitudDeEgreso}>
                <img src={GrabarIcon} alt="Grabar" style={{ width: "40px" }} />
              </Button>
            </CustomTooltip>
          </div>
          <div style={{ display: "flex", alignItems: "center", gap: "16px", padding: "5px" }}>
            <CustomTooltip title={"Imprimir"}>
              <Button onClick={imprimir}>
                <img src={ImpresionIcon} alt="Imprimir" style={{ width: "40px" }} />
              </Button>
            </CustomTooltip>
            <DetalleImpresion open={openModal} handleClose={handleCloseModal} infoGenerada={infoGenerada} />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "0 30px 30px 30px",
            fontSize: "25px",
          }}
        >
          <b>Editar Solicitud de Egreso</b>
        </div>
        <Container>
          <Solicitud>
            <InputLabel htmlFor="solicitud">Solicitud</InputLabel>
            <TextField id="solicitud" value={location.state.sgasolegr} disabled fullWidth />
          </Solicitud>
          <FechaI>
            <InputLabel>Fecha</InputLabel>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                sx={{ width: "100%" }}
                disabled
                format="DD/MM/YYYY"
                defaultValue={dayjs("2022-04-17")}
                value={fechaSolicitud}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </FechaI>
          <Cliente>
            <InputLabel>Cliente</InputLabel>
            <TextField value={codigoCliente} onChange={handleClienteChange} fullWidth disabled />
          </Cliente>
          <ClienteDescri>
            <InputLabel>Nombre</InputLabel>
            <TextField value={location.state.clinombre} onChange={handleClienteDescriptionChange} fullWidth disabled />
          </ClienteDescri>

          <Motivo>
            <InputLabel>Motivo</InputLabel>
            <TextField disabled id="motivo" value={location.state.motdescripcion} onChange={() => {}} fullWidth />
          </Motivo>
          <Estado>
            <InputLabel>Estado</InputLabel>
            <TextField disabled id="estado" value={location.state.sgastatus} onChange={() => {}} fullWidth />
          </Estado>
          <Proveedor>
            <InputLabel>Proveedor</InputLabel>
            <TextField value={codigoProveedor} onChange={handleClienteChange} fullWidth disabled />
          </Proveedor>
          <ProveedorDescri>
            <InputLabel>Descripción</InputLabel>
            <TextField value={location.state.pronombre} onChange={handleClienteDescriptionChange} fullWidth disabled />
          </ProveedorDescri>

          <Comentario>
            <InputLabel>Comentario de Envío</InputLabel>
            <TextField value={location.state.sgacomenenvio} fullWidth disabled />
          </Comentario>
          <FechaL>
            <InputLabel>Envío</InputLabel>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                sx={{ width: "100%" }}
                disabled
                value={fechaEnvio}
                dateFormat="yyyy-MM-dd hh:mm:ss.SSSSSS"
                timeFormat="HH:mm:ss.SSSSSS"
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </FechaL>
          <HoraL>
            <InputLabel>Hora de Envío</InputLabel>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <TimePicker sx={{ width: "100%" }} value={horaEnvio} disabled />
            </LocalizationProvider>
          </HoraL>
          <Detalle>
            <InputLabel>Detalle</InputLabel>
            <TextField value={detalle} onChange={handleDetalle} placeholder="" fullWidth />
          </Detalle>
          <AgenciaSol>
            <InputLabel>Agencia Solicita</InputLabel>
            <TextField value={location.state.agencodigosol} onChange={handleClienteChange} fullWidth disabled />
          </AgenciaSol>
          <AgenciaSolDescri>
            <InputLabel>Descripción</InputLabel>
            <TextField value={agenDescriSol} onChange={handleClienteChange} fullWidth disabled />
          </AgenciaSolDescri>
          <AgenciaEnv>
            <InputLabel>Agencia Envía</InputLabel>
            <TextField
              value={location.state.agencodigoenv}
              onChange={handleClienteDescriptionChange}
              fullWidth
              disabled
            />
          </AgenciaEnv>
          <AgenciaEnvDescri>
            <InputLabel>Descripción</InputLabel>
            <TextField value={agenDescriEnv} onChange={handleClienteDescriptionChange} fullWidth disabled />
          </AgenciaEnvDescri>
          <Contacto>
            <InputLabel>Contacto</InputLabel>
            <TextField value={location.state.concodrelextenv} onChange={handleClienteChange} fullWidth disabled />
          </Contacto>
          <ContactoDescri>
            <InputLabel>Descripción</InputLabel>
            <TextField
              value={location.state.condescrienv}
              onChange={handleClienteDescriptionChange}
              fullWidth
              disabled
            />
          </ContactoDescri>
        </Container>

        <div className="col-sm-12">
          <DatagridEditSolIng
            sgasolegr={location.state.sgasolegr}
            cliente={codigoCliente}
            onEnviarDatos={manejarDatosHijo}
          />
        </div>
      </div>
    </ThemeProvider>
  )
}

const DatagridEditSolIng = ({ sgasolegr, cliente, onEnviarDatos }) => {
  const [data, setData] = useState([])
  const [articulo, setArticulo] = useState("")
  const [articuloDescription, setArticuloDescription] = useState("")
  const [openAddProducto, setOpenAddProducto] = useState(false)
  const [cantidadSolicitada, setCantidadSolicitada] = useState("")
  const [estado, setEstado] = useState("PENDIENTE")
  const [editingRow, setEditingRow] = useState(null)
  const [editValues, setEditValues] = useState({})
  const [isEditing, setIsEditing] = useState(false)
  const { data: fetchedProductosSoling = [], refetch: refetchGetProductosSolIng } = useGetProductosSolIng(sgasolegr)
  const queryClient = useQueryClient()
  const [productosOriginales, setProductosOriginales] = useState("")
  const [editingProductId, setEditingProductId] = useState(null)
  const [autoCompleteValue, setAutoCompleteValue] = useState("")
  const [clienteSeleccionado, setClienteSeleccionado] = useState(true)
  const [openModal, setOpenModal] = useState(false)
  const [productos, setProductos] = useState([])
  // imagen de los productos
  const [openImageModal, setOpenImageModal] = useState(false)
  const [selectedImageUrl, setSelectedImageUrl] = useState("")
  const [imageHex, setImageHex] = useState("")

  const handleOpenModalExcel = () => {
    setOpenModal(true)
    console.log("Modal Excel should open:", openModal)
    console.log("cliente")
    console.log(cliente)
  }

  const enviarDato = () => {
    onEnviarDatos(dataProductos) // Llama a la función del padre y le pasa el dato
  }

  const useGetProductoImages = (artcodigo) => {
    return useQuery({
      queryKey: ["solicitudDeEgresoImagenes", artcodigo],
      queryFn: async () => {
        if (!artcodigo || artcodigo.trim() === "") {
          throw new Error("El código del artículo está vacío o es inválido.")
        }

        const response = await fetchwrapper(`/solicitudDeIngreso/getImagesxArtcodigo/${artcodigo}`)
        const result = await response.json()
        return result?.data
      },
      refetchOnWindowFocus: false,
      enabled: !!artcodigo,
    })
  }

  const { data: imagenProducto = [], refetch: refetchGetProductoImages, error } = useGetProductoImages(imageHex)

  const {
    productoSeleccionado,
    productoSeleccionadoDescri,
    articuloUsaDecimales,
    setProductosEgreso,
    productosEgreso,
  } = useContext(SolicitudEntregaContext)

  const handleProductoChange = (selectedValue) => {
    const [codigo, descripcion] = selectedValue.split("-")
    setArticulo(codigo)
    setArticuloDescription(descripcion)
    setAutoCompleteValue(selectedValue)
  }

  useEffect(() => {
    setProductosEgreso(productosEgreso)
  }, [productosEgreso])

  // imagenes
  const handleOpenImageModal = (hex) => {
    setImageHex(hex)
    setOpenImageModal(true)
  }

  useEffect(() => {
    setImageHex(imageHex)
  }, [imageHex])

  useEffect(() => {
    refetchGetProductoImages(imageHex)
  }, [refetchGetProductoImages, imageHex, imagenProducto])

  const handleCloseImageModal = () => {
    setOpenImageModal(false)
    setSelectedImageUrl("")
  }

  useEffect(() => {
    setArticulo(productoSeleccionado)
    setArticuloDescription(productoSeleccionadoDescri)
    setProductosEgreso(productosEgreso)
  }, [productoSeleccionadoDescri, data, productosEgreso])

  useEffect(() => {
    setProductosEgreso(fetchedProductosSoling) // Actualiza los productos correctamente
  }, [fetchedProductosSoling])

  useEffect(() => {
    if (fetchedProductosSoling && fetchedProductosSoling.length > 0) {
      setProductosEgreso(
        fetchedProductosSoling.map((producto) => ({
          ...producto,
          prevNumSecuencia: producto.numSecuencia,
        })),
      )
    }
  }, [fetchedProductosSoling])

  const handleClickCloseAddProducto = () => {
    handleCloseAddProducto()
    resetForm()
  }

  const handleCantidadSolicitada = (e) => {
    const value = e.target.value
    if (articuloUsaDecimales === 0) {
      // Permitir solo decimales
      if (/^\d*\.?\d*$/.test(value)) {
        setCantidadSolicitada(value)
      }
    } else {
      // Permitir solo enteros
      if (/^\d*$/.test(value)) {
        setCantidadSolicitada(value)
      }
    }
  }

  function useGetProductosSolIng(sgasolegr) {
    return useQuery({
      queryKey: ["solicitudDeEgresoPorProducto", sgasolegr],
      queryFn: async () => {
        const options = {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }
        let response = await fetchwrapper(`/solicitudDeEgreso/getArticulosPorSolEgr/${sgasolegr}`, options)
        response = await response.json()
        response = response?.productos
        return response
      },
      enabled: !!sgasolegr,
      refetchOnWindowFocus: true,
      refetchOnMount: true,
      refetchOnReconnect: true,
      onError: (error) => {
        console.log("Error fetching data", error)
      },
    })
  }

  useEffect(() => {
    refetchGetProductosSolIng()
  }, [sgasolegr, refetchGetProductosSolIng])

  const handleOpenAddProducto = (product = null) => {
    if (product) {
      setArticulo(product.artcodigo)
      setArticuloDescription(product.artdescri)
      setCantidadSolicitada(product.cantSolicitada)
      setEstado(product.estado || "PENDIENTE")
      setEditingRow(product.numSecuencia)
      setEditValues(product)
      setIsEditing(true)
    } else {
      resetForm()
      setIsEditing(false)
    }
    setOpenAddProducto(true)
  }

  const handleCloseAddProducto = () => {
    setOpenAddProducto(false)
    resetForm()
  }

  const resetForm = () => {
    setArticulo("")
    setArticuloDescription("")
    setCantidadSolicitada("")
    setEstado("PENDIENTE")
    setEditingRow(null)
    setEditValues({})
  }

  const handleAddSubmit = () => {
    if (articulo && cantidadSolicitada && cantidadSolicitada > 0 && estado) {
      const newRow = {
        numSecuencia: editingRow || productosEgreso.length + 1,
        artcodigo: articulo,
        artdescri: articuloDescription,
        cantSolicitada: cantidadSolicitada,
        estado: "PENDIENTE",
        prevNumSecuencia: editingRow ? editValues.prevNumSecuencia : 0,
      }

      if (editingRow) {
        setProductosEgreso((prevProductos) =>
          prevProductos.map((item) => (item.numSecuencia === editingRow ? newRow : item)),
        )
        setEditingRow(null)
      } else {
        setProductosEgreso((prevProductos) => [...prevProductos, newRow])
      }

      resetForm()
      handleCloseAddProducto()
    } else if (cantidadSolicitada <= 0) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Cantidad debe ser mayor a 0",
        allowOutsideClick: false,
        backdrop: false,
        focusConfirm: false,
      })
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Llene todos los campos",
        allowOutsideClick: false,
        backdrop: false,
        focusConfirm: false,
      })
    }
  }

  const handleDeleteClick = (row) => {
    const productIdToDelete = row.numSecuencia
    const productCodToDelete = row.artcodigo

    // Reviso si estaba en la fetched data original
    const isPersisted = fetchedProductosSoling.some((item) => item.numSecuencia === productIdToDelete)

    if (!isPersisted) {
      // Si no está (recién se agregó) solo lo elimino de la grid (no se llama al api)
      setProductosEgreso((prevProductos) => prevProductos.filter((item) => item.numSecuencia !== productIdToDelete))
      Swal.fire("Eliminado!", "El producto ha sido eliminado de la lista.", "success")
    } else {
      // Si está en la BD, llamo al api
      Swal.fire({
        title: "¿Estás seguro?",
        text: "No podrás revertir esto!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Sí, eliminar",
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.isConfirmed) {
          const previousState = productosEgreso

          const updatedProductos = productosEgreso.filter((item) => item.numSecuencia !== productIdToDelete)
          const reassignedProductos = updatedProductos.map((item, index) => ({
            ...item,
            numSecuencia: index + 1,
          }))

          setProductosEgreso(reassignedProductos)

          const options = {
            method: "DELETE",
            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
            },
            body: JSON.stringify({
              codigoSolicitud: sgasolegr,
              artcodigo: productCodToDelete,
              sgasecuen: productIdToDelete,
            }),
          }

          fetchwrapper("/solicitudDeEgreso/deleteProductosSolEgr", options)
            .then((response) => {
              if (!response.ok) {
                throw new Error("Error al eliminar el producto")
              }
              return response.json()
            })
            .then(() => {
              Swal.fire("Eliminado!", "El producto ha sido eliminado.", "success")
            })
            .catch((error) => {
              console.error("Error:", error)

              // Revertir cambios si falla la eliminación
              setProductosEgreso(previousState)

              Swal.fire("Error!", "No se pudo eliminar el producto.", "error")
            })
        }
      })
    }
  }

  const handleEditClick = (row) => {
    handleOpenAddProducto(row.original)
  }

  const handleChangeArticulo = (event) => {
    // setArticulo(event.target.value)
  }

  const handleCloseModal = () => {
    setOpenModal(false)
  }

  const {
    data: dataProductos = [],
    isError: dataProductosError,
    isFetching: isFetchingDataProductos,
    isLoading: isLoadingProductos,
    refetch: refetchGetProductos,
  } = useGetProductosXCliente(cliente)

  const {
    data: dataProductosSecondary = [],
    isError: dataProductosErrorSecondary,
    isFetching: isFetchingDataProductosSecondary,
    isLoading: isLoadingProductosSecondary,
    refetch: refetchGetProductosSecondary,
  } = useGetProductosXClienteSecondary(cliente)

  useEffect(() => {
    setProductos(dataProductos)
  }, [dataProductos])

  useEffect(() => {
    enviarDato()
  }, [dataProductos])

  function useGetProductosXCliente(cliente) {
    return useQuery({
      queryKey: ["solicitudDeEgresoProductosEdit", cliente],
      queryFn: async () => {
        const response = await fetchwrapper(`/solicitudDeIngreso/getProductosXCliente`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ cliente }),
        })
        const result = await response.json()
        return result.productos
      },
      enabled: clienteSeleccionado,
      onError: (error) => {
        console.log("Error fetching data", error)
      },
    })
  }

  function useGetProductosXClienteSecondary(cliente) {
    return useQuery({
      queryKey: ["solicitudDeEgresoProductosSecondaryEdit", cliente],
      queryFn: async () => {
        const response = await fetchwrapper(`/solicitudDeIngreso/getCod2ArticuloXCliente`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ cliente }),
        })
        const result = await response.json()
        return result.data
      },
      enabled: clienteSeleccionado,
      onError: (error) => {
        console.log("Error fetching data", error)
      },
    })
  }

  // para el insert en la grilla
  const handleAddProductoDirecto = () => {
    // Verificar si el producto seleccionado pertenece a la bodega del cliente
    const productoBodega = dataProductos.find((producto) => producto.split("-")[0] === articulo)
    let productoBodegaSecondary = true
    let descripcionProducto = articuloDescription
    let codProducto = articulo

    if (dataProductosSecondary.length > 0) {
      productoBodegaSecondary = dataProductosSecondary.find((producto) => producto.artcodigo2 === articulo)

      if (!articuloDescription && productoBodegaSecondary) {
        descripcionProducto = productoBodegaSecondary.artdescri
        codProducto = productoBodegaSecondary.artcodigo
      }
    } else {
      productoBodegaSecondary = false
    }

    if (!articulo || cantidadSolicitada <= 0 || (!productoBodega && !productoBodegaSecondary)) {
      // Mostrar error si el producto no pertenece a la bodega o si la cantidad es inválida
      Swal.fire({
        icon: "error",
        title: "Error",
        text: !articulo
          ? "Debe seleccionar un producto."
          : cantidadSolicitada <= 0
            ? "La cantidad solicitada debe ser mayor a 0."
            : `El producto con código ${articulo} no pertenece a la bodega del cliente.`,
      })
    } else {
      const newRow = {
        numSecuencia: editingProductId || productosEgreso.length + 1,
        artcodigo: codProducto,
        artdescri: descripcionProducto,
        cantSolicitada: cantidadSolicitada,
        estado: "PENDIENTE",
        prevNumSecuencia: editingProductId ? editValues.prevNumSecuencia : 0,
      }

      setProductosEgreso((prevProductos) => [...prevProductos, { ...newRow, numSecuencia: productosEgreso.length + 1 }])
      setArticulo("")
      setArticuloDescription("")
      setCantidadSolicitada("")
      setAutoCompleteValue("")
      setEstado("PENDIENTE")
      setIsEditing(false)
      setEditingProductId(null)
    }
  }

  const columns = [
    {
      id: "actions",
      header: "Actions",
      Cell: ({ row }) => {
        const isEditable = row.original.estado === "PENDIENTE"
        return (
          <div>
            <CustomTooltip title={isEditable ? "" : "No se pueden editar artículos con egresos"}>
              <span>
                <CustomTooltip title={"Editar"}>
                  <IconButton onClick={() => handleEditClick(row)} disabled={!isEditable}>
                    <img src={DetalleIcon} alt="Detalle" style={{ width: "30px" }} />
                  </IconButton>
                </CustomTooltip>
              </span>
            </CustomTooltip>
            <CustomTooltip title={isEditable ? "" : "No se pueden eliminar artículos con egresos"}>
              <span>
                <CustomTooltip title={"Eliminar"}>
                  <IconButton onClick={() => handleDeleteClick(row.original)} disabled={!isEditable}>
                    <img src={EliminarIcon} alt="Eliminar" style={{ width: "30px" }} />
                  </IconButton>
                </CustomTooltip>
              </span>
            </CustomTooltip>
          </div>
        )
      },
    },
    {
      accessorKey: "artcodigo",
      header: "Código",
    },
    {
      accessorKey: "artdescri",
      header: "Artículo",
    },
    {
      accessorKey: "cantSolicitada",
      header: "Cantidad Solicitada",
    },

    {
      accessorKey: "cantEgresada",
      header: "Cantidad Egresada",
    },
    {
      accessorKey: "estado",
      header: "Estado",
    },
    {
      id: "imagen",
      header: "Imagen",
      Cell: ({ row }) => {
        const { data: imagenProducto = [] } = useGetProductoImages(row.original.artcodigo)
        const tieneImagen = imagenProducto.length > 0 // Verificar si hay una imagen

        return (
          <CustomTooltip title={tieneImagen ? "Ver imagen" : "No hay imagen disponible"}>
            <span>
              <IconButton onClick={() => handleOpenImageModal(row.original.artcodigo)} disabled={!tieneImagen}>
                <FaImage />
              </IconButton>
            </span>
          </CustomTooltip>
        )
      },
    },
  ]

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "10px",
          paddingTop: "10px",
          paddingBottom: "10px",
        }}
      >
        <CustomTooltip title={"Importar artículos desde Excel"}>
          <Button onClick={handleOpenModalExcel}>
            <img src={ExcelImportar} alt="Importar" style={{ width: "40px" }} />
          </Button>
        </CustomTooltip>
        {openModal && (
          <ModalUploadFileEgreso
            openModal={openModal}
            onCloseModal={handleCloseModal}
            dataProductos={dataProductos}
            dataProductosSecondary={dataProductosSecondary}
          />
        )}
        <Modal id="modalAddProducto" open={openAddProducto} onClose={handleCloseAddProducto}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: {
                xs: "90vw",
                sm: "70vw",
                md: 600,
              },
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
            }}
          >
            <div style={{ marginBottom: 16 }}>
              {/* Componente personalizado para la entrada de artículo */}
              <CustomInputDetail
                label="Artículo*"
                placeholder1={"Código"}
                placeholder2={"Nombres"}
                values={[articulo, articuloDescription]}
                onChangeHandlers={[handleChangeArticulo]}
                fullWidth
                isEditing={isEditing}
                disabled={isEditing}
              />
            </div>

            <div style={{ display: "flex", gap: "16px", marginBottom: 16 }}>
              <div style={{ flex: 1 }}>
                <InputLabel htmlFor="cantidadSolicitada">Cantidad Solicitada*</InputLabel>
                <TextField
                  id="cantidadSolicitada"
                  value={cantidadSolicitada}
                  onChange={handleCantidadSolicitada}
                  fullWidth
                />
              </div>

              <div style={{ flex: 1 }}>
                <InputLabel>Estado</InputLabel>
                <Select value="PENDIENTE" fullWidth disabled>
                  <MenuItem value="PENDIENTE">PENDIENTE</MenuItem>
                </Select>
              </div>
            </div>
            <CustomTooltip title={"Guardar"}>
              <Button variant="text" color="primary" onClick={handleAddSubmit} sx={{ mt: 2 }}>
                <img src={GrabarIcon} alt="Guardar" style={{ width: "40px" }} />
              </Button>
            </CustomTooltip>
            <CustomTooltip title={"Cerrar"}>
              <Button variant="text" color="primary" onClick={handleClickCloseAddProducto} sx={{ mt: 2 }}>
                <img src={RegresarIcon} alt="Cerrar" style={{ width: "40px" }} />
              </Button>
            </CustomTooltip>
          </Box>
        </Modal>
        {clienteSeleccionado ? (
          dataProductos.length === 0 ? (
            <Tooltip title={"Este cliente no tiene productos en bodega"}>
              <span>
                <CustomAutoComplete
                  items={dataProductos}
                  value={autoCompleteValue}
                  disabled={true}
                  onSelect={handleProductoChange}
                  placeholder={"Buscar producto"}
                />
              </span>
            </Tooltip>
          ) : (
            <CustomAutoComplete
              items={productos}
              value={autoCompleteValue}
              disabled={dataProductos.length === 0 || cliente === ""}
              onSelect={handleProductoChange}
              placeholder={"Buscar producto"}
            />
          )
        ) : (
          <Tooltip title={"Primero debe seleccionar un cliente"}>
            <span>
              <CustomAutoComplete
                items={productos}
                value={autoCompleteValue}
                disabled={true}
                onSelect={handleProductoChange}
                placeholder={"Buscar producto"}
              />
            </span>
          </Tooltip>
        )}
        <TextField
          id="cantidadSolicitada"
          value={cantidadSolicitada}
          onChange={handleCantidadSolicitada}
          fullWidth
          placeholder="Cantidad Solicitada"
        />
        <CustomTooltip title={"Agregar Artículo"}>
          <Button onClick={() => handleAddProductoDirecto()}>
            <img src={ProductoAddIcon} alt="Agregar" style={{ width: "40px" }} />
          </Button>
        </CustomTooltip>
        <Select value="PENDIENTE" fullWidth disabled>
          <MenuItem value="PENDIENTE">PENDIENTE</MenuItem>
        </Select>
      </div>
      <MaterialReactTable columns={columns} data={productosEgreso || []} />
      <Modal
        open={openImageModal}
        onClose={handleCloseImageModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            maxWidth: "90vw",
            maxHeight: "90vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {openImageModal && (
            <div>
              <div>
                <h3>Imagen del Producto</h3>
              </div>
              {imagenProducto.length > 0 ? (
                <img
                  src={`data:image/jpeg;base64,${imagenProducto[0].artimagen}`}
                  alt="Imagen del artículo"
                  style={{ maxWidth: "100%", maxHeight: "100%" }}
                />
              ) : (
                <p>No hay imagen disponible</p>
              )}
              <div>
                <Button onClick={handleCloseImageModal}>Cerrar</Button>
              </div>
            </div>
          )}
          ;
        </Box>
      </Modal>
    </div>
  )
}

export default EditarSolicitudEntregaComponent
