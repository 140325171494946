import Header from "../../../../layouts/Header"
import { styled, createTheme, ThemeProvider } from "@mui/material/styles"
import { Box, Grid } from "@mui/material"

import BackIcon from "../../../../components/BackIcon"
import CustomTextFieldReadable from "../../../../components/CustomTextFieldReadable"

import { NavLink, useLocation } from "react-router-dom"
import { useQuery } from "@tanstack/react-query"
import fetchwrapper from "../../../../services/interceptors/fetchwrapper"
import CustomBackdrop from "../../../../components/CustomBackdrop"
import { useEffect, useState } from "react"
import CustomFieldset from "../../../../components/CustomFieldset"
import DatagridSpecificPickingSugerido from "../../components/DatagridSpecificPickingSugerido"

const StyledRoot = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  marginTop: theme.spacing(8),
  flexGrow: 1,
  padding: "0 16px",
  height: "100vh",
}))

const StyledIcons = styled(NavLink)(({ theme }) => ({
  height: 250,
  width: 250,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  // backgroundColor: theme.palette.background.default,
  // border: "1px solid #ddd",
  color: theme.palette.text.primary,
  cursor: "pointer",

  "& img": {
    width: "128px",
  },

  "&:hover": {
    textDecoration: "underline",
  },
}))

const StyledTextIcon = styled("div")(({ theme }) => ({
  fontSize: "18px",
  marginTop: "10px",
  fontWeight: "bolder",
}))

const theme = createTheme({
  palette: {
    primary: {
      main: "#196C87",
    },
    secondary: {
      main: "#196C87", // Cambia el color secundario a verde azulado
    },
  },
})

const Container = styled(Box)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "repeat(12, 1fr)",
  gridTemplateRows: "auto auto auto",
  gridTemplateAreas: `
        "IPicking IPicking IPicking IFechaHoy IFechaHoy IEstado IEstado ITipo ITipo IUsuario IUsuario IUsuario"
      "IFechaRealizar IFechaRealizar IFechaRealizar IHoraRealizar IHoraRealizar IComentario IComentario IComentario IComentario IComentario IComentario IComentario"
      `,
  gap: "8px",
  alignItems: "center",

  // [theme.breakpoints.down("sm")]: {
  //   gridTemplateColumns: "repeat(6, 1fr)",
  //   gridTemplateAreas: `
  //       "Solicitud Solicitud Solicitud FechaI FechaI FechaI"
  //       "Cliente Cliente Cliente Cliente Cliente Cliente"
  //       "Motivo Motivo Motivo Estado Estado Estado"
  //       "Proveedor Proveedor Proveedor Proveedor Proveedor Proveedor"
  //       "FechaL FechaL FechaL HoraL HoraL HoraL"
  //       "Comentario Comentario Comentario Comentario Comentario Comentario"
  //       "Detalle Detalle Detalle Detalle Detalle Detalle"
  //     `,
  // },
}))
const IPicking = styled(Box)({
  gridArea: "IPicking",
})

const IFechaHoy = styled(Box)({
  gridArea: "IFechaHoy",
})

const IEstado = styled(Box)({
  gridArea: "IEstado",
})

const ITipo = styled(Box)({
  gridArea: "ITipo",
})

const IUsuario = styled(Box)({
  gridArea: "IUsuario",
})

const IFechaRealizar = styled(Box)({
  gridArea: "IFechaRealizar",
})

const IHoraRealizar = styled(Box)({
  gridArea: "IHoraRealizar",
})

const IComentario = styled(Box)({
  gridArea: "IComentario",
})
const ContainerFiltros = styled(Box)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "repeat(12, 1fr)",
  gridTemplateRows: "auto auto auto",
  gridTemplateAreas: `
          "ISolicitudEgreso ISolicitudEgreso IClienteBodega IClienteBodega IClienteBodega IClienteBodega IClienteBodega IClienteBodega IClienteBodega IFechaInicial IFechaInicial IFechaInicial"
          "Ifactura Ifactura IclienteFactura IclienteFactura IclienteFactura IclienteFactura IclienteFactura IclienteFactura IclienteFactura IFechaFinal IFechaFinal IFechaFinal"
         
        `,
  gap: "8px",
  alignItems: "center",
}))

const ISolicitudEgreso = styled(Box)({
  gridArea: "ISolicitudEgreso",
})

const IClienteBodega = styled(Box)({
  gridArea: "IClienteBodega",
})
const Ifactura = styled(Box)({
  gridArea: "Ifactura",
})

const IclienteFactura = styled(Box)({
  gridArea: "IclienteFactura",
})

const IFechaInicial = styled(Box)({
  gridArea: "IFechaInicial",
})

const IFechaFinal = styled(Box)({
  gridArea: "IFechaFinal",
})
const PickingSugeridoVer = () => {
  const location = useLocation()
  const [cabecera, setCabecera] = useState({})
  const [detalle, setDetalle] = useState({})

  const {
    data: fetchedSpecificPikingSugerido = {},
    isError: isLoadingSpecificPikingSugeridoError,
    isFetching: isFetchingSpecificPikingSugerido,
    isLoading: isLoadingSpecificPikingSugerido,
    // refetch: refetchSpecificPikingSugerido,
  } = useGetSpecificPikingSugerido()

  function useGetSpecificPikingSugerido() {
    return useQuery({
      queryKey: ["SpecificPikingSugeridoVer"],
      queryFn: async () => {
        const options = {
          method: "POST",
          body: JSON.stringify({
            sgapicking: location?.state?.Picking || "",
          }),
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }
        let response = await fetchwrapper(`/pickingSugerido/searchSpecificPickingSugerido`, options)
        response = await response.json()

        return response.data
      },
      refetchOnWindowFocus: false,
      staleTime: Infinity,
    })
  }

  useEffect(() => {
    if (Object.keys(fetchedSpecificPikingSugerido).length > 0) {
      setCabecera(fetchedSpecificPikingSugerido?.registro)
      setDetalle(fetchedSpecificPikingSugerido?.detalles)
    }
  }, [fetchedSpecificPikingSugerido])

  return (
    <ThemeProvider theme={theme}>
      <Header />
      <div className="main main-app p-3 p-lg-4">
        <BackIcon />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "0 30px 30px 30px",
            fontSize: "25px",
          }}
        >
          <b>Detalle Picking Sugerido</b>
        </div>
        <CustomBackdrop isLoading={isLoadingSpecificPikingSugerido || isFetchingSpecificPikingSugerido} />

        <Box className={StyledRoot}>
          <CustomFieldset title={"Datos Generales"}>
            <Container>
              <IPicking>
                <CustomTextFieldReadable
                  label="Picking"
                  value={cabecera?.txtNumero}
                  sxInput={{
                    backgroundColor: "#E0CB79",
                  }}
                />
              </IPicking>
              <IFechaHoy>
                <CustomTextFieldReadable label="Fecha" value={new Date(cabecera?.txtFecha).toLocaleDateString()} />
              </IFechaHoy>

              <IEstado>
                <CustomTextFieldReadable label="Estado" value={cabecera?.cmbStatus} />
              </IEstado>

              <ITipo>
                <CustomTextFieldReadable label="Tipo" value={cabecera?.cmbTipo} />
              </ITipo>

              <IUsuario>
                <CustomTextFieldReadable label="Usuario" value={cabecera?.dcbUsuario} />
              </IUsuario>

              <IFechaRealizar>
                <CustomTextFieldReadable
                  label="Fecha a Realizar"
                  value={new Date(cabecera?.dtpFechaARealizar).toLocaleDateString()}
                />
              </IFechaRealizar>

              <IHoraRealizar>
                <CustomTextFieldReadable label="Hora a Realizar" value={cabecera?.dtpHoraARealizar} />
              </IHoraRealizar>

              <IComentario>
                <CustomTextFieldReadable label="Comentario" value={cabecera?.txtComenARealizar} />
              </IComentario>
            </Container>
          </CustomFieldset>
          <DatagridSpecificPickingSugerido
            fetchedInfo={detalle}
            isLoadingInfoError={isLoadingSpecificPikingSugeridoError}
            isLoadingInfo={isLoadingSpecificPikingSugerido}
            isFetchingInfo={isFetchingSpecificPikingSugerido}
            columnsTable={[
              {
                accessorKey: "sgasolegrfac",
                header: "Código de Solicitud",
                size: 150,
                Cell: ({ cell }) => {
                  const value = cell.getValue()
                  return <span>{value}</span>
                },
              },
              {
                accessorKey: "CliCodigo",
                header: "Código de Cliente",
                size: 100,
                Cell: ({ cell }) => {
                  const value = cell.getValue()
                  return <span>{value}</span>
                },
              },
              {
                accessorKey: "CliNombre",
                header: "Nombre del Cliente",
                size: 250,
                Cell: ({ cell }) => {
                  const value = cell.getValue()
                  return <span>{value}</span>
                },
              },
              {
                accessorKey: "artcodigo",
                header: "Código del Artículo",
                size: 100,
                Cell: ({ cell }) => {
                  const value = cell.getValue()
                  return <span>{value}</span>
                },
              },
              {
                accessorKey: "invcodigo",
                header: "Código de Inventario",
                size: 100,
                Cell: ({ cell }) => {
                  const value = cell.getValue()
                  return <span>{value}</span>
                },
              },
              {
                accessorKey: "artdescri",
                header: "Descripción del Artículo",
                size: 250,
                Cell: ({ cell }) => {
                  const value = cell.getValue()
                  return <span>{value}</span>
                },
              },
              {
                accessorKey: "sgacansolfac",
                header: "Cantidad Solicitada",
                size: 150,
                Cell: ({ cell }) => {
                  const value = cell.getValue()
                  return <span>{parseFloat(value).toFixed(2)}</span> // Formatea a dos decimales
                },
              },
              {
                accessorKey: "sgacanpicking",
                header: "Cantidad Picking",
                size: 150,
                Cell: ({ cell }) => {
                  const value = cell.getValue()
                  return <span>{parseFloat(value).toFixed(2)}</span> // Formatea a dos decimales
                },
              },
              {
                accessorKey: "pascodigo",
                header: "Código de Pasillo",
                size: 100,
                Cell: ({ cell }) => {
                  const value = cell.getValue()
                  return <span>{value}</span>
                },
              },
              {
                accessorKey: "pasdescripcion",
                header: "Descripción del Pasillo",
                size: 200,
                Cell: ({ cell }) => {
                  const value = cell.getValue()
                  return <span>{value}</span>
                },
              },
              {
                accessorKey: "izoncodigo",
                header: "Código de Zona",
                size: 100,
                Cell: ({ cell }) => {
                  const value = cell.getValue()
                  return <span>{value}</span>
                },
              },
              {
                accessorKey: "izondescripcion",
                header: "Descripción de Zona",
                size: 200,
                Cell: ({ cell }) => {
                  const value = cell.getValue()
                  return <span>{value}</span>
                },
              },
              {
                accessorKey: "bahcodigo",
                header: "Código de Bodega",
                size: 100,
                Cell: ({ cell }) => {
                  const value = cell.getValue()
                  return <span>{value}</span>
                },
              },
              {
                accessorKey: "bahdescripcion",
                header: "Descripción de Bodega",
                size: 200,
                Cell: ({ cell }) => {
                  const value = cell.getValue()
                  return <span>{value}</span>
                },
              },
              {
                accessorKey: "poscodigo",
                header: "Código de Posición",
                size: 100,
                Cell: ({ cell }) => {
                  const value = cell.getValue()
                  return <span>{value}</span>
                },
              },
              {
                accessorKey: "posdescripcion",
                header: "Descripción de Posición",
                size: 200,
                Cell: ({ cell }) => {
                  const value = cell.getValue()
                  return <span>{value}</span>
                },
              },
              {
                accessorKey: "nivcodigo",
                header: "Código de Nivel",
                size: 100,
                Cell: ({ cell }) => {
                  const value = cell.getValue()
                  return <span>{value}</span>
                },
              },
              {
                accessorKey: "nivdescripcion",
                header: "Descripción de Nivel",
                size: 200,
                Cell: ({ cell }) => {
                  const value = cell.getValue()
                  return <span>{value}</span>
                },
              },
              {
                accessorKey: "embcodigo",
                header: "Código de Embalaje",
                size: 100,
                Cell: ({ cell }) => {
                  const value = cell.getValue()
                  return <span>{value}</span>
                },
              },
              {
                accessorKey: "embdescripcion",
                header: "Descripción de Embalaje",
                size: 200,
                Cell: ({ cell }) => {
                  const value = cell.getValue()
                  return <span>{value}</span>
                },
              },
              {
                accessorKey: "toncodigo",
                header: "Código de Tonalidad",
                size: 100,
                Cell: ({ cell }) => {
                  const value = cell.getValue()
                  return <span>{value}</span>
                },
              },
              {
                accessorKey: "tondescripcion",
                header: "Descripción de Tonalidad",
                size: 200,
                Cell: ({ cell }) => {
                  const value = cell.getValue()
                  return <span>{value}</span>
                },
              },
              {
                accessorKey: "embpieza",
                header: "Piezas por Embalaje",
                size: 150,
                Cell: ({ cell }) => {
                  const value = cell.getValue()
                  return <span>{value}</span>
                },
              },
              {
                accessorKey: "embmetros2",
                header: "Metros Cuadrados de Embalaje",
                size: 200,
                Cell: ({ cell }) => {
                  const value = cell.getValue()
                  return <span>{parseFloat(value).toFixed(2)}</span> // Formatea a dos decimales
                },
              },
            ]}
          />
        </Box>
      </div>
    </ThemeProvider>
  )
}

export default PickingSugeridoVer
