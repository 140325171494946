import { useState } from "react"
import { PieChart } from "@mui/x-charts/PieChart"
import { Autocomplete, TextField, Button } from "@mui/material"
import fetchwrapper from "../../../services/interceptors/fetchwrapper"
import LoadingComponent from "../../../components/loadingComponent"

import months from "../assets/months"
import years from "../assets/years"
import BuscarIcon from "../../../assets/iconos/Buscar.ico"

export default function BasicPie({ pieData, isFetched, formattedCurrency }) {
  // const convertDataToPieData = (data) => {
  //   const pieData = data.map((d, index) => ({
  //     id: index,
  //     value: d?.facvaltot,
  //     label: d?.locdescri,
  //   }))

  //   return pieData
  // }

  // const getVtasDiasLocalidadPie = async () => {
  //   if (!month || !year) {
  //     alert("Complete los campos")
  //     return
  //   }
  //   try {
  //     setIsLoadingPie(true)
  //     const options = {
  //       method: "POST",
  //       body: JSON.stringify({
  //         day,
  //         month,
  //         year,
  //         isAllDaysSelected,
  //       }),
  //       headers: {
  //         "Content-Type": "application/json",
  //         "Access-Control-Allow-Origin": "*",
  //       },
  //     }
  //     let response = await fetchwrapper(`/facturas_ventas/getFactVentasPie`, options)
  //     response = await response.json()

  //     // Convertir el lo que devuelve el api a la estrucutura de dato que maneja el componente pie
  //     const pieData = convertDataToPieData(response?.data)
  //     setPieData(pieData)

  //     console.log(pieData)
  //   } catch (error) {
  //     alert("No se pudo encontrar data")
  //   } finally {
  //     setIsLoadingPie(false)
  //   }
  // }

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          margin: "0 30px 30px 30px",
          fontSize: "20px",
        }}
      ></div>
      <div style={{ display: "flex", justifyContent: "center", marginTop: "40px" }}>
        {!pieData || pieData.length === 0 ? (
          "No existen datos"
        ) : (
          <div>
            <PieChart
              series={[
                {
                  data: pieData.map((obj) => ({
                    ...obj,
                    value: Math.abs(obj.value), // Convierte el valor a positivo
                  })),
                },
              ]}
              width={400}
              height={200}
            />
            <div style={{ textAlign: "center", fontWeight: "bold" }}>
              Total Ventas: {formattedCurrency.format(pieData.reduce((acc, v) => acc + v?.value, 0))}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
