import React, { useState, useEffect } from "react"
import Header from "../../../layouts/Header"
import { styled, createTheme, ThemeProvider } from "@mui/material/styles"
import {
  Box,
  Grid,
  Card,
  CardContent,
  Typography,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  FormGroup,
  FormControlLabel,
  TextField,
  Button,
} from "@mui/material"

import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query"

import BackIcon from "../../../components/BackIcon"
import GrabarIcon from "../../../assets/iconos/Grabar.ico"
import OpcionesMultiples from "../components/OpcionesMultiples"
import fetchwrapper from "../../../services/interceptors/fetchwrapper"
import CustomBackdrop from "../../../components/CustomBackdrop"
import { useNavigate, useParams } from "react-router-dom"
import TextoLibre from "../components/TextoLibre"
import ListaOpciones from "../components/ListaOpciones"

const StyledRoot = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  marginTop: theme.spacing(8),
  flexGrow: 1,
  padding: "0 16px",
  height: "100vh",
}))

const theme = createTheme({
  palette: {
    primary: {
      main: "#196C87",
    },
    secondary: {
      main: "#196C87", // Cambia el color secundario a verde azulado
    },
  },
})

const EditarBancosDePreguntas = () => {
  const { id } = useParams()
  const navigate = useNavigate()

  // Valores por defectos para los comboboxes y el check
  const Estados = [
    { value: "A", description: "Activo" },
    { value: "I", description: "Inactivo" },
  ]
  const TiposPregunta = [
    { value: "U", description: "Texto Libre" },
    { value: "L", description: "Lista de Opciones" },
    { value: "M", description: "Opciones Múltiples" },
  ]

  const PreguntaObligatoria = [
    { value: -1, description: " Es obligatoria", checked: true },
    { value: 0, description: "No es obligatoría", checked: false },
  ]

  // Estados para manejaar el formulario de opciones
  const [codigo, setCodigo] = useState("")
  const [descripcion, setDescripcion] = useState("")
  const [estado, setEstado] = useState(Estados[0])
  const [tipoPregunta, setTipoPregunta] = useState(TiposPregunta[0])
  const [preguntaObligatoria, setPreguntaObligatoria] = useState(PreguntaObligatoria[0])
  const {
    data: fetchedBancoDePreguntas = {},
    isError: isLoadingBancoDePreguntasError,
    isFetching: isFetchingBancoDePreguntas,
    isLoading: isLoadingBancoDePreguntas,
  } = useGetSpecificBancoDePreguntas()

  if (isLoadingBancoDePreguntasError) {
    alert("Error en cargar las preguntas")
    navigate(-1)
  }

  // call UPDATE hook
  const { mutateAsync: updateSpecificBancoDePregunta, isPending: isUpdatingBancoDePregunta } =
    useUpdateSpecificBancoDePregunta()

  // Estado para manejar el tipo de pregunta seleccionado
  const [allPreguntas, setallPreguntas] = useState([])

  // Manejar el loading al momento de crear el banco de preguntas en el db
  const [isLoadingEditarBancoDePregunta, setIsLoadingEditarBancoDePregunta] = useState(false)

  // When the data is fethced, set state respectively
  useEffect(() => {
    if (Object.keys(fetchedBancoDePreguntas).length === 0) return

    const { cabecera, detalle } = fetchedBancoDePreguntas

    setDescripcion(cabecera.descripcion)
    setCodigo(cabecera.codigo)

    const estado = Estados.find((estado) => estado.value === cabecera.estado)
    const tipoPregunta = TiposPregunta.find((tipoPregunta) => tipoPregunta.value === cabecera.tipoPregunta)
    const preguntaObligatoria = PreguntaObligatoria.find(
      (preguntaObligatoria) => preguntaObligatoria.value === cabecera.preguntaObligatoria,
    )

    setEstado(estado)
    setTipoPregunta(tipoPregunta)
    setPreguntaObligatoria(preguntaObligatoria)
    setallPreguntas(detalle)
  }, [fetchedBancoDePreguntas])

  // READ hook (get specific bancoDePreguntas from api)
  function useGetSpecificBancoDePreguntas() {
    return useQuery({
      queryKey: ["BancoDePreguntas", id],
      queryFn: async () => {
        // send api request here
        let response = await fetchwrapper(`/BancoDePreguntas/getSpecificBancoDePreguntas/${id}`)
        response = await response.json()
        response = response?.data
        return response
      },
      refetchOnWindowFocus: false,
    })
  }
  // UPDATE hook (put specific bancoDePregunta in api)
  function useUpdateSpecificBancoDePregunta() {
    const queryClient = useQueryClient()
    return useMutation({
      mutationFn: async (bancoDePregunta) => {
        // send api update request here
        // send api update request here
        const options = {
          method: "PUT",
          body: JSON.stringify(bancoDePregunta),
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }

        let response = await fetchwrapper(`/BancoDePreguntas/editarSpecificBancoDePregunta/${id}`, options)

        response = response.json()
        return response
      },
      // client side optimistic update
      onMutate: (newBancoDePregunta) => {
        console.log(newBancoDePregunta, "onmutate")
        queryClient.setQueryData(["BancoDePreguntas", id], (prevBancoDePreguntas) => ({
          ...newBancoDePregunta,
        }))
      },
      // onSettled: () => queryClient.invalidateQueries({ queryKey: ['bancoDePreguntas'] }), //refetch bancoDePreguntas after mutation, disabled for demo
    })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (tipoPregunta.value !== "U") {
      if (!(descripcion && estado && tipoPregunta && preguntaObligatoria) || allPreguntas.length === 0) {
        alert("Complete los campos")
        return
      }
    }

    try {
      setIsLoadingEditarBancoDePregunta(true)

      const bancoDePregunta = {
        cabecera: {
          codigo,
          descripcion,
          estado: estado.value,
          tipoPregunta: tipoPregunta.value,
          preguntaObligatoria: preguntaObligatoria.value,
        },
        detalle: allPreguntas,
      }
      await updateSpecificBancoDePregunta(bancoDePregunta)

      alert(`Banco de pregunta actualizado`)
    } catch (error) {
      alert("No se puedo actualizar el banco de preguntas")
    } finally {
      setIsLoadingEditarBancoDePregunta(false)
    }
  }

  const clearAll = (tipoPregunta = TiposPregunta[0]) => {
    setDescripcion("")
    setEstado(Estados[0])
    setTipoPregunta(tipoPregunta)
    setPreguntaObligatoria(PreguntaObligatoria[0])
    setallPreguntas([])
  }

  return (
    <ThemeProvider theme={theme}>
      <Header />
      <div className="main main-app p-3 p-lg-4">
        <BackIcon />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "0 30px 30px 30px",
            fontSize: "25px",
          }}
        >
          <b>Banco de Preguntas</b>
        </div>
        <CustomBackdrop
          isLoading={
            isLoadingBancoDePreguntas ||
            isLoadingEditarBancoDePregunta ||
            isUpdatingBancoDePregunta ||
            isFetchingBancoDePreguntas
          }
        />

        <Box className={StyledRoot}>
          <form onSubmit={handleSubmit}>
            <TextField
              label="Código"
              margin="normal"
              fullWidth
              value={codigo}
              disabled
              required
              sx={{
                backgroundColor: "#e2dbd8",
              }}
            />

            <div>
              <InputLabel id="tiposPreguntasCB" sx={{ paddingBlock: "15px", paddingLeft: "5px" }}>
                Tipo de Pregunta
              </InputLabel>
              <Select
                fullWidth
                labelId="tiposPreguntasCB"
                value={tipoPregunta.value}
                onChange={(e) => {
                  const selectedValue = e.target.value
                  const tipoPregunta = TiposPregunta.find((tipoPregunta) => tipoPregunta.value === selectedValue)
                  setTipoPregunta(tipoPregunta)
                  clearAll(tipoPregunta)
                }}
              >
                {TiposPregunta.map((tipo) => (
                  <MenuItem key={tipo.value} value={tipo.value}>
                    {tipo.description}
                  </MenuItem>
                ))}
              </Select>
            </div>

            <TextField
              label="Descripción"
              margin="normal"
              fullWidth
              value={descripcion}
              onChange={(e) => setDescripcion(e.target.value)}
              required
            />

            <div>
              <InputLabel id="estadosCB" sx={{ paddingBlock: "10px", paddingLeft: "5px" }}>
                Estado
              </InputLabel>
              <Select
                fullWidth
                labelId="estadosCB"
                value={estado.value}
                onChange={(e) => {
                  const selectedValue = e.target.value
                  setEstado(Estados.find((estado) => estado.value === selectedValue))
                }}
              >
                {Estados.map((estado) => (
                  <MenuItem key={estado.value} value={estado.value}>
                    {estado.description}
                  </MenuItem>
                ))}
              </Select>
            </div>

            <FormGroup sx={{ paddingBlock: "15px", paddingLeft: "5px" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={preguntaObligatoria.checked}
                    onChange={(e) => {
                      const selectedValue = e.target.checked
                      setPreguntaObligatoria(
                        PreguntaObligatoria.find(
                          (preguntaObligatoria) => preguntaObligatoria.checked === selectedValue,
                        ),
                      )
                    }}
                  />
                }
                label="Pregunta Obligatoria"
              />
            </FormGroup>

            <Button type="submit" variant="outlined" color="primary">
              <img src={GrabarIcon} alt="Grabar" style={{ width: "40px" }} />
              Editar
            </Button>
          </form>

          {/* {tipoPregunta.value === "U" && (
            <div
              style={{
                boxSizing: "borderBox",
                marginTop: "20px",
                border: "5px solid #5a9996",
                padding: "10px",
                paddingTop: "15px",
              }}
            >
              <div>
                <p
                  style={{
                    fontSize: "18px",
                    color: "black",
                    marginLeft: "5px",
                  }}
                >
                  Opciones
                </p>

                <TextoLibre
                  allPreguntas={allPreguntas}
                  setallPreguntas={setallPreguntas}
                />
              </div>
            </div>
          )} */}

          {tipoPregunta.value === "L" && (
            <div
              style={{
                boxSizing: "borderBox",
                marginTop: "20px",
                border: "5px solid #5a9996",
                padding: "10px",
                paddingTop: "15px",
              }}
            >
              <div>
                <p
                  style={{
                    fontSize: "18px",
                    color: "black",
                    marginLeft: "5px",
                  }}
                >
                  Opciones
                </p>

                <ListaOpciones allPreguntas={allPreguntas} setallPreguntas={setallPreguntas} />
              </div>
            </div>
          )}

          {tipoPregunta.value === "M" && (
            <div
              style={{
                boxSizing: "borderBox",
                marginTop: "20px",
                border: "5px solid #5a9996",
                padding: "10px",
                paddingTop: "15px",
              }}
            >
              <div>
                <p
                  style={{
                    fontSize: "18px",
                    color: "black",
                    marginLeft: "5px",
                  }}
                >
                  Opciones
                </p>

                <OpcionesMultiples allPreguntas={allPreguntas} setallPreguntas={setallPreguntas} />
              </div>
            </div>
          )}
        </Box>
      </div>
    </ThemeProvider>
  )
}

export default EditarBancosDePreguntas
