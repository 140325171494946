import * as React from "react"
import { DemoContainer } from "@mui/x-date-pickers/internals/demo"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"
import TextField from "@mui/material/TextField"
import Stack from "@mui/material/Stack"
import Checkbox from "@mui/material/Checkbox"
import InputAdornment from "@mui/material/InputAdornment"
import { ThemeProvider, createTheme, useTheme, InputLabel } from "@mui/material"
import { esES } from "@mui/material/locale"

const mergeAdornments = (...adornments) => {
  const nonNullAdornments = adornments.filter((el) => el != null)
  if (nonNullAdornments.length === 0) {
    return null
  }

  if (nonNullAdornments.length === 1) {
    return nonNullAdornments[0]
  }

  return (
    <Stack direction="row">
      {nonNullAdornments.map((adornment, index) => (
        <React.Fragment key={index}>{adornment}</React.Fragment>
      ))}
    </Stack>
  )
}

const PickerTextField = ({ onCheckboxChange, isDisabled, ...rest }) => (
  <TextField
    {...rest}
    InputProps={{
      ...rest.InputProps,
      endAdornment: mergeAdornments(
        <InputAdornment position="end">
          <Checkbox checked={!isDisabled} onChange={onCheckboxChange} size="small" />
        </InputAdornment>,
        rest.InputProps?.endAdornment ?? null,
      ),
    }}
    disabled={isDisabled} // El TextField se deshabilita cuando el checkbox está marcado
  />
)

export default function CustomDatePicker({ label = "", value, setValue, isOptional = false, ...params }) {
  const theme = useTheme()
  // const [value, setValue] = React.useState(props.value ?? null)
  const [isDisabled, setIsDisabled] = React.useState(false) // Estado para deshabilitar el DatePicker

  const handleCheckboxChange = () => {
    setIsDisabled(!isDisabled)
    if (!isDisabled) {
      setValue(null) // Establecer valor en null cuando el DatePicker se deshabilita
    }
  }

  // isOptional: Esta propiedad define si este componente es opcional que elega una fecha o no
  const checkboxProps = isOptional
    ? {
        slotProps: {
          textField: {
            onCheckboxChange: handleCheckboxChange, // Pasar la función para manejar el checkbox
            isDisabled, // Pasar el estado de deshabilitado
            fullWidth: true,
          },
        },
        slots: {
          textField: PickerTextField, // Reemplazamos el TextField con nuestra versión personalizada
        },
      }
    : {}

  return (
    <ThemeProvider theme={createTheme(theme, esES)}>
      <InputLabel>{label}</InputLabel>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
        <DemoContainer components={["DatePicker"]} sx={{ paddingTop: 0 }}>
          <DatePicker value={value} onChange={(newValue) => setValue(newValue)} {...checkboxProps} {...params} />
        </DemoContainer>
      </LocalizationProvider>
    </ThemeProvider>
  )
}
