import React, { useEffect, useState, useMemo } from "react"
import { Modal, Box, Button, IconButton } from "@mui/material"
import { PDFViewer, pdf } from "@react-pdf/renderer"
import SolicitudDeEgresoPDF from "./SolicitudDeEgresoPDF"
import { FcDocument } from "react-icons/fc"
import RegresarIcon from "../../../assets/iconos/Regresar.ico"

const DetalleImpresion = ({ open, handleClose, infoGenerada }) => {
  const [key, setKey] = useState(0)

  const handlePrint = async () => {
    const blob = await pdf(<SolicitudDeEgresoPDF data={infoGenerada} />).toBlob()
    const url = URL.createObjectURL(blob)
    const link = document.createElement("a")
    link.href = url
    link.download = "solicitudDeEgreso.pdf"
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    handleClose()
  }

  const handleRegenerate = () => {
    setKey((prevKey) => prevKey + 1)
  }

  // Memoizamos el componente PDF para evitar que se re-renderice innecesariamente
  const memoizedPDF = useMemo(() => <SolicitudDeEgresoPDF data={infoGenerada} />, [infoGenerada])

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 600,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
        }}
      >
        <Button
          onClick={handlePrint}
          style={{ marginTop: "20px" }}
          variant="contained"
          sx={{
            marginRight: "15px",
            marginTop: "20px",
            marginBottom: "10px",
          }}
          color="secondary"
        >
          <span className="me-2">Descargar Solicitud de Egreso</span>
          <FcDocument size={"25px"} />
        </Button>

        {open && <PDFViewer style={{ width: "100%", height: "80vh" }}>{memoizedPDF}</PDFViewer>}

        <IconButton onClick={handleClose} sx={{ mt: 2 }}>
          <img src={RegresarIcon} alt="Cerrar" style={{ width: "40px" }} />
        </IconButton>
      </Box>
    </Modal>
  )
}

export default DetalleImpresion
