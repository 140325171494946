/* eslint-disable camelcase */
import React, { useState, useEffect } from "react"
import Header from "../../../layouts/Header"
import { styled, createTheme, ThemeProvider } from "@mui/material/styles"
import { Box, InputLabel, Select, MenuItem, TextField, Button, Tooltip } from "@mui/material"
import Swal from "sweetalert2"
import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import BackIcon from "../../../components/BackIcon"
import GrabarIcon from "../../../assets/iconos/Grabar.ico"
import fetchwrapper from "../../../services/interceptors/fetchwrapper"
import CustomBackdrop from "../../../components/CustomBackdrop"
import ImprimirIcon from "../../../assets/iconos/Impresion.ico"
import DetalleImpresion from "../../Zonas/components/DetalleImpresion"
import PasilloPdf from "../components/PasillosPdf"
const StyledRoot = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  marginTop: theme.spacing(8),
  flexGrow: 1,
  padding: "0 16px",
  height: "100vh",
}))
const theme = createTheme({
  palette: {
    primary: {
      main: "#196C87",
    },
    secondary: {
      main: "#196C87", // Cambia el color secundario a verde azulado
    },
  },
})
const EditarPasillo = () => {
  const { id } = useParams()
  const location = useLocation()
  const datoSeleccionado = location.state
  const estadoActual = datoSeleccionado.passtatus
  const descripcionActual = datoSeleccionado.pasdescripcion
  const [openModal, setOpenModal] = useState(false)
  const [infoGenerada, setInfoGenerada] = useState([])
  const handleOpenModal = () => {
    const datos = getDatosActuales()
    console.log("datos", datos)
    setInfoGenerada(datos) // Asigna los datos de la tabla al estado
    setOpenModal(true)
  }

  const handleCloseModal = () => {
    setOpenModal(false)
    navigate(`/home/dashboard/Pasillos`)
  }
  function getDatosActuales() {
    return [
      {
        pascodigo: id,
        pasdescripcion: descripcionActual,
        passtatus: estadoActual,
      },
    ]
  }
  const navigate = useNavigate()

  // Valores por defectos para los comboboxes y el check
  const Estados = [
    { value: "A", description: "ACTIVO" },
    { value: "I", description: "INACTIVO" },
  ]

  const findEstado = (estadoCurrent) => {
    const result = Estados.find((estado) => estado.value === estadoCurrent)
    return result
  }

  // Estados para manejaar el formulario de opciones
  // cuando llame a las funciones set, se actualizara el valor de los otros campos y se re renderiza
  const [codigo, setCodigo] = useState(id)
  const [descripcion, setDescripcion] = useState(descripcionActual)
  const [estado, setEstado] = useState(findEstado(estadoActual))

  const { mutateAsync: updatePasillo, isPending: isUpdatingPasillo } = useUpdatePasillo()

  function useUpdatePasillo() {
    const queryClient = useQueryClient()
    return useMutation({
      mutationFn: async (pasillo) => {
        // send api update request here
        // send api update request here
        const options = {
          method: "PUT",
          body: JSON.stringify(pasillo),
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }
        console.log("json", options.body)
        let response = await fetchwrapper(`/Pasillos/updatePasillo/${id}`, options)

        response = response.json()
        console.log(response)
        return response
      },
      // client side optimistic update
      onMutate: (newPasillo) => {
        console.log(newPasillo, "onmutate")
        queryClient.setQueryData(["Pasillo", id], (prevPasillo) => ({
          ...newPasillo,
        }))
      },
      // onSettled: () => queryClient.invalidateQueries({ queryKey: ['bancoDePreguntas'] }), //refetch bancoDePreguntas after mutation, disabled for demo
    })
  }
  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      const pasillo = {
        descripcion_pasillo: descripcion,
        estado_pasillo: estado.value,
      }
      await updatePasillo(pasillo)
      Swal.fire({
        icon: "success",
        title: "Operación exitosa",
        text: "Se pudo editar el pasillo",
      }).then((result) => {
        if (result.isConfirmed) {
          navigate("/home/dashboard/Pasillos") // Navegar a la ruta especificada
        }
      })
    } catch (error) {
      Swal.fire({
        title: "Error",
        text: "No se pudo editar el pasillo",
        icon: "error",
        confirmButtonText: "OK",
      }).then(() => {
        navigate("/home/dashboard/Pasillos") // Navega de vuelta cuando se cierre el SweetAlert
      })
    }
  }
  return (
    <ThemeProvider theme={theme}>
      <Header />
      <div className="main main-app p-3 p-lg-4">
        <BackIcon />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "0 30px 30px 30px",
            fontSize: "25px",
          }}
        >
          <b>Pasillos</b>
        </div>
        <Box className={StyledRoot}>
          <form onSubmit={handleSubmit}>
            <Tooltip title="Grabar">
              <Button type="submit" color="primary" sx={{ marginTop: "15px" }}>
                <img src={GrabarIcon} alt="Grabar" style={{ width: "30px" }} />
              </Button>
            </Tooltip>
            <Tooltip title="Imprimir">
              <Button color="primary" onClick={handleOpenModal} sx={{ marginTop: "15px" }}>
                <img src={ImprimirIcon} alt="Imprimir" style={{ width: "30px" }} />
              </Button>
            </Tooltip>
            <DetalleImpresion
              open={openModal}
              handleClose={handleCloseModal}
              infoGenerada={getDatosActuales()}
              nombrePdf={"Pasillo"}
              PdfComponent={PasilloPdf}
            />
            <TextField
              label="Código"
              margin="normal"
              fullWidth
              value={codigo}
              disabled
              required
              sx={{
                backgroundColor: "#e2dbd8",
              }}
            />
            <TextField
              label="Descripción"
              margin="normal"
              fullWidth
              value={descripcion}
              onChange={(e) => setDescripcion(e.target.value)}
              required
            />
            <div>
              <InputLabel id="estadoPasillosCB" sx={{ paddingBlock: "15px", paddingLeft: "5px" }}>
                Estado de el pasillo
              </InputLabel>
              <Select
                fullWidth
                labelId="estadoPasillosCB"
                value={estado.value}
                onChange={(e) => {
                  const selectedValue = e.target.value
                  const pasilloEstado = Estados.find((estado) => estado.value === selectedValue)
                  setEstado(pasilloEstado)
                  //   clearAll(tipoPregunta)
                }}
              >
                {Estados.map((estado) => (
                  <MenuItem key={estado.value} value={estado.value}>
                    {estado.description}
                  </MenuItem>
                ))}
              </Select>
            </div>
          </form>
        </Box>
        <CustomBackdrop isLoading={isUpdatingPasillo} />
      </div>
    </ThemeProvider>
  )
}

export default EditarPasillo
