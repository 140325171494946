import Header from "../../layouts/Header"
import { styled, createTheme, ThemeProvider } from "@mui/material/styles"
import { Box, Grid } from "@mui/material"
import { AprobacionContextProvider } from "./AprobacionContext"
import BuscarSolicitudEntregaAprobacion from "./components/BuscarSolicitudEntregaAprobacion"

import BackIcon from "../../components/BackIcon"

import { NavLink } from "react-router-dom"

import BuscarIcon from "../../assets/iconos/Buscar.ico"

const StyledRoot = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  marginTop: theme.spacing(8),
  flexGrow: 1,
  padding: "0 16px",
  height: "100vh",
}))

const StyledIcons = styled(NavLink)(({ theme }) => ({
  height: 250,
  width: 250,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  // backgroundColor: theme.palette.background.default,
  // border: "1px solid #ddd",
  color: theme.palette.text.primary,
  cursor: "pointer",

  "& img": {
    width: "128px",
  },

  "&:hover": {
    textDecoration: "underline",
  },
}))

const StyledTextIcon = styled("div")(({ theme }) => ({
  fontSize: "18px",
  marginTop: "10px",
  fontWeight: "bolder",
}))

const theme = createTheme({
  palette: {
    primary: {
      main: "#196C87",
    },
    secondary: {
      main: "#196C87", // Cambia el color secundario a verde azulado
    },
  },
})
const AprobacionSolicitudEntrega = () => {
  return (
    <AprobacionContextProvider>
      <ThemeProvider theme={theme}>
        <Header />

        <Box className={StyledRoot}>
          <BuscarSolicitudEntregaAprobacion />
        </Box>
      </ThemeProvider>
    </AprobacionContextProvider>
  )
}

export default AprobacionSolicitudEntrega
