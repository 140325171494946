import React, { useEffect, useState } from "react"
import { Box, Button } from "@mui/material"
import { DataGrid } from "@mui/x-data-grid"
import styled from "@emotion/styled"

import LotesIcon from "../../../assets/iconos/Lotes.ico"
import truncateNumber from "../../utils/math/truncate"
import fetchwrapper from "../../../services/interceptors/fetchwrapper"
import CustomBackdrop from "../../../components/CustomBackdrop"

import { useNavigate } from "react-router-dom"

const StyledDataGridContainer = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  overflowX: "auto",
}))

const Info = ({ info }) => {
  const { palletid, invdescri, boddescri, artcodigo, artdescri, stokdetcantidad, stokdetcantconver } = info
  return (
    <div
      style={{
        textAlign: "center",
        fontWeight: "bolder",
        fontSize: "17px",
        marginBottom: "50px",
      }}
    >
      <p>Pallet ID: {palletid}</p>
      <p>Descripción de Inventario: {invdescri}</p>
      <p>Descripción del Almacén: {boddescri}</p>
      <p>Código de Artículo: {artcodigo}</p>
      <p>Descripción del Artículo: {artdescri}</p>
      <p>Cantidad de Stock: {truncateNumber(stokdetcantidad)}</p>
      <p>Cantidad de Stock Convertida: {truncateNumber(stokdetcantconver)}</p>
    </div>
  )
}

const DatagridBuscarUbicacion = ({ data, setData, filtrarBusqueda, info }) => {
  const navigate = useNavigate()

  const ejecutarDetalle = (row) => {
    const { numLotes } = row
    navigate(`${numLotes}`, { state: row })
  }

  const columnas = [
    {
      headerName: "Zona Código",
      field: "izoncodigo",
      width: 150,
    },
    {
      headerName: "Zona",
      field: "izondescripcion",
      width: 200,
    },
    {
      headerName: "Bahía Código",
      field: "bahcodigo",
      width: 150,
    },
    {
      headerName: "Bahía",
      field: "bahdescripcion",
      width: 200,
    },
    {
      headerName: "Pasillo Código",
      field: "pascodigo",
      width: 150,
    },
    {
      headerName: "Pasillo",
      field: "pasdescripcion",
      width: 200,
    },
    {
      headerName: "Nivel Código",
      field: "nivcodigo",
      width: 150,
    },
    {
      headerName: "Nivel",
      field: "nivdescripcion",
      width: 200,
    },
    {
      headerName: "Posición Código",
      field: "poscodigo",
      width: 150,
    },

    {
      headerName: "Posición",
      field: "posdescripcion",
      width: 200,
    },
    {
      headerName: "Emb Código",
      field: "embcodigo",
      width: 150,
    },
    {
      headerName: "Embalaje",
      field: "embdescripcion",
      width: 200,
    },
    {
      headerName: "Ton Código",
      field: "toncodigo",
      width: 150,
    },
    {
      headerName: "Tonalidad",
      field: "tondescripcion",
      width: 200,
    },
    {
      headerName: "Artículo Código",
      field: "artcodigo",
      width: 200,
    },
    {
      headerName: "Cantidad",
      field: "stokdetcantidad",
      width: 200,
      valueFormatter: (params) => {
        return truncateNumber(params.value)
      },
    },
    {
      headerName: "Cantidad Conversión",
      field: "stokdetcantconver",
      width: 200,
      valueFormatter: (params) => {
        return truncateNumber(params.value)
      },
    },
    {
      headerName: "Lotes",
      field: "numLotes",
      width: 200,
      renderCell: (params) => {
        console.log(params)
        if (+params.value > 0) {
          return (
            <div>
              <Button variant="outlined" color="primary" onClick={() => ejecutarDetalle(params.row)}>
                <img src={LotesIcon} alt="lotes" style={{ width: "40px" }} />
              </Button>
            </div>
          )
        } else {
          return <p>No disponible</p>
        }
      },
    },
  ]

  return (
    <>
      <Info info={info} />
      <Box className={StyledDataGridContainer} sx={{ height: 800, width: "100%" }}>
        <DataGrid
          getRowId={(data) =>
            data.izoncodigo +
            "|" +
            data.bahcodigo +
            "|" +
            data.pascodigo +
            "|" +
            data.nivcodigo +
            "|" +
            data.poscodigo +
            "|" +
            data.embcodigo +
            "|" +
            data.toncodigo +
            "|" +
            data.artcodigo
          }
          columnVisibilityModel={{
            izondescripcion: false,
            bahdescripcion: false,
            pasdescripcion: false,
            nivdescripcion: false,
            posdescripcion: false,
            embcodigo: false,
            toncodigo: false,
            artcodigo: false,
          }}
          columns={columnas}
          rows={data}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          pageSizeOptions={[10, 25]}
        />
      </Box>
    </>
  )
}

export default DatagridBuscarUbicacion
