import React, { useState, useEffect } from "react"
import Header from "../../../layouts/Header"
import { styled, createTheme, ThemeProvider } from "@mui/material/styles"
import { Box, Grid, Card, CardContent, Typography, Button } from "@mui/material"
import BuscarSolicitudEntregaComponent from "../components/BuscarSolicitudEntregaComponent"
import BackIcon from "../../../components/BackIcon"
import CustomBackdrop from "../../../components/CustomBackdrop"
import { SolicitudEntregaContextProvider } from "../SolicitudDeEntregaContext"

const StyledRoot = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  marginTop: theme.spacing(8),
  flexGrow: 1,
  padding: "0 16px",
  height: "100vh",
}))

const theme = createTheme({
  palette: {
    primary: {
      main: "#196C87",
    },
    secondary: {
      main: "#196C87", // Cambia el color secundario a verde azulado
    },
  },
})

const BuscarSolicitudEntrega = () => {
  return (
    <SolicitudEntregaContextProvider>
      <ThemeProvider theme={theme}>
        <Header />
        <BuscarSolicitudEntregaComponent />
      </ThemeProvider>
    </SolicitudEntregaContextProvider>
  )
}

export default BuscarSolicitudEntrega
