import { useState, useEffect, useMemo } from "react"
import {
  IconButton,
  InputAdornment,
  Modal,
  InputLabel,
  TextField,
  Box,
  TablePagination,
  CircularProgress,
} from "@mui/material"
import { MaterialReactTable, useMaterialReactTable } from "material-react-table"
import { MRT_Localization_ES } from "material-react-table/locales/es"

import SearchIcon from "@mui/icons-material/Search"
import CloseIcon from "@mui/icons-material/Close"

import { useQuery } from "@tanstack/react-query"
import fetchwrapper from "../services/interceptors/fetchwrapper"

const style = {
  Modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "98%",
    minHeight: "98%",
    maxHeight: "98%",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    overflowY: "auto",
  },

  CloseIcon: {
    cursor: "pointer",
    marginLeft: "95%",
  },
}

// const helperType = {
//   INPUT_SEARCH_SINGLE: "single",
//   INPUT_SEARCH_DETAIL: "detail",
// }

// Este objeto sireve para determinar que tipo de busqueda hace
// cuando el usuario solo digita el id o key en el textfield
// hace una busqueda por "id" y si esa busqueda falla entonces abre
// una tabla (la ayuda) en donde podra filtrar por cualquier campo y
// esa busqueda es de tipo "filter"
const SEARCH_TYPE_HELPER = {
  ID_SEARCH: "id",
  FILTER_TABLE_SEARCH: "filter",
}

// --------------------------------------------------
// --------------------------------------------------
//                    CustomTable
// --------------------------------------------------
// --------------------------------------------------

const CustomTable = ({
  data,
  columnsTable,
  perPage,
  isLoading,
  isError,
  totalPages,
  currentPage,
  onPageChange,
  onFilterChange,
  onItemSelected,
  handleCloseModal,
  errorMsgFilterSearch,
}) => {
  const columns = useMemo(
    () =>
      columnsTable.map((column) => ({
        ...column,
        Filter: ({ column }) => (
          <TextField
            variant="standard"
            placeholder="Filtrar"
            onChange={(e) => onFilterChange(column.id, e.target.value)}
          />
        ),
      })),
    [onFilterChange],
  )

  const table = useMaterialReactTable({
    columns,
    data,
    initialState: { columnVisibility: { ciacodigo: false }, showColumnFilters: true },
    enableEditing: false,
    enableSorting: false,
    enablePagination: false,
    enableRowActions: false,
    enableTopToolbar: false,
    enableColumnResizing: true,
    enableSelectAll: false,
    enableRowSelection: true,
    enableMultiRowSelection: false,
    enableColumnFilters: true,
    localization: { ...MRT_Localization_ES },
    muiToolbarAlertBannerProps: isError ? { color: "error", children: errorMsgFilterSearch } : undefined,
    muiTableContainerProps: {
      sx: {
        minWidth: "100%",
        maxWidth: "100%",
        maxHeight: "600px", // Ajusta la altura máxima de la tabla
        overflowY: "auto",
        overflowX: "auto",
      },
    },
    muiTableBodyCellProps: {
      sx: {
        py: 0.4, // Ajusta la altura de las celdas
        fontSize: "0.875rem", // Ajusta el tamaño de la fuente
      },
    },
    muiTableHeadCellProps: {
      sx: {
        fontSize: "0.875rem",
        fontWeight: "bold",
      },
    },
    state: {
      isLoading,
    },
  })

  useEffect(() => {
    if (table.getSelectedRowModel().rows[0] != null) {
      onItemSelected(table.getSelectedRowModel().rows[0].original)
      handleCloseModal()
    }
  }, [table.getSelectedRowModel()])

  return (
    <>
      <MaterialReactTable table={table} />
      <TablePagination
        rowsPerPageOptions={[perPage]}
        component="div"
        count={totalPages * perPage}
        rowsPerPage={perPage}
        page={currentPage - 1}
        onPageChange={(_, newPage) => onPageChange(newPage + 1)}
      />
    </>
  )
}

// --------------------------------------------------
// --------------------------------------------------
//                  CustomModal
// --------------------------------------------------
// --------------------------------------------------

const CustomModal = ({
  endpoint,
  openModal,
  handleCloseModal,
  queryKeyModal,
  perPage,
  onItemSelected,
  columnsTable,
  errorMsgFilterSearch,
}) => {
  const [filters, setFilters] = useState({}) // Estado para filtros de columnas
  const [page, setPage] = useState(1)
  const { data, isLoading, isError, refetch } = useGetClientes(page, filters)
  const [totalPages, setTotalPages] = useState(1)

  const handleFilterChange = (columnId, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [columnId]: value,
    }))
  }

  // Obtener los datos siempre que el num pagina actual, filtros cambien
  useEffect(() => {
    refetch()
  }, [page, filters, refetch])

  // refresca tabla solo cuando se abre por primera vez el modal y cuando se cierre setear todo por defecto
  useEffect(() => {
    if (openModal) {
      refetch()
    } else {
      setFilters({})
      setPage(1)
    }
  }, [openModal, refetch])

  function useGetClientes(pageNumber, filters) {
    return useQuery({
      queryKey: [queryKeyModal, pageNumber, filters],
      queryFn: async () => {
        const response = await fetchwrapper(`${endpoint}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            typeSearch: SEARCH_TYPE_HELPER.FILTER_TABLE_SEARCH,
            page: pageNumber,
            perPage,
            filters, // Envía los filtros al backend
          }),
        })
        const result = await response.json()
        setTotalPages(result.total_pages)
        return result.data
      },
      keepPreviousData: false,
    })
  }
  return (
    <div>
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style.Modal}>
          <IconButton style={style.CloseIcon} onClick={() => handleCloseModal()}>
            <CloseIcon />
          </IconButton>
          <CustomTable
            data={data || []}
            columnsTable={columnsTable || []}
            perPage={perPage}
            isLoading={isLoading}
            isError={isError}
            totalPages={totalPages}
            currentPage={page}
            onPageChange={setPage}
            onFilterChange={handleFilterChange} // Pasa la función de manejo de filtros
            onItemSelected={onItemSelected}
            handleCloseModal={handleCloseModal}
            errorMsgFilterSearch={errorMsgFilterSearch}
          />
        </Box>
      </Modal>
    </div>
  )
}

// --------------------------------------------------
// --------------------------------------------------
//              CustomHelperDetail
// --------------------------------------------------
// --------------------------------------------------

const CustomHelperDetail = ({
  label = "", // Este va a ser el titutlo de arriba del textfield
  valueSearched = "", // Este va a ser el valor ya buscado cuando se selecciono algo en la busqueda
  endpoint = "", // Este el endpoint que va a hacer las peticiones
  valueInputMain = "", // Esta es la propiedad del objeto que va a ser visible el input text field cuando se seleccione o se encuentre el dato
  valueInputSecondary = "", // Esta es la propiedad del objeto que va a ser visible el input text field segundario cuando se seleccione o se encuentre el dato
  idSearchField = "", // Esta es la propieadad por la que se va a hacer la peticion en la busqueda por id
  errorMsgIdSearch = "Error fetching data:", // Este errror solo aparecera por consola cuando falle el busqueda por id
  errorMsgFilterSearch = "Error en cargar datos", // Este errror solo aparecera en el react table cuando falle el busqueda por filter
  queryKeyModal = "", // Este es el key que usa react query en el modal para manejar la cache
  perPage = 10, // Estas son las filas que se muestran por cada pagina
  placeholderInputMain = "", // Este es el placeholder  que se muestra en el textfield principal
  placeholderInputSecondary = "", // Este es el placeholder  que se muestra en el textfield secundario
  columnsTable = [], // Estas son las columnas que estaran siempre presente en las tablas
  onHandleSelectedData, // Esta es una funcion que se usara cuando ya se haya encontrado o seleccionada alguna fila ya sea por la busqueda de id o filter
  sxInputMain = {},
  sxInputSecondary = {},
}) => {
  // Modal
  const [openModal, setOpenModal] = useState(false)
  const handleOpenModal = () => setOpenModal(true)
  const handleCloseModal = () => setOpenModal(false)

  const [value, setValue] = useState("")
  // Selected item in table or id search
  const [itemSelected, setItemSelected] = useState(null)
  const [isLoadingIdSearch, setIsLoadingIdSearch] = useState(false)

  // This function is used when set the row of the table in modal or when idSearch return a value
  const onItemSelected = (obj) => {
    setItemSelected(obj)
    const newValue = obj[valueInputMain]
    setValue(newValue)
  }

  useEffect(() => {
    if (itemSelected && Object.keys(itemSelected).length > 0) {
      onHandleSelectedData(itemSelected)
    }
  }, [itemSelected])

  // function for searching by ID
  const handleIdSearch = async () => {
    setIsLoadingIdSearch(true) // Show loading spinner
    try {
      const response = await fetchwrapper(`${endpoint}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          typeSearch: SEARCH_TYPE_HELPER.ID_SEARCH,
          [idSearchField]: value,
        }),
      })

      const result = await response.json()

      if (result.data && Object.keys(result.data).length > 0) {
        // If search by ID returns results, handle it accordingly
        onItemSelected(result.data)
      } else {
        // If no result, open modal
        handleOpenModal()
      }
    } catch (error) {
      console.error(`${errorMsgIdSearch} `, error)
      handleOpenModal() // Open modal on error as well
    } finally {
      setIsLoadingIdSearch(false) // Hide loading spinner
    }
  }

  return (
    <>
      <CustomModal
        endpoint={endpoint}
        openModal={openModal}
        handleCloseModal={handleCloseModal}
        queryKeyModal={queryKeyModal}
        perPage={perPage}
        onItemSelected={onItemSelected}
        columnsTable={columnsTable}
        errorMsgFilterSearch={errorMsgFilterSearch}
      />
      <Box display="flex" flexDirection="column">
        <InputLabel>{label}</InputLabel>
        <Box display="flex">
          <TextField
            sx={{ ...sxInputMain }}
            value={value}
            onChange={(event) => setValue(event.target.value)}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                handleIdSearch()
              }
            }}
            onBlur={(event) => {
              if (event.target.value !== valueSearched) {
                setValue("")
                setItemSelected({})
                onHandleSelectedData({})
              }
            }}
            placeholder={placeholderInputMain}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {isLoadingIdSearch ? (
                    <CircularProgress size={24} />
                  ) : (
                    <IconButton onClick={() => handleOpenModal()}>
                      <SearchIcon />
                    </IconButton>
                  )}
                </InputAdornment>
              ),
            }}
          />
          <TextField
            sx={{ ...sxInputSecondary }}
            // id={`outlined-${sgasoling}-${idProducto}`}
            // variant="standard"
            value={itemSelected?.[valueInputSecondary] ?? ""}
            // fullWidth
            disabled
            placeholder={placeholderInputSecondary}
          />
        </Box>
      </Box>
    </>
  )
}

export default CustomHelperDetail
