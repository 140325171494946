import React, { useState, useEffect } from "react"
import Header from "../../../../layouts/Header"
import { styled, createTheme, ThemeProvider } from "@mui/material/styles"
import { Box, Grid, Card, CardContent, Typography } from "@mui/material"

import BackIcon from "../../../../components/BackIcon"

import { NavLink, useNavigate, useLocation } from "react-router-dom"
import HorizontalLinearStepper from "../../components/HorizontalLinearStepper"

import { useQuery } from "@tanstack/react-query"
import fetchwrapper from "../../../../services/interceptors/fetchwrapper"
import CustomBackdrop from "../../../../components/CustomBackdrop"

import { VerificacionDocumentalProvider } from "../../Contexts/VerificacionDocumentalContext"

const StyledRoot = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  marginTop: theme.spacing(8),
  flexGrow: 1,
  padding: "0 16px",
  height: "100vh",
}))

const theme = createTheme({
  palette: {
    primary: {
      main: "#196C87",
    },
    secondary: {
      main: "#196C87", // Cambia el color secundario a verde azulado
    },
  },
})
const FormulariosReadable = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const {
    data: fetchedFormTransporte = [],
    isError: isLoadingFormTransporteError,
    isFetching: isFetchingFormTransporte,
    isLoading: isLoadingFormTransporte,
  } = useGetSpecificFormTransporte()
  // READ hook (get specific FormTransporte from api)
  function useGetSpecificFormTransporte() {
    return useQuery({
      queryKey: ["FormTransporteReadable"],
      queryFn: async () => {
        // send api request here
        let response = await fetchwrapper(`/verificacionDocumental/getFormRespondido/${location.state.sgasoling}`)
        response = await response.json()
        return response.data
      },
      refetchOnWindowFocus: false,
    })
  }

  // const {
  //   data: fetchedFormRecepciones = [],
  //   isError: isLoadingFormRecepcionError,
  //   isFetching: isFetchingFormRecepcion,
  //   isLoading: isLoadingFormRecepcion,
  // } = useGetSpecificFormRecepcion();
  // //READ hook (get specific FormTransporte from api)
  // function useGetSpecificFormRecepcion() {
  //   return useQuery({
  //     queryKey: ["FormRecepcion"],
  //     queryFn: async () => {
  //       //send api request here
  //       let response = await fetchwrapper(
  //         `/verificacionDocumental/getSpecificForm/Acta de Recepción/${location.state.sgasoling}`
  //       );
  //       response = await response.json();
  //       return response.data;
  //     },
  //     refetchOnWindowFocus: false,
  //   });
  // }

  if (isLoadingFormTransporteError) {
    alert("No se pudieron encontrar los formularios")
    navigate(-1)
  }
  return (
    <VerificacionDocumentalProvider>
      <ThemeProvider theme={theme}>
        <Header />
        <div className="main main-app p-3 p-lg-4">
          <BackIcon />
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "0 30px 30px 30px",
              fontSize: "25px",
            }}
          >
            <b>Ver Formularios</b>
          </div>
          <CustomBackdrop
            isLoading={
              isLoadingFormTransporte || isFetchingFormTransporte
              // ||isLoadingFormRecepcion ||
              // isFetchingFormRecepcion
            }
          />

          <Box className={StyledRoot}>
            <HorizontalLinearStepper
              fetchedForms={[
                // ...fetchedFormRecepciones,
                ...fetchedFormTransporte,
              ]}
              isReadable={true}
            />
          </Box>
        </div>
      </ThemeProvider>
    </VerificacionDocumentalProvider>
  )
}

export default FormulariosReadable
