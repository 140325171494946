/* eslint-disable camelcase */
import { useMemo, useRef, useState } from "react"
import {
  MaterialReactTable,
  // createRow,
  useMaterialReactTable,
} from "material-react-table"
import { createTheme, ThemeProvider, useTheme, Box, Button, IconButton, Tooltip } from "@mui/material"
import { esES } from "@mui/material/locale"
import { MRT_Localization_ES } from "material-react-table/locales/es"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import EditIcon from "@mui/icons-material/Edit"
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye"
import fetchwrapper from "../../../services/interceptors/fetchwrapper"
import { useNavigate } from "react-router-dom"
import CrearIcon from "../../../assets/iconos/Crear.ico"
import normalFormatDate from "../../utils/date/DDMMYYYFormatDate"

const CustomTable = ({ fetchedInfo, isLoadingInfoError, isLoadingInfo, isFetchingInfo }) => {
  const navigate = useNavigate()

  const columns = useMemo(
    () => [
      {
        accessorKey: "sgasoling",
        header: "Código de Solicitud de Ingreso",
        enableEditing: false,
        size: 200,
      },
      {
        accessorKey: "sgastatus",
        header: "Estado de la Solicitud Ingreso",
        enableEditing: false,
        size: 200,
      },
      {
        accessorKey: "sgadescri",
        header: "Descripción",
        enableEditing: false,
        size: 200,
      },
      {
        accessorKey: "clinombre",
        header: "Cliente",
        enableEditing: false,
        size: 120,
      },
      {
        accessorKey: "pronombre",
        header: "Proveedor",
        enableEditing: false,
        size: 120,
      },
      {
        accessorKey: "motdescripcion",
        header: "Motivo",
        enableEditing: false,
        size: 120,
      },
      {
        accessorKey: "sgafecsol",
        header: "Fecha de Emisión",
        enableEditing: false,
        size: 120,
        Cell: ({ cell }) => {
          return normalFormatDate(cell.getValue())
        },
      },
      {
        accessorKey: "sgafecllegada",
        header: "Fecha de Llegada",
        enableEditing: false,
        size: 120,
        Cell: ({ cell }) => normalFormatDate(cell.getValue()),
      },
      {
        accessorKey: "sgacomenllegada",
        header: "Comentario de Llegada",
        enableEditing: false,
        size: 120,
      },
    ],
    [],
  )

  const table = useMaterialReactTable({
    columns,
    data: fetchedInfo,
    enableRowSelection: true,
    // enableEditing: true,
    // enableRowActions: true,
    localization: { ...MRT_Localization_ES },
    getRowId: (row) => row.formcodigo,
    muiToolbarAlertBannerProps: isLoadingInfoError
      ? {
          color: "error",
          children: "Error en cargar las Solicitudes Ingresos",
        }
      : undefined,
    muiTableContainerProps: {
      sx: {
        minHeight: "500px",
      },
    },

    renderTopToolbarCustomActions: ({ table }) => (
      <Button
        variant="contained"
        color="primary"
        onClick={() => {
          const selectedRows = table.getSelectedRowModel().rows // or read entire rows
          if (selectedRows.length === 0) {
            alert("Seleccione al menos una solicitud")
            return
          }

          if (selectedRows.length >= 2) {
            console.log(selectedRows[0].original, "aqui")
            const firstRowSelectedCliente = selectedRows[0].original.clinombre
            for (let i = 1; i < selectedRows.length; i++) {
              if (selectedRows[i].original.clinombre !== firstRowSelectedCliente) {
                alert("Escoga solo aquellas solicitudes que tengan el mismo cliente asignado")
                return
              }
            }
          }

          const rows = selectedRows.map((row) => row.original)

          navigate(`ver`, {
            state: { rows },
          })
        }}
      >
        Descargar a Cuarentena
      </Button>
    ),

    // renderRowActions: ({ row, table }) => (
    //   <Box sx={{ display: "flex", gap: "1rem" }}>
    //     <Tooltip title="Ver">
    //       <IconButton
    //         onClick={() => {
    //           navigate(`ver`, {
    //             state: row?.original,
    //           });
    //         }}
    //       >
    //         <RemoveRedEyeIcon />
    //       </IconButton>
    //     </Tooltip>
    //   </Box>
    // ),
    state: {
      isLoading: isLoadingInfo,
      showAlertBanner: isLoadingInfoError,
      showProgressBars: isFetchingInfo,
    },
  })

  return (
    <>
      <MaterialReactTable table={table} />
    </>
  )
}

const DatagridSolicitudesIngresos = (params) => {
  const theme = useTheme()
  return (
    <div>
      <ThemeProvider theme={createTheme(theme, esES)}>
        <CustomTable {...params} />
      </ThemeProvider>
    </div>
  )
}

export default DatagridSolicitudesIngresos
