import CustomTooltip from "../../../components/ToolTip"
import { useMemo, useState } from "react"
import {
  MaterialReactTable,
  // createRow,
  useMaterialReactTable,
} from "material-react-table"
import { MRT_Localization_ES } from "material-react-table/locales/es"
import { createTheme, ThemeProvider, useTheme, Box, Button, IconButton, Tooltip } from "@mui/material"
import { esES } from "@mui/material/locale"
import CustomBackdrop from "../../../components/CustomBackdrop"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import EditIcon from "@mui/icons-material/Edit"
import DeleteIcon from "@mui/icons-material/Delete"
import fetchwrapper from "../../../services/interceptors/fetchwrapper"
import { useNavigate } from "react-router-dom"
import CrearIcon from "../../../assets/iconos/Crear.ico"
import EliminarIcon from "../../../assets/iconos/Eliminar.ico"
import DetalleIcon from "../../../assets/iconos/Detalle.ico"
import ImprimirIcon from "../../../assets/iconos/Impresion.ico"
import DetalleImpresion from "./DetalleImpresion"
import ZonasPDF from "./ZonasPdf"
import normalFormatDate from "../../utils/date/DDMMYYYFormatDate"
import normalFormatHour from "../../utils/date/HHMMSSFormatHour"
const CustomTable = () => {
  // para movernos a crear y editar
  const navigate = useNavigate()
  const columns = useMemo(
    () => [
      {
        accessorKey: "izoncodigo",
        header: "Código",
        enableEditing: false,
        size: 80,
      },
      {
        accessorKey: "izondescripcion",
        header: "Descripción",
        enableEditing: false,
        size: 80,
      },
      {
        accessorKey: "izontipo",
        header: "Tipo",
        enableEditing: false,
        size: 80,
      },
      {
        accessorKey: "izonstatus",
        header: "Estado",
        enableEditing: false,
        size: 80,
        Cell: ({ cell }) => {
          let cellValue = cell.getValue()
          if (cellValue === "A") {
            cellValue = "ACTIVO"
          } else {
            cellValue = "INACTIVO"
          }
          return cellValue
        },
      },
      {
        accessorKey: "izonusuisys",
        header: "Usuario Creacion",
        enableEditing: false,
        size: 80,
      },
      {
        accessorKey: "izonfecisys",
        header: "Fecha de Creación",
        enableEditing: false,
        size: 80,
        Cell: ({ cell }) => {
          const cellValue = cell.getValue()
          return normalFormatDate(cellValue)
        },
      },
      {
        accessorKey: "izonhorisys",
        header: "Hora de Creación",
        enableEditing: false,
        size: 80,
        Cell: ({ cell }) => {
          const cellValue = cell.getValue()
          return new Date(cellValue).toLocaleTimeString()
        },
      },
      {
        accessorKey: "izonusumsys",
        header: "Usuario MOdifica",
        enableEditing: false,
        size: 80,
      },
      {
        accessorKey: "izonfecmsys",
        header: "Fecha Modifica",
        enableEditing: false,
        size: 80,
        Cell: ({ cell }) => {
          const cellValue = cell.getValue()
          return normalFormatDate(cellValue)
        },
      },
      {
        accessorKey: "izonhormsys",
        header: "Hora Modifica",
        enableEditing: false,
        size: 80,
        Cell: ({ cell }) => {
          const cellValue = cell.getValue()
          return new Date(cellValue).toLocaleTimeString()
        },
      },
    ],
    [],
  )
  // useCreateZonas crea las zonas
  // createZonas funcion que se llama
  const { mutateAsync: createZonas, isPending: isCreatingZonas } = useCreateZonas()
  // useGetZonas hook para mostrar las zonas

  const {
    data: fetchedZonas = [],
    isError: isLoadingZonasError,
    isFetching: isFetchingZonas,
    isLoading: isLoadingZonas,
  } = useGetZonas()
  // useUpdateZona() hook de update, ,updateZona funcion que se ejecuta
  const { mutateAsync: updateZona, isPending: isUpdatingZona } = useUpdateZona()
  // useDeleteZona() hook de delete , usa la funcion deleteZona()
  const { mutateAsync: deleteZona, isPending: isDeletingZona } = useDeleteZona()
  // funcion que muestra el modal de eliminacion
  const openDeleteConfirmModal = async (row) => {
    if (window.confirm("¿Está seguro que quiere eliminar esta zona?")) {
      await deleteZona(row.original.izoncodigo)
    }
  }
  const table = useMaterialReactTable({
    columns,
    data: fetchedZonas,
    enableEditing: true,
    enableRowActions: true,
    initialState: {
      showColumnFilters: true,
    },
    localization: { ...MRT_Localization_ES },
    getRowId: (row) => row.izoncodigo,
    // si se cargar el error al importar los datos
    muiToolbarAlertBannerProps: isLoadingZonasError
      ? {
          color: "error",
          children: "Error en cargar las Zonas",
        }
      : undefined,
    muiTableContainerProps: {
      sx: {
        minHeight: "500px",
      },
    },
    renderRowActions: ({ row, table }) => (
      <Box sx={{ display: "flex", gap: "1rem" }}>
        <Tooltip title="Editar">
          <IconButton
            onClick={() => {
              // le pasa a editar el codigo de la zona que seleccione cuando de click en el icono
              navigate(`editar/${row?.original.izoncodigo}`, {
                state: row?.original,
              })
            }}
          >
            <img src={DetalleIcon} alt="Editar" style={{ width: "20px" }} />
          </IconButton>
        </Tooltip>
        <Tooltip title="Eliminar">
          <IconButton color="error" onClick={() => openDeleteConfirmModal(row)}>
            <img src={EliminarIcon} alt="Grabar" style={{ width: "20px" }} />
          </IconButton>
        </Tooltip>
      </Box>
    ),
    state: {
      isLoading: isLoadingZonas,
      isSaving: isCreatingZonas || isUpdatingZona || isDeletingZona,
      showAlertBanner: isLoadingZonas,
      showProgressBars: isFetchingZonas,
    },
  })
  return (
    <>
      <MaterialReactTable table={table} />
      <CustomBackdrop isLoading={table.getState().isSaving} />
    </>
  )
}
function useGetZonas() {
  return useQuery({
    queryKey: ["Zonas"],
    queryFn: async () => {
      // send api request here
      let response = await fetchwrapper("/Zonas/getZonas")
      response = await response.json()
      response = response?.data
      // await new Promise((resolve) => setTimeout(resolve, 1000)); //fake api call
      return response
    },
    refetchOnWindowFocus: false,
  })
}

// hooks a los que se les asigna una api y se encargan de actualizar el estado del componente
function useCreateZonas() {
  // hook para crear la zona
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (zona) => {
      await new Promise((resolve) => setTimeout(resolve, 1000)) // fake api call
      return Promise.resolve()
    },
    onMutate: (newZona) => {
      queryClient.setQueryData(["Zonas"], (prevZonas) =>
        prevZonas?.map((prevZonas) => (prevZonas.izoncodigo === newZona.izoncodigo ? newZona : prevZonas)),
      )
    },
  })
}
function useUpdateZona() {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (zona) => {
      // send api update request here
      await new Promise((resolve) => setTimeout(resolve, 1000)) // fake api call
      return Promise.resolve()
    },
    // client side optimistic update
    onMutate: (newZonaInfo) => {
      queryClient.setQueryData(["Zonas"], (prevZonas) =>
        prevZonas?.map((prevZonas) => (prevZonas.izoncodigo === newZonaInfo.izoncodigo ? newZonaInfo : prevZonas)),
      )
    },
    // onSettled: () => queryClient.invalidateQueries({ queryKey: ['bancoDePreguntas'] }), //refetch bancoDePreguntas after mutation, disabled for demo
  })
}
function useDeleteZona() {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (codigoZona) => {
      const options = {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }

      const response = await fetchwrapper(`/Zonas/deleteZona/${codigoZona}`, options)
      return response
    },
    // actualizacion optimista
    onMutate: (codigoZona) => {
      queryClient.setQueryData(["Zonas"], (prevZonas) => prevZonas?.filter((zona) => zona.izoncodigo !== codigoZona))
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ["Zonas"] })
    },
    onSuccess: () => {
      alert("Zona eliminada con exito")
    },
    onError: () => {
      alert("No se pudo eliminar la zona")
    },
  })
}

const TooltipCrear = () => {
  const navigate = useNavigate()
  return (
    <>
      <Tooltip title="Crear Zona">
        <Button color="primary" onClick={() => navigate("crear")} sx={{ marginBottom: "15px" }}>
          <img src={CrearIcon} alt="Crear" style={{ width: "30px" }} />
        </Button>
      </Tooltip>
    </>
  )
}

const DataGridZonas = () => {
  const [openModal, setOpenModal] = useState(false)
  const [infoGenerada, setInfoGenerada] = useState([])
  const {
    data: fetchedZonas = [],
    isError: isLoadingZonasError,
    isFetching: isFetchingZonas,
    isLoading: isLoadingZonas,
  } = useGetZonas()

  const handleOpenModal = () => {
    setInfoGenerada(fetchedZonas) // Asigna los datos de la tabla al estado
    setOpenModal(true)
  }
  console.log("info pdf", fetchedZonas)
  const handleCloseModal = () => {
    setOpenModal(false)
    navigate("/home/dashboard/Zonas")
  }
  const navigate = useNavigate()
  const theme = useTheme()
  return (
    <div>
      <TooltipCrear></TooltipCrear>
      <Tooltip title="Imprimir">
        <Button color="primary" onClick={handleOpenModal} sx={{ marginBottom: "15px" }}>
          <img src={ImprimirIcon} alt="Imprimir" style={{ width: "30px" }} />
        </Button>
      </Tooltip>
      <DetalleImpresion
        open={openModal}
        handleClose={handleCloseModal}
        infoGenerada={fetchedZonas}
        nombrePdf={"Zonas"}
        PdfComponent={ZonasPDF}
      />
      <ThemeProvider theme={createTheme(theme, esES)}>
        <CustomTable />
      </ThemeProvider>
    </div>
  )
}
export default DataGridZonas
