import React, { useEffect, useState } from "react"
import Header from "../../../../layouts/Header"
import { styled, createTheme, ThemeProvider } from "@mui/material/styles"
import { Box, Grid, TextField } from "@mui/material"

import BackIcon from "../../../../components/BackIcon"

import { useLocation, useNavigate } from "react-router-dom"
import { useQuery } from "@tanstack/react-query"
import fetchwrapper from "../../../../services/interceptors/fetchwrapper"
import DatagridProductos from "../../components/DatagridProductos"
import CustomBackdrop from "../../../../components/CustomBackdrop"
import CustomSelectComponent from "../../../../components/CustomSelectComponent"
import PalletsManagment from "../../components/PalletsManagment"
import ZebraPrinter from "../../../utils/zebraPrinter"
import normalFormatDate from "../../../utils/date/DDMMYYYFormatDate"

const StyledRoot = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  marginTop: theme.spacing(8),
  flexGrow: 1,
  padding: "0 16px",
  height: "100vh",
}))

const theme = createTheme({
  palette: {
    primary: {
      main: "#196C87",
    },
    secondary: {
      main: "#196C87", // Cambia el color secundario a verde azulado
    },
  },
})
const VerTodosIngresos = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const [observacionTxt, setObservacionTxt] = useState("")
  const [selectedBodega, setSelectedBodega] = useState(null)
  const [productsCuarentena, setProductsCuarentena] = useState([])
  const [enModoLlenado, setEnModoLlenado] = useState(false)
  const [isLoadingSavePalletLleno, setisLoadingSavePalletLleno] = useState(false)
  const comenzarLlenadoPallets = () => setEnModoLlenado(true)
  const terminarLlenadoPallets = () => setEnModoLlenado(false)
  const [isLoadingPrintingLabels, setIsLoadingPrintingLabels] = useState(false)

  const {
    data: allPrintersProceso = [],
    isError: isLoadingallPrintersProcesoError,
    isFetching: isFetchingallPrintersProceso,
    isLoading: isLoadingallPrintersProceso,
    error: isFetchingallPrintersProcesoError,
  } = useGetallPrintersProceso()
  function useGetallPrintersProceso() {
    return useQuery({
      queryKey: ["ImpresorasDescargaCuarentenaCrear"],
      queryFn: async () => {
        // send api request here
        const options = {
          method: "POST",
          body: JSON.stringify({
            printproceso: "ZEBRA",
          }),
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }
        let response = await fetchwrapper("/externos/get_impresoras", options)
        response = await response.json()
        return response
      },
      refetchOnWindowFocus: false,
    })
  }

  const printLabels = async (allIngresos) => {
    for (const ingreso of allIngresos) {
      const { cabecera, detalle = [] } = ingreso
      if (!detalle || detalle.length === 0) continue

      // Recorre cada proceso de impresion
      // Deberia haber solo 2, uno para imprimir lotes ()
      // Otro para imprimir pallets ()
      for (const printerProceso of allPrintersProceso) {
        try {
          const ipPrinter = printerProceso.printip
          const printproceso = printerProceso.printproceso
          const lincodigo = printerProceso.lincodigo
          const temporalProductsToPrint = detalle?.detalle ?? []
          if (!ipPrinter && !printproceso && !lincodigo) {
            alert("No se pudo reconocer ninguna impresora")
            return
          }
          if (!ipPrinter && !printproceso && !lincodigo) {
            alert("No se pudo reconocer ninguna impresora")
            return
          }

          const CustomZebraPrinter = new ZebraPrinter(ipPrinter)

          console.log(
            printproceso,
            lincodigo,
            {
              dataLabels: temporalProductsToPrint,
              palletID: cabecera.palletID,
              numIngreso: cabecera.numIngreso,
              fecIngreso: cabecera.fecIngreso,
              clinombre: cabecera.clinombre,
              numSol: cabecera.numSol,
            },
            "aquii",
          )

          setIsLoadingPrintingLabels(true)

          await CustomZebraPrinter.imprimirEtiquetas(printproceso, lincodigo, {
            dataLabels: temporalProductsToPrint,
            palletID: cabecera.palletID,
            numIngreso: cabecera.numIngreso,
            fecIngreso: cabecera.fecIngreso,
            clinombre: cabecera.clinombre,
            numSol: cabecera.numSol,
          })

          console.log("impreso")
        } catch (errr) {
          console.error(errr)
          continue
        } finally {
          setIsLoadingPrintingLabels(false)
        }
      }
    }
  }

  function agruparPorSolicitudes(data) {
    const solicitudes = {}

    // Recorrer cada producto en la lista de datos
    data.forEach((item) => {
      const sgasoling = item.sgasoling

      // Si la solicitud no está en el objeto, agregarla
      if (!solicitudes[sgasoling]) {
        solicitudes[sgasoling] = {
          cabecera: {
            sgasoling,
            clicodigo: location.state.rows[0].clicodigo,
            invcodigo: selectedBodega.invcodigo,
            bodcodigo: selectedBodega.bodcodigo,
            tradescripcion: observacionTxt,
          },
          detalle: [],
        }
      }

      // Añadir el producto al detalle de la solicitud correspondiente
      solicitudes[sgasoling].detalle.push(item)
    })

    // Convertir el objeto a un arreglo de subarreglos
    const resultado = Object.keys(solicitudes).map((key) => solicitudes[key])

    return resultado
  }

  const palletLleno = async () => {
    //  solo registrar aquellos productos que tenga la info completa: cantidad de embalaje y lotes
    const productsWithInfoComplete = []

    // Verdicar los productos que tengan cantidad de embalaje y que su cantidad total de lotes registrados sea igual que su cantidad de embalaje
    productsCuarentena.forEach((product) => {
      if (product.cantidadEmbalaje > 0) {
        const cantidadTotalLotesRegistrados = product.lotes.reduce((acc, lote) => acc + lote.cantidadLote ?? 0, 0)
        if (product.cantidadEmbalaje === cantidadTotalLotesRegistrados) {
          productsWithInfoComplete.push(product)
        }
      }
    })

    if (productsWithInfoComplete.length === 0) {
      alert("Registre al menos un producto en el pallet")
      return
    }

    const ingresoInventario = {
      // datos de necesario para la cabecera del ingreso
      cabecera: {
        clicodigo: location.state.rows[0].clicodigo,
        invcodigo: selectedBodega.invcodigo,
        bodcodigo: selectedBodega.bodcodigo,
        tradescripcion: observacionTxt,
      }, // Todos estos campos son iguales en cada cada cabecera de cada solicitud
      detalle: agruparPorSolicitudes(productsWithInfoComplete), // Arreglo de solicitudes que que conitine sus productos con sus respectivos datos y lotes
    }

    try {
      setisLoadingSavePalletLleno(true)

      const options = {
        method: "POST",
        body: JSON.stringify(ingresoInventario),
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
      let response = await fetchwrapper("/cuarentena/savePalletLleno", options)
      response = await response.json()

      // Imprimir las etiquetas de los lotes y pallet de cada ingreso
      const ingresosGuardados = response.ingresosRegistrados.map((ingreso) => {
        return {
          cabecera: {
            palletID: response.idPalletcodigoGenerated,
            numIngreso: ingreso.ingresoInventariocodigoGenerated,
            fecIngreso: normalFormatDate(response.fechaIngreso),
            clinombre: location.state.rows[0].clinombre,
            numSol: ingreso.sgasoling,
          },
          detalle: ingresoInventario.detalle.find(
            (ingresoFormateado) => ingresoFormateado.cabecera.sgasoling === ingreso.sgasoling,
          ),
        }
      })

      await printLabels(ingresosGuardados)

      // Cada vez que se registra elpallet get un numero de ingreso y pallet
      // y volver a cargar los productos ya que su cantidaddes solicitadas cambiaron y tambien nuevo numerod eingreso y pallet
      await refetchProductos()
      await refetchIngresoInventarioID()
      await refetcPalletID()

      terminarLlenadoPallets()
      alert("Ingreso registrado exitosamente")
    } catch (error) {
      alert("No se peudo registrar el ingreso, intente de nuevo")
    } finally {
      setisLoadingSavePalletLleno(false)
    }
  }

  // -----------------------------------------------------
  const {
    data: fetchedIngresoInventarioID = "",
    isError: isLoadiIngresoInventarioIDError,
    isFetching: isFetchIngresoInventarioID,
    isLoading: isLoadiIngresoInventarioID,
    refetch: refetchIngresoInventarioID,
    error: isFetchIngresoInventarioIDError,
  } = useGetPIngresoInventarioID()

  // READ hook (get Ingreso Inventario ID from api)
  function useGetPIngresoInventarioID() {
    return useQuery({
      queryKey: ["ProductIngresoInventarioIDDescargaCuarentena"],
      queryFn: async () => {
        // send api request here
        let response = await fetchwrapper("/cuarentena/getIngresoInventarioId")
        response = await response.json()
        response = response?.data
        return response
      },
      refetchOnWindowFocus: false,
      enabled: false,
    })
  }
  const {
    data: fetchedProductos = [],
    isError: isLoadingProductosError,
    isFetching: isFetchingProductos,
    isLoading: isLoadingProductos,
    refetch: refetchProductos,
    error: isFetchingProductosError,
  } = useGetProductos()

  const {
    data: fetchedPalletID = "",
    isError: isLoadiPalletIDError,
    isFetching: isFetchPalletID,
    isLoading: isLoadiPalletID,
    refetch: refetcPalletID,
    error: isFetchPalletIDError,
  } = useGetPPalletID()

  // READ hook (get InPalletID from api)
  function useGetPPalletID() {
    return useQuery({
      queryKey: ["ProductPalletIDDescargaCuarentena"],
      queryFn: async () => {
        // send api request here
        let response = await fetchwrapper("/cuarentena/getIdPallet")
        response = await response.json()
        response = response?.data
        return response
      },
      refetchOnWindowFocus: false,
      enabled: false,
    })
  }

  // READ hook (get Productos from api)
  function useGetProductos() {
    return useQuery({
      queryKey: ["ProductosDescargaCuarentena"],
      queryFn: async () => {
        // send api request here
        const bodyParams = location.state.rows.map((row) => ({
          NumeroSolicitud: row.sgasoling,
          bodcodigo: selectedBodega.bodcodigo,
          clicodigo: row.clicodigo,
        }))
        const options = {
          method: "POST",
          body: JSON.stringify({
            allSolicitudes: bodyParams,
          }),
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }
        let response = await fetchwrapper("/cuarentena/obtenerAllProductosSolicitud", options)
        response = await response.json()
        response = response?.data
        return response
      },
      refetchOnWindowFocus: false,
      enabled: false,
    })
  }

  const {
    data: fetchedBodegas = [],
    isError: isLoadingBodegasError,
    // isFetching: isFetchingBodegas,
    isLoading: isLoadingBodegas,
  } = useGetBodegas()

  if (isLoadingBodegasError) {
    alert("No se pudo cargar la lista de bodegas")
    navigate(-1)
  }

  // READ hook (getBodegas from api)
  function useGetBodegas() {
    return useQuery({
      queryKey: ["BodegasDescargaCuarentena"],
      queryFn: async () => {
        // send api request here
        let response = await fetchwrapper(`/cuarentena/getAllBodegas/${location.state.rows[0].clicodigo}`)
        response = await response.json()
        response = response?.data
        return response
      },
      refetchOnWindowFocus: false,
    })
  }

  // const {
  //   data: fetchedCabeceraSoli = {},
  //   isError: isLoadingCabeceraSoliError,
  //   // isFetching: isFetchingCabeceraSoli,
  //   isLoading: isLoadingCabeceraSoli,
  // } = useGetCabeceraSolicitud();

  // //READ hook (get CabeceraSoli from api)
  // function useGetCabeceraSolicitud() {
  //   return useQuery({
  //     queryKey: ["CabeceraSoliDescargaCuarentena"],
  //     queryFn: async () => {
  //       let response = await fetchwrapper(
  //         `/cuarentena/getSolicitudIngreso/${location.state.sgasoling}`
  //       );
  //       response = await response.json();
  //       response = response?.data;
  //       return response;
  //     },
  //     refetchOnWindowFocus: false,
  //   });
  // }

  const {
    data: fetchedEmbalajes = [],
    isError: isLoadingEmbalajesError,
    isFetching: isFetchingEmbalajes,
    isLoading: isLoadingEmbalajes,
    refetch: refetchEmbalajes,
    error: isFetchingEmbalajesError,
  } = useGetEmbalajes()

  // READ hook (get Embalajes from api)
  function useGetEmbalajes() {
    return useQuery({
      queryKey: ["EmbalajesDescargaCuarentena"],
      queryFn: async () => {
        let response = await fetchwrapper(`/cuarentena/getEmbalajes`)
        response = await response.json()
        response = response?.data
        return response
      },
      refetchOnWindowFocus: false,
      enabled: false,
    })
  }

  const {
    data: fetchedTonos = [],
    isError: isLoadingTonosError,
    isFetching: isFetchingTonos,
    isLoading: isLoadingTonos,
    refetch: refetchTonos,
    error: isFetchingTonosError,
  } = useGetTonos()

  // READ hook (get Tonos from api)
  function useGetTonos() {
    return useQuery({
      queryKey: ["TonosDescargaCuarentena"],
      queryFn: async () => {
        let response = await fetchwrapper(`/cuarentena/getTonos`)
        response = await response.json()
        response = response?.data
        return response
      },
      refetchOnWindowFocus: false,
      enabled: false,
    })
  }

  const {
    data: fetchedDefaultUbicacion = {},
    isError: isLoadingDefaultUbicacionError,
    isFetching: isFetchingDefaultUbicacion,
    isLoading: isLoadingDefaultUbicacion,
    refetch: refetchDefaultUbicacion,
    error: isFetchingDefaultUbicacionError,
  } = useGetDefaultUbicacion()

  // READ hook (get DefaultUbicacion from api)
  function useGetDefaultUbicacion() {
    return useQuery({
      queryKey: ["DefaultUbicacionDescargaCuarentena"],
      queryFn: async () => {
        let response = await fetchwrapper(
          `/cuarentena/getDefaultUbicacion/${selectedBodega?.invcodigo}/${selectedBodega?.bodcodigo}`,
        )
        response = await response.json()
        response = response?.data
        return response
      },
      refetchOnWindowFocus: false,
      enabled: false,
    })
  }
  // -----------------------------------------------------

  // Cargar codigos de ingreso y pallet al inicio de la app
  useEffect(() => {
    refetchIngresoInventarioID()
    refetcPalletID()
  }, [])
  useEffect(() => {
    if (selectedBodega) {
      refetchProductos()
      refetchEmbalajes()
      refetchTonos()
      refetchDefaultUbicacion()
    }

    console.log(selectedBodega, fetchedBodegas, "hereeee")
  }, [selectedBodega])

  useEffect(() => {
    if (
      fetchedProductos.length !== 0 &&
      selectedBodega &&
      Object.keys(selectedBodega).length !== 0 &&
      Object.keys(fetchedDefaultUbicacion).length !== 0
    ) {
      const productsCuarentenaFormat = fetchedProductos.map((product) => ({
        sgasoling: product.sgasoling,
        artcodigo: product.artcodigo,
        artdescri: product.artdescri,

        invcodigo: selectedBodega.invcodigo,
        bodcodigo: selectedBodega.bodcodigo,

        embcodigo: product.embcodigo,
        embdescripcion: product.embdescripcion,
        embmetros3: product.embmetros3,
        embpieza: product.embpieza,
        embmetros2: product.embmetros2,

        tondescripcion: product.tondescripcion,
        toncodigo: product.toncodigo,

        bahcodigo: fetchedDefaultUbicacion.bahcodigo,
        bahdescripcion: fetchedDefaultUbicacion.bahdescripcion,
        bahmetros3: fetchedDefaultUbicacion.bahmetros3,
        izoncodigo: fetchedDefaultUbicacion.izoncodigo,
        izondescripcion: fetchedDefaultUbicacion.izondescripcion,
        pascodigo: fetchedDefaultUbicacion.pascodigo,
        pasdescripcion: fetchedDefaultUbicacion.pasdescripcion,
        poscodigo: fetchedDefaultUbicacion.poscodigo,
        nivcodigo: fetchedDefaultUbicacion.nivcodigo,

        sgacansol: product.sgacansol - product.sgacanrec,
        cantidadEmbalaje: 0,

        artregissani: product.artregissani,
        artobserva: product.artobserva,
        arttemperatura: product.arttemperatura,
        artconcentra: product.artconcentra,
        predescri: product.predescri,
        marcodigo: product.marcodigo,
        mardescri: product.mardescri,
        medcodigo: product.medcodigo,
        meddescri: product.meddescri,
        artcodbarra: product.artcodbarra,
        artalias: product.artalias,
        artcodigo2: product.artcodigo2,
        artcomentari: product.artcomentari,

        artdiasseg: product.artdiasseg, // Dias de seguridad

        lotes: [],
      }))

      setProductsCuarentena(productsCuarentenaFormat)
    }
  }, [fetchedProductos, selectedBodega, fetchedDefaultUbicacion])
  return (
    <ThemeProvider theme={theme}>
      <Header />
      <div className="main main-app p-3 p-lg-4">
        <BackIcon />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "0 30px 30px 30px",
            fontSize: "25px",
          }}
        >
          <b>DESCARGA CUARENTENA</b>
        </div>

        <div style={{ textAlign: "center" }}>
          <p>
            <strong>Código de Ingreso:</strong> {fetchedIngresoInventarioID}
          </p>
          <p>
            <strong>Código de Palet:</strong> {fetchedPalletID}
          </p>

          <p>
            <strong>Cliente:</strong> {location.state.rows[0].clinombre}
          </p>

          <TextField
            fullWidth
            id="observacion"
            label="Observacion"
            value={observacionTxt}
            onChange={(event) => {
              setObservacionTxt(event.target.value)
            }}
          />
          <br />
          <br />
        </div>

        <CustomBackdrop
          isLoading={
            isLoadiIngresoInventarioID ||
            isFetchIngresoInventarioID ||
            isLoadiPalletID ||
            isFetchPalletID ||
            isLoadingProductos ||
            isFetchingProductos ||
            isLoadingBodegas ||
            isLoadingEmbalajes ||
            isFetchingEmbalajes ||
            isLoadingTonos ||
            isFetchingTonos ||
            isLoadingDefaultUbicacion ||
            isFetchingDefaultUbicacion ||
            isLoadingSavePalletLleno ||
            isLoadingPrintingLabels ||
            isLoadingallPrintersProceso ||
            isFetchingallPrintersProceso
          }
        />

        {isLoadiIngresoInventarioIDError ||
        isFetchIngresoInventarioIDError ||
        isLoadiPalletIDError ||
        isFetchPalletIDError ||
        isLoadingProductosError ||
        isFetchingProductosError ||
        isLoadingBodegasError ||
        isLoadingEmbalajesError ||
        isFetchingEmbalajesError ||
        isLoadingTonosError ||
        isFetchingTonosError ? (
          <div>Error al obtener la información</div>
        ) : (
          <Box className={StyledRoot}>
            <CustomSelectComponent
              id="bodegas"
              floatLabel="Bodegas"
              optionLabel="boddescri"
              values={fetchedBodegas}
              selectedValue={selectedBodega}
              setSelectedValue={setSelectedBodega}
              placeholder="Bodegas"
            />

            <br />

            {!selectedBodega ? (
              <div>Seleccione una bodega</div>
            ) : (
              <>
                {isLoadingDefaultUbicacionError || isFetchingDefaultUbicacionError ? (
                  <div>No se pudo encontrar una ubicación por defecto en esa bodega. Intente con otra bodega</div>
                ) : (
                  <PalletsManagment
                    enModoLlenado={enModoLlenado}
                    comenzarLlenadoPallets={comenzarLlenadoPallets}
                    terminarLlenadoPallets={terminarLlenadoPallets}
                    palletLleno={palletLleno}
                    fetchedProductos={fetchedProductos}
                  >
                    <DatagridProductos
                      productsCuarentena={productsCuarentena}
                      setProductsCuarentena={setProductsCuarentena}
                      fetchedEmbalajes={fetchedEmbalajes}
                      fetchedTonos={fetchedTonos}
                    />
                  </PalletsManagment>
                )}
              </>
            )}
          </Box>
        )}
      </div>
    </ThemeProvider>
  )
}

export default VerTodosIngresos
