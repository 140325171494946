import React, { useEffect, useState, useMemo } from "react"
import { MaterialReactTable, useMaterialReactTable } from "material-react-table"
import {
  TextField,
  createTheme,
  ThemeProvider,
  useTheme,
  Box,
  IconButton,
  Tooltip,
  TablePagination,
} from "@mui/material"

import Swal from "sweetalert2"
import { styled } from "@mui/material/styles"
import { esES } from "@mui/material/locale"
import { MRT_Localization_ES } from "material-react-table/locales/es"
import fetchwrapper from "../../../services/interceptors/fetchwrapper"
import EditIcon from "@mui/icons-material/Edit"
import { useNavigate } from "react-router-dom"
import EliminarIcon from "../../../assets/iconos/Eliminar.ico"
import DetalleIcon from "../../../assets/iconos/Detalle.ico"
import normalFormatDate from "../../utils/date/DDMMYYYFormatDate"
import normalFormatHour from "../../utils/date/HHMMSSFormatHour"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import CustomBackdrop from "../../../components/CustomBackdrop"
const CustomTable = ({
  fetchedInfo = [],
  isLoadingInfoError,
  isLoadingInfo,
  isFetchingInfo,
  searchAttempted,
  setBahias,
  refetch,
  onPageChange,
  deleteFunction,
}) => {
  const navigate = useNavigate()
  // Maneja cambios en los filtros de la grilla

  const columns = useMemo(
    () => [
      {
        accessorKey: "acciones",
        header: "Acciones",
        enableColumnFilter: false,
        size: 150,
        Cell: ({ row }) => (
          <Box sx={{ display: "flex", gap: "1rem" }}>
            <Tooltip title="Editar">
              <IconButton
                onClick={() => {
                  setTimeout(() => {
                    navigate(`editar/Bahias`, { state: row.original })
                  }, 0)
                }}
              >
                <img src={DetalleIcon} alt="Editar" style={{ width: "20px" }} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Eliminar">
              <IconButton color="error" onClick={() => deleteFunction(row)}>
                <img src={EliminarIcon} alt="Grabar" style={{ width: "20px" }} />
              </IconButton>
            </Tooltip>
          </Box>
        ),
      },
      {
        accessorKey: "loccodigo",
        header: "Localidad",
        enableEditing: false,
        size: 120,
      },

      {
        accessorKey: "bahcodigo",
        header: "Codigo Bahia",
        enableEditing: false,
        size: 120,
      },

      {
        accessorKey: "bahdescripcion",
        header: "Descripcion",
        enableEditing: false,
        size: 120,
      },
      {
        accessorKey: "bahmetros3",
        header: "M3",
        enableEditing: false,
        size: 120,
      },
      {
        accessorKey: "bahstatus",
        header: "Estado",
        enableEditing: false,
        size: 120,
        Cell: ({ cell }) => {
          let cellValue = cell.getValue()
          if (cellValue === "A") {
            cellValue = "ACTIVO"
          } else {
            cellValue = "INACTIVO"
          }
          return cellValue
        },
      },
      {
        accessorKey: "bahclasi",
        header: "Clasificacion",
        enableEditing: false,
        size: 120,
      },
      {
        accessorKey: "bodcodigo",
        header: "Codigo Bodega",
        enableEditing: false,
        size: 200,
      },
      {
        accessorKey: "boddescri",
        header: "Descripcion",
        enableEditing: false,
        size: 200,
      },

      {
        accessorKey: "izoncodigo",
        header: "Codigo Zona",
        enableEditing: false,
        size: 120,
      },
      {
        accessorKey: "izondescripcion",
        header: "Descripcion",
        enableEditing: false,
        size: 120,
      },
      {
        accessorKey: "izonstatus",
        header: "Estado",
        enableEditing: false,
        size: 120,
        Cell: ({ cell }) => {
          let cellValue = cell.getValue()
          if (cellValue === "A") {
            cellValue = "ACTIVO"
          } else {
            cellValue = "INACTIVO"
          }
          return cellValue
        },
      },
      {
        accessorKey: "izontipo",
        header: "Tipo",
        enableEditing: false,
        size: 120,
      },
      {
        accessorKey: "pascodigo",
        header: "Codigo Pasillo",
        enableEditing: false,
        size: 120,
      },
      {
        accessorKey: "pasdescripcion",
        header: "Descripcion",
        enableEditing: false,
        size: 120,
      },
      {
        accessorKey: "bahusuisys",
        header: "Usuario Creacion",
        enableEditing: false,
        size: 80,
      },
      {
        accessorKey: "bahfecisys",
        header: "Fecha de Creación",
        enableEditing: false,
        size: 80,
        Cell: ({ cell }) => {
          const cellValue = cell.getValue()
          return normalFormatDate(cellValue)
        },
      },
      {
        accessorKey: "bahhorisys",
        header: "Hora de Creación",
        enableEditing: false,
        size: 80,
        Cell: ({ cell }) => {
          const cellValue = cell.getValue()
          return new Date(cellValue).toLocaleTimeString()
        },
      },
      {
        accessorKey: "bahusumsys",
        header: "Usuario Modifica",
        enableEditing: false,
        size: 80,
      },
      {
        accessorKey: "bahfecmsys",
        header: "Fecha Modifica",
        enableEditing: false,
        size: 80,
        Cell: ({ cell }) => {
          const cellValue = cell.getValue()
          return normalFormatDate(cellValue)
        },
      },
      {
        accessorKey: "bahhormsys",
        header: "Hora Modifica",
        enableEditing: false,
        size: 80,
        Cell: ({ cell }) => {
          const cellValue = cell.getValue()
          return new Date(cellValue).toLocaleTimeString()
        },
      },
    ],
    [navigate],
  )

  const table = useMaterialReactTable({
    columns,
    data: fetchedInfo.length > 0 ? fetchedInfo : [],
    enableEditing: false,
    enableRowActions: false,
    localization: { ...MRT_Localization_ES },
    initialState: {
      showColumnFilters: true,
    },
    getRowId: (row) => row.formcodigo,
    muiToolbarAlertBannerProps:
      searchAttempted && !isLoadingInfo && fetchedInfo.length === 0 && !isFetchingInfo
        ? {
            color: "info",
            children: "No se encontraron Bahias",
          }
        : undefined,
    muiTableContainerProps: {
      sx: {
        minHeight: "500px",
      },
    },
    renderToolbarInternalActions: ({ table }) => <Box></Box>,

    state: {
      isLoading: isLoadingInfo,
      showAlertBanner: isLoadingInfoError,
      showProgressBars: isFetchingInfo,
    },
  })

  return (
    <>
      <MaterialReactTable table={table} />
    </>
  )
}

const GridBahia = ({ refetch, fetchedInfo, isLoadingInfo, isError, isFetchingInfo, setBahias, deleteFunction }) => {
  const theme = useTheme()

  // Maneja cambios en los filtros de la grilla

  return (
    <div>
      <ThemeProvider theme={createTheme(theme, esES)}>
        <CustomTable
          fetchedInfo={fetchedInfo}
          isLoadingInfoError={isError}
          isLoadingInfo={isLoadingInfo}
          isFetchingInfo={isFetchingInfo}
          refetch={refetch}
          setBahias={setBahias}
          deleteFunction={deleteFunction}
        />
      </ThemeProvider>
    </div>
  )
}

export default GridBahia
