import { useMemo, useState } from "react"
import {
  MaterialReactTable,
  // createRow,
  useMaterialReactTable,
} from "material-react-table"
import DetalleImpresion from "../../Zonas/components/DetalleImpresion"
import CustomBackdrop from "../../../components/CustomBackdrop"
import { MRT_Localization_ES } from "material-react-table/locales/es"
import { createTheme, ThemeProvider, useTheme, Box, Button, IconButton, Tooltip } from "@mui/material"
import { esES } from "@mui/material/locale"
import ImprimirIcon from "../../../assets/iconos/Impresion.ico"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import EditIcon from "@mui/icons-material/Edit"
import EliminarIcon from "../../../assets/iconos/Eliminar.ico"
import DetalleIcon from "../../../assets/iconos/Detalle.ico"
import DeleteIcon from "@mui/icons-material/Delete"
import fetchwrapper from "../../../services/interceptors/fetchwrapper"
import { useNavigate } from "react-router-dom"
import CrearIcon from "../../../assets/iconos/Crear.ico"
import NivelesPdf from "./NivelesPdf"
import normalFormatDate from "../../utils/date/DDMMYYYFormatDate"
import normalFormatHour from "../../utils/date/HHMMSSFormatHour"

const CustomTable = () => {
  // para movernos a crear y editar
  const navigate = useNavigate()
  const columns = useMemo(
    () => [
      {
        accessorKey: "nivcodigo",
        header: "Código",
        enableEditing: false,
        size: 80,
      },
      {
        accessorKey: "nivdescripcion",
        header: "Descripción",
        enableEditing: false,
        size: 80,
      },
      {
        accessorKey: "nivstatus",
        header: "Estado",
        enableEditing: false,
        size: 80,
        Cell: ({ cell }) => {
          let cellValue = cell.getValue()
          if (cellValue === "A") {
            cellValue = "ACTIVO"
          } else {
            cellValue = "INACTIVO"
          }
          return cellValue
        },
      },
      {
        accessorKey: "nivusuisys",
        header: "Usuario Creacion",
        enableEditing: false,
        size: 80,
      },
      {
        accessorKey: "nivfecisys",
        header: "Fecha de Creación",
        enableEditing: false,
        size: 80,
        Cell: ({ cell }) => {
          const cellValue = cell.getValue()
          return normalFormatDate(cellValue)
        },
      },
      {
        accessorKey: "nivhorisys",
        header: "Hora de Creación",
        enableEditing: false,
        size: 80,
        Cell: ({ cell }) => {
          const cellValue = cell.getValue()
          return new Date(cellValue).toLocaleTimeString()
        },
      },
      {
        accessorKey: "nivusumsys",
        header: "Usuario MOdifica",
        enableEditing: false,
        size: 80,
      },
      {
        accessorKey: "nivfecmsys",
        header: "Fecha Modifica",
        enableEditing: false,
        size: 80,
        Cell: ({ cell }) => {
          const cellValue = cell.getValue()
          return normalFormatDate(cellValue)
        },
      },
      {
        accessorKey: "nivhormsys",
        header: "Hora Modifica",
        enableEditing: false,
        size: 80,
        Cell: ({ cell }) => {
          const cellValue = cell.getValue()
          return new Date(cellValue).toLocaleTimeString()
        },
      },
    ],
    [],
  )

  const { mutateAsync: createNiveles, isPending: isCreatingNiveles } = useCreateNiveles()

  const {
    data: fetchedNiveles = [],
    isError: isLoadingNivelesError,
    isFetching: isFetchingNiveles,
    isLoading: isLoadingNiveles,
  } = useGetNiveles()

  const { mutateAsync: updateNivel, isPending: isUpdatingNivel } = useUpdateNiveles()

  const { mutateAsync: deleteNivel, isPending: isDeletingNivel } = useDeleteNivel()

  const openDeleteConfirmModal = async (row) => {
    if (window.confirm("¿Está seguro que quiere eliminar esta nivel?")) {
      await deleteNivel(row.original.nivcodigo)
    }
  }
  const table = useMaterialReactTable({
    columns,
    data: fetchedNiveles,
    enableEditing: true,
    initialState: {
      showColumnFilters: true,
    },
    enableRowActions: true,
    localization: { ...MRT_Localization_ES },
    getRowId: (row) => row.nivcodigo,
    // si se cargar el error al importar los datos
    muiToolbarAlertBannerProps: isLoadingNivelesError
      ? {
          color: "error",
          children: "Error en cargar los niveles",
        }
      : undefined,
    muiTableContainerProps: {
      sx: {
        minHeight: "500px",
      },
    },
    renderRowActions: ({ row, table }) => (
      <Box sx={{ display: "flex", gap: "1rem" }}>
        <Tooltip title="Editar">
          <IconButton
            onClick={() => {
              navigate(`editar/${row?.original.nivcodigo}`, {
                state: row?.original,
              })
            }}
          >
            <img src={DetalleIcon} alt="Editar" style={{ width: "20px" }} />
          </IconButton>
        </Tooltip>
        <Tooltip title="Eliminar">
          <IconButton color="error" onClick={() => openDeleteConfirmModal(row)}>
            <img src={EliminarIcon} alt="Grabar" style={{ width: "20px" }} />
          </IconButton>
        </Tooltip>
      </Box>
    ),
    state: {
      isLoading: isLoadingNiveles,
      isSaving: isCreatingNiveles || isUpdatingNivel || isDeletingNivel,
      showAlertBanner: isLoadingNiveles,
      showProgressBars: isFetchingNiveles,
    },
  })
  return (
    <>
      <MaterialReactTable table={table} />
      <CustomBackdrop isLoading={table.getState().isSaving} />
    </>
  )
}
function useGetNiveles() {
  return useQuery({
    queryKey: ["Niveles"],
    queryFn: async () => {
      // send api request here
      let response = await fetchwrapper("/Niveles/getNiveles")
      response = await response.json()
      response = response?.data
      // await new Promise((resolve) => setTimeout(resolve, 1000)); //fake api call
      return response
    },
    refetchOnWindowFocus: false,
  })
}

// hooks a los que se les asigna una api y se encargan de actualizar el estado del componente
function useCreateNiveles() {
  // hook para crear la zona
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (nivel) => {
      await new Promise((resolve) => setTimeout(resolve, 1000)) // fake api call
      return Promise.resolve()
    },
    onMutate: (newNivel) => {
      queryClient.setQueryData(["Niveles"], (prevNiveles) =>
        prevNiveles?.map((prevNiveles) => (prevNiveles.nivcodigo === newNivel.nivcodigo ? newNivel : prevNiveles)),
      )
    },
  })
}
function useUpdateNiveles() {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (nivel) => {
      // send api update request here
      await new Promise((resolve) => setTimeout(resolve, 1000)) // fake api call
      return Promise.resolve()
    },
    // client side optimistic update
    onMutate: (newNivelInfo) => {
      queryClient.setQueryData(["Niveles"], (prevNiveles) =>
        prevNiveles?.map((prevNiveles) =>
          prevNiveles.nivcodigo === newNivelInfo.nivcodigo ? newNivelInfo : prevNiveles,
        ),
      )
    },
  })
}
function useDeleteNivel() {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (codigoNivel) => {
      const options = {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }

      const response = await fetchwrapper(`/Niveles/deleteNivel/${codigoNivel}`, options)
      return response
    },
    // actualizacion optimista
    onMutate: (codigoNivel) => {
      queryClient.setQueryData(["Niveles"], (prevNiveles) =>
        prevNiveles?.filter((nivel) => nivel.nivcodigo !== codigoNivel),
      )
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ["Niveles"] })
    },
    onSuccess: () => {
      alert("Nivel eliminado con exito")
    },
    onError: () => {
      alert("No se pudo eliminar el nivel")
    },
  })
}

const DataGridNiveles = () => {
  const [openModal, setOpenModal] = useState(false)
  const [infoGenerada, setInfoGenerada] = useState([])
  const {
    data: fetchedNiveles = [],
    isError: isLoadingNivelesError,
    isFetching: isFetchingNiveles,
    isLoading: isLoadingNiveles,
  } = useGetNiveles()
  const handleOpenModal = () => {
    setInfoGenerada(fetchedNiveles) // Asigna los datos de la tabla al estado
    setOpenModal(true)
  }
  console.log("info pdf", fetchedNiveles)
  const handleCloseModal = () => {
    setOpenModal(false)
    navigate("/home/dashboard/Niveles")
  }
  const theme = useTheme()
  const navigate = useNavigate()
  return (
    <div>
      <Tooltip title="Crear Nivel">
        <Button color="primary" onClick={() => navigate("crear")} sx={{ marginBottom: "15px" }}>
          <img src={CrearIcon} alt="Crear" style={{ width: "30px" }} />
        </Button>
      </Tooltip>
      <Tooltip title="Imprimir">
        <Button color="primary" onClick={handleOpenModal} sx={{ marginBottom: "15px" }}>
          <img src={ImprimirIcon} alt="Imprimir" style={{ width: "30px" }} />
        </Button>
      </Tooltip>
      <DetalleImpresion
        open={openModal}
        handleClose={handleCloseModal}
        infoGenerada={fetchedNiveles}
        nombrePdf={"Niveles"}
        PdfComponent={NivelesPdf}
      />
      <ThemeProvider theme={createTheme(theme, esES)}>
        <CustomTable />
      </ThemeProvider>
    </div>
  )
}
export default DataGridNiveles
