import React, { useEffect, useState, useMemo } from "react"
import { MaterialReactTable, useMaterialReactTable } from "material-react-table"
import {
  TextField,
  createTheme,
  ThemeProvider,
  useTheme,
  Box,
  IconButton,
  Tooltip,
  TablePagination,
} from "@mui/material"

import Swal from "sweetalert2"
import { styled } from "@mui/material/styles"
import { esES } from "@mui/material/locale"
import { MRT_Localization_ES } from "material-react-table/locales/es"
import fetchwrapper from "../../../services/interceptors/fetchwrapper"
import EditIcon from "@mui/icons-material/Edit"
import { useNavigate } from "react-router-dom"
import EliminarIcon from "../../../assets/iconos/Eliminar.ico"
import DetalleIcon from "../../../assets/iconos/Detalle.ico"
import normalFormatDate from "../../utils/date/DDMMYYYFormatDate"
import normalFormatHour from "../../utils/date/HHMMSSFormatHour"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import CustomBackdrop from "../../../components/CustomBackdrop"
const CustomTable = ({
  fetchedInfo = [],
  isLoadingInfoError,
  isLoadingInfo,
  isFetchingInfo,
  setBodegas,
  refetch,
  selectedBods,
  setSelectedBods,
  bodegasAnteriores,
  setBodegasAnteriores,
}) => {
  // Preselect rows based on 'bodstatus' property value
  useEffect(() => {
    if (fetchedInfo.length > 0) {
      // Construye el estado inicial de selección basado en la propiedad `clicodigo`
      const initialSelection = {}
      const initialSelectedRows = []

      fetchedInfo.forEach((row) => {
        if (row.clicodigo !== null) {
          initialSelection[row.bodcodigo] = true
          initialSelectedRows.push(row) // Marca el índice de la fila como seleccionado
        }
      })
      setRowSelection(initialSelection)
      setSelectedBods(initialSelectedRows)
      setBodegasAnteriores(initialSelectedRows)
    }
  }, [fetchedInfo, setSelectedBods, setBodegasAnteriores])
  const navigate = useNavigate()
  // Maneja cambios en los filtros de la grilla

  const columns = useMemo(
    () => [
      {
        accessorKey: "acciones",
        header: "Acciones",
        enableColumnFilter: false,
        size: 150,
      },
      {
        accessorKey: "invcodigo",
        header: "Inv",
        enableEditing: false,
        size: 120,
      },

      {
        accessorKey: "bodcodigo",
        header: "Codigo Bodega",
        enableEditing: false,
        size: 120,
      },
      {
        accessorKey: "boddescri",
        header: "Descripcion",
        enableEditing: false,
        size: 120,
      },
      {
        accessorKey: "loccodigo",
        header: "Localidad",
        enableEditing: false,
        size: 120,
      },

      {
        accessorKey: "bodstatus",
        header: "Estado",
        enableEditing: false,
        size: 120,
        Cell: ({ cell }) => {
          let cellValue = cell.getValue()
          if (cellValue === "A") {
            cellValue = "ACTIVO"
          } else {
            cellValue = "INACTIVO"
          }
          return cellValue
        },
      },
    ],
    [navigate],
  )

  const [rowSelection, setRowSelection] = useState({})
  const table = useMaterialReactTable({
    columns,
    data: fetchedInfo.length > 0 ? fetchedInfo : [],
    enableEditing: false,
    enableRowSelection: true,
    enableRowActions: false,
    onRowSelectionChange: setRowSelection,

    localization: { ...MRT_Localization_ES },
    initialState: {
      showColumnFilters: true,
    },
    getRowId: (row) => `${row.bodcodigo}`,
    muiToolbarAlertBannerProps:
      // searchAttempted &&
      !isLoadingInfo && fetchedInfo.length === 0 && !isFetchingInfo
        ? {
            color: "info",
            children: "No se encontraron Bodegas",
          }
        : undefined,
    muiTableContainerProps: {
      sx: {
        minHeight: "500px",
      },
    },
    renderToolbarInternalActions: ({ table }) => <Box></Box>,

    state: {
      isLoading: isLoadingInfo,
      showAlertBanner: isLoadingInfoError,
      showProgressBars: isFetchingInfo,
      rowSelection,
    },
  })

  useEffect(() => {
    const selectedRowsData = table.getSelectedRowModel().flatRows.map((row) => row.original)
    setSelectedBods(selectedRowsData)
  }, [rowSelection, setSelectedBods])
  useEffect(() => {
    console.log("Bodegas seleccionadas (datos originales):", selectedBods)
    console.log("Bodegas seleccionadas inicialmente:", bodegasAnteriores) // Mostrar las bodegas seleccionadas inicialmente
  }, [selectedBods, bodegasAnteriores])
  return (
    <>
      <MaterialReactTable table={table} />
    </>
  )
}

const BodegasGrid = ({
  refetch,
  fetchedInfo,
  isLoadingInfo,
  isError,
  isFetchingInfo,
  setBodegas,
  selectedBods,
  setSelectedBods,
  bodegasAnteriores,
  setBodegasAnteriores,
}) => {
  const theme = useTheme()

  // Maneja cambios en los filtros de la grilla

  return (
    <div>
      <ThemeProvider theme={createTheme(theme, esES)}>
        <CustomTable
          fetchedInfo={fetchedInfo}
          isLoadingInfoError={isError}
          isLoadingInfo={isLoadingInfo}
          isFetchingInfo={isFetchingInfo}
          refetch={refetch}
          setBodegas={setBodegas}
          selectedBods={selectedBods}
          setSelectedBods={setSelectedBods}
          bodegasAnteriores={bodegasAnteriores} // Pasar `bodegasAnteriores` al componente hijo
          setBodegasAnteriores={setBodegasAnteriores}
        />
      </ThemeProvider>
    </div>
  )
}

export default BodegasGrid
