import React, { useEffect, useState } from "react"
import fetchwrapper from "../../../services/interceptors/fetchwrapper"
import LoadingComponent from "../../../components/loadingComponent"
import { Grid } from "@mui/material"

const GalleryImages = ({ producto }) => {
  const { artcodigo, invcodigo } = producto
  const [allImages, setAllImages] = useState([])
  const [isLoadingImages, setIsLoadingImages] = useState(false)

  const getAllImagesOfProduct = async () => {
    try {
      setIsLoadingImages(true)
      const options = {
        method: "POST",
        body: JSON.stringify({
          artcodigo,
          invcodigo,
        }),
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
      let response = await fetchwrapper("/paletizacion/buscar/getImagesxArtcodigo", options)
      response = await response.json()

      setAllImages(response.data)
    } catch (err) {
      alert("Error en encontrar imágenes")
    } finally {
      setIsLoadingImages(false)
    }
  }

  useEffect(() => {
    getAllImagesOfProduct()
  }, [])
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          margin: "50px 30px 50px 30px",
          fontSize: "25px",
        }}
      >
        <b>Galería de imágenes</b>
      </div>
      {isLoadingImages ? (
        <LoadingComponent />
      ) : allImages.length > 0 ? (
        <Grid
          container
          spacing={1}
          style={{
            maxWidth: "1200px",
            margin: "auto",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {allImages.map((image, index) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
              <img src={`data:image/jpeg;base64,${image.artimagen}`} alt={image.alt} style={{ width: "100%" }} />
            </Grid>
          ))}
        </Grid>
      ) : (
        <p style={{ textAlign: "center" }}>No existen imágenes para este árticulo</p>
      )}
    </>
  )
}

export default GalleryImages
