import { useEffect, useState } from "react"
import { styled } from "@mui/material/styles"
import { useNavigate, useLocation } from "react-router-dom" // Usa hooks de react-router-dom
import BackIco from "../../../assets/iconos/Regresar.ico"

// STYLES
const StyledBackIcon = styled("div")(({ theme }) => ({
  marginTop: "25px",
  marginBottom: "25px",
  alignItems: "center",
  cursor: "pointer",
}))

const CustomBackIcon = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const goBack = () => {
    navigate(-1)
  }

  useEffect(() => {
    return () => {
      const urlRestante = window.location.pathname
      if (urlRestante === "/home") {
        document.body.classList.add("sidebar-show")
      }
    }
  }, [location.pathname]) // Ejecuta el efecto solo cuando cambie la ruta

  return (
    <StyledBackIcon onClick={goBack}>
      <img src={BackIco} style={{ width: "40px", marginRight: "8px" }} alt="regresar" />
      Regresar
    </StyledBackIcon>
  )
}

export default CustomBackIcon
