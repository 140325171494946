// eslint-disable-next-line no-unused-vars
import { useEffect, useState } from "react"
import Accordion from "@mui/material/Accordion"
import AccordionSummary from "@mui/material/AccordionSummary"
import AccordionDetails from "@mui/material/AccordionDetails"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import { TextField, FormControl, Select, MenuItem } from "@mui/material"
import { styled } from "@mui/system"
import normalFormatDate from "../../utils/date/DDMMYYYFormatDate"

const DisabledTextField = ({ label = "", value }) => {
  return (
    <span style={{ flexGrow: 1 }}>
      <TextField fullWidth id={`outlined`} variant="standard" label={label} value={value ?? ""} disabled />
    </span>
  )
}
const CustomSelect = styled(Select)(({ theme }) => ({
  "& .MuiSelect-selectMenu": {
    minWidth: 80,
    overflowX: "scroll",
  },
}))

const CustomAccordion = ({ product }) => {
  const styles = {
    accordionSummary: {
      backgroundColor: "rgb(25, 108, 135)",
      color: "white",
      fontWeight: "bolder",
    },
    row: {
      display: "flex",
      // justifyContent: "space-between",
      alignItems: "center",
      paddingBlock: "10px",
    },
    rowTitle: {
      alignSelf: "end",
      paddingRight: "20px",
      fontWeight: "bolder",
    },
  }

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`panel-${product.artcodigo}`}
        id={product.artcodigo}
        style={styles.accordionSummary}
      >
        {`${product.artcodigo} - ${product.artdescri}`}
      </AccordionSummary>
      <AccordionDetails>
        <div style={styles.row}>
          <span style={styles.rowTitle}>Código Solicitud:</span>
          <DisabledTextField value={product.sgasoling} />
        </div>
        {/* EMBALAJES */}
        <div style={styles.row}>
          <span style={styles.rowTitle}>Código Embalaje:</span>
          <DisabledTextField value={product.embcodigo} />
        </div>

        <div style={styles.row}>
          <span style={styles.rowTitle}>Descripción Embalaje:</span>
          <DisabledTextField value={product.embdescripcion} />
        </div>

        <div style={styles.row}>
          <span style={styles.rowTitle}>Metros Cúbicos Embalaje:</span>
          <DisabledTextField value={product.embmetros3} />
        </div>

        <div style={styles.row}>
          <span style={styles.rowTitle}>Conversión:</span>
          <DisabledTextField value={product.embpieza} />
        </div>

        <div style={styles.row}>
          <span style={styles.rowTitle}>Metros Cuadrados:</span>
          <DisabledTextField value={product.embmetros2} />
        </div>

        {/* TONOS */}
        <div style={styles.row}>
          <span style={styles.rowTitle}>Código Tono:</span>
          <DisabledTextField value={product.toncodigo} />
        </div>

        <div style={styles.row}>
          <span style={styles.rowTitle}>Descripción Tono:</span>
          <DisabledTextField value={product.tondescripcion} />
        </div>

        <div style={styles.row}>
          <span style={styles.rowTitle}>Cantidad Recibida:</span>
          <DisabledTextField value={Number(product.cantidadEmbalada)} />
        </div>

        <div style={styles.row}>
          <span style={styles.rowTitle}>Series/Lote Registrados:</span>
          <FormControl fullWidth variant="standard" sx={{ m: 1, minWidth: 80, overflowX: "scroll" }}>
            <CustomSelect
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              MenuProps={{
                PaperProps: { style: { maxHeight: 200, overflowX: "scroll" } },
              }}
            >
              {product.lotes.map((lote) => (
                // eslint-disable-next-line react/jsx-key
                <MenuItem value="">{`${lote.codigoLote} Cant:${Number(lote.cantidadLote)} Elab:${normalFormatDate(
                  lote.fechaElaboracionLote,
                )} Vence:${normalFormatDate(lote.fechaVencimientoLote)}`}</MenuItem>
              ))}
            </CustomSelect>
          </FormControl>
        </div>
      </AccordionDetails>
    </Accordion>
  )
}

const ProductsReadable = ({ products = [] }) => {
  return (
    <div>
      {products.map((product) => (
        <CustomAccordion key={product.artcodigo} product={product} />
      ))}
    </div>
  )
}

export default ProductsReadable
