import React from "react"
import { Box } from "@mui/material"
import { DataGrid } from "@mui/x-data-grid"
import { styled } from "@mui/material/styles"

import math from "../../utils/math"
/* *****************************************************
                          STYLES
****************************************************** */

const StyledRoot = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  marginTop: theme.spacing(8),
  flexGrow: 1,
  padding: "0 16px",
}))
const StyledDataGridContainer = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  overflowX: "auto",
}))

export default function ReadableTableDetalleProforma({ detallesProforma }) {
  // *****************************************************
  //                       COLUMNS
  // ******************************************************

  // Definir las columnas personalizadas de la tabla
  const columnas = [
    {
      headerName: "Código Item",
      field: "codigo",
      sort: "asc",
      width: 150,
    },

    {
      headerName: "Descripción",
      field: "descripcion",
      sort: "asc",
      width: 350,
    },
    {
      headerName: "Cantidad",
      field: "totalToBuy",
      sort: "asc",
      width: 250,
      type: "number",
      editable: true,
      valueFormatter: (params) => {
        return `${math.round(params.value, 0)}`
      },
      // renderCell: (params) => {
      //   return <div>{truncateNumber(params.value)}</div>;
      // },
    },
    {
      headerName: "Precio de venta",
      field: "price",
      sort: "asc",
      width: 250,
      renderCell: (params) => {
        return <div>${math.round(params.value, 2)}</div>
      },
    },
    {
      headerName: "IVA",
      field: "sysiva",
      sort: "asc",
      width: 250,
      renderCell: (params) => {
        return <div>{math.round(params.value, 0)}%</div>
      },
    },
    // {
    //   headerName: "Descuento",
    //   field: "pedvaldesc",
    //   sort: "asc",
    //   width: 250,
    //   renderCell: (params) => {
    //     return <div>{truncateNumber(params.value)}</div>;
    //   },
    // },
    {
      headerName: "Valor",
      field: "totalPrice",
      sort: "asc",
      width: 250,
      renderCell: (params) => {
        return <div>${math.round(params.value, 2)}</div>
      },
    },

    {
      headerName: "Valor IVA",
      field: "totalIVA",
      sort: "asc",
      width: 250,
      renderCell: (params) => {
        return <div>${math.round(params.value, 2)}</div>
      },
    },
    {
      headerName: "Valor Total",
      field: "totalPriceWithIVA",
      sort: "asc",
      width: 250,
      renderCell: (params) => {
        return <div>${math.round(params.value, 2)}</div>
      },
    },
  ]

  return (
    <Box className={StyledRoot}>
      <Box className={StyledDataGridContainer} sx={{ width: "100%" }}>
        <DataGrid
          rows={detallesProforma}
          columns={columnas}
          getRowId={(detalle) => detalle.codigo}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          pageSizeOptions={[5, 10, 25]}
          localeText={{
            noRowsLabel: "Dale click a agregar para añadir productos a la proforma",
          }}
          disableSelectionOnClick
        />
      </Box>
    </Box>
  )
}
