import { useMemo, useState } from "react"
import {
  MaterialReactTable,
  // createRow,
  useMaterialReactTable,
} from "material-react-table"
import DetalleImpresion from "../../Zonas/components/DetalleImpresion"
import CustomBackdrop from "../../../components/CustomBackdrop"
import { MRT_Localization_ES } from "material-react-table/locales/es"
import { createTheme, ThemeProvider, useTheme, Box, Button, IconButton, Tooltip } from "@mui/material"
import { esES } from "@mui/material/locale"
import ImprimirIcon from "../../../assets/iconos/Impresion.ico"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import EditIcon from "@mui/icons-material/Edit"
import EliminarIcon from "../../../assets/iconos/Eliminar.ico"
import DetalleIcon from "../../../assets/iconos/Detalle.ico"
import DeleteIcon from "@mui/icons-material/Delete"
import fetchwrapper from "../../../services/interceptors/fetchwrapper"
import { useNavigate } from "react-router-dom"
import CrearIcon from "../../../assets/iconos/Crear.ico"
import EmpaquesPdf from "./EmpaquesPdf"
import normalFormatDate from "../../utils/date/DDMMYYYFormatDate"
import normalFormatHour from "../../utils/date/HHMMSSFormatHour"

const CustomTable = () => {
  // para movernos a crear y editar
  const navigate = useNavigate()
  const columns = useMemo(
    () => [
      {
        accessorKey: "ciacodigo",
        header: "Código CIA",
        enableEditing: false,
        size: 80,
      },
      {
        accessorKey: "empaquetipo",
        header: "Descripción",
        enableEditing: false,
        size: 80,
      },
      {
        accessorKey: "empaqueusuisys",
        header: "Usuario Creacion",
        enableEditing: false,
        size: 80,
      },
      {
        accessorKey: "empaquefecisys",
        header: "Fecha de Creación",
        enableEditing: false,
        size: 80,
        Cell: ({ cell }) => {
          const cellValue = cell.getValue()
          return normalFormatDate(cellValue)
        },
      },
      {
        accessorKey: "empaquehorisys",
        header: "Hora de Creación",
        enableEditing: false,
        size: 80,
        Cell: ({ cell }) => {
          const cellValue = cell.getValue()
          return new Date(cellValue).toLocaleTimeString()
        },
      },
      {
        accessorKey: "empaqueusumsys",
        header: "Usuario Modifica",
        enableEditing: false,
        size: 80,
      },
      {
        accessorKey: "empaquefecmsys",
        header: "Fecha Modifica",
        enableEditing: false,
        size: 80,
        Cell: ({ cell }) => {
          const cellValue = cell.getValue()
          return normalFormatDate(cellValue)
        },
      },
      {
        accessorKey: "empaquehormsys",
        header: "Hora Modifica",
        enableEditing: false,
        size: 80,
        Cell: ({ cell }) => {
          const cellValue = cell.getValue()
          return new Date(cellValue).toLocaleTimeString()
        },
      },
    ],
    [],
  )
  const { mutateAsync: createEmpaque, isPending: isCreatingEmpaque } = useCreateEmpaque()

  const {
    data: fetchedEmpaques = [],
    isError: isLoadingEmpaquesError,
    isFetching: isFetchingEmpaques,
    isLoading: isLoadingEmpaques,
  } = useGetEmpaques()
  // useUpdateZona() hook de update, ,updateZona funcion que se ejecuta
  // useDeleteZona() hook de delete , usa la funcion deleteZona()
  const { mutateAsync: deleteEmpaque, isPending: isDeletingEmpaque } = useDeleteEmpaque()
  // funcion que muestra el modal de eliminacion
  const openDeleteConfirmModal = async (row) => {
    if (window.confirm("¿Está seguro que quiere eliminar esta empaque?")) {
      await deleteEmpaque(row.original.empaquetipo)
    }
  }
  const table = useMaterialReactTable({
    columns,
    data: fetchedEmpaques,
    enableEditing: true,
    initialState: {
      showColumnFilters: true,
    },
    enableRowActions: true,
    localization: { ...MRT_Localization_ES },
    getRowId: (row) => row.empaquetipo,
    // si se cargar el error al importar los datos
    muiToolbarAlertBannerProps: isLoadingEmpaquesError
      ? {
          color: "error",
          children: "Error en cargar los empaques",
        }
      : undefined,
    muiTableContainerProps: {
      sx: {
        minHeight: "500px",
      },
    },
    renderRowActions: ({ row, table }) => (
      <Box sx={{ display: "flex", gap: "1rem" }}>
        <Tooltip title="Detalle">
          <IconButton
            onClick={() => {
              navigate(`detalle/${row?.original.empaquetipo}`, {
                state: row?.original,
              })
            }}
          >
            <img src={DetalleIcon} alt="Detalle" style={{ width: "20px" }} />
          </IconButton>
        </Tooltip>
        <Tooltip title="Eliminar">
          <IconButton color="error" onClick={() => openDeleteConfirmModal(row)}>
            <img src={EliminarIcon} alt="Grabar" style={{ width: "20px" }} />
          </IconButton>
        </Tooltip>
      </Box>
    ),
    state: {
      isLoading: isLoadingEmpaques,
      isSaving: isCreatingEmpaque || isDeletingEmpaque,
      showAlertBanner: isLoadingEmpaques,
      showProgressBars: isFetchingEmpaques,
    },
  })
  return (
    <>
      <MaterialReactTable table={table} />
      <CustomBackdrop isLoading={table.getState().isSaving} />
    </>
  )
}
function useGetEmpaques() {
  return useQuery({
    queryKey: ["Empaques"],
    queryFn: async () => {
      // send api request here
      let response = await fetchwrapper("/Empaques/getEmpaques")
      response = await response.json()
      response = response?.data
      // await new Promise((resolve) => setTimeout(resolve, 1000)); //fake api call
      return response
    },
    refetchOnWindowFocus: false,
  })
}

// hooks a los que se les asigna una api y se encargan de actualizar el estado del componente
function useCreateEmpaque() {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (empaque) => {
      await new Promise((resolve) => setTimeout(resolve, 1000)) // fake api call
      return Promise.resolve()
    },
    onMutate: (newEmpaque) => {
      queryClient.setQueryData(["Empaques"], (prevEmpaques) =>
        prevEmpaques?.map((prevEmpaques) =>
          prevEmpaques.embcodigo === newEmpaque.empaquetipo ? newEmpaque : prevEmpaques,
        ),
      )
    },
  })
}

function useDeleteEmpaque() {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (codigoEmpaque) => {
      const options = {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }

      const response = await fetchwrapper(`/Empaques/deleteEmpaque/${codigoEmpaque}`, options)
      return response
    },
    // actualizacion optimista
    onMutate: (codigoEmpaque) => {
      queryClient.setQueryData(["Empaques"], (prevEmpaques) =>
        prevEmpaques?.filter((empaque) => empaque.empaquetipo !== codigoEmpaque),
      )
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ["Empaques"] })
    },
    onSuccess: () => {
      alert("Empaque eliminado con exito")
    },
    onError: () => {
      alert("No se pudo eliminar el empaque")
    },
  })
}

const DataGridEmpaques = () => {
  const [openModal, setOpenModal] = useState(false)
  const [infoGenerada, setInfoGenerada] = useState([])
  const {
    data: fetchedEmpaques = [],
    isError: isLoadingEmpaquesError,
    isFetching: isFetchingEmpaques,
    isLoading: isLoadingEmpaques,
  } = useGetEmpaques()
  const handleOpenModal = () => {
    setInfoGenerada(fetchedEmpaques) // Asigna los datos de la tabla al estado
    setOpenModal(true)
  }
  console.log("info pdf", fetchedEmpaques)
  const handleCloseModal = () => {
    setOpenModal(false)
    navigate("/home/dashboard/Empaques")
  }
  const theme = useTheme()
  const navigate = useNavigate()
  return (
    <div>
      <Tooltip title="Crear Empaque">
        <Button color="primary" onClick={() => navigate("crear")} sx={{ marginBottom: "15px" }}>
          <img src={CrearIcon} alt="Crear" style={{ width: "30px" }} />
        </Button>
      </Tooltip>
      <Tooltip title="Imprimir">
        <Button color="primary" onClick={handleOpenModal} sx={{ marginBottom: "15px" }}>
          <img src={ImprimirIcon} alt="Imprimir" style={{ width: "30px" }} />
        </Button>
      </Tooltip>
      <DetalleImpresion
        open={openModal}
        handleClose={handleCloseModal}
        infoGenerada={fetchedEmpaques}
        nombrePdf={"Empaques"}
        PdfComponent={EmpaquesPdf}
      />
      <ThemeProvider theme={createTheme(theme, esES)}>
        <CustomTable />
      </ThemeProvider>
    </div>
  )
}
export default DataGridEmpaques
