import React, { useEffect, useState } from "react"
import dayjs from "dayjs"
import "dayjs/locale/es"
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@mui/material"

const TableLine = ({ selectedDate, infoLine, formattedCurrency }) => {
  // Obtener el mes y el año de la fecha seleccionada
  const month = selectedDate.month() // 0 = enero, 1 = febrero, ..., 11 = diciembre
  const year = selectedDate.year()

  // Función para generar los días del mes
  const getDaysInMonth = (month, year) => {
    const daysInMonth = dayjs(`${year}-${month + 1}`).daysInMonth() // daysInMonth devuelve el número de días del mes
    return Array.from({ length: daysInMonth }, (_, i) => dayjs(`${year}-${month + 1}-${i + 1}`))
  }

  // Días del mes
  const days = getDaysInMonth(month, year)

  useEffect(() => {
    console.log(infoLine, "aquiippp")
  }, [infoLine])

  return (
    <TableContainer component={Paper} sx={{ maxHeight: 360, overflowY: "scroll" }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <strong>Día</strong>
            </TableCell>
            <TableCell>
              <strong>Venta</strong>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {days
            .map((day, index) => ({
              day,
              sales: infoLine[index],
            }))
            .filter(({ sales }) => sales > 0) // Filtrar solo ventas mayores a 0
            .map(({ day }, index) => (
              <TableRow key={index}>
                <TableCell>{day.locale("es").format("dddd D [de] MMMM")}</TableCell>
                <TableCell>{formattedCurrency.format(infoLine[days.indexOf(day)])}</TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default TableLine
