/* eslint-disable no-unused-vars */
import React, { useState, useRef, useEffect } from "react"
import Header from "../../layouts/Header"
import { styled, createTheme, ThemeProvider } from "@mui/material/styles"
import {
  Box,
  Button,
  Grid,
  Typography,
  Divider,
  FormControlLabel,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
} from "@mui/material"

import BackIcon from "../../components/BackIcon"
import BuscarIcon from "../../assets/iconos/Buscar.ico"

import Pie from "./components/Pie"
import Line from "./components/Line"
import CustomDatePicker from "../../components/CustomDatePicker"
import dayjs from "dayjs"
import "dayjs/locale/es"
import CustomTextFieldReadable from "../../components/CustomTextFieldReadable"
import CustomTooltip from "../../components/ToolTip"
import { useQuery } from "@tanstack/react-query"
import fetchwrapper from "../../services/interceptors/fetchwrapper"
import CustomBackdrop from "../../components/CustomBackdrop"
import VisibilityTwoToneIcon from "@mui/icons-material/VisibilityTwoTone"
import TableLine from "./components/TableLine"
const StyledRoot = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  marginTop: theme.spacing(8),
  flexGrow: 1,
  padding: "0 16px",
  height: "100vh",
}))

const theme = createTheme({
  palette: {
    primary: {
      main: "#196C87",
    },
    secondary: {
      main: "#196C87", // Cambia el color secundario a verde azulado
    },
  },
})

const formattedCurrency = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
})

const convertDataToMultiSelectData = (data) => {
  const result = data.map((d, index) => ({
    id: index,
    label: d?.locdescri,
    value: d?.loccodigo,
  }))

  return result
}
const ContainerFiltros = styled(Box)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "repeat(12, 1fr)",
  gridTemplateRows: "auto auto auto",
  gridTemplateAreas: `
        "IAllDias IAllDias IAllDias IDia IDia IDia IMes IMes IMes IAno IAno IAno"       
      `,
  gap: "8px",
  alignItems: "center",
}))

const IAllDias = styled(Box)({
  gridArea: "IAllDias",
})

const IDia = styled(Box)({
  gridArea: "IDia",
})
const IMes = styled(Box)({
  gridArea: "IMes",
})
const IAno = styled(Box)({
  gridArea: "IAno",
})

const ContainerPie = styled(Box)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "repeat(12, 1fr)",
  gridTemplateRows: "auto auto auto",
  gridTemplateAreas: `
        "ITable ITable ITable ITable IChart IChart IChart IChart IChart IChart IChart IChart"       
      `,
  gap: "2px",
  overflowX: "auto",
  width: "100%",
  whiteSpace: "nowrap", // Asegúrate de que el contenido no se envuelva

  [theme.breakpoints.down("md")]: {
    gridTemplateColumns: "repeat(8, 1fr)",
    gridTemplateAreas: `
      "IChart IChart IChart IChart IChart IChart IChart IChart"
      "ITable ITable ITable ITable ITable ITable ITable ITable"
    `,
  },
}))

const ITable = styled(Box)({
  gridArea: "ITable",
})
const IChart = styled(Box)({
  gridArea: "IChart",
})

const VtasDiasLocalidad = () => {
  const [filtros, setFiltros] = useState({
    fecha: null,
    day: "",
    month: "",
    year: "",
    isAllDaysSelected: false,
    fechaFiltered: null, // Las variables que terminan en filtered son aquellas que se van a actualizar cuando ya se haya hecho la busqueda del fitlro por fecha
    dayFiltered: "",
    monthFiltered: "",
    yearFiltered: "",
    isAllDaysSelectedWhenFiltered: false,
  })
  const handleSetFiltros = (k, v) => setFiltros((prev) => ({ ...prev, [k]: v }))

  const [pieData, setPieData] = useState([])

  const [allLocalidades, setAllLocalidades] = useState([]) // Al cargar la pagina por primera vez se cargaran todas las localidades
  const [selectedLocalidades, setSelectedLocalidades] = useState([]) // Se guardara el localidad seleccionada cuando se filtran por todos los dias

  const [lineData, setLineData] = useState([])
  const [isLoadingLine, setIsLoadingLine] = useState(false)

  const {
    data: fetchedPieData = [],
    isError: isLoadingPieDataError,
    isFetching: isFetchingPieData,
    isLoading: isLoadingPieData,
    error: isFetchingPieDataError,
    refetch: refetchPieData,
    isFetched: isFetchedPieData,
  } = useGetPieData()

  function useGetPieData() {
    return useQuery({
      queryKey: ["PieDataVtasDiasLocalidad"],
      queryFn: async () => {
        const options = {
          method: "POST",
          body: JSON.stringify({
            day: filtros?.day,
            month: filtros?.month,
            year: filtros?.year,
            isAllDaysSelected: filtros?.isAllDaysSelected,
          }),
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }
        let response = await fetchwrapper(`/facturas_ventas/getFactVentasPie`, options)
        response = await response.json()
        handleSetFiltros("isAllDaysSelectedWhenFiltered", filtros?.isAllDaysSelected)
        handleSetFiltros("fechaFiltered", filtros?.fecha)
        handleSetFiltros("dayFiltered", filtros?.day)
        handleSetFiltros("monthFiltered", filtros?.month)
        handleSetFiltros("yearFiltered", filtros?.year)

        return response?.data
      },
      refetchOnWindowFocus: false,
      enabled: false,
      staleTime: 0, // Los datos se considerarán "obsoletos" inmediatamente
      cacheTime: 0, // Los datos se eliminarán de la caché inmediatamente
    })
  }

  useEffect(() => {
    if (fetchedPieData.length > 0) {
      // Normalizar la data para el grafico
      const convertedDataToPieData = fetchedPieData.map((d, index) => ({
        id: index,
        value: d?.facvaltot,
        label: d?.locdescri,
      }))

      setPieData(convertedDataToPieData)
    }
  }, [fetchedPieData])

  useEffect(() => {
    const getAllLocalidades = async () => {
      const cliciausu = localStorage.getItem("cliciausu")
      const company = JSON.parse(localStorage.getItem("jwt"))?.seleccion

      const options = {
        method: "POST",
        body: JSON.stringify({
          user: cliciausu,
          seleccion: company,
        }),
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
      try {
        let response = await fetchwrapper("/login/get_localidad", options)
        response = await response.json()
        const multiselectData = convertDataToMultiSelectData(response)
        setAllLocalidades(multiselectData)
      } catch (e) {
        alert("No se encontraron localidades")
      }
    }

    getAllLocalidades()
  }, [])

  const handleSearching = () => {
    if (!filtros.month || !filtros.year) {
      alert("Digite una fecha")
      return
    }
    refetchPieData()
    setLineData([])
    setSelectedLocalidades([])
  }

  const handleSearchingLineData = async (selectedLocalidades) => {
    if (!filtros.monthFiltered || !filtros.yearFiltered || !selectedLocalidades) {
      return alert("Complete los campos")
    }

    if (!filtros.isAllDaysSelectedWhenFiltered) {
      return alert("No hay información disponible")
    }
    try {
      const options = {
        method: "POST",
        body: JSON.stringify({
          month: filtros?.monthFiltered,
          year: filtros?.yearFiltered,
          loccodigos: selectedLocalidades,
        }),
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
      setIsLoadingLine(true)
      let response = await fetchwrapper(`/facturas_ventas/getFacVentasBar`, options)
      response = await response.json()

      setLineData(response?.data)
    } catch (error) {
      alert("No se pudo encontrar data")
    } finally {
      setIsLoadingLine(false)
    }
  }

  // Cada vez que algo cambie en el filtro quier que se desturya la pantalla
  /// del resultado que se habia filtrado
  useEffect(() => {
    if (pieData.length > 0 || lineData > 0) {
      setPieData([])
      setLineData([])
    }
  }, [filtros])

  return (
    <ThemeProvider theme={theme}>
      <Header />
      <div className="main main-app p-3 p-lg-4">
        <BackIcon />
        <div style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
          <div style={{ paddingTop: "10px", paddingBottom: "10px" }}>
            <CustomTooltip title={"Buscar"}>
              <Button style={{ fontSize: "0.9vw" }} onClick={handleSearching}>
                <img src={BuscarIcon} alt="Crear" style={{ width: "40px" }} />
              </Button>
            </CustomTooltip>
          </div>
        </div>
        <CustomBackdrop isLoading={isLoadingPieData || isFetchingPieData || isLoadingLine} />
        <Box className={StyledRoot}>
          <CustomDatePicker
            label="Fecha"
            value={filtros.fecha}
            setValue={(v) =>
              setFiltros((prev) => {
                const diaNum = v.format("D") // Obtener el día del mes (1-31)
                const mesNum = v.format("M") // Obtener el número del mes (1-12)
                const anoNum = v.format("YYYY") // Año en número
                return {
                  ...prev,
                  fecha: v,
                  day: diaNum,
                  month: mesNum,
                  year: anoNum,
                }
              })
            }
            slotProps={{ textField: { fullWidth: true } }}
          />
          <ContainerFiltros>
            <IAllDias>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={filtros?.isAllDaysSelected}
                    onChange={(event) => handleSetFiltros("isAllDaysSelected", event.target.checked)}
                    name="checkbox"
                    color="primary"
                  />
                }
                label="Todos los días"
              />
            </IAllDias>
            <IDia>
              <CustomTextFieldReadable label="Día" value={filtros?.isAllDaysSelected ? "Todos" : filtros?.day} />
            </IDia>
            <IMes>
              <CustomTextFieldReadable label="Mes" value={filtros?.fecha?.locale("es").format("MMMM")} />
            </IMes>

            <IAno>
              <CustomTextFieldReadable label="Año" value={filtros?.year} />
            </IAno>
          </ContainerFiltros>

          <ContainerPie>
            {isFetchedPieData && pieData.length > 0 ? (
              <ITable>
                <TableContainer component={Paper} sx={{ maxHeight: 310, overflowY: "scroll" }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        {filtros.isAllDaysSelectedWhenFiltered && (
                          <TableCell>
                            <strong>Acción</strong>
                          </TableCell>
                        )}
                        <TableCell>
                          <strong>Localidad</strong>
                        </TableCell>
                        <TableCell>
                          <strong>Venta</strong>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {pieData.map((row, index) => (
                        <TableRow key={index}>
                          {filtros.isAllDaysSelectedWhenFiltered && (
                            <TableCell>
                              <IconButton
                                onClick={() => {
                                  if (allLocalidades.length > 0) {
                                    const l = allLocalidades.find((v) => v?.label === row.label)
                                    setSelectedLocalidades([l])
                                    handleSearchingLineData([l])
                                  } else {
                                    alert("Error: No se puedo obtner la información de la localidad seleccionada ")
                                  }
                                }}
                              >
                                <VisibilityTwoToneIcon />
                              </IconButton>
                            </TableCell>
                          )}
                          <TableCell>{row.label}</TableCell>
                          <TableCell>{formattedCurrency.format(row.value)}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </ITable>
            ) : null}

            <IChart>
              <Pie pieData={pieData} isFetched={isFetchedPieData} formattedCurrency={formattedCurrency} />
            </IChart>
          </ContainerPie>

          {lineData.length > 0 && filtros?.isAllDaysSelectedWhenFiltered ? (
            <>
              <Divider sx={{ backgroundColor: "#000", height: "2px", marginBlock: "50px" }} />
              <ContainerPie>
                <IChart>
                  <Line lineData={lineData} month={filtros?.month} year={filtros?.year} />
                </IChart>
                <ITable>
                  <TableLine
                    selectedDate={filtros?.fechaFiltered}
                    infoLine={lineData[0]?.data}
                    formattedCurrency={formattedCurrency}
                  />
                </ITable>
              </ContainerPie>
            </>
          ) : null}
        </Box>
      </div>
    </ThemeProvider>
  )
}

export default VtasDiasLocalidad
