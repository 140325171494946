/* eslint-disable camelcase */
import { useEffect, useState } from "react"
import {
  Autocomplete,
  TextField,
  Button,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  CircularProgress,
} from "@mui/material"
import fetchwrapper from "../../../services/interceptors/fetchwrapper"
import ImprimirIcon from "../../../assets/iconos/Impresion.ico"
import ZebraPrinter from "../../utils/zebraPrinter"
import CustomBackdrop from "../../../components/CustomBackdrop"

function InnerModalPrinter({ getProductsPrintable }) {
  const BrowserPrint = window.BrowserPrint
  const [selected_device, setSelected_device] = useState({})
  const [devices, setDevices] = useState([])
  const [textToPrint, setTextToPrint] = useState("")
  const [isLoadingPrinter, setIsLoadingPrinter] = useState(false)
  const [isPrintingLabels, setIsPrintingLabels] = useState(false)
  const [codeType, setCodeType] = useState("") // default value
  const [productsToPrint, setProductsToPrint] = useState([])
  const [IP_PRINTERS_AVAILIABLE, setIP_PRINTERS_AVAILIABLE] = useState([])
  const [loadingImprimirBtn, setLoadingImprimirBtn] = useState(false)

  const [allProcesosImpresoras, setAllProcesosImpresoras] = useState({})
  const [selectedProcesoImpresoras, setSelectedProcesoImpresoras] = useState([])
  const [selectedImpresora, setSelectedImpresora] = useState({})

  // const IP_PRINTERS_AVAILIABLE = ["192.168.1.7", ""]; //192.168.1.7

  async function setup() {
    let IP_PRINTERS_AVAILIABLE_HERE = []
    if (isLoadingPrinter) {
      return
    }
    if (IP_PRINTERS_AVAILIABLE.length === 0) {
      let printersAviliable = await fetchwrapper("/dispositivos/impresoraPerUser")
      printersAviliable = await printersAviliable.json()
      const ipsPrinters = printersAviliable.map((printer) => printer.printip)
      IP_PRINTERS_AVAILIABLE_HERE = ipsPrinters
      console.log(IP_PRINTERS_AVAILIABLE_HERE, "////////////////")

      setIP_PRINTERS_AVAILIABLE(ipsPrinters)
      setSelected_device({ name: IP_PRINTERS_AVAILIABLE_HERE[0] })
      setDevices([{ name: IP_PRINTERS_AVAILIABLE_HERE[0] }])
    } else {
      IP_PRINTERS_AVAILIABLE_HERE = IP_PRINTERS_AVAILIABLE
    }

    setIsLoadingPrinter(true)
    // setSelected_device({});
    // setDevices([]);

    // Discover any other devices available to the application
    BrowserPrint.getLocalDevices(
      function (device_list) {
        // Add new devices discovered to list if ip printer is avaliable
        console.log(device_list)
        let newDevices = []
        newDevices = device_list.filter((device) => {
          return IP_PRINTERS_AVAILIABLE_HERE.includes(device.uid.split(":")[0])
        })

        setDevices((prev) => {
          if (newDevices[0]) {
            setSelected_device(newDevices[0])
          } else {
            console.log(newDevices)
          }
          return [...prev, ...newDevices]
        })

        console.log(newDevices, "-------**")

        setIsLoadingPrinter(false)

        // console.log("Default device", selected_device);
        console.log("Devices", devices)
      },
      function () {
        alert("No se pudo conectar a una impresora local")
        setIsLoadingPrinter(false)
      },
      "printer",
    )
  }

  async function writeToSelectedPrinter(dataToWrite) {
    const ipPrinter = selectedImpresora.printip
    const printproceso = selectedImpresora.printproceso
    const lincodigo = selectedImpresora.lincodigo
    const temporalProductsToPrint = [...getProductsPrintable()]

    if (!ipPrinter && !printproceso && !lincodigo) {
      alert("Seleccione un impresora")
      return
    }

    const CustomZebraPrinter = new ZebraPrinter(ipPrinter)

    try {
      setLoadingImprimirBtn(true)
      await CustomZebraPrinter.imprimirEtiquetas(printproceso, lincodigo, {
        dataLabels: temporalProductsToPrint,
        cliciacianombre: localStorage.getItem("cliciagrupo"),
      })
    } catch (error) {
      console.error(error)
      // alert("No se pudo")
    } finally {
      setLoadingImprimirBtn(false)
    }
    // let labels = [];
    // // Detect if printer is selected
    // if (temporalProductsToPrint.length === 0) {
    //   return;
    // }

    // if (
    //   selected_device &&
    //   Object.keys(selected_device).length === 0 &&
    //   selected_device.constructor === Object
    // ) {
    //   alert("Seleccione un impresora");
    //   return;
    // }

    // console.log(temporalProductsToPrint, "aquiii");
    // if (codeType === "qrcode") {
    //   //Format all zpl qr codes of products

    //   const formatQR = (codigo) => {
    //     return "/productos/get_producto_por_codigo-" + codigo + "*";
    //   };

    //   temporalProductsToPrint.forEach((producto) => {
    //     const numEtiquetas = Math.floor(producto.artcantactual) || 0;
    //     const labelsPerProducto = [];
    //     for (let i = 0; i < numEtiquetas; i += 2) {
    //       console.log(numEtiquetas, i, i + 2, i + 2 <= numEtiquetas);
    //       labelsPerProducto.push(
    //         `^XA^PR7^MD15^CI28^FO47,170^BQN,2,6^FD  ${
    //           formatQR(producto?.codigo) +
    //           `^FS^FO10,50^A0N,15,15^FD${producto.descripcion}^FS`
    //         }${
    //           i + 2 <= numEtiquetas
    //             ? "^FS^FO390,170^BQN,2,6^FD" +
    //               "  " +
    //               formatQR(producto?.codigo) +
    //               `^FS^FO353,50^A0N,15,15^FD${producto.descripcion}^FS`
    //             : ""
    //         }^FS^XZ`
    //       );
    //     }
    //     labels.push(...labelsPerProducto);
    //   });
    // } else {
    //   //Format all zpl bar codes of products

    //   const formatBar = (codigo) => {
    //     return codigo + "*";
    //   };

    //   temporalProductsToPrint.forEach((producto) => {
    //     const numEtiquetas = Math.floor(producto.artcantactual) || 0;
    //     const labelsPerProducto = [];
    //     for (let i = 0; i < numEtiquetas; i += 2) {
    //       console.log(numEtiquetas, i, i + 2, i + 2 <= numEtiquetas);
    //       labelsPerProducto.push(
    //         `^XA^PR2,2,2^FO100,30^BY3^BCR,100,Y,N,N^FD${
    //           formatBar(producto?.codigo) +
    //           `^FS^FO210,30^A0R,15,15^FD${producto.descripcion}^FS`
    //         }${
    //           i + 2 <= numEtiquetas
    //             ? "^FS^FO450,30^BY3^BCR,100,Y,N,N^FD" +
    //               formatBar(producto?.codigo) +
    //               `^FS^FO560,30^A0R,15,15^FD${producto.descripcion}^FS`
    //             : ""
    //         }^FS^XZ`
    //       );
    //     }
    //     labels.push(...labelsPerProducto);
    //   });
    // }

    // setIsPrintingLabels(true);

    // // Printing all labels
    // try {
    //   labels.forEach((label) => {
    //     selected_device.send(
    //       label,
    //       () => {
    //         const lastLabel = labels[labels.length - 1];
    //         if (label === lastLabel) {
    //           alert("Etiquetas impresas");
    //           setIsPrintingLabels(false); //Last label printed
    //         }
    //       },
    //       (errorMessage) => {
    //         alert("Error: " + errorMessage);
    //       }
    //     );
    //   });
    // } catch (err) {
    //   alert("No se pudo conectar a la impresora");
    //   setIsPrintingLabels(false);
    // }
    // console.log(labels, codeType + "impresionnn");
  }
  const onTextToPrint = (e) => {
    setTextToPrint(e.target.value)
  }
  const onSelectedDevice = (e, value) => {
    setSelected_device(value)
  }
  const onSelectedImpresora = (e, value) => {
    setSelectedImpresora(value)
  }
  const onCodeType = (event) => {
    const proceso = event.target.value
    setCodeType(proceso)
    setSelectedImpresora({})
    setSelectedProcesoImpresoras(allProcesosImpresoras[proceso])
  }

  const isOptionEqualToValue = (option, value) => {
    return option.uid === value.uid
  }

  const getAllImpresoras = async () => {
    try {
      let response = await fetchwrapper("/dispositivos/getImpresorasxProceso")

      response = await response.json()
      console.log(response, "***************")

      setAllProcesosImpresoras(response.data)
    } catch (error) {
      console.error("No se pudo obtener informacion de las impresoras disponibles")
    }
  }
  useEffect(() => {
    // Load all procesos and impresoras avaliables for user
    getAllImpresoras()
    // Setup printer connection in network
    // setup();
  }, [])

  return (
    <div className="App" style={{ textAlign: "center" }}>
      <CustomBackdrop isLoading={loadingImprimirBtn} />
      <FormControl>
        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          value={codeType}
          onChange={onCodeType}
          sx={{ marginTop: "25px" }}
        >
          {Object.keys(allProcesosImpresoras).map((proceso) => {
            // eslint-disable-next-line react/jsx-key
            return <FormControlLabel value={proceso} control={<Radio />} label={proceso} />
          })}
        </RadioGroup>
      </FormControl>
      <Autocomplete
        disablePortal
        id="combo-autocomplete"
        options={selectedProcesoImpresoras}
        value={selectedImpresora}
        onChange={onSelectedImpresora}
        getOptionLabel={(impresora) => impresora?.lincodigo || ""}
        // isOptionEqualToValue={isOptionEqualToValue}
        loading={isLoadingPrinter}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Selecciona una impresora"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {isLoadingPrinter ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />

      {/* <Button
        variant="contained"
        onClick={setup}
        color="secondary"
        sx={{ marginTop: "25px" }}
      >
        Buscar impresora
      </Button> */}

      <div>
        {codeType !== "" && (
          <Button
            variant="contained"
            onClick={() => writeToSelectedPrinter(textToPrint)}
            color="secondary"
            disabled={isPrintingLabels}
            sx={{ marginTop: "25px" }}
          >
            {isPrintingLabels ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              <div>
                Imprimir etiquetas tipo {codeType}
                <img src={ImprimirIcon} style={{ width: "22px", marginLeft: "8px" }} alt="imprimir" />
              </div>
            )}
          </Button>
        )}
      </div>
    </div>
  )
}

export default InnerModalPrinter
