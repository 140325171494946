import React from "react"
import { Page, Text, View, Document, StyleSheet } from "@react-pdf/renderer"
import normalFormatDate from "../../utils/date/DDMMYYYFormatDate"
const styles = StyleSheet.create({
  page: {
    padding: 20,
    fontSize: 9, // Reducir el tamaño de la fuente general
    lineHeight: 1.2,
  },
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    borderBottom: "1px solid black",
    paddingBottom: 4,
  },
  title: {
    fontSize: 11, // Reducir el tamaño del título
    fontWeight: "bold",
    textAlign: "center",
    marginVertical: 8,
  },
  subTitle: {
    fontSize: 9, // Reducir tamaño del subtítulo
    textAlign: "center",
    marginVertical: 4,
  },
  detailItem: {
    marginBottom: 3, // Reducir margen inferior de cada ítem
    fontSize: 8, // Reducir tamaño de la fuente
  },
  details: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 5,
  },
  section: {
    marginBottom: 5, // Reducir el margen entre secciones
  },
  table: {
    display: "table",
    width: "100%",
    marginVertical: 10,
    borderWidth: 1,
    borderColor: "#000",
    borderStyle: "solid",
  },
  tableRow: {
    flexDirection: "row",
  },
  tableColHeader: {
    flex: 1,
    borderWidth: 1,
    borderColor: "#000",
    borderStyle: "solid",
    padding: 5,
    backgroundColor: "#f2f2f2",
  },
  tableCol: {
    flex: 1,
    borderWidth: 1,
    borderColor: "#000",
    borderStyle: "solid",
    padding: 5,
  },
  tableCellHeader: {
    margin: 5,
    fontWeight: "bold",
  },
  tableCell: {
    margin: 5,
  },
  signatureSection: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 20,
    paddingTop: 10,
  },
  signatureBlock: {
    flex: 1,
    textAlign: "center",
    margin: "0 5px", // Espacio entre las secciones
  },
  signatureLine: {
    borderTop: "1px solid #000",
    width: 200,
    margin: "10px auto",
  },
  footer: {
    textAlign: "center",
    marginTop: 10,
  },
})

const EmpaquesPdf = ({ data }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      {/* Encabezado */}
      <View style={styles.header}>
        <View>
          <Text> crpinEmpaques.Rpt</Text>
        </View>
        <View>
          <Text>Fech-Emisión: {new Date().toLocaleDateString()}</Text>
          <Text>Hora-Emisión: {new Date().toLocaleTimeString()}</Text>
        </View>
      </View>

      {/* Título */}
      <Text style={styles.title}>MERCATTI S.A.</Text>
      <Text style={styles.subTitle}>TIPOS DE EMPAQUE</Text>

      {/* Tabla */}
      <View style={styles.table}>
        <View style={styles.tableRow}>
          <View style={styles.tableColHeader}>
            <Text style={styles.tableCellHeader}>Código CIA</Text>
          </View>
          <View style={styles.tableColHeader}>
            <Text style={styles.tableCellHeader}>Descripción</Text>
          </View>
          <View style={styles.tableColHeader}>
            <Text style={styles.tableCellHeader}>Fecha de Creación</Text>
          </View>
        </View>
        {data.map((item, index) => (
          <View style={styles.tableRow} key={index}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{item.ciacodigo}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{item.empaquetipo}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{normalFormatDate(item.empaquefecisys)}</Text>
            </View>
          </View>
        ))}
      </View>

      {/* Pie de página */}
      <Text style={styles.footer}>ORIGINAL</Text>
    </Page>
  </Document>
)

export default EmpaquesPdf
