/* eslint-disable camelcase */
import React, { useState, useEffect } from "react"
import Header from "../../../layouts/Header"
import { styled, createTheme, ThemeProvider } from "@mui/material/styles"
import { Box, InputLabel, Select, MenuItem, TextField, Button, Tooltip } from "@mui/material"
import Swal from "sweetalert2"
import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import BackIcon from "../../../components/BackIcon"

import ImprimirIcon from "../../../assets/iconos/Impresion.ico"
import DetalleImpresion from "../../Zonas/components/DetalleImpresion"
import EmpaquesPdf from "../components/EmpaquesPdf"
const StyledRoot = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  marginTop: theme.spacing(8),
  flexGrow: 1,
  padding: "0 16px",
  height: "100vh",
}))
const theme = createTheme({
  palette: {
    primary: {
      main: "#196C87",
    },
    secondary: {
      main: "#196C87", // Cambia el color secundario a verde azulado
    },
  },
})
const DetalleEmpaque = () => {
  const { id } = useParams()
  const location = useLocation()
  const datoSeleccionado = location.state
  const ciaCod = datoSeleccionado.ciacodigo
  const descripcionEmpaque = datoSeleccionado.empaquetipo
  const [openModal, setOpenModal] = useState(false)
  const [infoGenerada, setInfoGenerada] = useState([])
  const handleOpenModal = () => {
    const datos = getDatosActuales()
    console.log("datos", datos)
    setInfoGenerada(datos) // Asigna los datos de la tabla al estado
    setOpenModal(true)
  }

  const handleCloseModal = () => {
    setOpenModal(false)
    navigate(`/home/dashboard/Empaques`)
  }
  function getDatosActuales() {
    return [
      {
        ciacodigo: ciaCod,
        empaquetipo: descripcionEmpaque,
      },
    ]
  }
  const navigate = useNavigate()

  const [descripcion, setDescripcion] = useState(descripcionEmpaque)

  const handleSubmit = async (e) => {
    e.preventDefault()
  }
  return (
    <ThemeProvider theme={theme}>
      <Header />
      <div className="main main-app p-3 p-lg-4">
        <BackIcon />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "0 30px 30px 30px",
            fontSize: "25px",
          }}
        >
          <b>Tipos de Empaque</b>
        </div>
        <Box className={StyledRoot}>
          <form onSubmit={handleSubmit}>
            <Tooltip title="Imprimir">
              <Button color="primary" onClick={handleOpenModal} sx={{ marginTop: "15px" }}>
                <img src={ImprimirIcon} alt="Imprimir" style={{ width: "30px" }} />
              </Button>
            </Tooltip>
            <DetalleImpresion
              open={openModal}
              handleClose={handleCloseModal}
              infoGenerada={getDatosActuales()}
              nombrePdf={"Empaque"}
              PdfComponent={EmpaquesPdf}
            />
            <TextField
              label="Descripción"
              margin="normal"
              fullWidth
              value={descripcion}
              required
              disabled
              onChange={(e) => setDescripcion(e.target.value)}
              inputProps={{ maxLength: 10 }}
            />
          </form>
        </Box>
      </div>
    </ThemeProvider>
  )
}

export default DetalleEmpaque
