/* eslint-disable camelcase */
import React, { useState, useEffect, useImperativeHandle, forwardRef } from "react"
import Button from "@mui/material/Button"
import { styled, createTheme, ThemeProvider } from "@mui/material/styles"
import {
  Grid,
  TextField,
  Paper,
  Typography,
  useMediaQuery,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Checkbox,
  FormControlLabel,
} from "@mui/material"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import InputFiltrarProductos from "./InputFiltrarProductos"
import InputMultivalueFiltrarProductos from "./InputMultivalueFiltrarProductos"
import ModalCameraQr from "./ModalCameraQr"
import fetchwrapper from "../../../services/interceptors/fetchwrapper"
import InputNestedChildren from "./InputNestedChildren"
import LoadingButton from "@mui/lab/LoadingButton"
import PrintIcon from "@mui/icons-material/Print"
import FilterAltIcon from "@mui/icons-material/FilterAlt"
import ScannerReaderButton from "./ScannerReaderButton"
import BuscarIcon from "../../../assets/iconos/Buscar.ico"

const StyledButtonContainerFiltro = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
  marginLeft: theme.spacing(2),
  marginRight: theme.spacing(2),
}))
const StyledButtonsFiltro = styled(Button)(({ theme }) => ({
  margin: "auto",
  marginLeft: theme.spacing(1),
  marginRight: theme.spacing(1),
}))

const AccordionFiltros = forwardRef(({ onFilteredProducts, apiTODOS, apiFILTROS, page, limit, elem }, ref) => {
  // const API_URL = "/productos/obtener_viewProductos_x_pagina";
  const isSmallScreen = useMediaQuery("(max-width:600px)")
  const [expanded, setExpanded] = useState(!isSmallScreen)
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingAll, setIsLoadingAll] = useState(false)
  // const [productos, setProductos] = useState([]);
  const [mostrarTodos, setMostrarTodos] = useState([])
  const [value, setValue] = useState([])
  const [presentacionCodes, setPresentacionCodes] = useState([])
  const [medidaCodes, setMedidaCodes] = useState([])
  const [marcaCodes, setMarcaCodes] = useState([])
  const [ingresoFiltro, setIngresoFiltro] = useState("")
  const [lineaCodes, setLineaCodes] = useState([])
  const [showProductosConCantidad, setShowProductosConCantidad] = useState(false)
  const [productosConCantidad, setProductosConCantidad] = useState(false)
  const handleChange = () => {
    setExpanded((prevExpanded) => !prevExpanded)
  }

  useImperativeHandle(ref, () => ({
    handleFiltros: (page) => {
      console.log("handleFiltros", page)
      filtros(page)
    },
  }))

  // Función para cargar los datos de los productos desde API
  useEffect(() => {
    function handleResize() {
      if (window.innerWidth <= 600) {
        setExpanded(false)
      } else {
        setExpanded(true)
      }
    }

    // window.addEventListener('resize', handleResize);

    // Cleanup function to remove the event listener
    return () => {
      window.removeEventListener("resize", handleResize)
    }
    // cargarProductos();
  }, [page])

  const mostrarProductos = () => {
    const cargarProductos = async () => {
      setIsLoadingAll(true)
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify({
          limit: 100,
          page: 1,
        }),
      }
      try {
        console.log("request", options)
        let response = await fetchwrapper(apiTODOS, options)
        response = await response.json()
        if (response !== null) {
          const productosUnicos = [...new Set(response)]
          console.log(productosUnicos)
          /* //map para agregar la propiedad "seleccionado" a cada producto	
                     productosUnicos.map((producto) => {
                       producto.seleccionado = false;
                     }); */
          // setProductos(productosUnicos);
          onFilteredProducts(productosUnicos)
          setMostrarTodos(productosUnicos)
        }
      } catch (error) {
        console.log(error)
      }
      setIsLoadingAll(false)
    }
    cargarProductos()
    // setProductos(mostrarTodos);
    onFilteredProducts(mostrarTodos)
  }
  // Función que realiza la búsqueda de productos por filtro
  const filtros = async (newPage) => {
    console.log("filtros Catalogo", newPage)
    if (newPage < 0) newPage = 1
    setIsLoading(true)
    console.log("filtroSSSSSSSSSSSSSSSSSSSSSSSSSs", newPage)
    const productosFiltrados = [...mostrarTodos]
    // if page or newPage is 0, then set page to 1
    try {
      const request = {
        codigo: {
          codigo_descripcion: value,
          presentacion: presentacionCodes,
          marca: marcaCodes,
          medida: medidaCodes,
          linea: lineaCodes,
          p_ingreso_egreso: ingresoFiltro,
          p_show: productosConCantidad,
        },
        limit,
        page: newPage + 1,
      }
      console.log("request", newPage + 1)
      console.log(request)
      const options = {
        method: "POST",
        body: JSON.stringify(request),
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
      console.log("request", options)
      let response = await fetchwrapper(apiFILTROS, options)
      response = await response.json()
      console.log(response, "response")
      // map to add a seleccionados property
      // setProductos(response);
      onFilteredProducts(response)
      console.log(response, "response")
      setIsLoading(false)
    } catch (err) {
      console.error(err)
      setIsLoading(false)
    }
  }

  return (
    <Accordion
      expanded={expanded}
      onChange={handleChange}
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        maxWidth: "100%",
      }}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
        <Typography variant="h6">Criterios de búsqueda</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2}>
          <InputFiltrarProductos label="Producto" placeholder="Filtrar por código" handleChange={setValue} />
          <InputMultivalueFiltrarProductos
            label="Presentación"
            placeholder="Filtrar por presentación"
            setCodes={setPresentacionCodes}
            fetchLink="/filter/get_presentacion"
            fetchResponse={{
              code: "precodigo",
              description: "predescri",
            }}
          />
          <InputMultivalueFiltrarProductos
            label="Medida"
            placeholder="Filtrar por medida"
            setCodes={setMedidaCodes}
            fetchLink="/filter/get_medidas"
            fetchResponse={{
              code: "medcodigo",
              description: "meddescri",
            }}
          />
          <InputMultivalueFiltrarProductos
            label="Marca"
            placeholder="Filtrar por marca"
            setCodes={setMarcaCodes}
            fetchLink="/filter/get_marcas"
            fetchResponse={{
              code: "marcodigo",
              description: "mardescri",
            }}
          />
          <InputNestedChildren
            label="Línea"
            placeholder="Filtrar por línea"
            setCodes={setLineaCodes}
            fetchLink="/filter/get_lineas"
            fetchResponse={{
              code: "lincodigo",
              description: "lindescri",
            }}
          />
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              fullWidth
              label="IMR/EMR"
              placeholder="Filtrar por IMR/EMR"
              value={ingresoFiltro}
              onChange={(event) => setIngresoFiltro(event.target.value)}
              variant="outlined"
              size="small"
              type="text"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            {/* <Checkbox
                            checked={showProductosConCantidad}
                            onChange={(e) => setShowProductosConCantidad(e.target.checked)}
                            inputProps={{ 'aria-label': 'show productos con cantidad' }}
                        >
                            Productos Con Cantidad
                        </Checkbox> */}

            <FormControlLabel
              control={
                <Checkbox
                  checked={productosConCantidad}
                  onChange={() => setProductosConCantidad(!productosConCantidad)}
                  color="primary"
                />
              }
              label="Productos Con Cantidad"
            />
          </Grid>
          <Grid container spacing={2} justify="center" style={{ marginTop: 20, marginLeft: 5 }}>
            <Grid item>
              <div className={StyledButtonContainerFiltro}>
                <LoadingButton
                  variant="contained"
                  endIcon={<img src={BuscarIcon} style={{ width: "22px" }} alt="buscar" />}
                  onClick={() => filtros(0)}
                  color="secondary"
                  loading={isLoading}
                  style={{ marginRight: "16px" }}
                >
                  Filtrar
                </LoadingButton>
              </div>
            </Grid>
            {/* <Grid item>
                            <LoadingButton
                                variant="outlined"
                                onClick={mostrarProductos}
                                color="secondary"
                                loading={isLoadingAll}
                                style={{ marginRight: "16px" }}
                            >
                                Mostrar todos los productos
                            </LoadingButton>
                        </Grid> */}
            <Grid item>
              <div style={{ marginRight: "10px", marginBottom: "25px" }}>
                {/* <ScannerReaderButton codeType="qrCode" /> */}
                {elem}
              </div>
            </Grid>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  )
})

export default AccordionFiltros
