/* eslint-disable camelcase */
import React, { useState, useEffect } from "react"
import {
  Box,
  Button,
  Step,
  StepLabel,
  Stepper,
  Typography,
  Snackbar,
  Alert,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material"
import fetchwrapper from "../../../services/interceptors/fetchwrapper" // Assuming a custom service for API calls
import Modal from "@mui/material/Modal"
import Dropzone from "react-dropzone"
import Draggable from "react-draggable" // Importamos Draggable
import { read } from "xlsx"
import { createTheme } from "@mui/material/styles"
import CloseIcon from "@mui/icons-material/Close"

const VALID_COLUMNS = ["Orden", "Almacen", "Codigo", "Cantidad", "FechaPedido"]

function ModalUploadFile({ openModal, id, onCloseModal, refreshParentScreen }) {
  const [open, setOpen] = useState(openModal)
  const [openDialog, setOpenDialog] = useState(false)
  const [activeStep, setActiveStep] = useState(0)
  const [file, setFile] = useState(null)
  const [snackbarMessage, setSnackbarMessage] = useState("")
  const [openSnackbar, setOpenSnackbar] = useState(false)
  const [isLoading, setIsLoading] = useState(false) // Flag for loading spinner
  const [tableData, setTableData] = useState([]) // Array to store preview data
  const [allData, setAllData] = useState([]) // Array to store preview data
  const [errors, setErrors] = useState([])
  const [successCount, setSuccessCount] = useState(0)
  const [snackbarSeverity, setSnackbarSeverity] = useState("success")

  useEffect(() => {
    console.log("Inicia componente")
    console.log(openModal)
    setOpen(openModal)
  }, [openModal])

  const handleClose = () => {
    setOpen(false)
    console.log("Clic Cerrar")
    console.log(open)
    onCloseModal() // Notify the parent component that the modal is closed
  }
  const handleCloseDialog = () => setOpenDialog(false)

  const downloadErrorLog = () => {
    const content = errors
      .map(
        (error) =>
          `${error.idwmsInp}, ${error.BNFPO}, ${error.MATNR}, ${error.MENGE}, ${error.LIFNR}, ${error.msgError}`,
      )
      .join("\n")
    const element = document.createElement("a")
    const file = new Blob([content], { type: "text/plain" })
    element.href = URL.createObjectURL(file)
    element.download = "error_log.txt"
    document.body.appendChild(element)
    element.click()
  }

  const theme = createTheme({
    palette: {
      primary: {
        main: "#196C87",
      },
      secondary: {
        main: "#196C87", // Cambia el color secundario a verde azulado
      },
    },
  })

  const excelDateToJSDate = (serial) => {
    const utc_days = Math.floor(serial - 25569)
    const utc_value = utc_days * 86400
    const date_info = new Date(utc_value * 1000)

    const day = date_info.getUTCDate()
    const month = date_info.getUTCMonth() + 1
    const year = date_info.getUTCFullYear()

    return `${day.toString().padStart(2, "0")}/${month.toString().padStart(2, "0")}/${year}`
  }

  const handleFileDrop = (acceptedFiles) => {
    const uploadedFile = acceptedFiles[0]
    const reader = new FileReader()
    // Validación del tipo de archivo
    if (
      uploadedFile.type !== "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" &&
      uploadedFile.type !== "application/vnd.ms-excel" &&
      uploadedFile.type !== "text/csv"
    ) {
      setSnackbarMessage("Tipo de archivo no compatible")
      setOpenSnackbar(true)
      return
    }

    const validateColumns = (workbook) => {
      console.log("Inicia Validación de Columnas...")
      setIsLoading(true)
      const firstSheetName = workbook.SheetNames[0]
      const worksheet = workbook.Sheets[firstSheetName]
      const headers = []
      let columnIndex = 0 // Comenzamos desde la columna A (índice 0)

      // Leer las cabeceras en todas las columnas (A1, B1, C1, etc.)
      let header
      do {
        const cellKey = `${String.fromCharCode(65 + columnIndex)}1` // Convierte el índice de columna en la letra correspondiente (A, B, C, etc.)
        header = worksheet[cellKey]?.v
        if (header) headers.push(header)
        columnIndex++
      } while (header)

      console.log("Cabeceras:", headers)
      console.log(VALID_COLUMNS)

      if (headers.length !== VALID_COLUMNS.length || !headers.every((h, idx) => h === VALID_COLUMNS[idx])) {
        setSnackbarMessage("Verifique el nombre de las columnas")
        setOpenSnackbar(true)
        console.log("No cumple Validación de Columnas...")
        return false
      }
      console.log("Validación de Columnas Exitosa...")
      return true
    }

    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result)
      // const workbook = read(data, { type: 'array' });
      setIsLoading(true) // Start loading spinner
      try {
        const workbook = read(data, { type: "array" })

        if (!validateColumns(workbook)) return // Early return if columns are invalid

        // Process the data
        const sheetData = workbook.Sheets[workbook.SheetNames[0]]

        // Convert sheet data into an array of objects
        const allData = Object.keys(sheetData).map((cell) => {
          const col = cell.replace(/[0-9]/g, "") // Extract column name
          const row = parseInt(cell.replace(/[A-Z]/gi, ""), 10) // Extract row number
          let value = sheetData[cell]?.v || ""

          // Convert date serial number to readable date if column is 'E'
          if (col === "E" && typeof value === "number") {
            value = excelDateToJSDate(value)
          }

          // return { col, row, value: sheetData[cell]?.v || '' }; // Extract cell value
          return { col, row, value } // Extract cell value
        })

        // Filter out only the rows where column 'A' has a value (excluding the first row as header)
        const dataWithoutHeader = allData.filter((cell) => cell.row !== 1)
        // Filtrar solo las filas con un número de fila válido (no NaN)
        const validData = dataWithoutHeader.filter((cell) => !isNaN(cell.row))

        // Group the data by row number and transform it into an array of objects
        const groupedData = validData.reduce((acc, curr) => {
          // Ignorar la fila de encabezados (fila 1)
          if (curr.row === 1) return acc
          const existingRow = acc.find((row) => row.row === curr.row)
          if (existingRow) {
            existingRow[curr.col] = curr.value
          } else {
            acc.push({ row: curr.row, [curr.col]: curr.value })
          }
          return acc
        }, [])

        // Slice the data for preview (50 per page)
        const slicedData = groupedData.slice(0, 50)
        setAllData(groupedData)
        console.log("All Data")
        console.log(groupedData)

        setTableData(slicedData)

        console.log("Slice Data")
        console.log(slicedData)
        setActiveStep(1) // Move to pre-visualization step
      } catch (error) {
        console.error("Error al leer el archivo:", error)
        setSnackbarMessage("Error al leer el archivo") // Inform user about the error
        setOpenSnackbar(true)
      } finally {
        setIsLoading(false) // Stop loading spinner
      }
    }

    reader.readAsArrayBuffer(uploadedFile)
    setFile(uploadedFile)
  }

  const handleNext = () => {
    if (activeStep === 0) {
      if (file === null) {
        setSnackbarMessage("Debe cargar el archivo")
        setOpenSnackbar(true)
      } else {
        setActiveStep(1) // Avanzar al paso 2
      }
    } else if (activeStep === 1) {
      // Paso 2: ActiveStep 1
      // setActiveStep(2); // Avanzar al paso 3
      setOpenDialog(true)
    }
  }

  const handleBack = () => {
    if (activeStep === 1 || activeStep === 2) {
      if (activeStep === 1) {
        setFile(null)
        setAllData([])
      } // Pasos 2 y 3: ActiveStep 1 o 2
      setActiveStep(activeStep - 1) // Retroceder al paso anterior
    }
  }

  const newErrors = []
  let successfulCount = 0 // Variable para contar los registros exitosos

  const handleConfirm = async () => {
    try {
      // Array para almacenar los errores
      console.log("Inicia Carga.....")

      setIsLoading(true)
      console.log(allData)
      // Iterar sobre todos los registros y llamar a la API para insertarlos
      for (const rowData of allData) {
        const requestOptions = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            idwmsInp: id,
            BNFPO: rowData.row,
            MATNR: rowData.C,
            MENGE: rowData.D.toString() ?? "",
            LIFNR: "",
            Fecha_Pedido: rowData.E,
          }),
        }

        // Aquí se llama a fetchwrapper para hacer la solicitud POST
        await fetchwrapper("/integracionjbg/insertsapTsolpedtras", requestOptions)
          .then((response) => {
            if (response.ok) {
              successfulCount++ // Incrementar el contador de registros exitosos
              return response.json()
            }
          })
          .catch((error) => {
            // Si hay un error, agrega el registro al array de errores
            newErrors.push({
              idwmsInp: rowData.idwmsInp,
              BNFPO: rowData.BNFPO,
              MATNR: rowData.MATNR,
              MENGE: rowData.MENGE,
              LIFNR: rowData.LIFNR,
              Fecha_Pedido: rowData.Fecha_Pedido,
              msgError: error.message,
            })
          })
      }

      // Actualizar el array de errores con los nuevos errores
      setErrors([...errors, ...newErrors])

      // Si no hay errores, mostrar una alerta de éxito
      if (newErrors.length === 0) {
        // alert('Todos los registros se insertaron exitosamente.');
        setSnackbarSeverity("success")
        setSnackbarMessage(` ${successfulCount} Registros insertados exitosamente`)
        setOpenSnackbar(true)
      }
      refreshParentScreen() // Debes definir esta función en el componente padre
    } catch (error) {
      console.error("Error al procesar los registros:", error)
      // En caso de error, mostrar una alerta de error
      // alert('Ocurrió un error al procesar los registros. Por favor, inténtalo de nuevo más tarde.');
      setSnackbarSeverity("warning")
      setSnackbarMessage(`Se encontraron ${newErrors.length} errores al procesar algunos registros.`)
      setOpenSnackbar(true)
    }
    setOpenDialog(false)
    handleClose()

    setIsLoading(false)
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-upload-file"
      aria-describedby="modal-upload-file-description"
    >
      {/* <Draggable> */}
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "50vw",
          maxHeight: "70vh",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          overflow: "hidden", // Oculta el overflow
        }}
      >
        <Stepper activeStep={activeStep} alternativeLabel>
          <Step>
            <StepLabel>Subir archivo</StepLabel>
          </Step>
          <Step>
            <StepLabel>Previsualización</StepLabel>
          </Step>
          <Step>
            <StepLabel>Confirmar</StepLabel>
          </Step>
        </Stepper>
        <Box sx={{ flexGrow: 1, overflowY: "auto", height: "50%" }}>
          {activeStep === 0 && (
            <Box>
              <Dropzone onDrop={handleFileDrop}>
                {({ getRootProps, getInputProps }) => (
                  <Box {...getRootProps()} sx={{ textAlign: "center", mb: 4 }}>
                    <input {...getInputProps()} />
                    <Button
                      variant="contained"
                      component="div"
                      sx={{ width: "50%", margin: "0 auto" }} // Ancho del 50% y centrado horizontalmente
                    >
                      Seleccionar archivo
                    </Button>
                  </Box>
                )}
              </Dropzone>
            </Box>
          )}
          {activeStep === 1 && (
            <Box>
              <Typography variant="h6">Nombre de Archivo: {file?.name}</Typography>
              <Typography variant="body2">Número de registros: {allData?.length}</Typography>
              <Typography variant="caption" sx={{ fontStyle: "italic" }}>
                Solo se muestran los 50 primeros registros
              </Typography>
              {/* Display table with preview data (pagination implemented here) */}
              {tableData.length > 0 && (
                <Box sx={{ maxHeight: "30vh", overflowY: "auto", textAlign: "center" }}>
                  <table style={{ maxWidth: "100%", borderSpacing: "0", borderCollapse: "collapse", margin: "0 auto" }}>
                    <thead>
                      <tr>
                        {VALID_COLUMNS.map((column) => (
                          <th
                            key={column}
                            style={{
                              padding: "10px",
                              backgroundColor: theme.palette.primary.main,
                              color: "#fff",
                              border: "1px solid #ccc",
                              minWidth: "150px",
                            }}
                          >
                            {column}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {tableData.map((item, index) => (
                        <tr key={index} style={{ padding: "10px", border: "1px solid #ccc" }}>
                          <td style={{ textAlign: "right", border: "1px solid #ccc" }}>{item.A}</td>
                          <td style={{ textAlign: "right", border: "1px solid #ccc" }}>{item.B}</td>
                          <td style={{ textAlign: "right", border: "1px solid #ccc" }}>{item.C}</td>
                          <td style={{ textAlign: "right", border: "1px solid #ccc" }}>{item.D}</td>
                          <td style={{ textAlign: "right", border: "1px solid #ccc" }}>{item.E}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </Box>
              )}
            </Box>
          )}
        </Box>
        {/* Botones de "Anterior" y "Siguiente" */}
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          {" "}
          {/* Alinea los botones a la derecha */}
          {activeStep !== 0 && (
            <Button onClick={handleBack} variant="contained" sx={{ mr: 2 }}>
              Prev
            </Button>
          )}
          {activeStep !== 2 && (
            <Button onClick={handleNext} variant="contained">
              Next
            </Button>
          )}
        </Box>
        <Box sx={{ position: "absolute", bottom: "20px", left: "20px" }}>
          <Button onClick={handleClose} color="secondary">
            <CloseIcon />
          </Button>
        </Box>
        <Dialog
          open={openDialog}
          onClose={handleCloseDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Desea Cargar e archivo?"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">Desea cargar el archivo?</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog}>Aún no</Button>
            <Button onClick={handleConfirm}>Cargar</Button>
            {successCount > 0 && <Alert severity="success">Se guardaron {successCount} registros exitosamente.</Alert>}
            {errors.length > 0 && (
              <>
                <Alert severity="warning">
                  Se encontraron {errors.length} errores al procesar algunos registros.
                  <Button onClick={downloadErrorLog}>Descargar registro de errores</Button>
                </Alert>
              </>
            )}
          </DialogActions>
        </Dialog>
        <Snackbar
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={() => setOpenSnackbar(false)}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          severity={snackbarSeverity}
          message={snackbarMessage}
        >
          <Alert onClose={() => setOpenSnackbar(false)} severity="warning">
            {snackbarMessage}
          </Alert>
        </Snackbar>
        {isLoading && <CircularProgress size={24} color="secondary" sx={{ textAlign: "center", marginTop: 20 }} />}
      </Box>
      {/* </Draggable> */}
    </Modal>
  )
}

export default ModalUploadFile
