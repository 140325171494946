import React, { useEffect, useState, useMemo } from "react"
import { MaterialReactTable, useMaterialReactTable } from "material-react-table"
import {
  TextField,
  createTheme,
  ThemeProvider,
  useTheme,
  Box,
  IconButton,
  Tooltip,
  TablePagination,
} from "@mui/material"

import Swal from "sweetalert2"
import { styled } from "@mui/material/styles"
import { esES } from "@mui/material/locale"
import { MRT_Localization_ES } from "material-react-table/locales/es"
import fetchwrapper from "../../../services/interceptors/fetchwrapper"
import EditIcon from "@mui/icons-material/Edit"
import { useNavigate } from "react-router-dom"
import EliminarIcon from "../../../assets/iconos/Eliminar.ico"
import DetalleIcon from "../../../assets/iconos/Detalle.ico"
import normalFormatDate from "../../utils/date/DDMMYYYFormatDate"
import normalFormatHour from "../../utils/date/HHMMSSFormatHour"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import CustomBackdrop from "../../../components/CustomBackdrop"
const CustomTable = ({
  fetchedInfo = [],
  isLoadingInfoError,
  isLoadingInfo,
  isFetchingInfo,
  searchAttempted,
  setClientes,
  refetch,
  onPageChange,
}) => {
  const navigate = useNavigate()
  // Maneja cambios en los filtros de la grilla

  const columns = useMemo(
    () => [
      {
        accessorKey: "acciones",
        header: "Acciones",
        enableColumnFilter: false,
        size: 150,
        Cell: ({ row }) => (
          <Box sx={{ display: "flex", gap: "1rem" }}>
            <Tooltip title="Editar">
              <IconButton
                onClick={() => {
                  setTimeout(() => {
                    navigate(`asociar`, { state: row.original })
                  }, 0)
                }}
              >
                <img src={DetalleIcon} alt="Detalle" style={{ width: "20px" }} />
              </IconButton>
            </Tooltip>
          </Box>
        ),
      },
      {
        accessorKey: "ciacodigo",
        header: "CIA",
        enableEditing: false,
        size: 120,
      },

      {
        accessorKey: "clicodigo",
        header: "Codigo Cliente",
        enableEditing: false,
        size: 120,
      },

      {
        accessorKey: "clinombre",
        header: "Nombre",
        enableEditing: false,
        size: 120,
      },
      {
        accessorKey: "cliruc",
        header: "Identificacion",
        enableEditing: false,
        size: 120,
      },
      {
        accessorKey: "clistatus",
        header: "Estado",
        enableEditing: false,
        size: 120,
        Cell: ({ cell }) => {
          let cellValue = cell.getValue()
          if (cellValue === "A") {
            cellValue = "ACTIVO"
          } else {
            cellValue = "INACTIVO"
          }
          return cellValue
        },
      },
    ],
    [navigate],
  )

  const table = useMaterialReactTable({
    columns,
    data: fetchedInfo.length > 0 ? fetchedInfo : [],
    enableEditing: false,
    enableRowActions: false,
    localization: { ...MRT_Localization_ES },
    initialState: {
      showColumnFilters: true,
    },
    getRowId: (row) => row.formcodigo,
    muiToolbarAlertBannerProps:
      searchAttempted && !isLoadingInfo && fetchedInfo.length === 0 && !isFetchingInfo
        ? {
            color: "info",
            children: "No se encontraron Clientes",
          }
        : undefined,
    muiTableContainerProps: {
      sx: {
        minHeight: "500px",
      },
    },
    renderToolbarInternalActions: ({ table }) => <Box></Box>,

    state: {
      isLoading: isLoadingInfo,
      showAlertBanner: isLoadingInfoError,
      showProgressBars: isFetchingInfo,
    },
  })

  return (
    <>
      <MaterialReactTable table={table} />
    </>
  )
}

const ClienteGrid = ({ refetch, fetchedInfo, isLoadingInfo, isError, isFetchingInfo, setClientes }) => {
  const theme = useTheme()

  // Maneja cambios en los filtros de la grilla

  return (
    <div>
      <ThemeProvider theme={createTheme(theme, esES)}>
        <CustomTable
          fetchedInfo={fetchedInfo}
          isLoadingInfoError={isError}
          isLoadingInfo={isLoadingInfo}
          isFetchingInfo={isFetchingInfo}
          refetch={refetch}
          setSolicitudesIngresos={setClientes}
        />
      </ThemeProvider>
    </div>
  )
}

export default ClienteGrid
