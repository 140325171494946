import React, { useState, useEffect } from "react"
import { AutoComplete } from "primereact/autocomplete"
import styled from "@emotion/styled" // Usando styled-components o @emotion/styled

const CustomAutoComplete = ({ items, onSelect, placeholder, disabled, value }) => {
  const [searchTerm, setSearchTerm] = useState("")

  useEffect(() => {
    // Sincroniza el valor desde el padre cuando cambia
    setSearchTerm(value)
  }, [value])

  const onSearch = (event) => {
    let _filteredValues

    if (!event.query.trim().length) {
      _filteredValues = [...items]
    } else {
      _filteredValues = items.filter((value) => value.toLowerCase().includes(event.query.toLowerCase()))
    }

    setFilteredItems(_filteredValues)
  }

  const [filteredItems, setFilteredItems] = useState(items)

  return (
    <StyledAutoComplete
      value={searchTerm}
      dropdown
      disabled={disabled}
      suggestions={filteredItems}
      completeMethod={onSearch}
      onChange={(e) => {
        setSearchTerm(e.value)
        if (onSelect) onSelect(e.value)
      }}
      placeholder={placeholder}
      virtualScrollerOptions={{ itemSize: 20 }}
    />
  )
}

export default CustomAutoComplete

const StyledAutoComplete = styled(AutoComplete)(({ theme }) => ({
  width: "100%",
  border: "1px solid #ccc",
  padding: "3px",
  borderRadius: "4px",
  fontSize: "1rem",
  zIndex: 1000,
  "& .p-autocomplete-input": {
    border: "none",
    boxShadow: "none",
  },
  "& .p-autocomplete-dropdown": {
    height: "100%",
    border: "none",
    boxShadow: "none",
    backgroundColor: "#fff",
    color: "#ccc",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "0 4px 4px 0",
    cursor: "pointer",
    zIndex: 1000,
    "& .p-button-icon": {
      margin: 0,
    },
  },
  "& .p-autocomplete-panel": {
    position: "absolute", // Asegura que el dropdown flote sobre otros elementos
    zIndex: 1000, // Establece z-index alto
    maxHeight: "200px", // Limita la altura del dropdown
    overflowY: "auto", // Habilita el scroll si la lista es larga
  },
  [theme.breakpoints.down("sm")]: {
    "& .p-autocomplete-panel": {
      maxHeight: "150px", // Reduce la altura máxima en pantallas pequeñas
    },
    "& .p-autocomplete-dropdown": {
      width: "100%", // Asegúrate de que el dropdown ocupe el ancho completo en pantallas pequeñas
    },
  },
}))
