import React, { useState, useContext } from "react"

import Header from "../../../layouts/Header"
import { styled, createTheme, ThemeProvider } from "@mui/material/styles"
import BuscarBahiaComponent from "../components/BuscarBahiaComponent"
const theme = createTheme({
  palette: {
    primary: {
      main: "#196C87",
    },
    secondary: {
      main: "#196C87", // Cambia el color secundario a verde azulado
    },
  },
})

const BuscarBahia = () => {
  return (
    <ThemeProvider theme={theme}>
      <Header />
      <b>Bahias o Perchas</b>
      <BuscarBahiaComponent></BuscarBahiaComponent>
    </ThemeProvider>
  )
}

export default BuscarBahia
