/* eslint-disable camelcase */
import { useEffect, useMemo, useState } from "react"
import { MaterialReactTable, useMaterialReactTable } from "material-react-table"
import { Box, Typography, Button, createTheme, ThemeProvider, useTheme } from "@mui/material"
import SkipNextIcon from "@mui/icons-material/SkipNext"
import SkipPreviousIcon from "@mui/icons-material/SkipPrevious"

import { esES } from "@mui/material/locale"
import { MRT_Localization_ES } from "material-react-table/locales/es"
const DatagridDragDrop = ({ data1, setData1, data2, setData2 }) => {
  const theme = useTheme()

  const columns = useMemo(
    // column definitions...
    () => [
      {
        accessorKey: "pregcodigo",
        header: "Cóidgo",
      },
      {
        accessorKey: "pregdescri",
        header: "Pregunta",
      },
      {
        accessorKey: "pregtipo",
        header: "Tipo",
      },
      {
        accessorKey: "pregstatus",
        header: "Status",
      },
    ],
    [],
    // end
  )

  const [rowSelection, setRowSelection] = useState({})

  const [draggingRow, setDraggingRow] = useState(null)
  const [hoveredTable, setHoveredTable] = useState(null)
  const [initialCaptureStartDragging, setInitialCaptureStartDragging] = useState({})

  const areObjectsEqual = (obj1, obj2) => {
    // Implementa tu lógica de comparación aquí
    // Por ejemplo, podrías comparar propiedades específicas
    return obj1.pregcodigo === obj2.pregcodigo && obj1.pregdescri === obj2.pregdescri
  }

  const addTable2 = () => {
    setData2((data2) => [...data2, ...table1.getSelectedRowModel().rows.map((obj) => obj.original)])
    setData1((data1) =>
      data1.filter((d) => !table1.getSelectedRowModel().rows.some((obj) => areObjectsEqual(obj?.original, d))),
    )

    setRowSelection({})
  }

  const addTable1 = () => {
    setData1((data1) => [...table2.getSelectedRowModel().rows.map((obj) => obj.original), ...data1])
    setData2((data2) =>
      data2.filter((d) => !table2.getSelectedRowModel().rows.some((obj) => areObjectsEqual(obj?.original, d))),
    )
    setRowSelection({})
  }

  const commonTableProps = {
    columns,
    localization: { ...MRT_Localization_ES },
    enableRowDragging: true,
    enableFullScreenToggle: false,
    muiTableContainerProps: {
      sx: {
        minHeight: "320px",
      },
    },
    onDraggingRowChange: setDraggingRow,
    state: { draggingRow, rowSelection },

    muiTableBodyRowProps: ({ row }) => ({
      // implement row selection click events manually
      onClick: () => {
        setRowSelection((prev) => ({
          ...prev,
          [row.id]: !prev[row.id],
        }))
      },
      selected: rowSelection[row.id],
      sx: {
        cursor: "pointer",
      },
    }),
    onRowSelectionChange: setRowSelection,
  }

  const table1 = useMaterialReactTable({
    ...commonTableProps,
    data: data1,
    getRowId: (originalRow) => `table-1-${originalRow.pregcodigo}`,
    muiRowDragHandleProps: {
      onDragEnd: () => {
        if (hoveredTable === "table-2") {
          setData2((data2) => [...data2, draggingRow.original])
          setData1((data1) => data1.filter((d) => d !== draggingRow.original))
        }
        setHoveredTable(null)
      },
    },
    muiTablePaperProps: {
      onDragEnter: () => setHoveredTable("table-1"),
      sx: {
        outline: hoveredTable === "table-1" ? "2px dashed pink" : undefined,
      },
      onDragStartCapture: () => {
        setInitialCaptureStartDragging("table-1")
      },
    },
    renderTopToolbarCustomActions: () => {
      return (
        <div style={{ display: "flex" }}>
          <Typography color="success.main" component="span" variant="h4">
            Preguntas
          </Typography>
          <Button variant="outlined" color="primary" onClick={addTable2}>
            <SkipNextIcon />
          </Button>
        </div>
      )
    },
  })

  const table2 = useMaterialReactTable({
    ...commonTableProps,
    enableRowOrdering: initialCaptureStartDragging !== "table-1",
    enableSorting: false,
    data: data2,
    defaultColumn: {
      size: 100,
    },
    getRowId: (originalRow) => `table-2-${originalRow.pregcodigo}`,
    muiRowDragHandleProps: ({ table }) => ({
      onDragEnd: () => {
        if (hoveredTable === "table-1") {
          setData1((data1) => [...data1, draggingRow.original])
          setData2((data2) => data2.filter((d) => d !== draggingRow.original))
        }
        setHoveredTable(null)

        // Auto ordering logic
        const { draggingRow, hoveredRow } = table.getState()

        if (hoveredRow && draggingRow) {
          data2.splice(hoveredRow.index, 0, data2.splice(draggingRow.index, 1)[0])
          setData2([...data2])
        }
      },
    }),
    muiTablePaperProps: {
      onDragEnter: () => {
        setHoveredTable("table-2")
      },
      sx: {
        outline: hoveredTable === "table-2" ? "2px dashed pink" : undefined,
      },
      onDragStartCapture: () => {
        setInitialCaptureStartDragging("table-2")
      },
    },
    renderTopToolbarCustomActions: () => {
      return (
        <div style={{ display: "flex" }}>
          <Typography color="error.main" component="span" variant="h4">
            Formulario
          </Typography>
          <Button variant="outlined" color="primary" onClick={addTable1}>
            <SkipPreviousIcon />
          </Button>
        </div>
      )
    },
  })

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: { xs: "auto", lg: "1fr 1fr" },
        gap: "1rem",
        overflow: "auto",
        p: "4px",
      }}
    >
      <ThemeProvider theme={createTheme(theme, esES)}>
        <MaterialReactTable table={table1} />
        <MaterialReactTable table={table2} />
      </ThemeProvider>
    </Box>
  )
}

export default DatagridDragDrop
