import React, { useEffect, useState, useMemo, useCallback } from "react"
import { MaterialReactTable, useMaterialReactTable } from "material-react-table"
import {
  TextField,
  createTheme,
  ThemeProvider,
  useTheme,
  Box,
  IconButton,
  Tooltip,
  TablePagination,
} from "@mui/material"
import { styled } from "@mui/material/styles"
import { esES } from "@mui/material/locale"
import { MRT_Localization_ES } from "material-react-table/locales/es"
import DetalleIcon from "../../../assets/iconos/Detalle.ico"
import EditIcon from "@mui/icons-material/Edit"
import { useNavigate } from "react-router-dom"

import { AprobacionContextProvider } from "../AprobacionContext"
import normalFormatDate from "../../utils/date/DDMMYYYFormatDate"
import normalFormatHour from "../../utils/date/HHMMSSFormatHour"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import CustomTooltip from "../../../components/ToolTip"

const CustomTable = ({
  fetchedInfo = [],
  isLoadingInfoError,
  isLoadingInfo,
  isFetchingInfo,
  searchAttempted,
  totalPages,
  refetch,
  onFilterChange,
  onPageChange,
  currentPage,
}) => {
  const navigate = useNavigate()

  const [filters, setFilters] = useState({})
  const [page, setPage] = useState(1)
  const [fechaSolicitud, setFechaSolicitud] = useState(null)
  const [fechaLlegada, setFechaLlegada] = useState(null)

  // Maneja cambios en los filtros de la grilla
  const handleFilterChange = (columnId, value) => {
    onFilterChange(columnId, value)
  }

  useEffect(() => {
    setPage(currentPage) // Sincroniza la página interna con el prop currentPage
  }, [currentPage])

  const handleFechaSolicitud = (newFecha) => {
    setFechaSolicitud(newFecha)
    if (newFecha) {
      handleFilterChange("sgafecsol", newFecha.format("YYYY-MM-DD"))
    } else {
      handleFilterChange("sgafecsol", "")
    }
  }

  const handleFechaLlegada = (newFecha) => {
    setFechaLlegada(newFecha)
    if (newFecha) {
      handleFilterChange("sgafecllegada", newFecha.format("YYYY-MM-DD"))
    } else {
      handleFilterChange("sgafecllegada", "")
    }
  }

  // Actualiza la página al cambiar el número de página en la paginación
  const handlePageChange = useCallback(
    (event, newPage) => {
      setPage(newPage + 1)
      onPageChange(newPage + 1)
    },
    [onPageChange],
  )

  useEffect(() => {
    setPage(page)
  }, [page])

  const columns = useMemo(
    () => [
      {
        accessorKey: "acciones",
        header: "Acciones",
        enableColumnFilter: false,
        size: 150,
        Cell: ({ row }) => (
          <Box sx={{ display: "flex", gap: "1rem" }}>
            <CustomTooltip title="Editar">
              <IconButton
                onClick={() => {
                  navigate(`Buscar/Aprobar`, { state: row.original })
                }}
              >
                <img src={DetalleIcon} alt="Detalle" style={{ width: "30px" }} />
              </IconButton>
            </CustomTooltip>
          </Box>
        ),
      },
      {
        accessorKey: "sgasolegr",
        header: "Solicitud",
        enableEditing: false,
        size: 200,
        Filter: ({ column }) => (
          <TextField
            variant="standard"
            placeholder="Filtrar"
            fullWidth
            InputProps={{ style: { padding: "8px" } }}
            onChange={(e) => handleFilterChange(column.id, e.target.value)}
            sx={{
              width: 100,
              "& .MuiInputBase-input": {
                padding: "8px",
              },
            }}
          />
        ),
      },
      {
        accessorKey: "sgafecsol",
        header: "Fecha Emisión",
        enableEditing: false,
        size: 200,
        Cell: ({ cell }) => {
          const cellValue = cell.getValue()
          return normalFormatDate(cellValue)
        },
        Filter: ({ column }) => (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              value={fechaSolicitud}
              format="DD/MM/YYYY"
              slotProps={{
                textField: {
                  variant: "filled",
                  sx: {
                    "& .MuiFilledInput-root": {
                      backgroundColor: "transparent",
                    },
                  },
                },
              }}
              onChange={handleFechaSolicitud}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
            />
          </LocalizationProvider>
        ),
      },
      {
        accessorKey: "sgahorsol",
        header: "Hora Emisión",
        enableEditing: false,
        size: 120,
        enableColumnFilter: false,
        Cell: ({ cell }) => {
          const cellValue = cell.getValue()
          return normalFormatHour(cellValue)
        },
      },
      {
        accessorKey: "motdescripcion",
        header: "Motivo",
        enableEditing: false,
        size: 120,
        Filter: ({ column }) => (
          <TextField
            variant="standard"
            placeholder="Filtrar"
            fullWidth
            InputProps={{ style: { padding: "8px" } }}
            onChange={(e) => handleFilterChange(column.id, e.target.value)}
            sx={{
              width: 100,
              "& .MuiInputBase-input": {
                padding: "8px",
              },
            }}
          />
        ),
      },
      {
        accessorKey: "sgafecenvio",
        header: "Fecha Envío",
        enableEditing: false,
        size: 120,
        Cell: ({ cell }) => {
          const cellValue = cell.getValue()
          return normalFormatDate(cellValue)
        },
        Filter: ({ column }) => (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              value={fechaLlegada}
              format="DD/MM/YYYY"
              slotProps={{
                textField: {
                  variant: "filled",
                  sx: {
                    "& .MuiFilledInput-root": {
                      backgroundColor: "transparent",
                    },
                  },
                },
              }}
              onChange={handleFechaLlegada}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
            />
          </LocalizationProvider>
        ),
      },
      {
        accessorKey: "sgahorenvio",
        header: "Hora Envío",
        enableColumnFilter: false,
        size: 120,
        Cell: ({ cell }) => {
          const cellValue = cell.getValue()
          return normalFormatHour(cellValue)
        },
      },
      {
        accessorKey: "sgacomenenvio",
        header: "Comentarios",
        enableEditing: false,
        size: 120,
        Filter: ({ column }) => (
          <TextField
            variant="standard"
            placeholder="Filtrar"
            fullWidth
            InputProps={{ style: { padding: "8px" } }}
            onChange={(e) => handleFilterChange(column.id, e.target.value)}
            sx={{
              width: 100,
              "& .MuiInputBase-input": {
                padding: "8px",
              },
            }}
          />
        ),
      },
      {
        accessorKey: "sgadescri",
        header: "Descripción",
        enableEditing: false,
        size: 120,
        Filter: ({ column }) => (
          <TextField
            variant="standard"
            placeholder="Filtrar"
            fullWidth
            InputProps={{ style: { padding: "8px" } }}
            onChange={(e) => handleFilterChange(column.id, e.target.value)}
            sx={{
              width: 100,
              "& .MuiInputBase-input": {
                padding: "8px",
              },
            }}
          />
        ),
      },
      {
        accessorKey: "clinombre",
        header: "Cliente",
        enableEditing: false,
        size: 120,
        Filter: ({ column }) => (
          <TextField
            variant="standard"
            placeholder="Filtrar"
            fullWidth
            InputProps={{ style: { padding: "8px" } }}
            onChange={(e) => handleFilterChange(column.id, e.target.value)}
            sx={{
              width: 100,
              "& .MuiInputBase-input": {
                padding: "8px",
              },
            }}
          />
        ),
      },
      {
        accessorKey: "pronombre",
        header: "Proveedor",
        enableEditing: false,
        size: 120,
        Filter: ({ column }) => (
          <TextField
            variant="standard"
            placeholder="Filtrar"
            fullWidth
            InputProps={{ style: { padding: "8px" } }}
            onChange={(e) => handleFilterChange(column.id, e.target.value)}
            sx={{
              width: 100,
              "& .MuiInputBase-input": {
                padding: "8px",
              },
            }}
          />
        ),
      },
      {
        accessorKey: "sgagenepor",
        header: "Generado Por",
        enableEditing: false,
        size: 120,
        Filter: ({ column }) => (
          <TextField
            variant="standard"
            placeholder="Filtrar"
            fullWidth
            InputProps={{ style: { padding: "8px" } }}
            onChange={(e) => handleFilterChange(column.id, e.target.value)}
            sx={{
              width: 100,
              "& .MuiInputBase-input": {
                padding: "8px",
              },
            }}
          />
        ),
      },
      {
        accessorKey: "sgastatus",
        header: "Estado",
        enableEditing: false,
        size: 120,
        Filter: ({ column }) => (
          <TextField
            variant="standard"
            placeholder="Filtrar"
            fullWidth
            InputProps={{ style: { padding: "8px" } }}
            onChange={(e) => handleFilterChange(column.id, e.target.value)}
            sx={{
              width: 100,
              "& .MuiInputBase-input": {
                padding: "8px",
              },
            }}
          />
        ),
      },
      {
        accessorKey: "agencodigosol",
        header: "Agencia Solicita",
        enableEditing: false,
        size: 120,
        Filter: ({ column }) => (
          <TextField
            variant="standard"
            placeholder="Filtrar"
            fullWidth
            InputProps={{ style: { padding: "8px" } }}
            onChange={(e) => handleFilterChange(column.id, e.target.value)}
            sx={{
              width: 100,
              "& .MuiInputBase-input": {
                padding: "8px",
              },
            }}
          />
        ),
      },
      {
        accessorKey: "agencodigoenv",
        header: "Agencia Envía",
        enableEditing: false,
        size: 120,
        Filter: ({ column }) => (
          <TextField
            variant="standard"
            placeholder="Filtrar"
            fullWidth
            InputProps={{ style: { padding: "8px" } }}
            onChange={(e) => handleFilterChange(column.id, e.target.value)}
            sx={{
              width: 100,
              "& .MuiInputBase-input": {
                padding: "8px",
              },
            }}
          />
        ),
      },
      {
        accessorKey: "concodrelextenv",
        header: "Contacto",
        enableEditing: false,
        size: 120,
        Filter: ({ column }) => (
          <TextField
            variant="standard"
            placeholder="Filtrar"
            fullWidth
            InputProps={{ style: { padding: "8px" } }}
            onChange={(e) => handleFilterChange(column.id, e.target.value)}
            sx={{
              width: 100,
              "& .MuiInputBase-input": {
                padding: "8px",
              },
            }}
          />
        ),
      },
      {
        accessorKey: "condescrienv",
        header: "Contacto",
        enableEditing: false,
        size: 120,
        Filter: ({ column }) => (
          <TextField
            variant="standard"
            placeholder="Filtrar"
            fullWidth
            InputProps={{ style: { padding: "8px" } }}
            onChange={(e) => handleFilterChange(column.id, e.target.value)}
            sx={{
              width: 100,
              "& .MuiInputBase-input": {
                padding: "8px",
              },
            }}
          />
        ),
      },
    ],
    [fechaSolicitud, fechaLlegada],
  )

  const table = useMaterialReactTable({
    columns,
    data: fetchedInfo.length > 0 ? fetchedInfo : [],
    enableEditing: false,
    enableRowActions: false,
    localization: { ...MRT_Localization_ES },
    initialState: {
      showColumnFilters: true,
      columnVisibility: {
        agencodigoenv: false,
        agencodigosol: false,
        concodrelextenv: false,
        condescrienv: false,
      },
    },
    getRowId: (row) => row.formcodigo,
    muiToolbarAlertBannerProps:
      searchAttempted && !isLoadingInfo && fetchedInfo.length === 0 && !isFetchingInfo
        ? {
            color: "info",
            children: "No se encontraron solicitudes",
          }
        : undefined,
    muiTableContainerProps: {
      sx: {
        minHeight: "500px",
      },
    },
    enablePagination: false,
    renderToolbarInternalActions: ({ table }) => <Box></Box>,
    renderBottomToolbarCustomActions: () => (
      <Box sx={{ display: "flex", justifyContent: "flex-end", width: "100%", padding: "0.5rem" }}>
        <TablePagination
          rowsPerPageOptions={[10]}
          component="div"
          count={totalPages}
          rowsPerPage={10}
          page={page - 1}
          onPageChange={handlePageChange}
        />
      </Box>
    ),
    state: {
      isLoading: isLoadingInfo,
      showAlertBanner: isLoadingInfoError,
      showProgressBars: isFetchingInfo,
    },
  })

  return (
    <>
      <MaterialReactTable table={table} />
    </>
  )
}

const GridAprobacion = ({
  refetch,
  fetchedInfo,
  isLoadingInfo,
  isError,
  isFetchingInfo,
  totalPages,
  onPageChange,
  onFilterChange,
  setSolicitudesIngresos,
}) => {
  const theme = useTheme()

  // Maneja cambios en los filtros de la grilla
  const handleFilterChange = (columnId, value) => {
    onFilterChange(columnId, value)
  }

  return (
    <div>
      <AprobacionContextProvider>
        <ThemeProvider theme={createTheme(theme, esES)}>
          <CustomTable
            fetchedInfo={fetchedInfo}
            isLoadingInfoError={isError}
            isLoadingInfo={isLoadingInfo}
            isFetchingInfo={isFetchingInfo}
            totalPages={totalPages}
            onPageChange={onPageChange}
            refetch={refetch}
            onFilterChange={handleFilterChange}
            setSolicitudesIngresos={setSolicitudesIngresos}
          />
        </ThemeProvider>
      </AprobacionContextProvider>
    </div>
  )
}

export default GridAprobacion
