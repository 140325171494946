import { createContext, useEffect, useState } from "react"

const SolicitudIngresoContext = createContext()

export function SolicitudIngresoContextProvider({ children }) {
  const [clienteSeleccionado, setclienteSeleccionado] = useState("")
  const [clienteSeleccionadoDescri, setclienteSeleccionadoDescri] = useState("")
  const [productoSeleccionado, setproductoSeleccionado] = useState("")
  const [productoSeleccionadoDescri, setproductoSeleccionadoDescri] = useState("")
  const [proveedorSeleccionado, setproveedorSeleccionado] = useState("")
  const [proveedorSeleccionadoDescri, setproveedorSeleccionadoDescri] = useState("")
  const [articuloUsaDecimales, setarticuloUsaDecimales] = useState(0)
  const [productosIngreso, setproductosIngreso] = useState([])

  const [gridState, setGridState] = useState({
    filters: {},
    page: 1,
    solicitudesIngresos: [],
    totalPages: 1,
  })

  const updateGridState = (newState) => {
    setGridState((prevState) => ({
      ...prevState,
      ...newState,
    }))
  }

  const setProductoSeleccionado = (newProducto) => {
    setproductoSeleccionado(newProducto)
  }

  const setProductosIngreso = (newProductos) => {
    setproductosIngreso(newProductos)
  }

  const setArticuloUsaDecimales = (newArtDecimal) => {
    setarticuloUsaDecimales(newArtDecimal)
  }

  const setProductoSeleccionadoDescri = (newProductoDescri) => {
    setproductoSeleccionadoDescri(newProductoDescri)
  }

  const setProveedorSeleccionado = (newProveedor) => {
    setproveedorSeleccionado(newProveedor)
  }

  const setProveedorSeleccionadoDescri = (newDescriProveedor) => {
    setproveedorSeleccionadoDescri(newDescriProveedor)
  }

  const setClienteSeleccionado = (newCliente) => {
    setclienteSeleccionado(newCliente)
  }

  const setClienteSeleccionadoDescri = (newDescricliente) => {
    setclienteSeleccionadoDescri(newDescricliente)
  }

  const myContextData = {
    clienteSeleccionado,
    setclienteSeleccionado,
    clienteSeleccionadoDescri,
    setclienteSeleccionadoDescri,
    productoSeleccionado,
    setproductoSeleccionado,
    productoSeleccionadoDescri,
    setproductoSeleccionadoDescri,
    proveedorSeleccionado,
    setproveedorSeleccionado,
    articuloUsaDecimales,
    setarticuloUsaDecimales,
    productosIngreso,
    setproductosIngreso,
    proveedorSeleccionadoDescri,
    setproveedorSeleccionadoDescri,
    setProductoSeleccionado,
    setProductoSeleccionadoDescri,
    setProveedorSeleccionado,
    setProveedorSeleccionadoDescri,
    setClienteSeleccionado,
    setClienteSeleccionadoDescri,
    setArticuloUsaDecimales,
    setProductosIngreso,
    gridState,
    updateGridState,
  }
  return <SolicitudIngresoContext.Provider value={myContextData}>{children}</SolicitudIngresoContext.Provider>
}

export { SolicitudIngresoContext }
