import { useEffect, useState } from "react"
import Header from "../../layouts/Header"
import { styled, createTheme, ThemeProvider } from "@mui/material/styles"
import { Box, Grid } from "@mui/material"

import BackIcon from "../../components/BackIcon"

import { useParams } from "react-router-dom"

import fetchwrapper from "../../services/interceptors/fetchwrapper"

import CustomBackdrop from "../../components/CustomBackdrop"

const StyledRoot = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  marginTop: theme.spacing(8),
  flexGrow: 1,
  padding: "0 16px",
  height: "100vh",
}))

const theme = createTheme({
  palette: {
    primary: {
      main: "#196C87",
    },
    secondary: {
      main: "#196C87", // Cambia el color secundario a verde azulado
    },
  },
})

const Container = styled("div")({
  height: "80vh",
  overflowX: "scroll",
  overflowY: "scroll",
  display: "flex",
  justifyContent: "center" /* Centra horizontalmente */,
  alignItems: "center" /* Centra verticalmente */,
})

const IframeWrapper = styled("div")({
  width: "100%",
  display: "flex",
  justifyContent: "center" /* Centra horizontalmente */,
  alignItems: "center" /* Centra verticalmente */,
})

const PowerBi = () => {
  const [iframeHtml, setIframeHtml] = useState()
  const [isLoadingPBI, setIsLoadingPBI] = useState(false)
  const [opccaption, setOpccaption] = useState("")
  const { page } = useParams()

  const getPBI = async () => {
    try {
      setIsLoadingPBI(true)
      const response = await fetchwrapper(`/PBI/getPBI/${page}`)
      const result = await response.json()
      setOpccaption(result.data.opccaption)
      setIframeHtml(result.data.opcmenujquery)
    } catch (error) {
      console.error("ERROR: " + error)
    } finally {
      setIsLoadingPBI(false)
    }
  }

  useEffect(() => {
    getPBI()
  }, []) // El array vacío asegura que esto se ejecute solo una vez

  return (
    <ThemeProvider theme={theme}>
      <Header />
      <div className="main main-app p-3 p-lg-4">
        <BackIcon />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "0 30px 30px 30px",
            fontSize: "25px",
          }}
        >
          <b>{opccaption}</b>
        </div>
        <CustomBackdrop isLoading={isLoadingPBI} />

        <Box className={StyledRoot}>
          <Container>
            <IframeWrapper dangerouslySetInnerHTML={{ __html: iframeHtml }} />
          </Container>
        </Box>
      </div>
    </ThemeProvider>
  )
}

export default PowerBi
