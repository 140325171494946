import { useEffect, useState } from "react"
import Header from "../../../layouts/Header"
import { styled, createTheme, ThemeProvider } from "@mui/material/styles"
import { Box, Button } from "@mui/material"

import BackIcon from "../../../components/BackIcon"
import CustomFieldset from "../../../components/CustomFieldset"
import CustomHelperDetail from "../../../components/CustomHelperDetail"
import CustomTooltip from "../../../components/ToolTip"
import CustomDatePicker from "../../../components/CustomDatePicker"
import dayjs from "dayjs"
import CustomTextField from "../../../components/CustomTextField"
import CustomSelect from "../../../components/CustomSelect"
import CustomAutocomplete from "../../../components/CustomAutocomplete"
import fetchwrapper from "../../../services/interceptors/fetchwrapper"
import { useQuery } from "@tanstack/react-query"
import CustomBackdrop from "../../../components/CustomBackdrop"
// import BuscarIcon from "../../../assets/iconos/Buscar.ico"
import GrabarIcon from "../../../assets/iconos/Grabar.ico"
import EjecutarIcon from "../../../assets/iconos/EjecutarAccion.ico"
import CustomMultiSelectCheckmark from "../../../components/CustomMultiSelectCheckmark"
import DatagridAllPickingSugerido from "../components/DatagridAllPickingSugerido"
import { useNavigate } from "react-router-dom"

const StyledRoot = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  marginTop: theme.spacing(8),
  flexGrow: 1,
  padding: "0 16px",
  height: "100vh",
}))

const theme = createTheme({
  palette: {
    primary: {
      main: "#196C87",
    },
    secondary: {
      main: "#196C87", // Cambia el color secundario a verde azulado
    },
  },
})

const ContainerFiltros = styled(Box)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "repeat(12, 1fr)",
  gridTemplateRows: "auto auto auto",
  gridTemplateAreas: `
        "ISolicitudEgreso ISolicitudEgreso IClienteBodega IClienteBodega IClienteBodega IClienteBodega IClienteBodega IClienteBodega IClienteBodega IFechaInicial IFechaInicial IFechaInicial"
        "IEstado IEstado IEstado ITipo ITipo ITipo IUsuario IUsuario IUsuario IFechaFinal IFechaFinal IFechaFinal"
       
      `,
  gap: "8px",
  alignItems: "center",
}))

const ISolicitudEgreso = styled(Box)({
  gridArea: "ISolicitudEgreso",
})

const IClienteBodega = styled(Box)({
  gridArea: "IClienteBodega",
})

const IEstado = styled(Box)({
  gridArea: "IEstado",
})

const ITipo = styled(Box)({
  gridArea: "ITipo",
})

const IUsuario = styled(Box)({
  gridArea: "IUsuario",
})
const IFechaInicial = styled(Box)({
  gridArea: "IFechaInicial",
})

const IFechaFinal = styled(Box)({
  gridArea: "IFechaFinal",
})

const PickingSugeridoBuscar = () => {
  const navigate = useNavigate()
  const [filtros, setFiltros] = useState({
    solicitudEgreso: "",
    clienteBodega: {},
    estados: [],
    tipo: {},
    usuario: null,
    fechaInicial: dayjs(new Date()),
    fechaFinal: dayjs(new Date()),
  })

  const handleSetFiltros = (k, v) => setFiltros((prev) => ({ ...prev, [k]: v }))

  const {
    data: fetchedFilteredPickingsSugeridos = [],
    isError: isLoadingFilteredPickingsSugeridosError,
    isFetching: isFetchingFilteredPickingsSugeridos,
    isLoading: isLoadingFilteredPickingsSugeridos,
    refetch: refetchFilteredPickingsSugeridos,
  } = useGetFilteredPickingsSugeridos()

  function useGetFilteredPickingsSugeridos() {
    return useQuery({
      queryKey: ["fetchedFilteredPickingsSugeridosBuscar"],
      queryFn: async () => {
        const options = {
          method: "POST",
          body: JSON.stringify({
            ...filtros,
          }),
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }
        let response = await fetchwrapper(`/pickingSugerido/searchPickingsSugeridos`, options)
        response = await response.json()

        return response.data
      },
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      enabled: false,
    })
  }

  const handleSearching = () => {
    console.log(filtros, "aqui")
    refetchFilteredPickingsSugeridos()
  }
  const {
    data: fetchedUsuarios = [],
    isError: isLoadingUsuariosoError,
    isFetching: isFetchinUsuarios,
    isLoading: isLoadingUsuarios,
  } = useGetUsuarios()

  function useGetUsuarios() {
    return useQuery({
      queryKey: ["USUARIOSPickingSugerido"],
      queryFn: async () => {
        let response = await fetchwrapper(`/pickingSugerido/getUsuarios`)
        response = await response.json()

        console.log("alerta", response.data)

        return response.data
      },
      refetchOnWindowFocus: false,
      staleTime: Infinity,
    })
  }

  return (
    <ThemeProvider theme={theme}>
      <Header />
      <div className="main main-app p-3 p-lg-4">
        <BackIcon />
        <div style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
          <div style={{ paddingTop: "10px", paddingBottom: "10px" }}>
            <CustomTooltip title={"Buscar"}>
              <Button style={{ fontSize: "0.9vw" }} onClick={handleSearching}>
                <img src={EjecutarIcon} alt="Crear" style={{ width: "40px" }} />
              </Button>
            </CustomTooltip>
          </div>
          <div style={{ display: "flex", alignItems: "center", gap: "16px", padding: "5px" }}>
            <CustomTooltip title={"Crear"}>
              <Button onClick={() => navigate("/home/dashboard/PickingSugerido/crear")}>
                <img src={GrabarIcon} alt="Grabar" style={{ width: "40px" }} />
              </Button>
            </CustomTooltip>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "0 30px 30px 30px",
            fontSize: "25px",
          }}
        >
          <b>Buscar Picking Sugerido</b>
        </div>

        <CustomBackdrop
          isLoading={
            isLoadingUsuarios ||
            isFetchinUsuarios ||
            isLoadingFilteredPickingsSugeridos ||
            isFetchingFilteredPickingsSugeridos
          }
        />

        <Box className={StyledRoot}>
          <CustomFieldset title={"Parámetros de Búsqueda"}>
            <ContainerFiltros>
              <ISolicitudEgreso>
                <CustomTextField
                  label="Solicitud Egreso"
                  value={filtros.solicitudEgreso}
                  setValue={(v) => handleSetFiltros("solicitudEgreso", v)}
                />
              </ISolicitudEgreso>
              <IClienteBodega>
                <CustomHelperDetail
                  valueSearched={filtros.clienteBodega?.clicodigo}
                  label="Cliente"
                  endpoint="/pickingSugerido/getClientesHelper"
                  valueInputMain="clicodigo"
                  valueInputSecondary="clinombre"
                  idSearchField="clicodigo"
                  errorMsgIdSearch="Error fetching data:"
                  errorMsgFilterSearch="Error en cargar datos"
                  queryKeyModal="clienteBodegaPickingSugeridoBuscar"
                  perPage={10}
                  placeholder=""
                  columnsTable={[
                    {
                      accessorKey: "ciacodigo",
                      header: "Ciacodigo",
                      size: 100,
                      Cell: ({ cell }) => {
                        const value = cell.getValue()
                        return <span>{value}</span>
                      },
                    },
                    {
                      accessorKey: "clicodigo",
                      header: "Código de Cliente",
                      size: 100,
                      Cell: ({ cell }) => {
                        const value = cell.getValue()
                        return <span>{value}</span>
                      },
                    },
                    {
                      accessorKey: "clinombre",
                      header: "Nombre del Cliente",
                      size: 250,
                      Cell: ({ cell }) => {
                        const value = cell.getValue()
                        return <span>{value}</span>
                      },
                    },
                    {
                      accessorKey: "cliruc",
                      header: "Número de identificación",
                      size: 150,
                      Cell: ({ cell }) => {
                        const value = cell.getValue()
                        return <span>{value}</span>
                      },
                    },
                    {
                      accessorKey: "clitelef1",
                      header: "Teléfono",
                      size: 100,
                      Cell: ({ cell }) => {
                        const value = cell.getValue()
                        return <span>{value}</span>
                      },
                    },
                    {
                      accessorKey: "clidirec",
                      header: "Dirección",
                      size: 400,
                      Cell: ({ cell }) => {
                        const value = cell.getValue()
                        return <p>{value}</p>
                      },
                    },
                    {
                      accessorKey: "clireferencia1",
                      header: "Referencia Rápida",
                      size: 120,
                      Cell: ({ cell }) => {
                        const value = cell.getValue()
                        return <p>{value}</p>
                      },
                    },
                    {
                      accessorKey: "zoncodigo",
                      header: "Zona",
                      size: 120,
                      Cell: ({ cell }) => {
                        const value = cell.getValue()
                        return <span>{value}</span>
                      },
                    },
                    {
                      accessorKey: "clistatus",
                      header: "Estado",
                      size: 120,
                      Cell: ({ cell }) => {
                        const value = cell.getValue()
                        if (value === "A") return "Activo"
                        if (value === "I") return "Inactivo"
                        return <span>{value}</span>
                      },
                    },
                  ]}
                  onHandleSelectedData={(v) => handleSetFiltros("clienteBodega", v)}
                  sxInputMain={{
                    minWidth: "170px",
                    maxWidth: "170px",
                    marginRight: "10px",
                  }}
                  sxInputSecondary={{
                    width: "100%",
                  }}
                />
              </IClienteBodega>

              <IEstado>
                <CustomMultiSelectCheckmark
                  label="Estados"
                  selectedOptions={filtros.estados}
                  setSelectedOptions={(v) => handleSetFiltros("estados", v)}
                  options={[
                    { value: "PENDIENTE", label: "PENDIENTE" },
                    { value: "NEGADA", label: "NEGADA" },
                    { value: "CERRADA", label: "CERRADA" },
                    { value: "RECIBIDA TOTAL", label: "RECIBIDA TOTAL" },
                    { value: "RECIBIDA PARCIAL", label: "RECIBIDA PARCIAL" },
                  ]}
                />
              </IEstado>

              <ITipo>
                <CustomSelect
                  label="Tipo"
                  selectedOption={filtros.tipo}
                  setSelectedOption={(v) => handleSetFiltros("tipo", v)}
                  options={[
                    { value: "INDIVIDUAL", label: "INDIVIDUAL" },
                    { value: "TANDA", label: "TANDA" },
                    { value: "MULTIBATCH", label: "MULTI-BATCH" },
                    { value: "ZONA", label: "ZONA" },
                    { value: "OLA", label: "OLA" },
                  ]}
                />
              </ITipo>
              <IUsuario>
                <CustomAutocomplete
                  label="Usuario"
                  selectedOption={filtros.usuario}
                  setSelectedOption={(v) => handleSetFiltros("usuario", v)}
                  options={fetchedUsuarios}
                />
              </IUsuario>

              <IFechaInicial>
                <CustomDatePicker
                  label="Fecha Inicial"
                  value={filtros.fechaInicial}
                  setValue={(v) => handleSetFiltros("fechaInicial", v)}
                  isOptional={true}
                  // slotProps={{ textField: { fullWidth: true } }}
                />
              </IFechaInicial>

              <IFechaFinal>
                <CustomDatePicker
                  label="Fecha Final"
                  value={filtros.fechaFinal}
                  setValue={(v) => handleSetFiltros("fechaFinal", v)}
                  isOptional={true}
                  // slotProps={{ textField: { fullWidth: true } }}
                />
              </IFechaFinal>
            </ContainerFiltros>
          </CustomFieldset>
          <br />
          <br />

          <DatagridAllPickingSugerido
            fetchedInfo={fetchedFilteredPickingsSugeridos}
            isLoadingInfoError={isLoadingFilteredPickingsSugeridosError}
            isLoadingInfo={isLoadingFilteredPickingsSugeridos}
            isFetchingInfo={isFetchingFilteredPickingsSugeridos}
            columnsTable={[
              {
                accessorKey: "Cia",
                header: "Cía",
                size: 100,
                Cell: ({ cell }) => {
                  const value = cell.getValue()
                  return <span>{value}</span>
                },
              },
              {
                accessorKey: "Picking",
                header: "Picking",
                size: 150,
                Cell: ({ cell }) => {
                  const value = cell.getValue()
                  return <span>{value}</span>
                },
              },
              {
                accessorKey: "Fecha_Emision",
                header: "Fecha Emisión",
                size: 200,
                Cell: ({ cell }) => {
                  const value = new Date(cell.getValue()).toLocaleDateString()
                  return <span>{value}</span>
                },
              },
              {
                accessorKey: "Hora_Emision",
                header: "Hora Emisión",
                size: 100,
                Cell: ({ cell }) => {
                  const value = cell.getValue()
                  return <span>{value}</span>
                },
              },
              {
                accessorKey: "Fecha_Picking",
                header: "Fecha Picking",
                size: 200,
                Cell: ({ cell }) => {
                  const value = new Date(cell.getValue()).toLocaleDateString()
                  return <span>{value}</span>
                },
              },
              {
                accessorKey: "Hora_Picking",
                header: "Hora Picking",
                size: 100,
                Cell: ({ cell }) => {
                  const value = cell.getValue()
                  return <span>{value}</span>
                },
              },
              {
                accessorKey: "Comentario_Picking",
                header: "Comentario Picking",
                size: 250,
                Cell: ({ cell }) => {
                  const value = cell.getValue()
                  return <span>{value}</span>
                },
              },
              {
                accessorKey: "Usuario_Picking",
                header: "Usuario Picking",
                size: 150,
                Cell: ({ cell }) => {
                  const value = cell.getValue()
                  return <span>{value}</span>
                },
              },
              {
                accessorKey: "Tipo_Picking",
                header: "Tipo Picking",
                size: 100,
                Cell: ({ cell }) => {
                  const value = cell.getValue()
                  return <span>{value}</span>
                },
              },
              {
                accessorKey: "Estado",
                header: "Estado",
                size: 100,
                Cell: ({ cell }) => {
                  const value = cell.getValue()
                  return <span>{value}</span>
                },
              },
            ]}
          />
        </Box>
      </div>
    </ThemeProvider>
  )
}

export default PickingSugeridoBuscar
