import React, { useState, useContext } from "react"
import { SolicitudIngresoContextProvider } from "../SolicitudIngresoContext"
import Header from "../../../layouts/Header"
import { styled, createTheme, ThemeProvider } from "@mui/material/styles"
import BuscarSolicitud from "../components/BuscarSolicitud"

const theme = createTheme({
  palette: {
    primary: {
      main: "#196C87",
    },
    secondary: {
      main: "#196C87", // Cambia el color secundario a verde azulado
    },
  },
})

const BuscarSolicitudDeIngreso = () => {
  return (
    <SolicitudIngresoContextProvider>
      <ThemeProvider theme={theme}>
        <Header />
        <BuscarSolicitud />
      </ThemeProvider>
    </SolicitudIngresoContextProvider>
  )
}

export default BuscarSolicitudDeIngreso
