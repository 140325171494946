import React, { useState, useContext } from "react"

import Header from "../../layouts/Header"
import { styled, createTheme, ThemeProvider } from "@mui/material/styles"
import BuscarClienteComponent from "./components/BuscarClienteComponent"
const theme = createTheme({
  palette: {
    primary: {
      main: "#196C87",
    },
    secondary: {
      main: "#196C87", // Cambia el color secundario a verde azulado
    },
  },
})

const AsociacionBodegasCliente = () => {
  return (
    <ThemeProvider theme={theme}>
      <Header />
      <b>Asociación de Bodegas a Clientes</b>
      <BuscarClienteComponent></BuscarClienteComponent>
    </ThemeProvider>
  )
}

export default AsociacionBodegasCliente
