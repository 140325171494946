import React, { useEffect, useState, useContext } from "react"
import { useNavigate } from "react-router-dom"

import Header from "../../../layouts/Header"
import { styled, createTheme, ThemeProvider } from "@mui/material/styles"
import {
  Box,
  TextField,
  InputLabel,
  Button,
  Typography,
  FormControl,
  Select,
  FormHelperText,
  MenuItem,
  Tooltip,
} from "@mui/material"
import DetalleImpresion from "../../Zonas/components/DetalleImpresion"
import Swal from "sweetalert2"
import ImprimirIcon from "../../../assets/iconos/Impresion.ico"
import BackIcon from "../../../components/BackIcon"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"
import fetchwrapper from "../../../services/interceptors/fetchwrapper"
import { useQuery, queryClient, useQueryClient, useMutation } from "@tanstack/react-query"
import CustomAutoComplete from "../../SolicitudDeIngreso/components/CustomAutoComplete"
import CrearIcon from "../../../assets/iconos/Crear.ico"
import BuscarIcon from "../../../assets/iconos/Buscar.ico"
import CustomTooltip from "../../../components/ToolTip"
import Accordion from "@mui/material/Accordion"
import AccordionDetails from "@mui/material/AccordionDetails"
import AccordionSummary from "@mui/material/AccordionSummary"
import ExpandMore from "@mui/icons-material/ExpandMore"
import { SelectAllSharp } from "@mui/icons-material"
import BahiaPdf from "./BahiaPdf"
import GridBahia from "./GridBahia"
import { BahiaContext } from "./BahiaContext"
import EjecutarAccionIcon from "../../../assets/iconos/EjecutarAccion.ico"
// Campos de busqueda
const CodigoBox = styled(Box)({
  gridArea: "Codigo",
})
const DescripcionBox = styled(Box)({
  gridArea: "Descripcion",
})
const BodegaBox = styled(Box)({
  gridArea: "Bodega",
})
const ZonaBox = styled(Box)({
  gridArea: "Zona",
})
const PasilloBox = styled(Box)({
  gridArea: "Pasillo",
})
const M3Box = styled(Box)({
  gridArea: "M3",
})
const ClasificacionBox = styled(Box)({
  gridArea: "Clasificacion",
})
const EstadoBox = styled(Box)({
  gridArea: "Estado",
})
// Contenedor de campos de busqueda
const Container = styled(Box)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "repeat(12, 1fr)",
  gridTemplateRows: "auto auto auto",
  gridTemplateAreas: `
    "Codigo Codigo Codigo Codigo Descripcion Descripcion Descripcion Descripcion Descripcion Descripcion Descripcion Descripcion"
    "Bodega Bodega Bodega Bodega Zona Zona Zona Zona Pasillo Pasillo Pasillo Pasillo"
    "M3 M3 M3 M3 Estado Estado Estado Estado Clasificacion Clasificacion Clasificacion Clasificacion"
  `,
  gap: "8px",
  alignItems: "center",

  [theme.breakpoints.down("sm")]: {
    gridTemplateColumns: "repeat(6, 1fr)",
    gridTemplateAreas: `
      "Codigo Codigo Codigo Descripcion Descripcion Descripcion"
      "Bodega Bodega Bodega Zona Zona Zona"
      "Pasillo Pasillo Pasillo M3 M3 M3"
      "Estado Estado Estado Clasificacion Clasificacion Clasificacion"
    `,
  },
}))
const BuscarBahiaComponent = () => {
  const [expanded, setExpanded] = useState(false)
  // estados de los filtros
  const [error, setError] = useState(false)
  const [m3, setM3] = useState("")
  const [codigo, setCodigo] = useState("")
  const [descripcion, setDescripcion] = useState("")
  const [bodegas, setBodegas] = useState([])
  const [bodega, setBodega] = useState("")
  const [bodegaDescripcion, setBodegaDescripcion] = useState("")
  const [autoCompleteValueBodega, setAutoCompleteValueBodega] = useState("")
  const [zonas, setZonas] = useState([])
  const [zona, setZona] = useState("")
  const [zonaDescripcion, setZonaDescripcion] = useState("")
  const [autoCompleteValueZona, setAutoCompleteValueZona] = useState("")
  const [pasillos, setPasillos] = useState([])
  const [pasillo, setPasillo] = useState("")
  const [pasilloDescripcion, setPasilloDescripcion] = useState("")
  const [autoCompleteValuePasillo, setAutoCompleteValuePasillo] = useState("")
  const [clasificaciones, setClasificaciones] = useState([])
  const [clasificacion, setClasificacion] = useState("")
  const [autoCompleteValueClasificacion, setAutoCompleteValueClasificacion] = useState("")
  const [searchAttempted, setSearchAttempted] = useState(false)
  const [bahias, setBahias] = useState([])
  const [isFirstTime, setFirstTime] = useState(true)
  const [openModal, setOpenModal] = useState(false)
  const [infoGenerada, setInfoGenerada] = useState([])

  const handleCodigoBahia = (event) => {
    setCodigo(event.target.value)
  }
  useEffect(() => {
    setCodigo(codigo)
    console.log(codigo)
  }, [codigo])
  const handleDescripcionBahia = (event) => {
    setDescripcion(event.target.value)
  }
  useEffect(() => {
    setDescripcion(descripcion)
    console.log(descripcion)
  }, [descripcion])
  const handleExpandAccordion = () => {
    setExpanded((prevExpanded) => !prevExpanded)
  }
  // bodegas
  const {
    data: dataBodegas = [],
    isError: dataBodegasError,
    isFetching: isFetchingBodegas,
    isLoading: isLoadingBodegas,
    refetch: refetchGetAllBodegas,
  } = useGetAllBodegas()
  function useGetAllBodegas() {
    return useQuery({
      queryKey: ["BahiasBodegas"],
      queryFn: async () => {
        const response = await fetchwrapper("/Bodegas/getBodegas", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        })
        const result = await response.json()
        return result.bodegas
      },
      keepPreviousData: true,
      enabled: true,
      onError: (error) => {
        console.log("Error fetching data", error)
      },
    })
  }
  const handleBodegaChange = (selectedValue) => {
    const [codigo, descripcion] = selectedValue.split("-")
    setBodega(codigo)
    setBodegaDescripcion(descripcion)
    setAutoCompleteValueBodega(selectedValue)
    console.log(bodega, bodegaDescripcion)
  }
  useEffect(() => {
    setBodega(bodega)
    setBodegaDescripcion(bodegaDescripcion)
  }, [bodega])
  // zonas
  const {
    data: dataZonas = [],
    isError: dataZonasError,
    isFetching: isFetchingZonas,
    isLoading: isLoadingZonas,
    refetch: refetchGetAllZonas,
  } = useGetAllZonas()
  function useGetAllZonas() {
    return useQuery({
      queryKey: ["BahiasZonas"],
      queryFn: async () => {
        const response = await fetchwrapper("/Bahias/getZonas", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        })
        const result = await response.json()
        return result.zonas
      },
      keepPreviousData: true,
      enabled: true,
      onError: (error) => {
        console.log("Error fetching data", error)
      },
    })
  }
  const handleZonaChange = (selectedValue) => {
    const [codigo, descripcion] = selectedValue.split("-")
    setZona(codigo)
    setZonaDescripcion(descripcion)
    setAutoCompleteValueZona(selectedValue)
    console.log(zona, zonaDescripcion)
  }
  useEffect(() => {
    setZona(zona)
    setZonaDescripcion(zonaDescripcion)
  }, [zona])

  // pasillo
  const {
    data: dataPasillos = [],
    isError: dataPasillosError,
    isFetching: isFetchingPasillos,
    isLoading: isLoadingPasillos,
    refetch: refetchGetAllPasillos,
  } = useGetAllPasillos()
  function useGetAllPasillos() {
    return useQuery({
      queryKey: ["BahiasPasillos"],
      queryFn: async () => {
        const response = await fetchwrapper("/Bahias/getPasillos", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        })
        const result = await response.json()
        return result.pasillos
      },
      keepPreviousData: true,
      enabled: true,
      onError: (error) => {
        console.log("Error fetching data", error)
      },
    })
  }
  const handlePasilloChange = (selectedValue) => {
    const [codigo, descripcion] = selectedValue.split("-")
    setPasillo(codigo)
    setPasilloDescripcion(descripcion)
    setAutoCompleteValuePasillo(selectedValue)
    console.log(pasillo, pasilloDescripcion)
  }
  useEffect(() => {
    setPasillo(pasillo)
    setPasilloDescripcion(pasilloDescripcion)
  }, [pasillo])
  // clasificaciones

  const {
    data: dataClasificaciones = [],
    isError: dataClasificacionesError,
    isFetching: isFetchingClasificaciones,
    isLoading: isLoadingClasificaciones,
    refetch: refetchGetAllClasificaciones,
  } = useGetAllClasificaciones()
  function useGetAllClasificaciones() {
    return useQuery({
      queryKey: ["BahiasClasificaciones"],
      queryFn: async () => {
        const response = await fetchwrapper("/Bahias/getClasificacion", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        })
        const result = await response.json()
        const clases = result.clasificaciones
        const clasificacionList = clases.map((item) => item.bahclasi)
        return clasificacionList
      },
      keepPreviousData: true,
      enabled: true,
      onError: (error) => {
        console.log("Error fetching data", error)
      },
    })
  }
  const handleClasificacionChange = (selectedValue) => {
    console.log(dataClasificaciones)
    setClasificacion(selectedValue)
    setAutoCompleteValueClasificacion(selectedValue)
    console.log(selectedValue)
  }
  useEffect(() => {
    setClasificacion(clasificacion)
  }, [clasificacion])
  const Estados = [
    { value: "A", description: "ACTIVO" },
    { value: "I", description: "INACTIVO" },
  ]

  const [estadoBahia, setEstadoBahia] = useState(Estados[0])
  function useGetBahiasXFiltro() {
    return useQuery({
      queryKey: ["bahiasXFiltro"],
      queryFn: async () => {
        const options = {
          method: "POST",
          body: JSON.stringify({
            codigoBahia: codigo,
            descripcionBahia: descripcion,
            zonaBahia: zona,
            pasilloBahia: pasillo,
            bodegaBahia: bodega,
            metrosCubicos: m3,
            clasificacionBahia: clasificacion,
            estadoBahia: estadoBahia.value,
          }),
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }
        console.log(options.body)
        // send api request here
        let response = await fetchwrapper("/Bahias/getBahiasFiltro", options)
        response = await response.json()
        console.log(response)
        return response.bahiasDiccionario
      },
      refetchOnWindowFocus: false,
      refetchOnReconnect: false, // Añade esta línea para evitar refetch al reconectar
      enabled: !searchAttempted && !isFirstTime,
    })
  }
  const resetAllFilters = () => {
    setM3("")
    setCodigo("")
    setDescripcion("")
    setBodegas([])
    setBodega("")
    setAutoCompleteValueBodega("")
    setZonas([])
    setZona("")
    setAutoCompleteValueZona("")
    setPasillos([])
    setPasillo("")
    setAutoCompleteValuePasillo("")
    setClasificaciones([])
    setClasificacion("")
    setAutoCompleteValueClasificacion("")
  }
  const {
    data: fetchedBahias = [],
    isError: isLoadingBahiasError,
    isFetching: isFetchingBahias,
    isLoading: isLoadingBahias,
    refetch: refetchBahias,
  } = useGetBahiasXFiltro()
  const handleSearch = () => {
    setSearchAttempted(true)
    setFirstTime(false)
    setExpanded(false)
    refetchBahias()
    console.log("refetch", refetchBahias)
    resetAllFilters()
    console.log("fetchedBahias", fetchedBahias)
  }

  const navigate = useNavigate()
  const theme = createTheme({
    palette: {
      primary: {
        main: "#196C87",
      },
      secondary: {
        main: "#196C87",
      },
    },
  })

  // setea el valor de M3 y maneja que el valor sea numerico
  const handleNumericInput = (value, setValue) => {
    // Allow empty value, integers, or decimals
    if (/^\d*\.?\d*$/.test(value)) {
      setM3(value)
      setError(false) // No error if the input is valid
    } else {
      setError(true) // Show error if the input is invalid
    }
    console.log(m3)
  }
  useEffect(() => {
    setM3(m3)
    console.log(m3)
  }, [m3])
  useEffect(() => {
    console.log(searchAttempted)
    // Actualiza el estado de bahias con los datos recibidos
    if (searchAttempted) {
      setBahias(fetchedBahias)
    }
  }, [searchAttempted, fetchedBahias])
  useEffect(() => {
    // Verifica si la búsqueda fue intentada y los datos ya no están cargándose
    if (searchAttempted && !isLoadingBahias && !isFetchingBahias && !isFirstTime) {
      console.log("filtros limpioss")
      resetAllFilters()
    }
  }, [isFetchingBahias, isLoadingBahias, searchAttempted])
  // delete
  const { mutateAsync: deleteBahia, isPending: isDeletingBahia } = useDeleteBahia()
  // funcion que muestra el modal de eliminacion
  const openDeleteConfirmModal = async (row) => {
    const result = await Swal.fire({
      title: "¿Está seguro que quiere eliminar esta bahia?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sí, eliminar",
      cancelButtonText: "Cancelar",
    })

    if (result.isConfirmed) {
      try {
        await deleteBahia(row.original)
        // Actualizar el estado de bahias después de la eliminación
        setBahias((prevBahias) => prevBahias.filter((b) => b.bahcodigo !== row.original.bahcodigo))

        Swal.fire("Eliminado!", "La bahia ha sido eliminada.", "success")
      } catch (error) {
        Swal.fire("Error", "No se pudo eliminar la bahia.", "error")
      }
    }
  }
  function useDeleteBahia() {
    const queryClient = useQueryClient()
    return useMutation({
      mutationFn: async (bahia) => {
        const options = {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }
        console.log("bahia", bahia.bahcodigo)
        console.log("bodega", bahia.bodcodigo)
        const bahiaCod = bahia.bahcodigo
        const bodegaCod = bahia.bodcodigo
        const pasilloCod = bahia.pascodigo
        const zonaCod = bahia.izoncodigo
        console.log(`/Bahias/deleteBahia/${bahiaCod}/${bodegaCod}/${pasilloCod}/${zonaCod}`)
        const response = await fetchwrapper(
          `/Bahias/deleteBahia/${bahiaCod}/${bodegaCod}/${pasilloCod}/${zonaCod}`,
          options,
        )
        return response
      },
      // actualizacion optimista
      onMutate: (bahia) => {
        queryClient.setQueryData(["Bahias"], (prevBahias) => prevBahias?.filter((b) => b.bahcodigo !== bahia.bahcodigo))
      },
      onSettled: () => {
        // Invalidate and refetch
        queryClient.invalidateQueries(["bahiaXFiltro"])
      },
    })
  }
  const handleOpenModal = () => {
    setInfoGenerada(fetchedBahias) // Asigna los datos de la tabla al estado
    setOpenModal(true)
  }
  console.log("info pdf", fetchedBahias)
  const handleCloseModal = () => {
    setOpenModal(false)
    navigate("/home/dashboard/Bahias/buscar")
  }
  return (
    <ThemeProvider theme={theme}>
      <Header />

      <div className="main main-app p-3 p-lg-4">
        <BackIcon />
        <div style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
          <div style={{ paddingTop: "10px", paddingBottom: "10px" }}>
            <CustomTooltip title={"Buscar Bahia"}>
              <Button style={{ fontSize: "0.9vw" }} onClick={handleSearch}>
                <img src={EjecutarAccionIcon} alt="Crear" style={{ width: "40px" }} />
              </Button>
            </CustomTooltip>
            <Tooltip title="Imprimir">
              <Button color="primary" onClick={handleOpenModal} sx={{ marginBottom: "15px" }}>
                <img src={ImprimirIcon} alt="Imprimir" style={{ width: "30px" }} />
              </Button>
            </Tooltip>
            <DetalleImpresion
              open={openModal}
              handleClose={handleCloseModal}
              infoGenerada={fetchedBahias}
              nombrePdf={"Bahia"}
              PdfComponent={BahiaPdf}
            />
          </div>
        </div>
        <div style={{ paddingBottom: "10px" }}>
          <Accordion expanded={expanded} onChange={handleExpandAccordion} slotProps={{ heading: { component: "h2" } }}>
            <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1a-content" id="panel1a-header">
              <Typography variant="h6" style={{ width: "100%", textAlign: "center" }}>
                Criterios de búsqueda
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Container>
                <CodigoBox>
                  <InputLabel>Codigo</InputLabel>
                  <TextField fullWidth value={codigo} onChange={handleCodigoBahia} />
                </CodigoBox>
                <DescripcionBox>
                  <InputLabel>Descripcion</InputLabel>
                  <TextField fullWidth value={descripcion} onChange={handleDescripcionBahia} />
                </DescripcionBox>
                <BodegaBox>
                  <InputLabel>Bodega</InputLabel>
                  <CustomAutoComplete
                    items={dataBodegas}
                    onSelect={handleBodegaChange}
                    placeholder={"Buscar Bodega"}
                    value={autoCompleteValueBodega}
                  />
                </BodegaBox>
                <ZonaBox>
                  <InputLabel>Zona</InputLabel>
                  <CustomAutoComplete
                    items={dataZonas}
                    onSelect={handleZonaChange}
                    placeholder={"Buscar Zona"}
                    value={autoCompleteValueZona}
                  />
                </ZonaBox>
                <PasilloBox>
                  <InputLabel>Pasillo</InputLabel>
                  <CustomAutoComplete
                    items={dataPasillos}
                    onSelect={handlePasilloChange}
                    placeholder={"Buscar Pasillo"}
                    value={autoCompleteValuePasillo}
                  />
                </PasilloBox>
                <M3Box>
                  <InputLabel>M3</InputLabel>
                  <FormControl fullWidth error={error}>
                    <TextField
                      value={m3}
                      onChange={(e) => handleNumericInput(e.target.value)}
                      error={error} // Set error state in TextField
                      helperText={error ? "Por favor ingresa solo valores numéricos." : ""}
                    />
                    {error && <FormHelperText>El valor ingresado no es válido.</FormHelperText>}
                  </FormControl>
                </M3Box>
                <ClasificacionBox>
                  <InputLabel>Clasificacion</InputLabel>
                  <CustomAutoComplete
                    items={dataClasificaciones}
                    onSelect={handleClasificacionChange}
                    placeholder={"Buscar Clasificacion"}
                    value={autoCompleteValueClasificacion}
                  />
                </ClasificacionBox>
                <EstadoBox>
                  <InputLabel>Estado</InputLabel>
                  <Select
                    fullWidth
                    disabled
                    labelId="estadoBahiasCB"
                    value={estadoBahia.value}
                    onChange={(e) => {
                      const selectedValue = e.target.value
                      const bahiaestado = Estados.find((estado) => estado.value === selectedValue)
                      setEstadoBahia(bahiaestado)
                      //   clearAll(tipoPregunta)
                    }}
                  >
                    {Estados.map((estado) => (
                      <MenuItem key={estado.value} value={estado.value}>
                        {estado.description}
                      </MenuItem>
                    ))}
                  </Select>
                </EstadoBox>
              </Container>
            </AccordionDetails>
          </Accordion>
        </div>
        <GridBahia
          fetchedInfo={bahias}
          isLoadingInfoError={isLoadingBahiasError}
          isLoadingInfo={isLoadingBahias}
          isFetchingInfo={isFetchingBahias}
          searchAttempted={searchAttempted}
          setBahias={setBahias}
          refetch={refetchBahias}
          deleteFunction={openDeleteConfirmModal}
        ></GridBahia>
      </div>
    </ThemeProvider>
  )
}
export default BuscarBahiaComponent
