// import React from "react";

// import FiltrarProductos from "./FiltrarProductos/FiltrosCatalogosProductos";
// import ProductNotifications from "../../../components/ProductNotifications";
// import { useCartStore } from "../../../stores/cartStore";
// import CloseIcon from "@mui/icons-material/Close";
// import { IconButton } from "@mui/material";
// import fetchwrapper from "../../../services/interceptors/fetchwrapper";
// import { useMutation } from "@tanstack/react-query";

// const ModalAddProduct = ({
//   detallesProforma,
//   setDetallesProforma,
//   idProforma,
//   handleCloseModalAddProduct,
// }) => {
//   const { cart,clearUseCartStoreStorage } = useCartStore();
//   // Función para fusionar los arreglos
//   function fusionarArreglosPorCodigo(arr1, arr2, key) {
//     const map = {}; // Mapa para almacenar los elementos del primer arreglo

//     // Convertir el primer arreglo en un mapa con claves únicas basadas en el código
//     arr1.forEach((obj) => {
//       map[obj[key]] = obj;
//     });

//     // Iterar sobre el segundo arreglo
//     arr2.forEach((obj) => {
//       // Verificar si el código ya existe en el mapa
//       if (map.hasOwnProperty(obj[key])) {
//         // Si existe, sobrescribir el elemento en el primer arreglo
//         map[obj[key]] = obj;
//       } else {
//         // Si no existe, agregarlo al mapa
//         map[obj[key]] = obj;
//       }
//     });

//     // Convertir el mapa nuevamente en un arreglo
//     const mergedArray = Object.values(map);
//     return mergedArray;
//   }

//   //call UPDATE hook
//   const { mutateAsync: addFatPedDetalle//, isPending: isCreatingProforma
// } = useAddFatPedDetalle();

//   //UPDATE hook (put Proforma in api)
//   function useAddFatPedDetalle() {
//     return useMutation({
//       mutationFn: async (proforma) => {
//         //send api update request here
//         const options = {
//           method: "POST",
//           body: JSON.stringify({
//             ...proforma,
//           }),
//           headers: {
//             "Content-Type": "application/json",
//             "Access-Control-Allow-Origin": "*",
//           },
//         };

//         let response = await fetchwrapper("/proformas/addFatpedDetalle", options);
//         response = await response.json();

//         return response;
//       },
//     });
//   }

//   const handleAddFatPedDetalle = async () => {
//     const proforma = {
//       cabecera: {"pednumped":idProforma},
//       detalle: detallesProforma,
//     };
//     console.log("addFadPedDetalle...");
//     console.log(detallesProforma)
//     try {
//       if(idProforma){
//         const response = await addFatPedDetalle(proforma);
//         clearUseCartStoreStorage();
//         alert(`${response["mensaje"]??"Exito"}`);
//       }

//       //navigate(`/home/dashboard/Proforma/${response}`);
//     } catch (error) {
//       alert("No se pudo crear la proforma");
//     }
//   };

//   const handleProductsProfoma = () => {
//     //Remove duplictes products with the last row created

//     //Aqui llamar al api para agregar los nuevos productos
//     console.log(detallesProforma)
//     //handleAddFatPedDetalle();

//     const newDetalle = fusionarArreglosPorCodigo(
//       detallesProforma,
//       cart,
//       "codigo"
//     );

//     setDetallesProforma(newDetalle);
//       console.log(newDetalle);
//       console.log("33333333322")
//     //Close modal add product
//     handleCloseModalAddProduct();
//   };
//   return (
//     <div>
//       <div style={{ marginLeft: "96%" }}>
//         <IconButton onClick={handleCloseModalAddProduct}>
//           <CloseIcon />
//         </IconButton>
//       </div>

//       <h1 style={{ textAlign: "center" }}>Proforma {idProforma}</h1>
//       <div style={{ marginLeft: "94%" }}>
//         <ProductNotifications
//           type="modal"
//           handleProductsProfoma={handleProductsProfoma}
//         />
//       </div>
//       <FiltrarProductos />
//     </div>
//   );
// };

// export default ModalAddProduct;

// ModalAddProduct.js
import React from "react"
import FiltrarProductos from "./FiltrarProductos/FiltrosCatalogosProductos"
import ProductNotifications from "../../../components/ProductNotifications"
import { useCartStore } from "../../../stores/cartStore"
import CloseIcon from "@mui/icons-material/Close"
import { IconButton } from "@mui/material"
import fetchwrapper from "../../../services/interceptors/fetchwrapper"
import { useMutation } from "@tanstack/react-query"

const ModalAddProduct = ({ idProforma, handleCloseModalAddProduct }) => {
  const { cart, clearUseCartStoreStorage } = useCartStore()

  // call UPDATE hook
  const { mutateAsync: addFatPedDetalle, isPending: isCreatingProforma } = useAddFatPedDetalle()

  // UPDATE hook (put Proforma in api)
  function useAddFatPedDetalle() {
    return useMutation({
      mutationFn: async (proforma) => {
        // send api update request here
        const options = {
          method: "POST",
          body: JSON.stringify({
            ...proforma,
          }),
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }

        let response = await fetchwrapper("/proformas/addFatpedDetalle", options)
        response = await response.json()

        return response
      },
    })
  }

  const handleAddFatPedDetalle = async () => {
    const proforma = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      cabecera: { pednumped: idProforma },
      detalle: cart, // Solo se envían los elementos del carrito
    }
    console.log(proforma)
    try {
      if (idProforma) {
        const response = await addFatPedDetalle(proforma)
        clearUseCartStoreStorage()
        alert(`${response.mensaje ?? "Éxito"}`)
      }
    } catch (error) {
      alert("No se pudo agregar detalles " + error?.message)
    }
  }

  return (
    <div>
      <div style={{ marginLeft: "96%" }}>
        <IconButton onClick={handleCloseModalAddProduct}>
          <CloseIcon />
        </IconButton>
      </div>

      <h1 style={{ textAlign: "center" }}>Proforma {idProforma}</h1>
      <div style={{ marginLeft: "94%" }}>
        <ProductNotifications
          type="modal"
          handleProductsProfoma={handleAddFatPedDetalle} // Llama directamente a la función para agregar los detalles a la proforma
        />
      </div>
      <FiltrarProductos />
    </div>
  )
}

export default ModalAddProduct
