import React, { useState, useEffect } from "react"
import Header from "../../../layouts/Header"
import { styled, createTheme, ThemeProvider } from "@mui/material/styles"
import { Box, InputLabel, Select, MenuItem, TextField, Button, Tooltip } from "@mui/material"
import Alert from "@mui/material/Alert"
import Swal from "sweetalert2"
import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import BackIcon from "../../../components/BackIcon"
import GrabarIcon from "../../../assets/iconos/Grabar.ico"
import fetchwrapper from "../../../services/interceptors/fetchwrapper"
import CustomBackdrop from "../../../components/CustomBackdrop"

const StyledRoot = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  marginTop: theme.spacing(8),
  flexGrow: 1,
  padding: "0 16px",
  height: "100vh",
}))
const theme = createTheme({
  palette: {
    primary: {
      main: "#196C87",
    },
    secondary: {
      main: "#196C87", // Cambia el color secundario a verde azulado
    },
  },
})
const CrearNivel = () => {
  const navigate = useNavigate()

  // Valores por defectos para los comboboxes y el check
  const Estados = [
    { value: "A", description: "ACTIVO" },
    { value: "I", description: "INACTIVO" },
  ]
  // Estados para manejaar el formulario de opciones
  // cuando llame a las funciones set, se actualizara el valor de los otros campos y se re renderiza
  const [codigo, setCodigo] = useState("")
  const [descripcion, setDescripcion] = useState("")
  const [estadoNivel, setEstadoNivel] = useState(Estados[0])

  const { mutateAsync: createNivel, isPending: isCreatingNivel } = useCreateNivel()

  function useCreateNivel() {
    const queryClient = useQueryClient()
    return useMutation({
      mutationFn: async (nivel) => {
        // send api update request here
        // send api update request here
        const options = {
          method: "POST",
          body: JSON.stringify(nivel),
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }
        console.log("json", options.body)
        let response = await fetchwrapper(`/Niveles/createNivel`, options)

        response = response.json()
        console.log(response)
        return response
      },
      // client side optimistic update
      onMutate: (newNivel) => {
        console.log(newNivel, "onmutate")
        queryClient.setQueryData(["Nivel", newNivel.id], (prevNivel) => ({
          ...newNivel,
        }))
      },
      // onSettled: () => queryClient.invalidateQueries({ queryKey: ['bancoDePreguntas'] }), //refetch bancoDePreguntas after mutation, disabled for demo
    })
  }
  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      const nivel = {
        codigoNivel: codigo,
        descripcionNivel: descripcion,
        estadoNivel: estadoNivel.value,
      }
      await createNivel(nivel)
      Swal.fire({
        icon: "success",
        title: "Operación exitosa",
        text: "Se pudo crear el nivel",
      }).then((result) => {
        if (result.isConfirmed) {
          navigate("/home/dashboard/Niveles") // Navegar a la ruta especificada
        }
      })
    } catch (error) {
      Swal.fire({
        title: "Error",
        text: "No se pudo crear el nivel",
        icon: "error",
        confirmButtonText: "OK",
      }).then(() => {
        navigate("/home/dashboard/Niveles") // Navega de vuelta cuando se cierre el SweetAlert
      })
    }
  }
  return (
    <ThemeProvider theme={theme}>
      <Header />
      <div className="main main-app p-3 p-lg-4">
        <BackIcon />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "0 30px 30px 30px",
            fontSize: "25px",
          }}
        >
          <b>Niveles de bahias</b>
        </div>
        <Box className={StyledRoot}>
          <form onSubmit={handleSubmit}>
            <Tooltip title="Grabar">
              <Button type="submit" color="primary" sx={{ marginTop: "15px" }}>
                <img src={GrabarIcon} alt="Grabar" style={{ width: "40px" }} />
              </Button>
            </Tooltip>
            <TextField
              label="Código"
              margin="normal"
              fullWidth
              value={codigo}
              required
              onChange={(e) => setCodigo(e.target.value)}
            />
            <TextField
              label="Descripción"
              margin="normal"
              fullWidth
              value={descripcion}
              onChange={(e) => setDescripcion(e.target.value)}
              required
            />
            <div>
              <InputLabel id="estadoNivelesCB" sx={{ paddingBlock: "15px", paddingLeft: "5px" }}>
                Estado del nivel
              </InputLabel>
              <Select
                fullWidth
                disabled
                labelId="estadoPasillosCB"
                value={estadoNivel.value}
                onChange={(e) => {
                  const selectedValue = e.target.value
                  const nivelestado = Estados.find((estado) => estado.value === selectedValue)
                  setEstadoNivel(nivelestado)
                }}
              >
                {Estados.map((estado) => (
                  <MenuItem key={estado.value} value={estado.value}>
                    {estado.description}
                  </MenuItem>
                ))}
              </Select>
            </div>
          </form>
        </Box>
        <CustomBackdrop isLoading={isCreatingNivel} />
      </div>
    </ThemeProvider>
  )
}

export default CrearNivel
