/* eslint-disable camelcase */
import React, { useState, useEffect, useContext } from "react"
import {
  Box,
  Button,
  Step,
  StepLabel,
  Stepper,
  Typography,
  Snackbar,
  Alert,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material"
import fetchwrapper from "../../../services/interceptors/fetchwrapper" // Assuming a custom service for API calls
import Modal from "@mui/material/Modal"
import Dropzone from "react-dropzone"
import { read } from "xlsx"
import { createTheme } from "@mui/material/styles"
import CloseIcon from "@mui/icons-material/Close"
import { SolicitudEntregaContext } from "../SolicitudDeEntregaContext"
import Swal from "sweetalert2"

const VALID_COLUMNS = ["Codigo", "Cantidad"]

function ModalUploadFileEgreso({ openModal, onCloseModal, dataProductos, dataProductosSecondary }) {
  const [open, setOpen] = useState(openModal)
  const [openDialog, setOpenDialog] = useState(false)
  const [activeStep, setActiveStep] = useState(0)
  const [file, setFile] = useState(null)
  const [snackbarMessage, setSnackbarMessage] = useState("")
  const [openSnackbar, setOpenSnackbar] = useState(false)
  const [isLoading, setIsLoading] = useState(false) // Flag for loading spinner
  const [tableData, setTableData] = useState([]) // Array to store preview data
  const [allData, setAllData] = useState([]) // Array to store preview data
  const [errors, setErrors] = useState([]) // To store the errors
  const [snackbarSeverity, setSnackbarSeverity] = useState("success")
  const [productosCargados, setProductosCargados] = useState([]) // Productos cargados correctamente

  useEffect(() => {
    setOpen(openModal)
  }, [openModal])

  const handleClose = () => {
    setOpen(false)
    onCloseModal() // Notify the parent component that the modal is closed
  }
  const handleCloseDialog = () => setOpenDialog(false)

  const theme = createTheme({
    palette: {
      primary: {
        main: "#196C87",
      },
      secondary: {
        main: "#196C87", // Cambia el color secundario a verde azulado
      },
    },
  })

  const { productosEgreso, setProductosEgreso } = useContext(SolicitudEntregaContext)

  const handleFileDrop = (acceptedFiles) => {
    const uploadedFile = acceptedFiles[0]
    const reader = new FileReader()

    if (
      uploadedFile.type !== "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" &&
      uploadedFile.type !== "application/vnd.ms-excel" &&
      uploadedFile.type !== "text/csv"
    ) {
      setSnackbarMessage("Tipo de archivo no compatible")
      setOpenSnackbar(true)
      return
    }

    const validateColumns = (workbook) => {
      console.log("Inicia Validación de Columnas...")
      setIsLoading(true)
      const firstSheetName = workbook.SheetNames[0]
      const worksheet = workbook.Sheets[firstSheetName]
      const headers = []
      let columnIndex = 0

      let header
      do {
        const cellKey = `${String.fromCharCode(65 + columnIndex)}1`
        header = worksheet[cellKey]?.v
        if (header) headers.push(header)
        columnIndex++
      } while (header)

      console.log("Cabeceras:", headers)
      console.log(VALID_COLUMNS)

      if (headers.length !== VALID_COLUMNS.length || !headers.every((h, idx) => h === VALID_COLUMNS[idx])) {
        setSnackbarMessage("Verifique el nombre de las columnas")
        setOpenSnackbar(true)
        console.log("No cumple Validación de Columnas...")
        return false
      }
      console.log("Validación de Columnas Exitosa...")
      return true
    }

    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result)
      setIsLoading(true)
      try {
        const workbook = read(data, { type: "array" })

        if (!validateColumns(workbook)) return

        const sheetData = workbook.Sheets[workbook.SheetNames[0]]
        const allData = Object.keys(sheetData).map((cell) => {
          const col = cell.replace(/[0-9]/g, "")
          const row = parseInt(cell.replace(/[A-Z]/gi, ""), 10)
          let value = sheetData[cell]?.v || ""

          if (typeof value === "number" && col === "A") {
            value = value.toString().padStart(6, "0")
          }

          return { col, row, value }
        })

        const dataWithoutHeader = allData.filter((cell) => cell.row !== 1)
        const validData = dataWithoutHeader.filter((cell) => !isNaN(cell.row))

        const groupedData = validData.reduce((acc, curr) => {
          if (curr.row === 1) return acc
          const existingRow = acc.find((row) => row.row === curr.row)
          if (existingRow) {
            existingRow[curr.col] = curr.value
          } else {
            acc.push({ row: curr.row, [curr.col]: curr.value })
          }
          return acc
        }, [])

        const slicedData = groupedData.slice(0, 50)
        setAllData(groupedData)
        setTableData(slicedData)
        setActiveStep(1)
      } catch (error) {
        console.error("Error al leer el archivo:", error)
        setSnackbarMessage("Error al leer el archivo")
        setOpenSnackbar(true)
      } finally {
        setIsLoading(false)
      }
    }

    reader.readAsArrayBuffer(uploadedFile)
    setFile(uploadedFile)
  }

  const handleNext = () => {
    if (activeStep === 0) {
      if (file === null) {
        setSnackbarMessage("Debe cargar el archivo")
        setOpenSnackbar(true)
      } else {
        setActiveStep(1)
      }
    } else if (activeStep === 1) {
      setOpenDialog(true)
    }
  }

  const handleBack = () => {
    if (activeStep === 1 || activeStep === 2) {
      if (activeStep === 1) {
        setFile(null)
        setAllData([])
      }
      setActiveStep(activeStep - 1)
    }
  }

  const handleConfirm = async () => {
    try {
      console.log("Inicia Carga.....")
      const newErrors = []
      setIsLoading(true)
      const nuevosProductos = []
      let numOfProducts = productosEgreso.length + 1
      let successfulCount = 0

      for (const rowData of allData) {
        const artcodigoProducto = rowData.A.toString()
        let artDescripcion = rowData.B ?? ""
        let codProducto = artcodigoProducto

        const productoBodega = dataProductos.find((producto) => producto.split("-")[0] === artcodigoProducto)

        if (productoBodega) {
          try {
            const response = await fetchwrapper(`/solicitudDeIngreso/getArticuloDescrixArtCodigo/${artcodigoProducto}`)
            const data = await response.json()
            artDescripcion = data.dataResult.artdescri
          } catch (error) {
            console.error("Error fetching data:", error)
          }
        } else {
          if (dataProductosSecondary.length > 0) {
            const productoBodegaSecondary = dataProductosSecondary.find(
              (producto) => producto.artcodigo2 === artcodigoProducto,
            )
            if (productoBodegaSecondary) {
              artDescripcion = productoBodegaSecondary.artdescri || artDescripcion
              codProducto = productoBodegaSecondary.artcodigo
            } else {
              newErrors.push({
                artcodigo: artcodigoProducto,
                error: "Producto no pertenece a la bodega o no fue encontrado.",
              })
              continue
            }
          } else {
            newErrors.push({
              artcodigo: artcodigoProducto,
              error: "Producto no pertenece a la bodega o no fue encontrado.",
            })
            continue
          }
        }

        const newProducto = {
          numSecuencia: numOfProducts,
          artcodigo: codProducto,
          artdescri: artDescripcion,
          cantSolicitada: rowData.B?.toString() ?? "",
          estado: "PENDIENTE",
          prevNumSecuencia: 0,
        }

        nuevosProductos.push(newProducto)
        numOfProducts++
        successfulCount++
      }

      setProductosEgreso((prevProductos) => [...prevProductos, ...nuevosProductos])
      setErrors(newErrors)

      if (newErrors.length > 0) {
        setSnackbarSeverity("warning")
        setSnackbarMessage(`Se encontraron ${newErrors.length} errores.`)
        // Mostrar errores en SweetAlert2
        Swal.fire({
          icon: "warning",
          title: "Errores encontrados",
          html: `
            <table border="1" style="width: 100%; text-align: left; border-collapse: collapse;">
              <thead>
                <tr>
                  <th style="padding: 8px; background-color: #f2f2f2;">Código</th>
                  <th style="padding: 8px; background-color: #f2f2f2;">Error</th>
                </tr>
              </thead>
              <tbody>
                ${newErrors
                  .map(
                    (error) =>
                      `<tr>
                         <td style="padding: 8px; border: 1px solid #ddd;">${error.artcodigo}</td>
                         <td style="padding: 8px; border: 1px solid #ddd;">${error.error}</td>
                       </tr>`,
                  )
                  .join("")}
              </tbody>
            </table>`,
          confirmButtonText: "OK",
        })
      } else {
        setSnackbarSeverity("success")
        setSnackbarMessage(`${successfulCount} registros insertados exitosamente.`)
      }

      setOpenSnackbar(true)
      setOpenDialog(false)
      handleClose()
    } catch (error) {
      console.error("Error al procesar los registros:", error)
      setSnackbarMessage("Error al procesar los registros.")
      setSnackbarSeverity("error")
      setOpenSnackbar(true)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-upload-file"
      aria-describedby="modal-upload-file-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "50vw",
          maxHeight: "70vh",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          overflow: "hidden",
        }}
      >
        <Stepper activeStep={activeStep} alternativeLabel>
          <Step>
            <StepLabel>Subir archivo</StepLabel>
          </Step>
          <Step>
            <StepLabel>Previsualización</StepLabel>
          </Step>
          <Step>
            <StepLabel>Confirmar</StepLabel>
          </Step>
        </Stepper>
        <Box sx={{ flexGrow: 1, overflowY: "auto", height: "50%" }}>
          {activeStep === 0 && (
            <Box>
              <Dropzone onDrop={handleFileDrop}>
                {({ getRootProps, getInputProps }) => (
                  <Box {...getRootProps()} sx={{ textAlign: "center", mb: 4 }}>
                    <input {...getInputProps()} />
                    <Button variant="contained" component="div" sx={{ width: "50%", margin: "0 auto" }}>
                      Seleccionar archivo
                    </Button>
                  </Box>
                )}
              </Dropzone>
            </Box>
          )}
          {activeStep === 1 && (
            <Box>
              <Typography variant="h6">Nombre de Archivo: {file?.name}</Typography>
              <Typography variant="body2">Número de registros: {allData?.length}</Typography>
              <Typography variant="caption" sx={{ fontStyle: "italic" }}>
                Solo se muestran los 50 primeros registros
              </Typography>
              {tableData.length > 0 && (
                <Box
                  sx={{
                    maxHeight: "30vh",
                    overflowY: "auto",
                    textAlign: "center",
                  }}
                >
                  <table
                    style={{
                      maxWidth: "100%",
                      borderSpacing: "0",
                      borderCollapse: "collapse",
                      margin: "0 auto",
                    }}
                  >
                    <thead>
                      <tr>
                        {VALID_COLUMNS.map((column) => (
                          <th
                            key={column}
                            style={{
                              padding: "10px",
                              backgroundColor: theme.palette.primary.main,
                              color: "#fff",
                              border: "1px solid #ccc",
                              minWidth: "150px",
                            }}
                          >
                            {column}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {tableData.map((item, index) => (
                        <tr key={index} style={{ padding: "10px", border: "1px solid #ccc" }}>
                          <td
                            style={{
                              textAlign: "right",
                              border: "1px solid #ccc",
                            }}
                          >
                            {item.A}
                          </td>
                          <td
                            style={{
                              textAlign: "right",
                              border: "1px solid #ccc",
                            }}
                          >
                            {item.B}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </Box>
              )}
            </Box>
          )}
        </Box>
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          {activeStep !== 0 && (
            <Button onClick={handleBack} variant="contained" sx={{ mr: 2 }}>
              Prev
            </Button>
          )}
          {activeStep !== 2 && (
            <Button onClick={handleNext} variant="contained">
              Next
            </Button>
          )}
        </Box>
        <Box sx={{ position: "absolute", bottom: "20px", left: "20px" }}>
          <Button onClick={handleClose} color="secondary">
            <CloseIcon />
          </Button>
        </Box>
        <Dialog
          open={openDialog}
          onClose={handleCloseDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Desea Cargar el archivo?"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">Desea cargar el archivo?</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog}>Aún no</Button>
            <Button onClick={handleConfirm}>Cargar</Button>
          </DialogActions>
        </Dialog>
        <Snackbar
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={() => setOpenSnackbar(false)}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <Alert onClose={() => setOpenSnackbar(false)} severity={snackbarSeverity}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
        {isLoading && <CircularProgress size={24} color="secondary" sx={{ textAlign: "center", marginTop: 20 }} />}
      </Box>
    </Modal>
  )
}

export default ModalUploadFileEgreso
