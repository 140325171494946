import React from "react"
import { Select, MenuItem, Checkbox, ListItemText, InputLabel, FormControl, Chip, Box } from "@mui/material"
import { styled } from "@mui/material/styles"

const MultipleSelect = ({ label, value, onChange, options, fullWidth, multiple }) => {
  const StyledBox = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
  }))

  const renderValue = (selected) => (
    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
      {selected.map((value) => (
        <Chip key={value} label={options.find((option) => option.value === value).label} />
      ))}
    </Box>
  )

  return (
    <StyledBox>
      <InputLabel>{label}</InputLabel>
      <FormControl fullWidth={fullWidth}>
        <Select value={value} onChange={onChange} multiple={multiple} renderValue={renderValue}>
          {options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              <Checkbox checked={value.indexOf(option.value) > -1} />
              <ListItemText primary={option.label} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </StyledBox>
  )
}

export default MultipleSelect
