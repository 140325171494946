import React, { useState, useEffect, useMemo } from "react"
import Header from "../../../layouts/Header"
import { styled, createTheme, ThemeProvider } from "@mui/material/styles"
import {
  Box,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Button,
  Tooltip,
  Checkbox,
  FormControl,
  FormHelperText,
  FormControlLabel,
} from "@mui/material"
import {
  MaterialReactTable,
  // createRow,
  useMaterialReactTable,
} from "material-react-table"
import ImprimirIcon from "../../../assets/iconos/Impresion.ico"
import { MRT_Localization_ES } from "material-react-table/locales/es"
import normalFormatDate from "../../utils/date/DDMMYYYFormatDate"
import DetalleImpresion from "../../Zonas/components/DetalleImpresion"
import Alert from "@mui/material/Alert"
import Swal from "sweetalert2"
import { useQuery, useQueryClient, useMutation, dataTagSymbol } from "@tanstack/react-query"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import BackIcon from "../../../components/BackIcon"
import GrabarIcon from "../../../assets/iconos/Grabar.ico"
import fetchwrapper from "../../../services/interceptors/fetchwrapper"
import CustomBackdrop from "../../../components/CustomBackdrop"
import CustomTextField from "../../../components/CustomTextField"
import BodegasGrid from "../components/BodegasGrid"
import AsociacionesPdf from "../components/AsociacionesPdf"
//  crear funciona como un mantenedor normal y devuelve a la pagina de opciones
//  estilo del formulario
const ClienteBox = styled(Box)({
  gridArea: "Cliente",
})
const NombreBox = styled(Box)({
  gridArea: "Nombre",
})
const IdBox = styled(Box)({
  gridArea: "Id",
})
const EstadoBox = styled(Box)({
  gridArea: "Estado",
})
// Contenedor de campos de busqueda
const Container = styled(Box)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "repeat(12, 1fr)",
  gridTemplateRows: "auto auto auto",
  gridTemplateAreas: `
    "Cliente Cliente Nombre Nombre Nombre Nombre Id Id Id Id Estado Estado"
  `,
  gap: "8px",
  alignItems: "center",

  [theme.breakpoints.down("sm")]: {
    gridTemplateColumns: "repeat(6, 1fr)",
    gridTemplateAreas: `
      "Cliente Cliente Cliente Nombre Nombre Nombre"
      "Id Id Id Estado Estado Estado"
    
    `,
  },
}))
const StyledRoot = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  marginTop: theme.spacing(8),
  flexGrow: 1,
  padding: "0 16px",
  height: "100vh",
}))
const theme = createTheme({
  palette: {
    primary: {
      main: "#196C87",
    },
    secondary: {
      main: "#196C87", // Cambia el color secundario a verde azulado
    },
  },
})

const AsociarBodegaCliente = () => {
  const { id } = useParams()
  const location = useLocation()
  const datoSeleccionado = location.state
  const clienteCiaCodigo = datoSeleccionado.ciacodigo
  // codigo del cliente
  const clienteCodigoActual = datoSeleccionado.clicodigo
  const clienteNombreActual = datoSeleccionado.clinombre
  const clienteIdActual = datoSeleccionado.cliruc
  const clienteEstadoActual = datoSeleccionado.clistatus

  const datosActualesCliente = {
    clicodigo: clienteCodigoActual,
    clinombre: clienteNombreActual,
    cliruc: clienteIdActual,
    clistatus: clienteEstadoActual,
  }

  const [openModal, setOpenModal] = useState(false)
  const [infoGenerada, setInfoGenerada] = useState([])
  const handleOpenModal = () => {
    setInfoGenerada(infoPdf)
    console.log("infoGenerada", infoGenerada) // Asigna los datos de la tabla al estado
    setOpenModal(true)
  }
  // setea el valor de M3 y maneja que el valor sea numerico

  const navigate = useNavigate()
  // estados de elementos del forrmulario
  // estados de los filtros
  const [error, setError] = useState(false)
  const [idCliente, setId] = useState(clienteIdActual)
  const [nombre, setNombre] = useState(clienteNombreActual)
  // const [actionAttempted, setactionAttempted] = useState(false)
  // const [firsTime, setfirsTime] = useState(true)
  // bodegas que el cliente selecciono
  const [bodegasSeleccionadas, setBodegasSeleccionadas] = useState([])
  // bodegas que ya estaban relacionadas al clientes
  const [bodegasIniciales, setBodegasIniciales] = useState([])
  const [infoPdf, setInfoPdf] = useState(bodegasIniciales)
  useEffect(() => {
    setInfoPdf(bodegasIniciales)
  }, infoPdf)
  const handleNumericInput = (value, setValue) => {
    // Allow empty value, integers, or decimals
    if (/^\d*\.?\d*$/.test(value)) {
      setValue(value)
      setError(false) // No error if the input is valid
    } else {
      setError(true) // Show error if the input is invalid
    }
    console.log(idCliente)
  }
  useEffect(() => {
    setId(idCliente)
    console.log(idCliente)
  }, [idCliente])
  const handleNombreCliente = (event) => {
    setNombre(event.target.value)
  }
  useEffect(() => {
    setNombre(nombre)
    console.log(nombre)
  }, [nombre])

  const Estados = [
    { value: "A", description: "ACTIVO" },
    { value: "I", description: "INACTIVO" },
  ]
  const findEstado = (estadoCurrent) => {
    const result = Estados.find((estado) => estado.value === estadoCurrent)
    return result
  }
  const [estadoCliente, setEstadoCliente] = useState(findEstado(clienteEstadoActual))
  // funcion que me dice que bahias eliminar y que bahias agregar
  const distribuirBodegas = (prevBodegas, currentBodegas) => {
    // funcion que dado ambos arreglos me devuelve un arreglo con las bodegas de debo desasociar y otro con las que debo asociar
    const setIniciales = new Set(prevBodegas)
    const setActuales = new Set(currentBodegas)
    // Bodegas a desasociar: están en las iniciales pero no en las actuales
    const bodegasDesasociar = [...setIniciales].filter((bodega) => !setActuales.has(bodega))

    // Bodegas a asociar: están en las actuales pero no en las iniciales
    const bodegasAsociar = [...setActuales].filter((bodega) => !setIniciales.has(bodega))

    return { asociaciones: bodegasAsociar, deletes: bodegasDesasociar }
  }
  const { mutateAsync: createAsociacion, isPending: isCreatingAsociacion } = useCreateAsociacion()
  const { mutateAsync: deleteAsociacion, isPending: isDeletingAsociacion } = useDeleteAsociacion()
  const { mutateAsync: updateCliente, isPending: isUpdatingCliente } = useUpdateCliente()
  function useUpdateCliente() {
    const queryClient = useQueryClient()
    return useMutation({
      mutationFn: async (cliente) => {
        // send api update request here
        // send api update request here
        const options = {
          method: "PUT",
          body: JSON.stringify(cliente),
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }
        console.log("json", options.body)
        let response = await fetchwrapper(`/AsociacionCliBod/updateCliente`, options)

        response = response.json()
        console.log(response)
        return response
      },
      // client side optimistic update
      onMutate: (newCliente) => {
        console.log(newCliente, "onmutate")
        queryClient.setQueryData(["Cliente", id], (prevCliente) => ({
          ...newCliente,
        }))
      },
      onSuccess: () => {
        // Invalidate and refetch client data
        queryClient.invalidateQueries(["Cliente", id])
        queryClient.invalidateQueries(["bodegasXcliente"])
      },
      // onSettled: () => queryClient.invalidateQueries({ queryKey: ['bancoDePreguntas'] }), //refetch bancoDePreguntas after mutation, disabled for demo
    })
  }
  function useDeleteAsociacion() {
    const queryClient = useQueryClient()
    return useMutation({
      mutationFn: async (arregloDesasoci) => {
        const options = {
          method: "DELETE",
          body: JSON.stringify(arregloDesasoci),
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }

        const response = await fetchwrapper(`/AsociacionCliBod/eliminarAsociacion`, options)
        return response
      },

      // actualizacion optimista
      onMutate: (clicodigo) => {
        queryClient.setQueryData(["Asociaciones"], (prevAsociaciones) =>
          prevAsociaciones?.filter((asoci) => asoci.clicodigo !== clicodigo),
        )
      },
      onSuccess: () => {
        // Invalidate and refetch client data
        queryClient.invalidateQueries(["Cliente", id])
        queryClient.invalidateQueries(["bodegasXcliente"])
      },
    })
  }
  function useCreateAsociacion() {
    const queryClient = useQueryClient()
    return useMutation({
      mutationFn: async (asociaciones) => {
        // send api update request here
        // send api update request here
        const options = {
          method: "POST",
          body: JSON.stringify(asociaciones),
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }
        console.log("json", options.body)
        let response = await fetchwrapper(`/AsociacionCliBod/crearAsociacion`, options)

        response = response.json()
        console.log(response)
        return response
      },

      // client side optimistic update
      onMutate: (newAsociacion) => {
        console.log(newAsociacion, "onmutate")
        queryClient.setQueryData(["Asociacion", newAsociacion.id], (prevAsociacion) => ({
          ...newAsociacion,
        }))
      },
      onSuccess: () => {
        // Invalidate and refetch client and warehouse data
        queryClient.invalidateQueries(["Cliente", id])
        queryClient.invalidateQueries(["bodegasXcliente"])
      },
      // onSettled: () => queryClient.invalidateQueries({ queryKey: ['bancoDePreguntas'] }), //refetch bancoDePreguntas after mutation, disabled for demo
    })
  }

  // useEffect(() => {
  //   if (actionAttempted && firsTime) {
  //     setBodegas(refetchBodegas)
  //     setfirsTime(false)
  //   }
  // }, [actionAttempted, firsTime])
  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      const distribucion = distribuirBodegas(bodegasIniciales, bodegasSeleccionadas)
      // bodegas asociar
      const asociaciones = {
        clicodigo: clienteCodigoActual,
        bodegasAsociar: distribucion.asociaciones,
      }
      // bodegas eliminar
      const desasociaciones = {
        clicodigo: clienteCodigoActual,
        bodegasEliminar: distribucion.deletes,
      }
      // update del cliente (json que se manda al back)
      const cliente = {
        clicodigo: clienteCodigoActual,
        nombreCliente: nombre,
        idCliente,
      }
      if (distribucion.asociaciones.length !== 0) {
        await createAsociacion(asociaciones)
      }
      if (distribucion.deletes.length !== 0) {
        await deleteAsociacion(desasociaciones)
      }
      await updateCliente(cliente)
      // setactionAttempted(true)
      Swal.fire({
        icon: "success",
        title: "Operación exitosa",
        text: "Se guardaron los cambios",
      })
    } catch (error) {
      Swal.fire({
        title: "Error",
        text: "No se pudieron guardar los cambios",
        icon: "error",
        confirmButtonText: "OK",
      }).then(() => {
        navigate("/home/dashboard/AsociacionBodegasCliente/asociar") // Navega de vuelta cuando se cierre el SweetAlert
      })
    }
  }

  // LOGICA DE LA GRILLA DE BODEGAS
  // LLAMADA A LA API
  useEffect(() => {
    console.log(bodegasSeleccionadas)
  }, bodegasSeleccionadas)
  const [bodegas, setBodegas] = useState([])
  const {
    data: fetchedBodegas = [],
    isError: isLoadingBodegasError,
    isFetching: isFetchingBodegas,
    isLoading: isLoadingBodegas,
    refetch: refetchBodegas,
  } = useGetAllBodegas()
  function useGetAllBodegas() {
    return useQuery({
      queryKey: ["bodegasXcliente"],
      queryFn: async () => {
        const options = {
          method: "POST",
          body: JSON.stringify({
            clicodigo: clienteCodigoActual,
          }),
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }
        console.log(options.body)
        // send api request here
        let response = await fetchwrapper("/AsociacionCliBod/getAllBodegas", options)
        response = await response.json()
        console.log(response)
        return response.dictBodegas
      },
      refetchOnWindowFocus: false,
      refetchOnReconnect: false, // Añade esta línea para evitar refetch al reconectar
      // enabled: !searchAttempted && !isFirstTime,
    })
  }
  useEffect(() => {
    setBodegas(fetchedBodegas)
  }, [fetchedBodegas])
  const handleCloseModal = () => {
    setOpenModal(false)
    navigate("/home/dashboard/AsociacionBodegasCliente")
  }
  return (
    <ThemeProvider theme={theme}>
      <Header />
      <div className="main main-app p-3 p-lg-4">
        <BackIcon />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "0 30px 30px 30px",
            fontSize: "25px",
          }}
        >
          <b>Asociacion de Bodegas a Cliente</b>
        </div>
        <Box className={StyledRoot}>
          <Tooltip title="Grabar">
            <Button style={{ fontSize: "0.9vw" }} onClick={handleSubmit}>
              <img src={GrabarIcon} alt="Grabar" style={{ width: "40px" }} />
            </Button>
          </Tooltip>
          <Tooltip title="Imprimir">
            <Button color="primary" onClick={handleOpenModal} sx={{ marginBottom: "15px" }}>
              <img src={ImprimirIcon} alt="Imprimir" style={{ width: "30px" }} />
            </Button>
          </Tooltip>
          <DetalleImpresion
            open={openModal}
            handleClose={handleCloseModal}
            infoGenerada={bodegasIniciales}
            nombrePdf={"ASOCIACIONES DE CLIENTES"}
            PdfComponent={AsociacionesPdf}
          />
          <Container>
            <ClienteBox>
              <InputLabel>Cliente</InputLabel>
              <TextField disabled fullWidth value={clienteCodigoActual} />
            </ClienteBox>
            <NombreBox>
              <InputLabel>Nombre</InputLabel>
              <TextField fullWidth value={nombre} onChange={handleNombreCliente} />
            </NombreBox>
            <IdBox>
              <InputLabel>Identificacion</InputLabel>
              <FormControl fullWidth error={error}>
                <TextField
                  value={idCliente}
                  onChange={(e) => handleNumericInput(e.target.value, setId)}
                  error={error} // Set error state in TextField
                  helperText={error ? "Por favor ingresa solo valores numéricos." : ""}
                />
                {error && <FormHelperText>El valor ingresado no es válido.</FormHelperText>}
              </FormControl>
            </IdBox>
            <EstadoBox>
              <InputLabel>Estado</InputLabel>
              <Select
                fullWidth
                disabled
                labelId="estadoClientesCB"
                value={estadoCliente.value}
                onChange={(e) => {
                  const selectedValue = e.target.value
                  const clienteestado = Estados.find((estado) => estado.value === selectedValue)
                  setEstadoCliente(clienteestado)
                  //   clearAll(tipoPregunta)
                }}
              >
                {Estados.map((estado) => (
                  <MenuItem key={estado.value} value={estado.value}>
                    {estado.description}
                  </MenuItem>
                ))}
              </Select>
            </EstadoBox>
          </Container>
        </Box>
        <CustomBackdrop isLoading={isCreatingAsociacion || isDeletingAsociacion} />
        <BodegasGrid
          fetchedInfo={bodegas}
          isLoadingInfoError={isLoadingBodegasError}
          isLoadingInfo={isLoadingBodegas}
          isFetchingInfo={isFetchingBodegas}
          setBodegas={setBodegas}
          refetch={refetchBodegas}
          // bodegasSeleccionadas --> bodegas que se seleccionan al inicio
          selectedBods={bodegasSeleccionadas}
          setSelectedBods={setBodegasSeleccionadas}
          // bodegas Inciales-->bodegas cargadas al inicio
          bodegasAnteriores={bodegasIniciales}
          setBodegasAnteriores={setBodegasIniciales}
        ></BodegasGrid>
      </div>
    </ThemeProvider>
  )
}

export default AsociarBodegaCliente
