import { useEffect, useState } from "react"
import Header from "../../../layouts/Header"
import { styled, createTheme, ThemeProvider } from "@mui/material/styles"
import { Box, Grid, TextField, Button } from "@mui/material"

import BackIcon from "../../../components/BackIcon"
import CustomFieldset from "../../../components/CustomFieldset"
import CustomHelper from "../../../components/CustomHelper"
import CustomHelperDetail from "../../../components/CustomHelperDetail"
import CustomTooltip from "../../../components/ToolTip"
import CustomDatePicker from "../../../components/CustomDatePicker"
import dayjs from "dayjs"
import CustomTextFieldReadable from "../../../components/CustomTextFieldReadable"
import CustomTextField from "../../../components/CustomTextField"
import CustomTimePicker from "../../../components/CustomTimePicker"
import CustomSelect from "../../../components/CustomSelect"
import CustomAutocomplete from "../../../components/CustomAutocomplete"
import fetchwrapper from "../../../services/interceptors/fetchwrapper"
import { useQuery, useMutation } from "@tanstack/react-query"
import CustomBackdrop from "../../../components/CustomBackdrop"
import BuscarIcon from "../../../assets/iconos/Buscar.ico"
import GrabarIcon from "../../../assets/iconos/Grabar.ico"
import DatagridPickingSugerido from "./components/DatagridPickingSugerido"
import { useNavigate } from "react-router-dom"

const StyledRoot = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  marginTop: theme.spacing(8),
  flexGrow: 1,
  padding: "0 16px",
  height: "100vh",
}))

const theme = createTheme({
  palette: {
    primary: {
      main: "#196C87",
    },
    secondary: {
      main: "#196C87", // Cambia el color secundario a verde azulado
    },
  },
})

const Container = styled(Box)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "repeat(12, 1fr)",
  gridTemplateRows: "auto auto auto",
  gridTemplateAreas: `
      "IPicking IPicking IPicking IFechaHoy IFechaHoy IEstado IEstado ITipo ITipo IUsuario IUsuario IUsuario"
    "IFechaRealizar IFechaRealizar IFechaRealizar IHoraRealizar IHoraRealizar IComentario IComentario IComentario IComentario IComentario IComentario IComentario"
    `,
  gap: "8px",
  alignItems: "center",

  // [theme.breakpoints.down("sm")]: {
  //   gridTemplateColumns: "repeat(6, 1fr)",
  //   gridTemplateAreas: `
  //       "Solicitud Solicitud Solicitud FechaI FechaI FechaI"
  //       "Cliente Cliente Cliente Cliente Cliente Cliente"
  //       "Motivo Motivo Motivo Estado Estado Estado"
  //       "Proveedor Proveedor Proveedor Proveedor Proveedor Proveedor"
  //       "FechaL FechaL FechaL HoraL HoraL HoraL"
  //       "Comentario Comentario Comentario Comentario Comentario Comentario"
  //       "Detalle Detalle Detalle Detalle Detalle Detalle"
  //     `,
  // },
}))
const IPicking = styled(Box)({
  gridArea: "IPicking",
})

const IFechaHoy = styled(Box)({
  gridArea: "IFechaHoy",
})

const IEstado = styled(Box)({
  gridArea: "IEstado",
})

const ITipo = styled(Box)({
  gridArea: "ITipo",
})

const IUsuario = styled(Box)({
  gridArea: "IUsuario",
})

const IFechaRealizar = styled(Box)({
  gridArea: "IFechaRealizar",
})

const IHoraRealizar = styled(Box)({
  gridArea: "IHoraRealizar",
})

const IComentario = styled(Box)({
  gridArea: "IComentario",
})
const ContainerFiltros = styled(Box)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "repeat(12, 1fr)",
  gridTemplateRows: "auto auto auto",
  gridTemplateAreas: `
        "ISolicitudEgreso ISolicitudEgreso IClienteBodega IClienteBodega IClienteBodega IClienteBodega IClienteBodega IClienteBodega IClienteBodega IFechaInicial IFechaInicial IFechaInicial"
        "Ifactura Ifactura IclienteFactura IclienteFactura IclienteFactura IclienteFactura IclienteFactura IclienteFactura IclienteFactura IFechaFinal IFechaFinal IFechaFinal"
       
      `,
  gap: "8px",
  alignItems: "center",
}))

const ISolicitudEgreso = styled(Box)({
  gridArea: "ISolicitudEgreso",
})

const IClienteBodega = styled(Box)({
  gridArea: "IClienteBodega",
})
const Ifactura = styled(Box)({
  gridArea: "Ifactura",
})

const IclienteFactura = styled(Box)({
  gridArea: "IclienteFactura",
})

const IFechaInicial = styled(Box)({
  gridArea: "IFechaInicial",
})

const IFechaFinal = styled(Box)({
  gridArea: "IFechaFinal",
})

const PickingSugeridoCrear = () => {
  const navigate = useNavigate()
  const [columnsSelectedPS, setColumnsSelectedPS] = useState([])
  const [datosGen, setDatosGen] = useState({
    picking: "",
    fechaHoy: dayjs(new Date()),
    estado: "PENDIENTE",
    tipo: {},
    usuario: "",
    fechaRealizar: dayjs(new Date()),
    horaRealizar: dayjs("12:00 AM", "hh:mm A"),
    comentario: "",
  })
  const handleSetDatosGen = (k, v) => setDatosGen((prev) => ({ ...prev, [k]: v }))

  const [filtros, setFiltros] = useState({
    solicitudEgreso: {},
    clienteBodega: {},
    factura: {},
    clienteFactura: {},
    fechaInicial: dayjs(new Date(new Date().getFullYear(), new Date().getMonth(), 1)),
    fechaFinal: dayjs(new Date()),
  })

  const handleSetFiltros = (k, v) => setFiltros((prev) => ({ ...prev, [k]: v }))

  const {
    data: fetchedIDPickingSugerido = "",
    isError: isLoadingIDPickingSugeridoError,
    isFetching: isFetchingPickingSugerido,
    isLoading: isLoadingIDPickingSugerido,
  } = useGetIDPickingSugerido()

  function useGetIDPickingSugerido() {
    return useQuery({
      queryKey: ["IDPickingSugerido"],
      queryFn: async () => {
        let response = await fetchwrapper(`/pickingSugerido/getCodPickingSugerido`)
        response = await response.json()

        handleSetDatosGen("picking", response.data.id)

        return response.data.id
      },
      refetchOnWindowFocus: false,
      staleTime: Infinity,
    })
  }

  const {
    data: fetchedUsuarios = [],
    isError: isLoadingUsuariosoError,
    isFetching: isFetchinUsuarios,
    isLoading: isLoadingUsuarios,
  } = useGetUsuarios()

  function useGetUsuarios() {
    return useQuery({
      queryKey: ["USUARIOSPickingSugerido"],
      queryFn: async () => {
        let response = await fetchwrapper(`/pickingSugerido/getUsuarios`)
        response = await response.json()

        console.log("alerta", response.data)

        return response.data
      },
      refetchOnWindowFocus: false,
      staleTime: Infinity,
    })
  }

  const {
    data: fetchedPickingSugerido = [],
    isError: isLoadingPickingSugeridoError,
    isFetching: isFetchinPickingSugerido,
    isLoading: isLoadingPickingSugerido,
    refetch: refetchPickingSugerido,
  } = useGetPickingSugerido()

  function useGetPickingSugerido() {
    return useQuery({
      queryKey: ["PickingSugeridoitems"],
      queryFn: async () => {
        const options = {
          method: "POST",
          body: JSON.stringify({
            picking: datosGen.picking,
            fechaHoy: datosGen.fechaHoy,
            estado: datosGen.estado,
            tipo: datosGen.tipo,
            usuario: datosGen.usuario,
            fechaRealizar: datosGen.fechaRealizar,
            horaRealizar: datosGen.horaRealizar,
            comentario: datosGen.comentario,
            solicitudEgreso: filtros.solicitudEgreso,
            clienteBodega: filtros.clienteBodega,
            factura: filtros.factura,
            clienteFactura: filtros.clienteFactura,
            fechaInicial: filtros.fechaInicial,
            fechaFinal: filtros.fechaFinal,
          }),
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }
        let response = await fetchwrapper(`/pickingSugerido/getPickingSugerido`, options)
        response = await response.json()

        return response.data
      },
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      enabled: false,
    })
  }

  const handleSearching = () => {
    if (!datosGen.tipo || Object.keys(datosGen.tipo).length === 0) {
      return alert("Seleccione un tipo de la lista")
    }
    refetchPickingSugerido()
  }

  const { mutateAsync: createPickingSugerido, isPending: isCreatingPickingSugerido } = useCreatePickingSugerido()

  function useCreatePickingSugerido() {
    return useMutation({
      mutationFn: async () => {
        // send api update request here
        const options = {
          method: "POST",
          body: JSON.stringify({
            cabecera: { ...datosGen },
            detalle: [...columnsSelectedPS],
          }),
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }

        let response = await fetchwrapper("/pickingSugerido/savePickingSugerido", options)
        response = await response.json()

        return response
      },
      onSuccess: () => {
        alert("Picking creado con éxito")
        navigate(0)
      },
      onError: () => {
        alert("No se pudo crear el picking")
      },
    })
  }

  const handleCreating = () => {
    if (!datosGen.usuario || datosGen.usuario?.length === 0) {
      return alert("Seleccione un usuario de la lista")
    }

    if (fetchedPickingSugerido.length === 0) {
      return alert("No ha realizado la búsqueda de ningún picking")
    }
    createPickingSugerido()
  }

  return (
    <ThemeProvider theme={theme}>
      <Header />
      <div className="main main-app p-3 p-lg-4">
        <BackIcon />
        <div style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
          {/* <div style={{ paddingTop: "10px", paddingBottom: "10px" }}>
            <CustomTooltip title={"Buscar"}>
              <Button style={{ fontSize: "0.9vw" }} onClick={() => navigate("/home/dashboard/PickingSugerido/buscar")}>
                <img src={BuscarIcon} alt="Crear" style={{ width: "40px" }} />
              </Button>
            </CustomTooltip>
          </div> */}
          <div style={{ display: "flex", alignItems: "center", gap: "16px", padding: "5px" }}>
            <CustomTooltip title={"Crear"}>
              <Button onClick={handleCreating}>
                <img src={GrabarIcon} alt="Grabar" style={{ width: "40px" }} />
              </Button>
            </CustomTooltip>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "0 30px 30px 30px",
            fontSize: "25px",
          }}
        >
          <b>Crear Picking Sugerido</b>
        </div>

        <CustomBackdrop
          isLoading={
            isLoadingIDPickingSugerido ||
            isFetchingPickingSugerido ||
            isLoadingUsuarios ||
            isFetchinUsuarios ||
            isLoadingPickingSugerido ||
            isFetchinPickingSugerido ||
            isCreatingPickingSugerido
          }
        />

        <Box className={StyledRoot}>
          <CustomFieldset title={"Datos Generales"}>
            <Container>
              <IPicking>
                <CustomTextFieldReadable
                  label="Picking"
                  value={fetchedIDPickingSugerido}
                  sxInput={{
                    backgroundColor: "#E0CB79",
                  }}
                />
              </IPicking>
              <IFechaHoy>
                <CustomTextFieldReadable label="Fecha Hoy" value={datosGen.fechaHoy.format("DD/MM/YYYY")} />
              </IFechaHoy>

              <IEstado>
                <CustomTextFieldReadable label="Estado" value={datosGen.estado} />
              </IEstado>

              <ITipo>
                <CustomSelect
                  label="Tipo"
                  selectedOption={datosGen.tipo}
                  setSelectedOption={(v) => handleSetDatosGen("tipo", v)}
                  options={[
                    { value: "INDIVIDUAL", label: "INDIVIDUAL" },
                    { value: "TANDA", label: "TANDA" },
                    { value: "MULTIBATCH", label: "MULTI-BATCH" },
                    { value: "ZONA", label: "ZONA" },
                    { value: "OLA", label: "OLA" },
                  ]}
                />
              </ITipo>

              <IUsuario>
                <CustomAutocomplete
                  label="Usuario"
                  selectedOption={datosGen.usuario}
                  setSelectedOption={(v) => handleSetDatosGen("usuario", v)}
                  options={fetchedUsuarios}
                />
              </IUsuario>

              <IFechaRealizar>
                <CustomDatePicker
                  label="Fecha a Realizar"
                  value={datosGen.fechaRealizar}
                  setValue={(v) => handleSetDatosGen("fechaRealizar", v)}
                  slotProps={{ textField: { fullWidth: true } }}
                />
              </IFechaRealizar>

              <IHoraRealizar>
                <CustomTimePicker
                  label="Hora a Realizar"
                  value={datosGen.horaRealizar}
                  setValue={(v) => handleSetDatosGen("horaRealizar", v)}
                />
              </IHoraRealizar>

              <IComentario>
                <CustomTextField
                  label="Comentario"
                  value={datosGen.comentario}
                  setValue={(v) => handleSetDatosGen("comentario", v)}
                  fullwidth
                />
              </IComentario>
            </Container>
          </CustomFieldset>
          <br />
          <br />
          <br />
          <br />
          <CustomFieldset title={"Criterio de Búsqueda para seleccionar registros del picking"}>
            <ContainerFiltros>
              <ISolicitudEgreso>
                <CustomHelper
                  label="Solicitud Egreso"
                  valueSearched={filtros.solicitudEgreso?.sgasolegr}
                  endpoint="/pickingSugerido/getSolEgresoHelper"
                  valueInputMain="sgasolegr"
                  idSearchField="sgasolegr"
                  errorMsgIdSearch="Error fetching data:"
                  errorMsgFilterSearch="Error en cargar datos"
                  queryKeyModal="solicitudEgresoHelper"
                  perPage={10}
                  placeholder=""
                  columnsTable={[
                    {
                      accessorKey: "ciacodigo",
                      header: "Código CI",
                      size: 100,
                      Cell: ({ cell }) => {
                        const value = cell.getValue()
                        return <span>{value}</span>
                      },
                    },
                    {
                      accessorKey: "sgasolegr",
                      header: "Solicitud Egreso",
                      size: 150,
                      Cell: ({ cell }) => {
                        const value = cell.getValue()
                        return <span>{value}</span>
                      },
                    },
                    {
                      accessorKey: "sgafecsol",
                      header: "Fecha Solicitud",
                      size: 150,
                      Cell: ({ cell }) => {
                        const value = cell.getValue()
                        return <span>{value}</span>
                      },
                    },
                    {
                      accessorKey: "sgahorsol",
                      header: "Hora Solicitud",
                      size: 100,
                      Cell: ({ cell }) => {
                        const value = cell.getValue()
                        return <span>{value}</span>
                      },
                    },
                    {
                      accessorKey: "motdescripcion",
                      header: "Descripción Motivo",
                      size: 200,
                      Cell: ({ cell }) => {
                        const value = cell.getValue()
                        return <span>{value}</span>
                      },
                    },
                    {
                      accessorKey: "sgafecenvio",
                      header: "Fecha Envío",
                      size: 150,
                      Cell: ({ cell }) => {
                        const value = cell.getValue()
                        return <span>{value}</span>
                      },
                    },
                    {
                      accessorKey: "sgahorenvio",
                      header: "Hora Envío",
                      size: 100,
                      Cell: ({ cell }) => {
                        const value = cell.getValue()
                        return <span>{value}</span>
                      },
                    },
                    {
                      accessorKey: "sgacomenenvio",
                      header: "Comentario Envío",
                      size: 200,
                      Cell: ({ cell }) => {
                        const value = cell.getValue()
                        return <p>{value}</p>
                      },
                    },
                    {
                      accessorKey: "sgadescri",
                      header: "Descripción",
                      size: 250,
                      Cell: ({ cell }) => {
                        const value = cell.getValue()
                        return <p>{value}</p>
                      },
                    },
                    {
                      accessorKey: "clinombre",
                      header: "Nombre del Cliente",
                      size: 250,
                      Cell: ({ cell }) => {
                        const value = cell.getValue()
                        return <span>{value}</span>
                      },
                    },
                    {
                      accessorKey: "pronombre",
                      header: "Nombre del Proveedor",
                      size: 200,
                      Cell: ({ cell }) => {
                        const value = cell.getValue()
                        return <span>{value}</span>
                      },
                    },
                    {
                      accessorKey: "sgastatus",
                      header: "Estado",
                      size: 120,
                      Cell: ({ cell }) => {
                        const value = cell.getValue()
                        if (value === "A") return "Activo"
                        if (value === "I") return "Inactivo"
                        return <span>{value}</span>
                      },
                    },
                  ]}
                  onHandleSelectedData={(v) => handleSetFiltros("solicitudEgreso", v)}
                />
              </ISolicitudEgreso>
              <IClienteBodega>
                <CustomHelperDetail
                  label="Cliente Bodega"
                  valueSearched={filtros.clienteBodega?.clicodigo}
                  endpoint="/pickingSugerido/getClientesHelper"
                  valueInputMain="clicodigo"
                  valueInputSecondary="clinombre"
                  idSearchField="clicodigo"
                  errorMsgIdSearch="Error fetching data:"
                  errorMsgFilterSearch="Error en cargar datos"
                  queryKeyModal="clienteBodegaPickingSugerido"
                  perPage={10}
                  placeholder=""
                  columnsTable={[
                    {
                      accessorKey: "ciacodigo",
                      header: "Ciacodigo",
                      size: 100,
                      Cell: ({ cell }) => {
                        const value = cell.getValue()
                        return <span>{value}</span>
                      },
                    },
                    {
                      accessorKey: "clicodigo",
                      header: "Código de Cliente",
                      size: 100,
                      Cell: ({ cell }) => {
                        const value = cell.getValue()
                        return <span>{value}</span>
                      },
                    },
                    {
                      accessorKey: "clinombre",
                      header: "Nombre del Cliente",
                      size: 250,
                      Cell: ({ cell }) => {
                        const value = cell.getValue()
                        return <span>{value}</span>
                      },
                    },
                    {
                      accessorKey: "cliruc",
                      header: "Número de identificación",
                      size: 150,
                      Cell: ({ cell }) => {
                        const value = cell.getValue()
                        return <span>{value}</span>
                      },
                    },
                    {
                      accessorKey: "clitelef1",
                      header: "Teléfono",
                      size: 100,
                      Cell: ({ cell }) => {
                        const value = cell.getValue()
                        return <span>{value}</span>
                      },
                    },
                    {
                      accessorKey: "clidirec",
                      header: "Dirección",
                      size: 400,
                      Cell: ({ cell }) => {
                        const value = cell.getValue()
                        return <p>{value}</p>
                      },
                    },
                    {
                      accessorKey: "clireferencia1",
                      header: "Referencia Rápida",
                      size: 120,
                      Cell: ({ cell }) => {
                        const value = cell.getValue()
                        return <p>{value}</p>
                      },
                    },
                    {
                      accessorKey: "zoncodigo",
                      header: "Zona",
                      size: 120,
                      Cell: ({ cell }) => {
                        const value = cell.getValue()
                        return <span>{value}</span>
                      },
                    },
                    {
                      accessorKey: "clistatus",
                      header: "Estado",
                      size: 120,
                      Cell: ({ cell }) => {
                        const value = cell.getValue()
                        if (value === "A") return "Activo"
                        if (value === "I") return "Inactivo"
                        return <span>{value}</span>
                      },
                    },
                  ]}
                  onHandleSelectedData={(v) => handleSetFiltros("clienteBodega", v)}
                  sxInputMain={{
                    minWidth: "170px",
                    maxWidth: "170px",
                    marginRight: "10px",
                  }}
                  sxInputSecondary={{
                    width: "100%",
                  }}
                />
              </IClienteBodega>

              <Ifactura>
                <CustomHelper
                  label="Factura"
                  valueSearched={filtros.factura?.facnumfac}
                  endpoint="/pickingSugerido/getFacturaHelper"
                  valueInputMain="facnumfac"
                  idSearchField="facnumfac"
                  errorMsgIdSearch="Error fetching data:"
                  errorMsgFilterSearch="Error en cargar datos"
                  queryKeyModal="facturaPickingSugerido"
                  perPage={10}
                  placeholder=""
                  columnsTable={[
                    {
                      accessorKey: "ciacodigo",
                      header: "Código CI",
                      size: 100,
                      Cell: ({ cell }) => {
                        const value = cell.getValue()
                        return <span>{value}</span>
                      },
                    },
                    {
                      accessorKey: "loccodigo",
                      header: "Código Localización",
                      size: 100,
                      Cell: ({ cell }) => {
                        const value = cell.getValue()
                        return <span>{value}</span>
                      },
                    },
                    {
                      accessorKey: "facnumfac",
                      header: "Número de Factura",
                      size: 200,
                      Cell: ({ cell }) => {
                        const value = cell.getValue()
                        return <span>{value}</span>
                      },
                    },
                    {
                      accessorKey: "facdetalle",
                      header: "Detalle de Factura",
                      size: 200,
                      Cell: ({ cell }) => {
                        const value = cell.getValue()
                        return <span>{value}</span>
                      },
                    },
                    {
                      accessorKey: "clinombre",
                      header: "Nombre del Cliente",
                      size: 250,
                      Cell: ({ cell }) => {
                        const value = cell.getValue()
                        return <span>{value}</span>
                      },
                    },
                    {
                      accessorKey: "facfecemi",
                      header: "Fecha de Emisión",
                      size: 150,
                      Cell: ({ cell }) => {
                        const value = cell.getValue()
                        return <span>{value}</span>
                      },
                    },
                    {
                      accessorKey: "clicodigo",
                      header: "Código Cliente",
                      size: 120,
                      Cell: ({ cell }) => {
                        const value = cell.getValue()
                        return <span>{value}</span>
                      },
                    },
                  ]}
                  onHandleSelectedData={(v) => handleSetFiltros("factura", v)}
                />
              </Ifactura>

              <IclienteFactura>
                <CustomHelperDetail
                  label="Cliente Factura"
                  valueSearched={filtros.clienteFactura?.clicodigo}
                  endpoint="/pickingSugerido/getClientesHelper"
                  valueInputMain="clicodigo"
                  valueInputSecondary="clinombre"
                  idSearchField="clicodigo"
                  errorMsgIdSearch="Error fetching data:"
                  errorMsgFilterSearch="Error en cargar datos"
                  queryKeyModal="clienteFacturaPickingSugerido"
                  perPage={10}
                  placeholder=""
                  columnsTable={[
                    {
                      accessorKey: "ciacodigo",
                      header: "Ciacodigo",
                      size: 100,
                      Cell: ({ cell }) => {
                        const value = cell.getValue()
                        return <span>{value}</span>
                      },
                    },
                    {
                      accessorKey: "clicodigo",
                      header: "Código de Cliente",
                      size: 100,
                      Cell: ({ cell }) => {
                        const value = cell.getValue()
                        return <span>{value}</span>
                      },
                    },
                    {
                      accessorKey: "clinombre",
                      header: "Nombre del Cliente",
                      size: 250,
                      Cell: ({ cell }) => {
                        const value = cell.getValue()
                        return <span>{value}</span>
                      },
                    },
                    {
                      accessorKey: "cliruc",
                      header: "Número de identificación",
                      size: 150,
                      Cell: ({ cell }) => {
                        const value = cell.getValue()
                        return <span>{value}</span>
                      },
                    },
                    {
                      accessorKey: "clitelef1",
                      header: "Teléfono",
                      size: 100,
                      Cell: ({ cell }) => {
                        const value = cell.getValue()
                        return <span>{value}</span>
                      },
                    },
                    {
                      accessorKey: "clidirec",
                      header: "Dirección",
                      size: 400,
                      Cell: ({ cell }) => {
                        const value = cell.getValue()
                        return <p>{value}</p>
                      },
                    },
                    {
                      accessorKey: "clireferencia1",
                      header: "Referencia Rápida",
                      size: 120,
                      Cell: ({ cell }) => {
                        const value = cell.getValue()
                        return <p>{value}</p>
                      },
                    },
                    {
                      accessorKey: "zoncodigo",
                      header: "Zona",
                      size: 120,
                      Cell: ({ cell }) => {
                        const value = cell.getValue()
                        return <span>{value}</span>
                      },
                    },
                    {
                      accessorKey: "clistatus",
                      header: "Estado",
                      size: 120,
                      Cell: ({ cell }) => {
                        const value = cell.getValue()
                        if (value === "A") return "Activo"
                        if (value === "I") return "Inactivo"
                        return <span>{value}</span>
                      },
                    },
                  ]}
                  onHandleSelectedData={(v) => handleSetFiltros("clienteFactura", v)}
                  sxInputMain={{
                    minWidth: "170px",
                    maxWidth: "170px",
                    marginRight: "10px",
                  }}
                  sxInputSecondary={{
                    width: "100%",
                  }}
                />
              </IclienteFactura>

              <IFechaInicial>
                <CustomDatePicker
                  label="Fecha Inicial"
                  value={filtros.fechaInicial}
                  setValue={(v) => handleSetFiltros("fechaInicial", v)}
                  slotProps={{ textField: { fullWidth: true } }}
                />
              </IFechaInicial>

              <IFechaFinal>
                <CustomDatePicker
                  label="Fecha Final"
                  value={filtros.fechaFinal}
                  setValue={(v) => handleSetFiltros("fechaFinal", v)}
                  slotProps={{ textField: { fullWidth: true } }}
                />
              </IFechaFinal>
            </ContainerFiltros>
            <div style={{ paddingTop: "10px", paddingBottom: "10px", textAlign: "end" }}>
              <Button variant="contained" color="primary" onClick={handleSearching}>
                Buscar
              </Button>
            </div>
          </CustomFieldset>
          <br />
          <br />
          <DatagridPickingSugerido
            data={fetchedPickingSugerido}
            onSetSelectedColumns={setColumnsSelectedPS}
            columnsTable={[
              {
                accessorKey: "sgasolegr",
                header: "Factura Solicitud / Egreso",
                size: 150,
                Cell: ({ cell }) => <span>{cell.getValue()}</span>,
              },
              {
                accessorKey: "clibodega",
                header: "Código Cliente",
                size: 150,
                Cell: ({ cell }) => <span>{cell.getValue()}</span>,
              },
              {
                accessorKey: "nombodega",
                header: "Nombre de Cliente",
                size: 300,
                Cell: ({ cell }) => <span>{cell.getValue()}</span>,
              },
              {
                accessorKey: "artcodigo",
                header: "Código de Artículo",
                size: 150,
                Cell: ({ cell }) => <span>{cell.getValue()}</span>,
              },
              {
                accessorKey: "artdescri",
                header: "Nombre de Artículo",
                size: 300,
                Cell: ({ cell }) => <span>{cell.getValue()}</span>,
              },
              {
                accessorKey: "cantpendiente",
                header: "Cantidad Pendiente",
                size: 150,
                Cell: ({ cell }) => <span>{cell.getValue()}</span>,
              },
              {
                accessorKey: "cantpicking",
                header: "Cantidad Picking",
                size: 150,
                Cell: ({ cell }) => <span>{cell.getValue()}</span>,
              },
              {
                accessorKey: "pascodigo",
                header: "Código de Pasillo",
                size: 150,
                Cell: ({ cell }) => <span>{cell.getValue()}</span>,
              },
              {
                accessorKey: "pasdescripcion",
                header: "Descripción de Pasillo",
                size: 300,
                Cell: ({ cell }) => <span>{cell.getValue()}</span>,
              },
              {
                accessorKey: "izoncodigo",
                header: "Código de Zona",
                size: 150,
                Cell: ({ cell }) => <span>{cell.getValue()}</span>,
              },
              {
                accessorKey: "izondescripcion",
                header: "Descripción de Zona",
                size: 300,
                Cell: ({ cell }) => <span>{cell.getValue()}</span>,
              },
              {
                accessorKey: "bahcodigo",
                header: "Código de Bahía",
                size: 150,
                Cell: ({ cell }) => <span>{cell.getValue()}</span>,
              },
              {
                accessorKey: "bahdescripcion",
                header: "Descripción de Bahía",
                size: 300,
                Cell: ({ cell }) => <span>{cell.getValue()}</span>,
              },
              {
                accessorKey: "poscodigo",
                header: "Código de Posición",
                size: 150,
                Cell: ({ cell }) => <span>{cell.getValue()}</span>,
              },
              {
                accessorKey: "posdescripcion",
                header: "Descripción de Posición",
                size: 300,
                Cell: ({ cell }) => <span>{cell.getValue()}</span>,
              },
              {
                accessorKey: "nivcodigo",
                header: "Código de Nivel",
                size: 150,
                Cell: ({ cell }) => <span>{cell.getValue()}</span>,
              },
              {
                accessorKey: "nivdescripcion",
                header: "Descripción de Nivel",
                size: 300,
                Cell: ({ cell }) => <span>{cell.getValue()}</span>,
              },
              {
                accessorKey: "embcodigo",
                header: "Código de Embalaje",
                size: 150,
                Cell: ({ cell }) => <span>{cell.getValue()}</span>,
              },
              {
                accessorKey: "embdescripcion",
                header: "Descripción de Embalaje",
                size: 300,
                Cell: ({ cell }) => <span>{cell.getValue()}</span>,
              },
              {
                accessorKey: "toncodigo",
                header: "Código de Tono",
                size: 150,
                Cell: ({ cell }) => <span>{cell.getValue()}</span>,
              },
              {
                accessorKey: "tondescripcion",
                header: "Descripción de Tono",
                size: 300,
                Cell: ({ cell }) => <span>{cell.getValue()}</span>,
              },
              {
                accessorKey: "embpieza",
                header: "Pieza",
                size: 150,
                Cell: ({ cell }) => <span>{cell.getValue()}</span>,
              },
              {
                accessorKey: "embmetros2",
                header: "Metros Cuadrados",
                size: 200,
                Cell: ({ cell }) => <span>{cell.getValue()}</span>,
              },
            ]}
          />
        </Box>
      </div>
    </ThemeProvider>
  )
}

export default PickingSugeridoCrear
