/* eslint-disable camelcase */
import React, { useState, useEffect } from "react"
import Header from "../../../layouts/Header"
import { styled, createTheme, ThemeProvider } from "@mui/material/styles"
import {
  Box,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Button,
  Tooltip,
  Checkbox,
  FormControlLabel,
} from "@mui/material"
import Swal from "sweetalert2"
import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import BackIcon from "../../../components/BackIcon"
import GrabarIcon from "../../../assets/iconos/Grabar.ico"
import fetchwrapper from "../../../services/interceptors/fetchwrapper"
import CustomBackdrop from "../../../components/CustomBackdrop"
import ImprimirIcon from "../../../assets/iconos/Impresion.ico"
import DetalleImpresion from "../../Zonas/components/DetalleImpresion"
import EmbalajesPdf from "../components/EmbalajesPdf"
const StyledRoot = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  marginTop: theme.spacing(8),
  flexGrow: 1,
  padding: "0 16px",
  height: "100vh",
}))
const theme = createTheme({
  palette: {
    primary: {
      main: "#196C87",
    },
    secondary: {
      main: "#196C87", // Cambia el color secundario a verde azulado
    },
  },
})
const EditarEmbalaje = () => {
  const { id } = useParams()
  const location = useLocation()
  const datoSeleccionado = location.state
  const estadoActual = datoSeleccionado.embstatus
  const descripcionActual = datoSeleccionado.embdescripcion
  const metros3Actual = datoSeleccionado.embmetros3
  const metros2Actual = datoSeleccionado.embmetros2
  const piezasActual = datoSeleccionado.embpieza
  const manejaLastra = datoSeleccionado.emblastra

  const [openModal, setOpenModal] = useState(false)
  const [infoGenerada, setInfoGenerada] = useState([])
  const handleOpenModal = () => {
    const datos = getDatosActuales()
    console.log("datos", datos)
    setInfoGenerada(datos) // Asigna los datos de la tabla al estado
    setOpenModal(true)
  }

  const handleCloseModal = () => {
    setOpenModal(false)
    navigate(`/home/dashboard/Embalajes`)
  }
  function getDatosActuales() {
    return [
      {
        embcodigo: id,
        embdescripcion: descripcionActual,
        embstatus: estadoActual,
        embmetros3: metros3Actual,
        embmetros2: metros2Actual,
        embpieza: piezasActual,
        emblastra: manejaLastra,
      },
    ]
  }
  const navigate = useNavigate()

  // Valores por defectos para los comboboxes y el check
  const Estados = [
    { value: "A", description: "ACTIVO" },
    { value: "I", description: "INACTIVO" },
  ]

  const findEstado = (estadoCurrent) => {
    const result = Estados.find((estado) => estado.value === estadoCurrent)
    return result
  }

  // Estados para manejaar el formulario de opciones
  // cuando llame a las funciones set, se actualizara el valor de los otros campos y se re renderiza
  const [codigo, setCodigo] = useState(id)
  const [descripcion, setDescripcion] = useState(descripcionActual)
  const [estado, setEstado] = useState(findEstado(estadoActual))
  const [metros3, setMetros3] = useState(metros3Actual)
  const [metros2, setMetros2] = useState(metros2Actual)
  const [pieza, setPiezas] = useState(piezasActual)
  const [lastra, setLastra] = useState(manejaLastra)
  const { mutateAsync: updateEmbalaje, isPending: isUpdatingEmbalaje } = useUpdateEmbalaje()

  function useUpdateEmbalaje() {
    const queryClient = useQueryClient()
    return useMutation({
      mutationFn: async (embalaje) => {
        // send api update request here
        // send api update request here
        const options = {
          method: "PUT",
          body: JSON.stringify(embalaje),
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }
        console.log("json", options.body)
        let response = await fetchwrapper(`/Embalajes/updateEmbalaje/${id}`, options)

        response = response.json()
        console.log(response)
        return response
      },
      // client side optimistic update
      onMutate: (newEmbalaje) => {
        console.log(newEmbalaje, "onmutate")
        queryClient.setQueryData(["Embalaje", id], (prevEmbalaje) => ({
          ...newEmbalaje,
        }))
      },
      // onSettled: () => queryClient.invalidateQueries({ queryKey: ['bancoDePreguntas'] }), //refetch bancoDePreguntas after mutation, disabled for demo
    })
  }
  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      const embalaje = {
        descripcionEmbalaje: descripcion,
        estadoEmbalaje: estado.value,
        metrosCubicos: metros3,
        metrosCuadrados: metros2,
        lastraEmbalaje: lastra,
        piezaEmbalaje: pieza,
      }
      console.log("json", embalaje)
      await updateEmbalaje(embalaje)
      Swal.fire({
        icon: "success",
        title: "Operación exitosa",
        text: "Se pudo editar el embalaje",
      }).then((result) => {
        if (result.isConfirmed) {
          navigate("/home/dashboard/Embalajes") // Navegar a la ruta especificada
        }
      })
    } catch (error) {
      Swal.fire({
        title: "Error",
        text: "No se pudo editar el embalaje",
        icon: "error",
        confirmButtonText: "OK",
      }).then(() => {
        navigate("/home/dashboard/Embalajes") // Navega de vuelta cuando se cierre el SweetAlert
      })
    }
  }
  const handleChange = (event) => {
    // Cambia el valor de 'lastra' a 1 si está marcado, o a 0 si no lo está
    setLastra(event.target.checked)
  }
  useEffect(() => {
    setLastra(manejaLastra)
    console.log("lastra", manejaLastra)
  }, [manejaLastra])
  const handleNumericInput = (value, setValue) => {
    // Allow empty value, integers, or decimals
    if (/^\d*\.?\d*$/.test(value)) {
      setValue(value)
    }
  }
  return (
    <ThemeProvider theme={theme}>
      <Header />
      <div className="main main-app p-3 p-lg-4">
        <BackIcon />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "0 30px 30px 30px",
            fontSize: "25px",
          }}
        >
          <b>Embalajes</b>
        </div>
        <Box className={StyledRoot}>
          <form onSubmit={handleSubmit}>
            <Tooltip title="Grabar">
              <Button type="submit" color="primary" sx={{ marginTop: "15px" }}>
                <img src={GrabarIcon} alt="Grabar" style={{ width: "40px" }} />
              </Button>
            </Tooltip>
            <Tooltip title="Imprimir">
              <Button color="primary" onClick={handleOpenModal} sx={{ marginTop: "15px" }}>
                <img src={ImprimirIcon} alt="Imprimir" style={{ width: "30px" }} />
              </Button>
            </Tooltip>
            <DetalleImpresion
              open={openModal}
              handleClose={handleCloseModal}
              infoGenerada={getDatosActuales()}
              nombrePdf={"Embalaje"}
              PdfComponent={EmbalajesPdf}
            />
            <TextField
              label="Código"
              margin="normal"
              fullWidth
              value={codigo}
              required
              disabled
              onChange={(e) => setCodigo(e.target.value)}
              inputProps={{ maxLength: 10 }}
            />
            <TextField
              label="Descripción"
              margin="normal"
              fullWidth
              value={descripcion}
              onChange={(e) => setDescripcion(e.target.value)}
              required
            />
            <TextField
              label="Metros3"
              margin="normal"
              fullWidth
              value={metros3}
              required
              onChange={(e) => handleNumericInput(e.target.value, setMetros3)}
            />
            <TextField
              label="Piezas"
              margin="normal"
              fullWidth
              value={pieza}
              required
              onChange={(e) => handleNumericInput(e.target.value, setPiezas)}
            />
            <TextField
              label="Metros2"
              margin="normal"
              fullWidth
              value={metros2}
              required
              onChange={(e) => handleNumericInput(e.target.value, setMetros2)}
            />
            <div>
              <FormControlLabel
                control={<Checkbox checked={lastra} onChange={handleChange} name="manejaLastra" color="primary" />}
                label="Maneja Lastra?"
              />
            </div>
            <div>
              <InputLabel id="estadoEmbalajesCB" sx={{ paddingBlock: "15px", paddingLeft: "5px" }}>
                Estado del embalaje
              </InputLabel>
              <Select
                fullWidth
                labelId="estadoEmbalajesCB"
                value={estado.value}
                onChange={(e) => {
                  const selectedValue = e.target.value
                  const embalajeestado = Estados.find((estado) => estado.value === selectedValue)
                  setEstado(embalajeestado)
                  //   clearAll(tipoPregunta)
                }}
              >
                {Estados.map((estado) => (
                  <MenuItem key={estado.value} value={estado.value}>
                    {estado.description}
                  </MenuItem>
                ))}
              </Select>
            </div>
          </form>
        </Box>
        <CustomBackdrop isLoading={isUpdatingEmbalaje} />
      </div>
    </ThemeProvider>
  )
}

export default EditarEmbalaje
