import React, { useEffect, useState } from "react"
import { Modal, Box, Button, IconButton } from "@mui/material"
import { PDFViewer, pdf } from "@react-pdf/renderer"
import { FcDocument } from "react-icons/fc"
import CloseIcon from "@mui/icons-material/Close"
import CustomTooltip from "../../../components/ToolTip"
import RegresarIcon from "../../../assets/iconos/Regresar.ico"

const DetalleImpresionSearch = ({ open, handleClose, infoGenerada, DetallePDFComponent, filename, buttonText }) => {
  const [key, setKey] = useState(0)

  const handlePrint = async () => {
    const blob = await pdf(<DetallePDFComponent data={infoGenerada} />).toBlob()
    const url = URL.createObjectURL(blob)
    const link = document.createElement("a")
    link.href = url
    link.download = `${filename}.pdf`
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    handleClose()
  }

  const handleRegenerate = () => {
    setKey((prevKey) => prevKey + 1)
  }

  useEffect(() => {
    // console.log(JSON.stringify(infoGenerada), "web")
  }, [infoGenerada])

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "75vw", // Aumenta el ancho para horizontal
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
        }}
      >
        <Button
          onClick={handlePrint}
          style={{ marginTop: "20px" }}
          variant="contained"
          sx={{
            marginRight: "15px",
            marginTop: "20px",
            marginBottom: "10px",
          }}
          color="secondary"
        >
          <span className="me-2">{buttonText}</span>
          <FcDocument size={"25px"} />
        </Button>

        <PDFViewer key={key} style={{ width: "100%", height: "70vh" }}>
          <DetallePDFComponent data={infoGenerada} />
        </PDFViewer>

        <CustomTooltip title={"Regresar"}>
          <IconButton onClick={handleClose} sx={{ mt: 2 }}>
            <img src={RegresarIcon} alt="Regresar" style={{ width: "40px" }} />
          </IconButton>
        </CustomTooltip>
      </Box>
    </Modal>
  )
}

export default DetalleImpresionSearch
