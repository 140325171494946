/* eslint-disable camelcase */
import React, { useState, useEffect } from "react"
import Header from "../../../layouts/Header"
import { styled, createTheme, ThemeProvider } from "@mui/material/styles"
import { Box, InputLabel, Select, MenuItem, TextField, Button, Tooltip } from "@mui/material"
import Swal from "sweetalert2"
import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import BackIcon from "../../../components/BackIcon"
import GrabarIcon from "../../../assets/iconos/Grabar.ico"
import fetchwrapper from "../../../services/interceptors/fetchwrapper"
import CustomBackdrop from "../../../components/CustomBackdrop"
import ImprimirIcon from "../../../assets/iconos/Impresion.ico"
import DetalleImpresion from "../../Zonas/components/DetalleImpresion"
import AccionesPdf from "../components/AccionesPdf"
const StyledRoot = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  marginTop: theme.spacing(8),
  flexGrow: 1,
  padding: "0 16px",
  height: "100vh",
}))
const theme = createTheme({
  palette: {
    primary: {
      main: "#196C87",
    },
    secondary: {
      main: "#196C87", // Cambia el color secundario a verde azulado
    },
  },
})
const EditarAccion = () => {
  const { id } = useParams()
  const location = useLocation()
  const datoSeleccionado = location.state
  const accionActual = datoSeleccionado.accion
  const [openModal, setOpenModal] = useState(false)
  const [infoGenerada, setInfoGenerada] = useState([])
  const handleOpenModal = () => {
    const datos = getDatosActuales()
    console.log("datos", datos)
    setInfoGenerada(datos) // Asigna los datos de la tabla al estado
    setOpenModal(true)
  }

  const handleCloseModal = () => {
    setOpenModal(false)
    navigate(`/home/dashboard/Acciones`)
  }
  function getDatosActuales() {
    return [
      {
        acccodigo: id,
        accion: accionActual,
      },
    ]
  }
  const navigate = useNavigate()
  const [codigo, setCodigo] = useState(id)
  const [accionAcc, setAccion] = useState(accionActual)

  const { mutateAsync: updateAccion, isPending: isUpdatingAccion } = useUpdateAccion()

  function useUpdateAccion() {
    const queryClient = useQueryClient()
    return useMutation({
      mutationFn: async (accion) => {
        // send api update request here
        // send api update request here
        const options = {
          method: "PUT",
          body: JSON.stringify(accion),
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }
        console.log("json", options.body)
        let response = await fetchwrapper(`/Acciones/updateAccion/${id}`, options)

        response = response.json()
        console.log(response)
        return response
      },
      // client side optimistic update
      onMutate: (newAccion) => {
        console.log(newAccion, "onmutate")
        queryClient.setQueryData(["Accion", id], (prevAccion) => ({
          ...newAccion,
        }))
      },
      // onSettled: () => queryClient.invalidateQueries({ queryKey: ['bancoDePreguntas'] }), //refetch bancoDePreguntas after mutation, disabled for demo
    })
  }
  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      const accion = {
        accionAccion: accionAcc,
      }
      await updateAccion(accion)
      Swal.fire({
        icon: "success",
        title: "Operación exitosa",
        text: "Se pudo editar la accion",
      }).then((result) => {
        if (result.isConfirmed) {
          navigate("/home/dashboard/Acciones") // Navegar a la ruta especificada
        }
      })
    } catch (error) {
      Swal.fire({
        title: "Error",
        text: "No se pudo editar la accion",
        icon: "error",
        confirmButtonText: "OK",
      }).then(() => {
        navigate("/home/dashboard/Acciones") // Navega de vuelta cuando se cierre el SweetAlert
      })
    }
  }
  return (
    <ThemeProvider theme={theme}>
      <Header />
      <div className="main main-app p-3 p-lg-4">
        <BackIcon />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "0 30px 30px 30px",
            fontSize: "25px",
          }}
        >
          <b>Acciones quimico</b>
        </div>
        <Box className={StyledRoot}>
          <form onSubmit={handleSubmit}>
            <Tooltip title="Grabar">
              <Button type="submit" color="primary" sx={{ marginTop: "15px" }}>
                <img src={GrabarIcon} alt="Grabar" style={{ width: "30px" }} />
              </Button>
            </Tooltip>
            <Tooltip title="Imprimir">
              <Button color="primary" onClick={handleOpenModal} sx={{ marginTop: "15px" }}>
                <img src={ImprimirIcon} alt="Imprimir" style={{ width: "30px" }} />
              </Button>
            </Tooltip>
            <DetalleImpresion
              open={openModal}
              handleClose={handleCloseModal}
              infoGenerada={getDatosActuales()}
              nombrePdf={"Accion"}
              PdfComponent={AccionesPdf}
            />
            <TextField
              label="Código"
              margin="normal"
              fullWidth
              value={codigo}
              disabled
              required
              sx={{
                backgroundColor: "#e2dbd8",
              }}
            />
            <TextField
              label="Descripción"
              margin="normal"
              fullWidth
              value={accionAcc}
              onChange={(e) => setAccion(e.target.value)}
              required
            />
          </form>
        </Box>
        <CustomBackdrop isLoading={isUpdatingAccion} />
      </div>
    </ThemeProvider>
  )
}

export default EditarAccion
