import { Grid, Autocomplete, TextField } from "@mui/material/"
import { useState } from "react"

const estadosProformas = [
  { label: "Aprobado", codigo: "C" },
  { label: "Pendiente", codigo: "P" },
  { label: "Perdido", codigo: "E" },
  { label: "Facturado", codigo: "F" },
  { label: "Anulado", codigo: "A" },
  { label: "Cerrado", codigo: "T" },
]
const InputMultivalueFiltrarProforma = ({ setEstadoFactura }) => {
  const handleSelectOption = (event, newValue) => {
    const codesValues = newValue.map((value) => value.codigo)
    setEstadoFactura(codesValues)
  }

  return (
    <Grid item xs={12} sm={6} md={4}>
      <Autocomplete
        multiple
        id="tags-outlined"
        options={estadosProformas}
        getOptionLabel={(option) => option.label}
        filterSelectedOptions
        onChange={handleSelectOption}
        renderInput={(params) => <TextField {...params} label="Estado" placeholder="Estado" />}
      />
    </Grid>
  )
}

export default InputMultivalueFiltrarProforma
