import React, { useEffect } from "react"
import { Card, CardHeader, CardMedia, CardContent, Typography } from "@mui/material"
import { styled, createTheme, ThemeProvider } from "@mui/material/styles"
import Grid from "@mui/material/Grid"
import Paper from "@mui/material/Paper"
import ButtonBase from "@mui/material/ButtonBase"

const Img = styled("img")({
  margin: "auto",
  display: "block",
  maxWidth: "100%",
  maxHeight: "100%",
})
const StyledCard = styled(Card)(({ theme }) => ({
  display: "flex",
  margin: "auto",
  maxWidth: 500,
  flexGrow: 1,
  backgroundColor: (theme) => (theme.palette.mode === "dark" ? "#1A2027" : "#fff"),
  cursor: "pointer",
}))

const CardProducto = ({ producto, onClick }) => {
  useEffect(() => {}, [producto])

  return (
    <>
      <Paper
        sx={{
          p: 2,
          margin: "auto",
          maxWidth: 500,
          flexGrow: 1,
          backgroundColor: (theme) => (theme.palette.mode === "dark" ? "#1A2027" : "#fff"),
          cursor: "pointer", // added cursor to indicate clickability
        }}
        onClick={onClick}
      >
        <Grid container spacing={2} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          {producto.imagen && (
            <Grid item sx={{ display: "flex", justifyContent: "center" }}>
              <img
                alt={producto.descripcion}
                src={`data:image/jpeg;base64,${producto.imagen}`}
                style={{ width: 128, height: 128, objectFit: "contain" }}
              />
            </Grid>
          )}
          <Grid item xs={12} sm container>
            <Grid item xs container direction="column" spacing={2}>
              <Grid item xs>
                <Typography
                  gutterBottom
                  variant="subtitle1"
                  component="div"
                  sx={{ textAlign: "center", fontWeight: "bold" }}
                >
                  Codigo: {producto.codigo}
                </Typography>
                <Typography variant="body2" gutterBottom>
                  <strong>Descripcion:</strong> {producto.descripcion}
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{ fontSize: "0.8rem" }}>
                  <strong>Marca:</strong> {producto.marca}
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{ fontSize: "0.8rem" }}>
                  <strong>Medida:</strong> {producto.medida ? producto.medida : "N/A"}
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{ fontSize: "0.8rem" }}>
                  <strong>Presentacion:</strong> {producto.presentacion ? producto.presentacion : "N/A"}
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{ fontSize: "0.8rem" }}>
                  <strong>Linea:</strong> {producto.linea ? producto.linea : "N/A"}
                </Typography>
                <Typography
                  variant="body2"
                  color={producto.cantidad > 1 ? "text.secondary" : "error"}
                  sx={{ fontSize: "0.8rem" }}
                >
                  <strong>Cantidad:</strong> {producto.cantidad ? Math.round(producto.cantidad) : "N/A"}
                </Typography>
              </Grid>
            </Grid>
            {producto.precio && (
              <Grid item>
                <Typography variant="subtitle1" component="div" sx={{ textAlign: "right", color: "error" }}>
                  {producto.precio ? `$${parseFloat(producto.precio).toFixed(2)}` : "N/A"}
                </Typography>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Paper>
    </>
  )
}

export default CardProducto
