/* eslint-disable camelcase */
import { useMemo, useRef, useState } from "react"
import {
  MaterialReactTable,
  // createRow,
  useMaterialReactTable,
} from "material-react-table"
import { createTheme, ThemeProvider, useTheme, Box, Button, IconButton, Tooltip } from "@mui/material"
import { esES } from "@mui/material/locale"
import { MRT_Localization_ES } from "material-react-table/locales/es"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import EditIcon from "@mui/icons-material/Edit"
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye"
import fetchwrapper from "../../../services/interceptors/fetchwrapper"
import { useNavigate } from "react-router-dom"
import CrearIcon from "../../../assets/iconos/Crear.ico"
import normalFormatDate from "../../utils/date/DDMMYYYFormatDate"

const CustomTable = ({ fetchedInfo, isLoadingInfoError, isLoadingInfo, isFetchingInfo, columnsTable = [] }) => {
  const navigate = useNavigate()

  const columns = useMemo(
    () =>
      columnsTable.map((column) => ({
        ...column,
      })),
    [],
  )

  const table = useMaterialReactTable({
    columns,
    data: fetchedInfo,
    // enableRowSelection: true,
    enableEditing: true,
    enableRowActions: true,
    localization: { ...MRT_Localization_ES },
    getRowId: (row) => row.formcodigo,
    muiToolbarAlertBannerProps: isLoadingInfoError
      ? {
          color: "error",
          children: "Error en cargar las Solicitudes Ingresos",
        }
      : undefined,
    muiTableContainerProps: {
      sx: {
        minHeight: "500px",
      },
    },

    renderRowActions: ({ row, table }) => (
      <Box sx={{ display: "flex", gap: "1rem" }}>
        <Tooltip title="Ver">
          <IconButton
            onClick={() => {
              navigate(`ver`, {
                state: row?.original,
              })
            }}
          >
            <RemoveRedEyeIcon />
          </IconButton>
        </Tooltip>
      </Box>
    ),
    state: {
      isLoading: isLoadingInfo,
      showAlertBanner: isLoadingInfoError,
      showProgressBars: isFetchingInfo,
    },
  })

  return (
    <>
      <MaterialReactTable table={table} />
    </>
  )
}

const DatagridAllPickingSugerido = (params) => {
  const theme = useTheme()
  return (
    <div>
      <ThemeProvider theme={createTheme(theme, esES)}>
        <CustomTable {...params} />
      </ThemeProvider>
    </div>
  )
}

export default DatagridAllPickingSugerido
