import { useMemo, useState } from "react"
import {
  MaterialReactTable,
  // createRow,
  useMaterialReactTable,
} from "material-react-table"
import DetalleImpresion from "../../Zonas/components/DetalleImpresion"
import CustomBackdrop from "../../../components/CustomBackdrop"
import { MRT_Localization_ES } from "material-react-table/locales/es"
import { createTheme, ThemeProvider, useTheme, Box, Button, IconButton, Tooltip } from "@mui/material"
import { esES } from "@mui/material/locale"
import ImprimirIcon from "../../../assets/iconos/Impresion.ico"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import EditIcon from "@mui/icons-material/Edit"
import EliminarIcon from "../../../assets/iconos/Eliminar.ico"
import DetalleIcon from "../../../assets/iconos/Detalle.ico"
import DeleteIcon from "@mui/icons-material/Delete"
import fetchwrapper from "../../../services/interceptors/fetchwrapper"
import { useNavigate } from "react-router-dom"
import CrearIcon from "../../../assets/iconos/Crear.ico"
import EmbalajesPdf from "./EmbalajesPdf"
import normalFormatDate from "../../utils/date/DDMMYYYFormatDate"
import normalFormatHour from "../../utils/date/HHMMSSFormatHour"

const CustomTable = () => {
  // para movernos a crear y editar
  const navigate = useNavigate()
  const columns = useMemo(
    () => [
      {
        accessorKey: "embcodigo",
        header: "Código",
        enableEditing: false,
        size: 80,
      },
      {
        accessorKey: "embdescripcion",
        header: "Descripción",
        enableEditing: false,
        size: 80,
      },
      {
        accessorKey: "embmetros3",
        header: "Metros Cubicos",
        enableEditing: false,
        size: 80,
      },
      {
        accessorKey: "embmetros2",
        header: "Metros Cuadrados",
        enableEditing: false,
        size: 80,
      },
      {
        accessorKey: "embpieza",
        header: "Piezas",
        enableEditing: false,
        size: 80,
      },
      {
        accessorKey: "emblastra",
        header: "Lastra",
        enableEditing: false,
        size: 80,
        Cell: ({ cell }) => {
          const cellValue = cell.getValue()
          return cellValue ? "Sí" : "No"
        },
      },
      {
        accessorKey: "embstatus",
        header: "Estado",
        enableEditing: false,
        size: 80,
        Cell: ({ cell }) => {
          let cellValue = cell.getValue()
          if (cellValue === "A") {
            cellValue = "ACTIVO"
          } else {
            cellValue = "INACTIVO"
          }
          return cellValue
        },
      },
      {
        accessorKey: "embusuisys",
        header: "Usuario Creacion",
        enableEditing: false,
        size: 80,
      },
      {
        accessorKey: "embfecisys",
        header: "Fecha de Creación",
        enableEditing: false,
        size: 80,
        Cell: ({ cell }) => {
          const cellValue = cell.getValue()
          return normalFormatDate(cellValue)
        },
      },
      {
        accessorKey: "embhorisys",
        header: "Hora de Creación",
        enableEditing: false,
        size: 80,
        Cell: ({ cell }) => {
          const cellValue = cell.getValue()
          return new Date(cellValue).toLocaleTimeString()
        },
      },
      {
        accessorKey: "embusumsys",
        header: "Usuario Modifica",
        enableEditing: false,
        size: 80,
      },
      {
        accessorKey: "embfecmsys",
        header: "Fecha Modifica",
        enableEditing: false,
        size: 80,
        Cell: ({ cell }) => {
          const cellValue = cell.getValue()
          return normalFormatDate(cellValue)
        },
      },
      {
        accessorKey: "embhormsys",
        header: "Hora Modifica",
        enableEditing: false,
        size: 80,
        Cell: ({ cell }) => {
          const cellValue = cell.getValue()
          return new Date(cellValue).toLocaleTimeString()
        },
      },
    ],
    [],
  )
  // useCreatePasillos crea las zonas
  // createPasillos funcion que se llama
  const { mutateAsync: createEmbalaje, isPending: isCreatingEmbalaje } = useCreateEmbalaje()

  const {
    data: fetchedEmbalajes = [],
    isError: isLoadingEmbalajesError,
    isFetching: isFetchingEmbalajes,
    isLoading: isLoadingEmbalajes,
  } = useGetEmbalajes()
  // useUpdateZona() hook de update, ,updateZona funcion que se ejecuta
  const { mutateAsync: updateEmbalaje, isPending: isUpdatingEmbalaje } = useUpdateEmbalaje()
  // useDeleteZona() hook de delete , usa la funcion deleteZona()
  const { mutateAsync: deleteEmbalaje, isPending: isDeletingEmbalaje } = useDeleteEmbalaje()
  // funcion que muestra el modal de eliminacion
  const openDeleteConfirmModal = async (row) => {
    if (window.confirm("¿Está seguro que quiere eliminar esta embalaje?")) {
      await deleteEmbalaje(row.original.embcodigo)
    }
  }
  const table = useMaterialReactTable({
    columns,
    data: fetchedEmbalajes,
    enableEditing: true,
    initialState: {
      showColumnFilters: true,
    },
    enableRowActions: true,
    localization: { ...MRT_Localization_ES },
    getRowId: (row) => row.embcodigo,
    // si se cargar el error al importar los datos
    muiToolbarAlertBannerProps: isLoadingEmbalajesError
      ? {
          color: "error",
          children: "Error en cargar los embalajes",
        }
      : undefined,
    muiTableContainerProps: {
      sx: {
        minHeight: "500px",
      },
    },
    renderRowActions: ({ row, table }) => (
      <Box sx={{ display: "flex", gap: "1rem" }}>
        <Tooltip title="Editar">
          <IconButton
            onClick={() => {
              navigate(`editar/${row?.original.embcodigo}`, {
                state: row?.original,
              })
            }}
          >
            <img src={DetalleIcon} alt="Editar" style={{ width: "20px" }} />
          </IconButton>
        </Tooltip>
        <Tooltip title="Eliminar">
          <IconButton color="error" onClick={() => openDeleteConfirmModal(row)}>
            <img src={EliminarIcon} alt="Grabar" style={{ width: "20px" }} />
          </IconButton>
        </Tooltip>
      </Box>
    ),
    state: {
      isLoading: isLoadingEmbalajes,
      isSaving: isCreatingEmbalaje || isUpdatingEmbalaje || isDeletingEmbalaje,
      showAlertBanner: isLoadingEmbalajes,
      showProgressBars: isFetchingEmbalajes,
    },
  })
  return (
    <>
      <MaterialReactTable table={table} />
      <CustomBackdrop isLoading={table.getState().isSaving} />
    </>
  )
}
function useGetEmbalajes() {
  return useQuery({
    queryKey: ["Embalajes"],
    queryFn: async () => {
      // send api request here
      let response = await fetchwrapper("/Embalajes/getEmbalajes")
      response = await response.json()
      response = response?.data
      // await new Promise((resolve) => setTimeout(resolve, 1000)); //fake api call
      return response
    },
    refetchOnWindowFocus: false,
  })
}

// hooks a los que se les asigna una api y se encargan de actualizar el estado del componente
function useCreateEmbalaje() {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (embalaje) => {
      await new Promise((resolve) => setTimeout(resolve, 1000)) // fake api call
      return Promise.resolve()
    },
    onMutate: (newEmbalaje) => {
      queryClient.setQueryData(["Embalajes"], (prevEmbalajes) =>
        prevEmbalajes?.map((prevEmbalajes) =>
          prevEmbalajes.embcodigo === newEmbalaje.embcodigo ? newEmbalaje : prevEmbalajes,
        ),
      )
    },
  })
}
function useUpdateEmbalaje() {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (embalaje) => {
      // send api update request here
      await new Promise((resolve) => setTimeout(resolve, 1000)) // fake api call
      return Promise.resolve()
    },
    // client side optimistic update
    onMutate: (newEmbalajeInfo) => {
      queryClient.setQueryData(["Embalajes"], (prevEmbalajes) =>
        prevEmbalajes?.map((prevEmbalajes) =>
          prevEmbalajes.embcodigo === newEmbalajeInfo.embcodigo ? newEmbalajeInfo : prevEmbalajes,
        ),
      )
    },
  })
}
function useDeleteEmbalaje() {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (codigoEmbalaje) => {
      const options = {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }

      const response = await fetchwrapper(`/Embalajes/deleteEmbalaje/${codigoEmbalaje}`, options)
      return response
    },
    // actualizacion optimista
    onMutate: (codigoEmbalaje) => {
      queryClient.setQueryData(["Embalajes"], (prevEmbalajes) =>
        prevEmbalajes?.filter((embalaje) => embalaje.embcodigo !== codigoEmbalaje),
      )
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ["Embalajes"] })
    },
    onSuccess: () => {
      alert("Embalaje eliminado con exito")
    },
    onError: () => {
      alert("No se pudo eliminar el embalaje")
    },
  })
}

const DataGridEmbalajes = () => {
  const [openModal, setOpenModal] = useState(false)
  const [infoGenerada, setInfoGenerada] = useState([])
  const {
    data: fetchedEmbalajes = [],
    isError: isLoadingEmbalajesError,
    isFetching: isFetchingEmbalajes,
    isLoading: isLoadingEmbalajes,
  } = useGetEmbalajes()
  const handleOpenModal = () => {
    setInfoGenerada(fetchedEmbalajes) // Asigna los datos de la tabla al estado
    setOpenModal(true)
  }
  console.log("info pdf", fetchedEmbalajes)
  const handleCloseModal = () => {
    setOpenModal(false)
    navigate("/home/dashboard/Embalajes")
  }
  const theme = useTheme()
  const navigate = useNavigate()
  return (
    <div>
      <Tooltip title="Crear Embalaje">
        <Button color="primary" onClick={() => navigate("crear")} sx={{ marginBottom: "15px" }}>
          <img src={CrearIcon} alt="Crear" style={{ width: "30px" }} />
        </Button>
      </Tooltip>
      <Tooltip title="Imprimir">
        <Button color="primary" onClick={handleOpenModal} sx={{ marginBottom: "15px" }}>
          <img src={ImprimirIcon} alt="Imprimir" style={{ width: "30px" }} />
        </Button>
      </Tooltip>
      <DetalleImpresion
        open={openModal}
        handleClose={handleCloseModal}
        infoGenerada={fetchedEmbalajes}
        nombrePdf={"Embalajes"}
        PdfComponent={EmbalajesPdf}
      />
      <ThemeProvider theme={createTheme(theme, esES)}>
        <CustomTable />
      </ThemeProvider>
    </div>
  )
}
export default DataGridEmbalajes
