// import React, { useState, useEffect, useCallback, useRef } from "react"
// import Webcam from "react-webcam"

// const ImagenTab = () => {
//   const [devices, setDevices] = useState([])
//   const [deviceId, setDeviceId] = useState("")
//   const webcamRef = useRef(null)

//   // Obtener dispositivos de video
//   const handleDevices = useCallback(
//     (mediaDevices) => setDevices(mediaDevices.filter(({ kind }) => kind === "videoinput")),
//     [setDevices],
//   )

//   useEffect(() => {
//     navigator.mediaDevices.enumerateDevices().then(handleDevices)
//   }, [handleDevices])

//   // Capturar foto
//   const capturePhoto = useCallback(() => {
//     const imageSrc = webcamRef.current.getScreenshot()
//     console.log(imageSrc) // Aquí puedes manejar la imagen capturada
//   }, [webcamRef])

//   return (
//     <div>
//       <h2>Seleccionar cámara</h2>
//       <select onChange={(e) => setDeviceId(e.target.value)} value={deviceId}>
//         {devices.map((device, index) => (
//           <option key={device.deviceId} value={device.deviceId}>
//             {device.label || `Cámara ${index + 1}`}
//           </option>
//         ))}
//       </select>

//       <Webcam
//         audio={false}
//         ref={webcamRef}
//         screenshotFormat="image/jpeg"
//         videoConstraints={{ deviceId: deviceId ? { exact: deviceId } : undefined }}
//       />

//       <button onClick={capturePhoto}>Capturar Foto</button>
//     </div>
//   )
// }

// export default ImagenTab

// import React, { useCallback, useRef, useMemo, useState } from "react"
// import Webcam from "react-webcam"
// import { Button } from "@mui/material"

// import { MaterialReactTable } from "material-react-table"

// const CameraCaptureComponent = ({ onCapture }) => {
//   const webcamRef = useRef(null)

//   // Función para capturar la foto
//   const capturePhoto = useCallback(() => {
//     const imageSrc = webcamRef.current.getScreenshot()
//     onCapture(imageSrc) // Devolver la imagen capturada
//   }, [webcamRef, onCapture])

//   return (
//     <div>
//       <h2>Captura de Imagen</h2>
//       <Webcam audio={false} ref={webcamRef} screenshotFormat="image/jpeg" width={720} height={480} />
//       <Button variant="contained" onClick={capturePhoto}>
//         Capturar Foto
//       </Button>
//     </div>
//   )
// }

// const ImageManagementComponent = () => {
//   // Estado que simula los datos traídos de la base de datos
//   const [imageData, setImageData] = useState([
//     { imageName: "Imagen 1", imageHex: "data:image/jpeg;base64,..." },
//     { imageName: "Imagen 2", imageHex: "data:image/jpeg;base64,..." },
//   ])

//   const [selectedRows, setSelectedRows] = useState([]) // Guardar las filas seleccionadas
//   const [isCameraOpen, setIsCameraOpen] = useState(false) // Controlar si la cámara está abierta
//   const [editMode, setEditMode] = useState(false) // Controlar si estamos en modo edición

//   // Definir las columnas para la tabla
//   const columns = useMemo(
//     () => [
//       {
//         accessorKey: "imageName",
//         header: "Nombre de la Imagen",
//       },
//       {
//         accessorKey: "imageHex",
//         header: "Imagen",
//         Cell: ({ cell }) => <img src={cell.getValue()} alt="Imagen" style={{ width: 100, height: 100 }} />,
//       },
//     ],
//     [],
//   )

//   // Función para abrir la cámara en modo agregar o editar
//   const handleAddOrEdit = (isEdit) => {
//     setEditMode(isEdit)
//     setIsCameraOpen(true)
//   }

//   // Capturar la imagen desde el componente CameraCaptureComponent
//   const handleCaptureImage = (imageHex) => {
//     if (editMode && selectedRows.length > 0) {
//       // Editar imagen seleccionada
//       const selectedRowIndex = selectedRows[0].index
//       const updatedImageData = [...imageData]
//       updatedImageData[selectedRowIndex].imageHex = imageHex
//       setImageData(updatedImageData)
//     } else {
//       // Agregar nueva imagen
//       setImageData([...imageData, { imageName: `Imagen ${imageData.length + 1}`, imageHex }])
//     }
//     setIsCameraOpen(false) // Cerrar la cámara
//   }

//   return (
//     <div>
//       <h1>Gestión de Imágenes</h1>

//       {/* Tabla con selección de filas */}
//       <MaterialReactTable
//         columns={columns}
//         data={imageData}
//         enableMultiRowSelection={false} // shows radio buttons instead of checkboxes
//         enableRowSelection={true}
//         onRowSelectionChange={setSelectedRows} // Actualizar las filas seleccionadas
//         state={{ rowSelection: selectedRows }}
//       />

//       {/* Botones de agregar y editar */}
//       <Button variant="contained" onClick={() => handleAddOrEdit(false)} style={{ marginTop: 10 }}>
//         Agregar Nueva Imagen
//       </Button>
//       <Button
//         variant="contained"
//         onClick={() => handleAddOrEdit(true)}
//         disabled={selectedRows.length === 0}
//         style={{ marginTop: 10, marginLeft: 10 }}
//       >
//         Editar Imagen Seleccionada
//       </Button>

//       {/* Componente de cámara para capturar imágenes */}
//       {isCameraOpen && <CameraCaptureComponent onCapture={handleCaptureImage} />}
//     </div>
//   )
// }

// export default ImageManagementComponent
// Función para convertir Base64 a hexadecimal
import React, { useMemo, useState, useEffect, useRef, useCallback } from "react"
import { MaterialReactTable, useMaterialReactTable } from "material-react-table"
import Webcam from "react-webcam"
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack } from "@mui/material"
import fetchwrapper from "../../../services/interceptors/fetchwrapper"
import CustomBackdrop from "../../../components/CustomBackdrop"
import { MRT_Localization_ES } from "material-react-table/locales/es"

// ------------------------------------------------------------------
// ------------------------------------------------------------------
//                         WebcamSelector
// ------------------------------------------------------------------
// ------------------------------------------------------------------

const WebcamSelector = ({ webcamRef }) => {
  const [devices, setDevices] = useState([]) // Lista de cámaras disponibles
  const [deviceId, setDeviceId] = useState("") // ID de la cámara seleccionada

  // Obtener dispositivos de video (cámaras)
  const handleDevices = useCallback(
    (mediaDevices) => setDevices(mediaDevices.filter(({ kind }) => kind === "videoinput")),
    [setDevices],
  )

  useEffect(() => {
    navigator.mediaDevices.enumerateDevices().then(handleDevices)
  }, [handleDevices])

  // // Capturar foto desde la webcam
  // const capturePhoto = useCallback(() => {
  //   const imageSrc = webcamRef.current.getScreenshot()
  //   console.log(imageSrc) // Aquí puedes manejar la imagen capturada, por ejemplo, subirla a un servidor
  // }, [webcamRef])

  // Video constraints que intenta usar la cámara trasera por defecto si no se selecciona otra
  const videoConstraints = {
    facingMode: deviceId ? undefined : { ideal: "environment" }, // Usa la cámara trasera si no hay otra seleccionada
    deviceId: deviceId ? { exact: deviceId } : undefined, // Usa la cámara seleccionada si existe
    width: 400,
    height: 300,
    aspectRatio: 4 / 3,
  }

  return (
    <div>
      <h6>Seleccionar cámara</h6>
      <select onChange={(e) => setDeviceId(e.target.value)} value={deviceId}>
        {devices.map((device, index) => (
          <option key={device.deviceId} value={device.deviceId}>
            {device.label || `Cámara ${index + 1}`}
          </option>
        ))}
      </select>

      <Webcam audio={false} ref={webcamRef} screenshotFormat="image/jpeg" videoConstraints={videoConstraints} />

      {/* <button onClick={capturePhoto}>Capturar Foto</button> */}
    </div>
  )
}

// ------------------------------------------------------------------
// ------------------------------------------------------------------
//                        ImageManagementComponent
// ------------------------------------------------------------------
// ------------------------------------------------------------------

const ImageManagementComponent = ({ artcodigo, invcodigo }) => {
  const [data, setData] = useState([])
  const [rowSelection, setRowSelection] = useState({})
  const [isCameraOpen, setIsCameraOpen] = useState(false)
  const [editingRow, setEditingRow] = useState(null)
  const webcamRef = useRef(null)
  const [hasPermissionImageTab, setHasPermissionImageTab] = useState(false)
  const [isLoadingGetImgs, setIsLoadingGetImgs] = useState(false)
  const [isLoadingSaveImg, setIsLoadingSaveImg] = useState(false)

  const getAllImages = async () => {
    try {
      setIsLoadingGetImgs(true)
      const options = {
        method: "POST",
        body: JSON.stringify({
          artcodigo,
        }),
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }

      let response = await fetchwrapper("/productos/getListImages", options)
      response = await response.json()
      response = response.data
      setHasPermissionImageTab(response.hasPermission)
      setData(response.images)
    } catch (error) {
      alert("Error al cargar la galeria de imágenes")
    } finally {
      setIsLoadingGetImgs(false)
    }
  }

  useEffect(() => {
    getAllImages()
  }, [])

  const columns = useMemo(
    () => [
      { accessorKey: "artsecuen", header: "Secuencia" },
      {
        accessorKey: "artimagen",
        header: "Imagen",
        Cell: ({ cell }) => (
          <img src={`data:image/jpeg;base64,${cell.getValue()}`} alt="Imagen" style={{ width: 100 }} />
        ),
      },
    ],
    [],
  )

  const handleOpenCamera = () => {
    const selectedRowKey = Object.keys(rowSelection)[0]
    setEditingRow(selectedRowKey ? data[selectedRowKey] : null)
    setIsCameraOpen(true)
  }

  const deleteImage = async () => {
    const artsecuen = parseInt(Object.keys(rowSelection)[0])
    try {
      setIsLoadingSaveImg(true)
      const options = {
        method: "POST",
        body: JSON.stringify({
          artcodigo,
          invcodigo,
          artsecuen,
        }),
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }

      await fetchwrapper("/productos/deleteSpecificImage", options)
      await getAllImages()
    } catch (error) {
      alert("Error al eliminar la imágen")
    } finally {
      setIsLoadingSaveImg(false)
    }
  }

  const createNewImage = async (artimagen) => {
    try {
      setIsLoadingSaveImg(true)
      const options = {
        method: "POST",
        body: JSON.stringify({
          artcodigo,
          invcodigo,
          artimagen,
        }),
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
      await fetchwrapper("/productos/addNewImage", options)
      await getAllImages()
    } catch (error) {
      alert("Error al agregar una nueva imágen")
    } finally {
      setIsLoadingSaveImg(false)
    }
  }

  const editOldImage = async (artimagen, artsecuen) => {
    try {
      setIsLoadingSaveImg(true)
      const options = {
        method: "POST",
        body: JSON.stringify({
          artcodigo,
          invcodigo,
          artsecuen,
          artimagen,
        }),
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }

      await fetchwrapper("/productos/editSpecificImage", options)
      await getAllImages()
    } catch (error) {
      alert("Error al editar la imágen")
    } finally {
      setIsLoadingSaveImg(false)
      setIsCameraOpen(false)
      handleClearSelection()
    }
  }

  const handleCapture = async () => {
    const screenshot = webcamRef.current.getScreenshot()
    if (editingRow !== null) {
      // Editar imagen existente
      const artsecuen = parseInt(Object.keys(rowSelection)[0])
      editOldImage(screenshot, artsecuen)
    } else {
      await createNewImage(screenshot)
    }
    setIsCameraOpen(false)
    setRowSelection({})
  }
  // Usamos useMaterialReactTable para la lógica de la tabla
  const table = useMaterialReactTable({
    columns,
    data,
    getRowId: (row) => row.artsecuen,
    enableRowSelection: true,
    enableMultiRowSelection: false, // Se permite solo una fila seleccionada
    muiTableBodyRowProps: ({ row }) => ({
      onClick: row.getToggleSelectedHandler(),
      sx: { cursor: "pointer" },
    }),
    localization: { ...MRT_Localization_ES },
    enableTopToolbar: false,
    onRowSelectionChange: setRowSelection, // Manejar la selección de filas
    state: { rowSelection },
  })

  const handleClearSelection = () => {
    setRowSelection({})
  }

  return (
    <>
      <CustomBackdrop isLoading={isLoadingGetImgs || isLoadingSaveImg} zIndex={999999} />

      {hasPermissionImageTab ? (
        <div>
          <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
            <Button variant="contained" color="primary" onClick={handleOpenCamera}>
              {Object.keys(rowSelection).length ? "Editar Imagen" : "Agregar Nueva Imagen"}
            </Button>

            {/* Mostrar botón "Eliminar" si hay algo seleccionado */}
            {Object.keys(rowSelection).length > 0 && (
              <Button variant="contained" color="error" onClick={deleteImage}>
                Eliminar
              </Button>
            )}

            <Button
              variant="outlined"
              color="secondary"
              onClick={handleClearSelection}
              disabled={Object.keys(rowSelection).length === 0}
            >
              Limpiar selección
            </Button>
          </Stack>

          <MaterialReactTable table={table} />

          {/* Dialog para la cámara */}
          <Dialog open={isCameraOpen} onClose={() => setIsCameraOpen(false)} maxWidth="md" fullWidth>
            <DialogTitle>{editingRow ? "Editar Imagen" : "Capturar Nueva Imagen"}</DialogTitle>
            <DialogContent>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <div style={{ width: "100%", maxWidth: "400px", aspectRatio: "4 / 3" }}>
                  <WebcamSelector webcamRef={webcamRef} />
                  {/* <Webcam
                    audio={false}
                    ref={webcamRef}
                    screenshotFormat="image/jpeg"
                    videoConstraints={{
                      width: 400,
                      height: 300,
                      aspectRatio: 4 / 3,
                    }}
                    style={{ width: "100%", height: "100%" }}
                  /> */}
                </div>
              </div>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setIsCameraOpen(false)}>Cancelar</Button>
              <Button onClick={handleCapture} variant="contained" color="primary">
                Capturar
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      ) : (
        <div>Usted no tiene permiso a esta vista</div>
      )}
    </>
  )
}

export default ImageManagementComponent
