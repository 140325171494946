import React, { useState, useEffect } from "react"
import Header from "../../layouts/Header"
import { styled, createTheme, ThemeProvider } from "@mui/material/styles"
import { Box, Button, TextField, Grid, Snackbar, CircularProgress, MenuItem, Select } from "@mui/material"
import axios from "axios"
import BuscarIcon from "../../assets/iconos/Buscar.ico"
import BackIcon from "../../components/BackIcon"
import MuiAlert from "@mui/material/Alert"
import SyncIcon from "@mui/icons-material/Sync"
import fetchwrapper from "../../services/interceptors/fetchwrapper"

// import DatagridBancoDePreguntas from "./components/DatagridBancoDePreguntas";

const StyledRoot = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  marginTop: theme.spacing(8),
  flexGrow: 1,
  padding: "0 16px",
  height: "80vh",
}))

const theme = createTheme({
  palette: {
    primary: {
      main: "#196C87",
    },
    secondary: {
      main: "#196C87", // Cambia el color secundario a verde azulado
    },
  },
})
const IntegracionExterna = () => {
  const [codigoTransaccion, setCodigoTransaccion] = useState("")
  const [tiposTransaccion, setTiposTransaccion] = useState([])
  const [parametros, setParametros] = useState("")
  const [apiData, setApiData] = useState(null)
  const [error, setError] = useState(null)
  const [messageType, setMessageType] = useState("error")
  const [openSnackbar, setOpenSnackbar] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const obtenerTiposTransaccion = async () => {
      try {
        const response = await fetchwrapper("/integracionjbg/getTiposTransaccionIntExt")
        const jsonData = await response.json() // Convertir la respuesta a JSON
        setTiposTransaccion(jsonData)
      } catch (error) {
        console.error("Error al obtener tipos de transacción:", error)
      }
    }

    obtenerTiposTransaccion()
  }, [])

  const handleSeleccionChange = (event) => {
    setCodigoTransaccion(event.target.value) // Actualiza el estado con el valor seleccionado
  }

  const handleSincronizarClick = async () => {
    try {
      console.log("Sincronizar")
      await handleBuscarClick()

      //   const requestData = {
      //     ...apiData, // Mantén los datos existentes
      //     action: 'sincronizar' // Añade el parámetro de acción
      // };

      const options = {
        method: "POST",
        body: JSON.stringify(apiData),
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
      try {
        if (apiData) {
          let response = await fetchwrapper("/integracionjbg/createPlanEntrega", options)
          response = await response.json()

          setError(response.mensaje)
          if (response.tipo === "OK") setMessageType("success")
          else if (response.tipo === "warning") setMessageType("warning")
          setOpenSnackbar(true)
        } else {
          setError("Api Data is null")
          setMessageType("warning")
          setOpenSnackbar(true)
        }
      } catch (e) {
        console.log(e)
        console.error(e)
        setError(e)
        setMessageType("error")
        setOpenSnackbar(true)
      }
    } catch (error) {
      console.error("Error al sincronizar:", error)
      setError(error)
      setMessageType("error")
      setOpenSnackbar(true)
    }
  }

  const handleBuscarClick = async () => {
    // Validar si los campos están vacíos
    if (!codigoTransaccion.trim() || !parametros.trim()) {
      setError("Llene los campos antes de buscar.")
      setMessageType("warning")
      setOpenSnackbar(true)
      return
    }
    // Construye el cuerpo del POST produ
    const requestBody = {
      usuario: "usrctdmerkati",
      pass: "j4Ns@&1n56Q1",
      codigoTransaccion,
      parametros,
    }

    // Configura el encabezado con el Bearer Token produ
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer eyJhbGciOiJIUzUxMiIsInR5cCI6IkpXVCJ9.eyJJZCI6IjJjNWMyNmNjLWM3YjMtNGM3ZS05MjJiLTlhNDVkNmNhMzA3YyIsInN1YiI6InVzcmN0ZG1lcmthdGkiLCJlbWFpbCI6InVzcmN0ZG1lcmthdGkiLCJqdGkiOiJhZDQ1MDc0YS02YmNjLTQ5OGYtODMzMC0xYjhiOWMzNjczMDAiLCJuYmYiOjE3MTAyNTg2MzYsImV4cCI6MTcxMDI1OTIzNiwiaWF0IjoxNzEwMjU4NjM2LCJpc3MiOiJodHRwczovL3d3dy5qdW50YWRlYmVuZWZpY2VuY2lhLm9yZy5lYy8iLCJhdWQiOiJodHRwczovL3d3dy5qdW50YWRlYmVuZWZpY2VuY2lhLm9yZy5lYy8ifQ.x9Vzh4tnq4Ihzg5r61SNTRxO9lKzvsLj_Ij2WHUauTnQ0UXPiYYVZlenyLuKGigdmJGE-i6wRtNUU8M-n95LVQ",
      },
    }

    try {
      setLoading(true) // Mostrar spinner de carga
      // url dev
      // const response = await axios.post('https://dyeapidev.azurewebsites.net/Process', requestBody, config); https://jbgapipro.azurewebsites.net/Process
      // url produ
      const response = await axios.post("https://jbgapipro.azurewebsites.net/Process", requestBody, config)

      setLoading(false) // Ocultar spinner de carga
      const responseData = response.data.data[0].attributes.payload
      const status = response.data.data[0].attributes.estado
      const msgerror = response.data.data[0].attributes.error
      if (status === "OK") {
        const jsonData = JSON.parse(responseData.replace(/\\/g, "")) // Elimina barras invertidas del string JSON
        // Verifica si los detalles son nulos o vacíos
        if (!jsonData.detalle || jsonData.detalle.length === 0) {
          setError("Esta orden no tiene detalle")
          setMessageType("warning")
        } else {
          setError("Orden encontrada")
          setMessageType("success")
        }
        setApiData(jsonData)
        setOpenSnackbar(true)
        console.log("response", responseData)
        console.log("jsonData", jsonData)
      } else {
        setError(msgerror)
        setMessageType("warning")
        setOpenSnackbar(true)
      }
    } catch (err) {
      setLoading(false) // Ocultar spinner de carga
      setError("API no responde. Contacte al administrador.")
      setMessageType("error")
      setOpenSnackbar(true)
      // Si la API responde con un error, puedes proporcionar una respuesta predeterminada
      // setApiData({
      //   ordenCompra: 'pruebajbg01017',
      //   proveedor: '10002',
      //   estado: 'OPEN',
      //   fecha: '2023-10-23T20:34:12.000+00:00',
      //   codigoBodega: 'ORG_1100LV',
      //   detalle: [
      //     {
      //       linea: 1,
      //       codigoProducto: 'prueba',
      //       cantidadOrdenada: 200,
      //       cantidadRecibida: 23,
      //       estadoItem: 'OPEN',
      //     },
      //   ],
      // });
      // setApiData(JSON.parse(apiData.replace(/\\/g, '')));
      console.error("Error al obtener datos de la API:", err)
      console.log("Error", err.response)
    }
  }

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false)
  }

  return (
    <ThemeProvider theme={theme}>
      <Header />
      <div className="main main-app p-3 p-lg-4">
        <BackIcon />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "0 30px 30px 30px",
            fontSize: "25px",
          }}
        >
          <b>Integración Externa</b>
        </div>
        <Box className={StyledRoot}>
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-evenly",
            }}
          >
            <div>
              <Select
                fullWidth
                label="Código de Transacción:"
                value={codigoTransaccion}
                onChange={handleSeleccionChange}
                variant="outlined"
                size="small"
                displayEmpty
                inputProps={{ "aria-label": "Seleccionar Código de Transacción" }}
              >
                <MenuItem value="" disabled>
                  Seleccionar Código de Transacción
                </MenuItem>
                {tiposTransaccion &&
                  tiposTransaccion.map((tipoTransaccion) => (
                    <MenuItem key={tipoTransaccion.codtra} value={tipoTransaccion.codtra}>
                      {`${tipoTransaccion.destra} - ${tipoTransaccion.codtra}`}
                    </MenuItem>
                  ))}
              </Select>
            </div>

            <div>
              <TextField
                fullWidth
                label="Parámetros:"
                placeholder="Filtrar por Parámetros"
                value={parametros}
                onChange={(e) => setParametros(e.target.value)}
                variant="outlined"
                size="small"
                type="text"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>

            <Button
              variant="contained"
              color="secondary"
              sx={{ width: "30%" }}
              onClick={handleBuscarClick}
              endIcon={<img src={BuscarIcon} style={{ width: "22px" }} alt="buscar" />}
            >
              Buscar
            </Button>

            {error && (
              <Snackbar
                open={openSnackbar}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: "top", horizontal: "center" }} // Ajusta la posición
              >
                <MuiAlert elevation={6} variant="filled" onClose={handleCloseSnackbar} severity={messageType}>
                  {error}
                </MuiAlert>
              </Snackbar>
            )}
          </div>
          {/* Spinner de carga */}
          {loading && (
            <div style={{ textAlign: "center", marginTop: "20px" }}>
              <CircularProgress />
            </div>
          )}
        </Box>

        <StyledRoot>
          {apiData && (
            <Grid container spacing={4} alignItems="center">
              <Grid item xs={12}>
                <div style={{ textAlign: "center" }}>
                  <h4 style={{ color: theme.palette.primary.main }}>Orden de Compra: {apiData.ordenCompra}</h4>
                  <p>Proveedor: {apiData.proveedor}</p>
                  <p>Estado: {apiData.estado}</p>
                  <p>Fecha: {apiData.fecha}</p>
                  <p>Código de Bodega: {apiData.codigoBodega}</p>
                </div>
              </Grid>

              {apiData.detalle && (
                <Grid item xs={12} style={{ textAlign: "center" }}>
                  <h4>Detalle</h4>
                  <table
                    style={{
                      width: "80%",
                      margin: "auto",
                      marginTop: "20px",
                      borderCollapse: "collapse",
                      border: "1px solid #ddd",
                    }}
                  >
                    <thead>
                      <tr>
                        <th style={{ color: theme.palette.primary.main, border: "1px solid #ddd", padding: "8px" }}>
                          Línea
                        </th>
                        <th style={{ color: theme.palette.primary.main, border: "1px solid #ddd", padding: "8px" }}>
                          Código de Producto
                        </th>
                        <th style={{ color: theme.palette.primary.main, border: "1px solid #ddd", padding: "8px" }}>
                          Cantidad Ordenada
                        </th>
                        <th style={{ color: theme.palette.primary.main, border: "1px solid #ddd", padding: "8px" }}>
                          Cantidad Recibida
                        </th>
                        <th style={{ color: theme.palette.primary.main, border: "1px solid #ddd", padding: "8px" }}>
                          Estado del Ítem
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {apiData.detalle.map((item) => (
                        <tr key={item.linea}>
                          <td style={{ border: "1px solid #ddd", padding: "8px" }}>{item.linea}</td>
                          <td style={{ border: "1px solid #ddd", padding: "8px" }}>{item.codigoProducto}</td>
                          <td style={{ border: "1px solid #ddd", padding: "8px" }}>{item.cantidadOrdenada}</td>
                          <td style={{ border: "1px solid #ddd", padding: "8px" }}>{item.cantidadRecibida}</td>
                          <td style={{ border: "1px solid #ddd", padding: "8px" }}>{item.estadoItem}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </Grid>
              )}

              {apiData.detalle && (
                <Grid item xs={12} style={{ textAlign: "center", marginTop: "20px" }}>
                  <Button variant="contained" endIcon={<SyncIcon />} onClick={handleSincronizarClick}>
                    Sincronizar
                  </Button>
                </Grid>
              )}
            </Grid>
          )}
        </StyledRoot>
      </div>
    </ThemeProvider>
  )
}

export default IntegracionExterna
