import { React, createContext, useEffect, useState, useContext } from "react"
const AprobacionContext = createContext()

export function AprobacionContextProvider({ children }) {
  const [clienteSeleccionado, setclienteSeleccionado] = useState("")
  const [clienteSeleccionadoDescri, setclienteSeleccionadoDescri] = useState("")
  const [agenciaEnvioSeleccionada, setagenciaEnvioSeleccionada] = useState("")
  const [agenciaEnvioSeleccionadaDescri, setagenciaEnvioSeleccionadaDescri] = useState("")
  const [agenciaSolicitaSeleccionada, setagenciaSolicitaSeleccionada] = useState("")
  const [agenciaSolicitaSeleccionadaDescri, setagenciaSolicitaSeleccionadaDescri] = useState("")
  const [productoSeleccionado, setproductoSeleccionado] = useState("")
  const [productoSeleccionadoDescri, setproductoSeleccionadoDescri] = useState("")
  const [proveedorSeleccionado, setproveedorSeleccionado] = useState("")
  const [proveedorSeleccionadoDescri, setproveedorSeleccionadoDescri] = useState("")
  const [articuloUsaDecimales, setarticuloUsaDecimales] = useState(0)
  const [productosEgreso, setproductosEgreso] = useState([])
  const [contactoSeleccionado, setcontactoSeleccionado] = useState("")

  const setProductoSeleccionado = (newProducto) => {
    setproductoSeleccionado(newProducto)
  }

  const setAgenciaEnvioSeleccionada = (newProducto) => {
    setagenciaEnvioSeleccionada(newProducto)
  }

  const setAgenciaEnvioSeleccionadaDescri = (newProducto) => {
    setagenciaEnvioSeleccionadaDescri(newProducto)
  }

  const setAgenciaSolicitaSeleccionada = (newProducto) => {
    setagenciaSolicitaSeleccionada(newProducto)
  }

  const setAgenciaSolicitaSeleccionadaDescri = (newProducto) => {
    setagenciaSolicitaSeleccionadaDescri(newProducto)
  }

  const setContactoSeleccionado = (newProducto) => {
    setcontactoSeleccionado(newProducto)
  }

  const setProductosEgreso = (newProductos) => {
    setproductosEgreso(newProductos)
  }

  const setArticuloUsaDecimales = (newArtDecimal) => {
    setarticuloUsaDecimales(newArtDecimal)
  }

  const setProductoSeleccionadoDescri = (newProductoDescri) => {
    setproductoSeleccionadoDescri(newProductoDescri)
  }

  const setProveedorSeleccionado = (newProveedor) => {
    setproveedorSeleccionado(newProveedor)
  }

  const setProveedorSeleccionadoDescri = (newDescriProveedor) => {
    setproveedorSeleccionadoDescri(newDescriProveedor)
  }

  const setClienteSeleccionado = (newCliente) => {
    setclienteSeleccionado(newCliente)
  }

  const setClienteSeleccionadoDescri = (newDescricliente) => {
    setclienteSeleccionadoDescri(newDescricliente)
  }

  const myContextData = {
    clienteSeleccionado,
    setclienteSeleccionado,
    clienteSeleccionadoDescri,
    setclienteSeleccionadoDescri,
    agenciaEnvioSeleccionada,
    setagenciaEnvioSeleccionada,
    agenciaEnvioSeleccionadaDescri,
    setagenciaEnvioSeleccionadaDescri,
    agenciaSolicitaSeleccionada,
    setagenciaSolicitaSeleccionada,
    agenciaSolicitaSeleccionadaDescri,
    setagenciaSolicitaSeleccionadaDescri,
    productoSeleccionado,
    setproductoSeleccionado,
    productoSeleccionadoDescri,
    setproductoSeleccionadoDescri,
    contactoSeleccionado,
    setcontactoSeleccionado,
    proveedorSeleccionado,
    setproveedorSeleccionado,
    articuloUsaDecimales,
    setarticuloUsaDecimales,
    productosEgreso,
    setproductosEgreso,
    proveedorSeleccionadoDescri,
    setproveedorSeleccionadoDescri,
    setProductoSeleccionado,
    setProductoSeleccionadoDescri,
    setProveedorSeleccionado,
    setProveedorSeleccionadoDescri,
    setClienteSeleccionado,
    setClienteSeleccionadoDescri,
    setArticuloUsaDecimales,
    setProductosEgreso,
    setAgenciaEnvioSeleccionada,
    setAgenciaEnvioSeleccionadaDescri,
    setAgenciaSolicitaSeleccionada,
    setAgenciaSolicitaSeleccionadaDescri,
    setContactoSeleccionado,
  }
  return <AprobacionContext.Provider value={myContextData}>{children}</AprobacionContext.Provider>
}

export { AprobacionContext }
