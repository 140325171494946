import React, { useEffect, useState, useRef } from "react"
import { useNavigate } from "react-router-dom"
import { SolicitudIngresoContextProvider } from "../SolicitudIngresoContext"
import Header from "../../../layouts/Header"
import { styled, createTheme, ThemeProvider } from "@mui/material/styles"
import { Box, TextField, InputLabel, Button, Typography } from "@mui/material"
import CustomSelect from "./CustomSelect"
import BackIcon from "../../../components/BackIcon"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"
import GridSolicitudDeIngreso from "./GridSolicitudDeIngreso"
import fetchwrapper from "../../../services/interceptors/fetchwrapper"
import { useQuery } from "@tanstack/react-query"
import MultipleSelect from "./MultipleSelect"
import CrearIcon from "../../../assets/iconos/Crear.ico"
import EjecutarIcon from "../../../assets/iconos/EjecutarAccion.ico"
import ImpresionIcon from "../../../assets/iconos/Impresion.ico"
import BuscarIcon from "../../../assets/iconos/Buscar.ico"

import CustomTooltip from "../../../components/ToolTip"
import Accordion from "@mui/material/Accordion"
import AccordionDetails from "@mui/material/AccordionDetails"
import AccordionSummary from "@mui/material/AccordionSummary"
import ExpandMore from "@mui/icons-material/ExpandMore"
import CustomAutoComplete from "./CustomAutoComplete"
import useDebounce from "./Debounce"
import DetalleImpresionSearch from "./DetalleImpresionSearch"
import DetalleSolicitudPDF from "./DetalleSolicitudPDF"

const theme = createTheme({
  palette: {
    primary: {
      main: "#196C87",
    },
    secondary: {
      main: "#196C87",
    },
  },
})

const Container = styled(Box)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "repeat(12, 1fr)",
  gridTemplateRows: "auto auto auto",
  gridTemplateAreas: `
    "Solicitud Solicitud FechaI FechaI FechaF FechaF Cliente Cliente Cliente Cliente Cliente Cliente"
    "Motivo Motivo Motivo Estado Estado Estado Proveedor Proveedor Proveedor Proveedor Proveedor Proveedor"
    "Detalle Detalle Detalle Detalle Detalle Detalle Articulo Articulo Articulo Articulo Articulo Articulo"
  `,
  gap: "8px",
  alignItems: "center",

  [theme.breakpoints.down("sm")]: {
    gridTemplateColumns: "repeat(6, 1fr)",
    gridTemplateAreas: `
      "Solicitud Solicitud FechaI FechaI FechaF FechaF"
      "Cliente Cliente Cliente Cliente Cliente Cliente"
      "Motivo Motivo Motivo Estado Estado Estado"
      "Proveedor Proveedor Proveedor Proveedor Proveedor Proveedor"
      "Detalle Detalle Detalle Detalle Detalle Detalle"
      "Articulo Articulo Articulo Articulo Articulo Articulo"
    `,
  },
}))

const Solicitud = styled(Box)({
  gridArea: "Solicitud",
})

const FechaI = styled(Box)({
  gridArea: "FechaI",
})

const FechaF = styled(Box)({
  gridArea: "FechaF",
})

const Cliente = styled(Box)({
  gridArea: "Cliente",
})

const Motivo = styled(Box)({
  gridArea: "Motivo",
})

const Estado = styled(Box)({
  gridArea: "Estado",
})

const Proveedor = styled(Box)({
  gridArea: "Proveedor",
})

const Detalle = styled(Box)({
  gridArea: "Detalle",
})

const Articulo = styled(Box)({
  gridArea: "Articulo",
})

const BuscarSolicitud = () => {
  const [inputValue, setInputValue] = useState("")

  const [cliente, setCliente] = useState("")
  const [proveedores, setProveedores] = useState([])
  const [producto, setProducto] = useState("")
  const [productoDescription, setproductoDescription] = useState("")
  const [productos, setProductos] = useState([])
  const [clienteDescription, setclienteDescription] = useState("")
  const [proveedor, setProveedor] = useState("")
  const [proveedorDescription, setproveedorDescription] = useState("")
  const [motivo, setMotivo] = useState("")
  const [fechaInicial, setFechaInicial] = useState(null)
  const [fechaFinal, setFechaFinal] = useState(null)
  const [detalle, setDetalle] = useState("")
  const [open, setOpen] = useState(false)
  const [estado, setEstado] = useState([])
  const [filtrosMotivo, setFiltrosMotivo] = useState([])
  const [searchAttempted, setSearchAttempted] = useState("")
  const [clienteSeleccionado, setClienteSeleccionado] = useState(false)
  const navigate = useNavigate()
  const [solicitudesIngresos, setSolicitudesIngresos] = useState([])
  const [isFirstTime, setFirstTime] = useState(true)
  const { allFiltrosMotivo } = useGetAllFiltros()
  const { data, isFetchingClientes, errorCliente, refetch: refetchGetAllClientes } = useGetAllClientes()
  // paginación
  const [totalPages, setTotalPages] = useState(1)
  const [page, setPage] = useState(1)
  // filtros
  const [filters, setFilters] = useState({})
  const isFirstRender = useRef(true)
  const debouncedFilters = useDebounce(filters, 500) // ms

  // cliente select
  const [autoCompleteValueCliente, setAutoCompleteValueCliente] = useState("")
  // proveedor select
  const [autoCompleteValueProveedor, setAutoCompleteValueProveedor] = useState("")
  // producto select
  const [autoCompleteValueProducto, setAutoCompleteValueProducto] = useState("")

  const [expanded, setExpanded] = useState(false)

  // impresion
  const [openModal, setOpenModal] = useState(false)
  const [infoGenerada, setInfoGenerada] = useState([])

  const handleExpandAccordion = () => {
    setExpanded((prevExpanded) => !prevExpanded)
  }

  // Función para manejar cambios en los filtros de la grilla
  const handleGrillaFilterChange = (columnId, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [columnId]: value,
    }))
  }

  const {
    data: dataProveedores = [],
    isError: dataProveedoresError,
    isFetching: isFetchingProveedores,
    isLoading: isLoadingProveedores,
    refetch: refetchGetAllProveedores,
  } = useGetAllProveedores()

  const {
    data: dataProductos = [],
    isError: dataProductosError,
    isFetching: isFetchingDataProductos,
    isLoading: isLoadingProductos,
    refetch: refetchGetProductos,
  } = useGetAllProductos()

  const handleChange = (event) => {
    setInputValue(event.target.value)
  }
  const handleMotivoChange = (event) => {
    setMotivo(event.target.value)
  }

  const handleEstadoChange = (event) => {
    setEstado(event.target.value)
  }

  const handleProveedorChange = (selectedValue) => {
    const [codigo, descripcion] = selectedValue.split("-")
    setProveedor(codigo)
    setproveedorDescription(descripcion)
    setAutoCompleteValueProveedor(selectedValue)
  }

  const handleProductoChange = (selectedValue) => {
    const [codigo, descripcion] = selectedValue.split("-")
    setProducto(codigo)
    setproductoDescription(descripcion)
    setAutoCompleteValueProducto(selectedValue)
  }

  const handleFechaInicial = (newFecha) => {
    setFechaInicial(newFecha)
  }

  const handleFechaFinal = (newFecha) => {
    setFechaFinal(newFecha)
  }

  const handleDetalle = (event) => {
    setDetalle(event.target.value)
  }

  const resetAllFilters = () => {
    // setFechaInicial(null)
    // setFechaFinal(null)
    // setProveedor("")
    // setCliente("")
    // setProducto("")
    // setDetalle("")
    // setMotivo("")
    // setEstado([])
    // setInputValue("")
    // setAutoCompleteValueCliente("")
    // setAutoCompleteValueProveedor("")
    // setAutoCompleteValueProducto("")
    setFilters({})
  }

  const handleSearch = () => {
    setSearchAttempted(true)
    setFirstTime(false)
    setExpanded(false)
    refetchSolicitudesIngresos()
    resetAllFilters()
    setSearchAttempted(false)
  }

  const estadoOptions = [
    { value: "PENDIENTE", label: "Pendiente" },
    { value: "APROBADA", label: "Aprobada" },
    { value: "NEGADA", label: "Negada" },
    { value: "CERRADA", label: "Cerrada" },
    { value: "RECIBIDA TOTAL", label: "Recibida Total" },
    { value: "RECIBIDA PARCIAL", label: "Recibida Parcial" },
  ]

  const {
    data: fetchedSolicitudesIngresos = {},
    isError: isLoadingSolicitudesIngresosError,
    isFetching: isFetchingSolicitudesIngresos,
    isLoading: isLoadingSolicitudesIngresos,
    refetch: refetchSolicitudesIngresos,
  } = useGetSolicitudesXFiltro(page)

  useEffect(() => {
    setSolicitudesIngresos(fetchedSolicitudesIngresos.solicitudes)
    setTotalPages(fetchedSolicitudesIngresos.total)
  }, [fetchedSolicitudesIngresos])

  useEffect(() => {
    if (searchAttempted && !isLoadingSolicitudesIngresos && !isFetchingSolicitudesIngresos && !isFirstTime) {
      console.log("filtros limpioss")
      // resetAllFilters()
    }
  }, [isFetchingSolicitudesIngresos, isLoadingSolicitudesIngresos, searchAttempted])

  useEffect(() => {
    if (Object.keys(filters).length) {
      console.log("prueba filtrosssss")
      setFilters(filters)
      refetchSolicitudesIngresos(debouncedFilters)
      setPage(1)
      resetAllFilters()
    }
  }, [debouncedFilters])

  // Función para manejar cambios en la paginación
  const onPageChange = (newPage) => {
    setPage(newPage)
  }

  useEffect(() => {
    if (isFirstRender.current) {
      // Evita refetch en el primer renderizado
      isFirstRender.current = false
      return
    }
    refetchSolicitudesIngresos()
  }, [page])

  useEffect(() => {
    setMotivo(motivo)
  }, [filtrosMotivo])

  // no
  /* useEffect(() => {
    console.log(searchAttempted)
    // Actualiza el estado de solicitudesIngresos con los datos recibidos
    if (searchAttempted) {
      console.log(searchAttempted)
      setSolicitudesIngresos(fetchedSolicitudesIngresos.solicitudes)
    }
  }, [searchAttempted, fetchedSolicitudesIngresos]) */

  useEffect(() => {
    setProveedor(proveedor)
    setproveedorDescription(proveedorDescription)
  }, [proveedor])

  useEffect(() => {
    setProducto(producto)
    setproductoDescription(productoDescription)
  }, [producto])

  useEffect(() => {
    setProveedores(dataProveedores)
    setProductos(dataProductos)
  }, [data])

  function useGetAllProveedores() {
    return useQuery({
      queryKey: ["solicitudDeIngresoProveedores"],
      queryFn: async () => {
        const response = await fetchwrapper(`/solicitudDeIngreso/getProveedoresBusqueda`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ cliente }),
        })
        const result = await response.json()
        return result.proveedores
      },
      keepPreviousData: true,
      enabled: true,
      onError: (error) => {
        console.log("Error fetching data", error)
      },
    })
  }

  function useGetAllProductos() {
    return useQuery({
      queryKey: ["solicitudDeIngresoProductos"],
      queryFn: async () => {
        const response = await fetchwrapper(`/solicitudDeIngreso/getProductosBusqueda`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ cliente }),
        })
        const result = await response.json()
        return result.productos
      },
      keepPreviousData: true,
      enabled: true,
      onError: (error) => {
        console.log("Error fetching data", error)
      },
    })
  }

  function useGetAllClientes() {
    return useQuery({
      queryKey: ["solicitudDeIngresoClientes"],
      queryFn: async () => {
        const response = await fetchwrapper(`/solicitudDeIngreso/getAllClientes`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        })
        const result = await response.json()
        return result.clientes
      },
      keepPreviousData: true,
      enabled: true,
      onError: () => {
        console.log("Error fetching data")
      },
    })
  }

  function useGetSolicitudesXFiltro() {
    return useQuery({
      queryKey: ["solicitudDeIngresoXFiltro"],
      queryFn: async () => {
        console.log(estado)
        const options = {
          method: "POST",
          body: JSON.stringify({
            codSolicitud: inputValue,
            motivo,
            fechaInicial,
            fechaFinal,
            codArticulo: producto,
            codProveedor: proveedor,
            codCliente: cliente,
            detalle,
            estado,
            page,
            filters,
          }),
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }
        // send api request here
        let response = await fetchwrapper("/solicitudDeIngreso/getSolicitudesXFiltro", options)
        response = await response.json()
        // console.log("fetched", response.solicitudes)
        return response
      },
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      enabled: false,
    })
  }

  function useGetAllFiltros() {
    return useQuery({
      queryKey: ["solicitudDeIngresoFiltrosSearch"],
      queryFn: async () => {
        try {
          const response = await fetchwrapper("/solicitudDeIngreso/getAllFiltros")
          const data = await response.json()
          if (!data || !data.data) {
            setFiltrosMotivo([])
            return []
          }

          setFiltrosMotivo(data.data)

          return data.data
        } catch (error) {
          console.error("Error fetching data:", error)
          setFiltrosMotivo([])
          return []
        }
      },
      refetchOnWindowFocus: false,
      enabled: true,
    })
  }

  const handleSelect = (selectedValue) => {
    const [codigo, descripcion] = selectedValue.split("-")
    setCliente(codigo)
    setclienteDescription(descripcion)
    setAutoCompleteValueCliente(selectedValue)
    setClienteSeleccionado(true)
  }

  const handleOpenModal = () => {
    setInfoGenerada(fetchedSolicitudesIngresos.solicitudes) // Asigna los datos de la tabla al estado
    setOpenModal(true)
  }

  const handleCloseModal = () => {
    setOpenModal(false)
  }

  return (
    <SolicitudIngresoContextProvider>
      <ThemeProvider theme={theme}>
        <Header />
        <div className="main main-app p-3 p-lg-4">
          <BackIcon />
          <div style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
            <div style={{ paddingTop: "10px", paddingBottom: "10px" }}>
              <CustomTooltip title={"Ejecutar"}>
                <Button style={{ fontSize: "0.9vw" }} onClick={handleSearch}>
                  <img src={EjecutarIcon} alt="Crear" style={{ width: "40px" }} />
                </Button>
              </CustomTooltip>
            </div>
            <div style={{ paddingTop: "10px", paddingBottom: "10px" }}>
              <CustomTooltip title={"Crear Solicitud de Ingreso"}>
                <Button
                  style={{ fontSize: "0.9vw" }}
                  onClick={() => {
                    navigate("/home/dashboard/SolicitudDeIngreso/crear")
                    // setSolicitudesIngresos([])
                  }}
                >
                  <img src={CrearIcon} alt="Crear" style={{ width: "40px" }} />
                </Button>
              </CustomTooltip>
            </div>
            <div style={{ paddingTop: "10px", paddingBottom: "10px" }}>
              <CustomTooltip title={"Imprimir Solicitudes"}>
                <Button
                  disabled={Object.keys(fetchedSolicitudesIngresos).length === 0}
                  style={{ fontSize: "0.9vw" }}
                  onClick={() => {
                    handleOpenModal()
                  }}
                >
                  <img src={ImpresionIcon} alt="Imprimir" style={{ width: "40px" }} />
                </Button>
              </CustomTooltip>
              <DetalleImpresionSearch
                open={openModal}
                handleClose={handleCloseModal}
                infoGenerada={fetchedSolicitudesIngresos.solicitudes}
                filename={"solicitudesDeIngreso"}
                DetallePDFComponent={DetalleSolicitudPDF}
                buttonText={"Descargar Solicitudes de Ingreso"}
              />
            </div>
          </div>
          <div style={{ paddingBottom: "10px" }}>
            <Accordion
              expanded={expanded}
              onChange={handleExpandAccordion}
              slotProps={{ heading: { component: "h2" } }}
            >
              <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1a-content" id="panel1a-header">
                <Typography variant="h6" style={{ width: "100%", textAlign: "center" }}>
                  Criterios de búsqueda
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Container>
                  <Solicitud>
                    <InputLabel>Solicitud</InputLabel>
                    <TextField value={inputValue} onChange={handleChange} fullWidth />
                  </Solicitud>
                  <FechaI>
                    <InputLabel>Fecha Inicial</InputLabel>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        value={fechaInicial}
                        format="DD/MM/YYYY"
                        dateFormat="yyyy-MM-dd hh:mm:ss.SSSSSS"
                        timeFormat="HH:mm:ss.SSSSSS"
                        onChange={handleFechaInicial}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </LocalizationProvider>
                  </FechaI>
                  <FechaF>
                    <InputLabel>Fecha Final</InputLabel>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        value={fechaFinal}
                        format="DD/MM/YYYY"
                        onChange={handleFechaFinal}
                        dateFormat="yyyy-MM-dd hh:mm:ss.SSSSSS"
                        timeFormat="HH:mm:ss.SSSSSS"
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </LocalizationProvider>
                  </FechaF>
                  <Cliente>
                    <InputLabel>Cliente</InputLabel>
                    <CustomAutoComplete
                      items={data}
                      onSelect={handleSelect}
                      placeholder={"Buscar cliente"}
                      value={autoCompleteValueCliente}
                    />
                  </Cliente>
                  <Motivo>
                    <CustomSelect
                      label="Motivo"
                      value={motivo}
                      onChange={handleMotivoChange}
                      options={filtrosMotivo}
                      fullWidth
                      disabled
                    />
                  </Motivo>
                  <Estado>
                    <MultipleSelect
                      label="Estado"
                      value={estado}
                      onChange={handleEstadoChange}
                      options={estadoOptions}
                      fullWidth
                      multiple
                      disabled={false}
                    />
                  </Estado>
                  <Proveedor>
                    <InputLabel>Proveedor</InputLabel>
                    <CustomAutoComplete
                      items={dataProveedores}
                      value={autoCompleteValueProveedor}
                      onSelect={handleProveedorChange}
                      placeholder={"Buscar proveedor"}
                    />
                  </Proveedor>

                  <Detalle>
                    <InputLabel>Detalle</InputLabel>
                    <TextField value={detalle} onChange={handleDetalle} placeholder="" fullWidth />
                  </Detalle>
                  <Articulo>
                    <InputLabel>Artículo</InputLabel>
                    <CustomAutoComplete
                      items={dataProductos}
                      onSelect={handleProductoChange}
                      placeholder={"Buscar producto"}
                      value={autoCompleteValueProducto}
                    />
                  </Articulo>
                </Container>
              </AccordionDetails>
            </Accordion>
          </div>
          <GridSolicitudDeIngreso
            fetchedInfo={solicitudesIngresos}
            isLoadingInfoError={isLoadingSolicitudesIngresosError}
            isLoadingInfo={isLoadingSolicitudesIngresos}
            isFetchingInfo={isFetchingSolicitudesIngresos}
            searchAttempted={searchAttempted}
            setSolicitudesIngresos={setSolicitudesIngresos}
            refetch={refetchSolicitudesIngresos}
            totalPages={totalPages}
            onPageChange={onPageChange}
            onFilterChange={handleGrillaFilterChange}
            currentPage={page}
          />
        </div>
      </ThemeProvider>
    </SolicitudIngresoContextProvider>
  )
}

export default BuscarSolicitud
