/* eslint-disable camelcase */
import React, { useEffect, useState } from "react"
import { Autocomplete, Grid, TextField, Typography } from "@mui/material/"
import TreeView from "@mui/lab/TreeView"
import TreeItem from "@mui/lab/TreeItem"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import ChevronRightIcon from "@mui/icons-material/ChevronRight"
import Checkbox from "@mui/material/Checkbox"
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank"
import CheckBoxIcon from "@mui/icons-material/CheckBox"
import fetchwrapper from "../../../../../services/interceptors/fetchwrapper"
import { createFilterOptions } from "@mui/material/Autocomplete"

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
const checkedIcon = <CheckBoxIcon fontSize="small" />

const InputNestedChildren = ({ label, placeholder, setCodes, fetchLink, fetchResponse }) => {
  const [options, setOptions] = React.useState([])
  const [selectedValues, setSelectedValues] = React.useState([])
  const [inputValue, setInputValue] = useState("")
  const [selectAll, setSelectAll] = useState(false)
  const [filteredOptions, setFilteredOptions] = useState([])

  const callAPI = async (url, request) => {
    try {
      const options = {
        method: "POST",
        body: JSON.stringify(request),
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
      let response = await fetchwrapper(url, options)
      response = await response.json()
      return response
    } catch (error) {
      console.log(error)
    }
  }
  const cliciaciacodigo = JSON.parse(localStorage.getItem("jwt")).seleccion.cliciaciacodigo

  const baseRequest = {
    user: "­v}xg",
    seleccion: {
      cliciaciacodigo,
      cliciacianombre: "PRACTICASA",
      clicianonBD: "SiacPracticasa",
      cliciarutaBD: "fsoftapptest.futuresoft-ec.com,14666",
    },
    localidad: {
      loccodigo: "07",
      locdescri: "BODEGA SAMBO",
    },
  }

  useEffect(() => {
    callOptions()
  }, [])

  const callOptions = async () => {
    const request = { ...baseRequest, lincodigo_root: "" }
    const response = await callAPI("/linea/get_lineas", request)
    setOptions(response)
  }

  const handleSelectOption = (event, newValue) => {
    if (newValue === null || newValue.length === 0) {
      setSelectAll(false)
      setSelectedValues([])
      setCodes([])
      return
    }
    setSelectAll(false)
    if (newValue.some((option) => option[fetchResponse.code] === "seleccionar-todos")) {
      setSelectAll(true)
      if (inputValue.length === 0 && !selectAll) {
        setSelectedValues(options.slice(0, 10))
        setCodes(options.map((option) => option[fetchResponse.code]))
        return
      }
      setSelectedValues(filteredOptions)
      setCodes(filteredOptions.map((option) => option[fetchResponse.code]))
      if (selectAll) {
        setSelectedValues([])
        setSelectAll(false)
        return
      }
      return
    }
    // find the childrens of the selected node, this only if the value is not checked
    const unselectedOptions = selectedValues.filter((value) => !newValue.includes(value))

    if (unselectedOptions.length > 0) {
      // Check if any deselected options have a selected parent
      const deselectedParents = []
      unselectedOptions.forEach((option) => {
        const parent = getParent(option, options)
        if (parent && selectedValues.includes(parent)) {
          deselectedParents.push(parent)
        }
      })

      // Deselect both the deselected options and their parents
      const newSelectedValues = selectedValues.filter(
        (value) => !unselectedOptions.includes(value) && !deselectedParents.includes(value),
      )
      setSelectedValues(newSelectedValues)
      setCodes(newSelectedValues.map((option) => option[fetchResponse.code]))
    } else {
      // Select the newly added options and their children
      const addedOptions = newValue.filter((value) => !selectedValues.includes(value))
      const addedChildren = getChildren(addedOptions)
      const newSelectedValues = [...selectedValues, ...addedChildren]
      setSelectedValues(newSelectedValues)
      setCodes(newSelectedValues.map((option) => option[fetchResponse.code]))
    }
  }

  // Helper function to get the parent option of an option
  function getParent(option, options) {
    const parent = options.find((o) => o.lincodigo === option.linlindes)
    return parent || null
  }

  function getAllDescendants(node) {
    const result = []

    const children = options.filter((option) => option.linlindes === node.lincodigo)
    children.forEach((child) => {
      result.push(child)
      result.push(...getAllDescendants(child, options))
    })

    return result
  }

  function getChildren(nodes) {
    const result = []

    nodes.forEach((node) => {
      result.push(node)
      result.push(...getAllDescendants(node, options))
    })

    return result
  }

  function createCheckBoxes(node, selected) {
    /*
        {
    "lincodigo": "01000000",
    "lincodigo1": "01",
    "lindescri": "PORCELANATOS Y CERAMICAS",
    "linlindes": "",
    "linnivel": 1,
    "linstatus": "A",
    "lintipo": "M"
    }
    
        */
    // create a checkbox for the node, and for the linnivel summ the marginLeft
    const isParent = node.linlindes === ""
    const marg = node.linnivel ? node.linnivel : 1
    const isSelectAllOption = node[fetchResponse.code] === "seleccionar-todos"
    return (
      <div style={{ marginLeft: 10 * marg }}>
        <Checkbox icon={icon} checkedIcon={checkedIcon} checked={selected || (isSelectAllOption && selectAll)} />
        <label style={{ marginLeft: 5 }}>
          <span style={{ fontSize: "smaller" }}>{node[fetchResponse.description]}</span>
        </label>
      </div>
    )
  }

  const addSelectAllOptions = (options) => {
    if (options) {
      // this only if there are options
      if (options.length === 0) {
        return []
      }
      return [
        {
          [fetchResponse.code]: "seleccionar-todos",
          [fetchResponse.description]: "Seleccionar todos",
        },
        ...options,
      ]
    }
  }
  const filterOptions = createFilterOptions({
    limit: 10,
    matchFrom: "any",
    stringify: (option) => option[fetchResponse.code] + " " + option[fetchResponse.description],
  })

  const filterAndAddSelectAllOptions = (options, params) => {
    const filtered = filterOptions(options, params)
    const optionsWithSelectAll = addSelectAllOptions(filtered)
    return optionsWithSelectAll
  }

  const getOptionLabel = (option) => {
    if (option.artcodigo === "seleccionar-todos") {
      return option[fetchResponse.description]
    } else {
      return option[fetchResponse.code] + " - " + option[fetchResponse.description]
    }
  }

  const handleInputChange = (event, newInputValue) => {
    setInputValue(newInputValue)
    if (newInputValue.length === 0) {
      setFilteredOptions(options)
      return
    }
    if (!newInputValue) {
      setFilteredOptions(options)
      return
    }
    const recommendedOptions = options.filter((option) => {
      return option[fetchResponse.description].toLowerCase().includes(newInputValue.toLowerCase())
    })
    setFilteredOptions(recommendedOptions)
  }

  return (
    <Grid item xs={12} sm={6} md={4}>
      <Autocomplete
        multiple
        id="tags-standard"
        size="small"
        disableCloseOnSelect
        options={options}
        filterOptions={filterAndAddSelectAllOptions}
        value={selectedValues}
        getOptionLabel={getOptionLabel}
        onInputChange={handleInputChange}
        onChange={handleSelectOption}
        sx={{ maxWidth: "75vw" }}
        renderInput={(params) => (
          <TextField {...params} label={label} placeholder={placeholder} key={options[fetchResponse.description]} />
        )}
        renderOption={(props, option, { selected }) => <li {...props}>{createCheckBoxes(option, selected)}</li>}
      />
    </Grid>
  )
}
export default InputNestedChildren
