import React, { useEffect, useState } from "react"
import { Box, Button, CircularProgress, Modal, Backdrop, Fade } from "@mui/material"
import { DataGrid } from "@mui/x-data-grid"
import styled from "@emotion/styled"

import EliminarIcon from "../../../assets/iconos/Eliminar.ico"

import truncateNumber from "../../utils/math/truncate"

import DetalleIcon from "../../../assets/iconos/Detalle.ico"
import { useNavigate } from "react-router-dom"
import fetchwrapper from "../../../services/interceptors/fetchwrapper"
import CustomBackdrop from "../../../components/CustomBackdrop"

const StyledDataGridContainer = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  overflowX: "auto",
}))

const DatagridBuscarEjecutar = ({ data, setData, filtrarBusqueda }) => {
  const [isLoadingDeletePallet, setIsLoadingDeletePallet] = useState(false)
  const navigate = useNavigate()
  const ejecutarDetalle = (row) => {
    const { artcodigo } = row
    navigate(`${artcodigo}`, { state: row })
  }

  const columnas = [
    {
      headerName: "Bodega",
      field: "boddescri",
      width: 400,
    },
    {
      headerName: "Artículo Código",
      field: "artcodigo",
      width: 200,
    },
    {
      headerName: "Artículo Descripción",
      field: "artdescri",
      width: 400,
    },
    {
      headerName: "Cantidad",
      field: "stokdetcantidad",
      width: 200,
      valueFormatter: (params) => {
        return truncateNumber(params.value)
      },
    },
    {
      headerName: "Cantidad Conversión",
      field: "stokdetcantconver",
      width: 200,
      valueFormatter: (params) => {
        return truncateNumber(params.value)
      },
    },
    {
      headerName: "Ubicación",
      field: "ubicacion",
      width: 200,
      renderCell: (params) => {
        return (
          <div>
            <Button variant="outlined" color="primary" onClick={() => ejecutarDetalle(params.row)}>
              <img src={DetalleIcon} alt="ejecutar" style={{ width: "40px" }} />
            </Button>
          </div>
        )
      },
    },
    {
      headerName: "Izon Código",
      field: "izoncodigo",
      width: 150,
    },
    //   {
    //     headerName: "Zona",
    //     field: "izondescripcion",
    //     width: 200,
    //   },
    {
      headerName: "Bah Código",
      field: "bahcodigo",
      width: 150,
    },
    //   {
    //     headerName: "Bahía",
    //     field: "bahdescripcion",
    //     width: 200,
    //   },
    {
      headerName: "Pas Código",
      field: "pascodigo",
      width: 150,
    },
    //   {
    //     headerName: "Pasillo",
    //     field: "pasdescripcion",
    //     width: 200,
    //   },
    {
      headerName: "Niv Código",
      field: "nivcodigo",
      width: 150,
    },
    //   {
    //     headerName: "Nivel",
    //     field: "nivdescripcion",
    //     width: 200,
    //   },
    {
      headerName: "Pos Código",
      field: "poscodigo",
      width: 150,
    },

    //   {
    //     headerName: "Posición",
    //     field: "posdescripcion",
    //     width: 200,
    //   },
    {
      headerName: "Emb Código",
      field: "embcodigo",
      width: 150,
    },
    //   {
    //     headerName: "Embalaje",
    //     field: "embdescripcion",
    //     width: 200,
    //   },
    {
      headerName: "Ton Código",
      field: "toncodigo",
      width: 150,
    },
    //   {
    //     headerName: "Tonalidad",
    //     field: "tondescripcion",
    //     width: 200,
    //   },
  ]

  const eliminarPallet = async () => {
    const idPallet = data.palletId

    try {
      setIsLoadingDeletePallet(true)
      const options = {
        method: "POST",
        body: JSON.stringify({
          idPallet,
        }),
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
      await fetchwrapper("/paletizacion/buscar/eliminarPallet", options)
      await filtrarBusqueda()
      alert("Pallet eliminado con éxito")
    } catch (err) {
      alert("Error en la eliminación del pallet")
      console.log(err)
    } finally {
      setIsLoadingDeletePallet(false)
    }
  }

  return (
    <>
      <CustomBackdrop isLoading={isLoadingDeletePallet} />

      {data.palletId ? (
        <div style={{ marginTop: "25px" }}>
          <b style={{ fontSize: "20px", marginRight: "10px" }}>{data.palletId}</b>

          <Button variant="outlined" color="primary" onClick={eliminarPallet} sx={{ marginBottom: "15px" }}>
            Eliminar
            <span style={{ marginLeft: "8px" }}>
              <img src={EliminarIcon} alt="guardar" style={{ width: "40px" }} />
            </span>
          </Button>
        </div>
      ) : (
        <div style={{ marginTop: "25px", marginBottom: "15px" }}>
          <b style={{ fontSize: "20px", marginRight: "10px" }}>Sin Pallet Asignados</b>
        </div>
      )}

      <Box className={StyledDataGridContainer} sx={{ height: 800, width: "100%" }}>
        <DataGrid
          getRowId={(data) =>
            data.izoncodigo +
            "|" +
            data.bahcodigo +
            "|" +
            data.pascodigo +
            "|" +
            data.nivcodigo +
            "|" +
            data.poscodigo +
            "|" +
            data.embcodigo +
            "|" +
            data.toncodigo +
            "|" +
            data.artcodigo +
            data.stokdetcantidad +
            "|" +
            data.stokdetcantconver
          }
          columnVisibilityModel={{
            izoncodigo: false,
            bahcodigo: false,
            pascodigo: false,
            nivcodigo: false,
            poscodigo: false,
            embcodigo: false,
            toncodigo: false,
          }}
          columns={columnas}
          rows={data.items}
          disableRowSelectionOnClick
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          pageSizeOptions={[10, 25]}
        />
      </Box>
    </>
  )
}

export default DatagridBuscarEjecutar
