import React, { useState, useEffect } from "react"
import Header from "../../../layouts/Header"
import { styled, createTheme, ThemeProvider } from "@mui/material/styles"
import {
  Box,
  Grid,
  Card,
  CardContent,
  Typography,
  Autocomplete,
  TextField,
  CircularProgress,
  Checkbox,
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material"

import { MultiSelect } from "react-multi-select-component"
import "./styles.css"

// import MultiSelect from "../../../components/Multiselect";

import BackIcon from "../../../components/BackIcon"
import fetchwrapper from "../../../services/interceptors/fetchwrapper"
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank"
import CheckBoxIcon from "@mui/icons-material/CheckBox"

import EjecutarAccionIcon from "../../../assets/iconos/EjecutarAccion.ico"
import { useNavigate } from "react-router-dom"
import MultiTags from "../components/MultiTags"
import Obturador from "../../../components/Obturador/Obturador"

const StyledRoot = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  marginTop: theme.spacing(8),
  flexGrow: 1,
  padding: "0 16px",
  height: "100vh",
}))

const StyledTextIcon = styled("div")(({ theme }) => ({
  fontSize: "18px",
  marginTop: "10px",
  fontWeight: "bolder",
}))

const theme = createTheme({
  palette: {
    primary: {
      main: "#196C87",
    },
    secondary: {
      main: "#196C87", // Cambia el color secundario a verde azulado
    },
  },
})

const CrearPaletizacion = () => {
  const navigate = useNavigate()

  // pallets
  const [selectedPallets, setSelectedPallets] = useState([])

  // lotes
  const [selectedLotes, setSelectedLotes] = useState([])

  // bodegas
  const [allBodegas, setAllBodegas] = useState([])
  const [selectedBodegas, setSelectedBodegas] = useState([])
  const [isLoadingAllBodegas, setIsLoadingAllBodegas] = useState(false)

  // productos
  const [allProductos, setAllProductos] = useState([])
  const [selectedProductos, setSelectedProductos] = useState([])
  const [isLoadingAllProductos, setIsLoadingAllProductos] = useState(false)

  // opciones de radio button para busqueda de pallets
  const [selectedRadioPallet, setSelectedRadioPallet] = useState("pallet")

  const handleChangeRadioPallet = (event) => {
    setSelectedRadioPallet(event.target.value)
  }

  const getAllBodegas = async () => {
    try {
      setIsLoadingAllBodegas(true)
      let allBodegas = await fetchwrapper("/paletizacion/buscar/getAllBodegas")
      allBodegas = await allBodegas.json()

      setAllBodegas(allBodegas.data)

      console.log(allBodegas.data)
    } catch {
      alert("No se encontraron bodegas")
    } finally {
      setIsLoadingAllBodegas(false)
    }
  }

  const getAllProductos = async () => {
    try {
      setIsLoadingAllProductos(true)
      let allProductos = await fetchwrapper(`/paletizacion/buscar/getAllProductos`)
      allProductos = await allProductos.json()

      setAllProductos(allProductos.data)

      console.log(allProductos)
    } catch {
      alert("No se encontraron productos")
    } finally {
      setIsLoadingAllProductos(false)
    }
  }

  useEffect(() => {
    getAllBodegas()
    getAllProductos()
  }, [])

  const ejecutarBusqueda = () => {
    const data = {
      pallets: selectedPallets,
      lotes: selectedLotes,
      bodegas: selectedBodegas,
      productos: selectedProductos,
      opcionPallet: selectedRadioPallet,
    }

    navigate("Ejecutar", { state: data })
  }

  const textIsAlreadyDecodedPallet = (infoRead) => {
    const textDecoded = infoRead?.barcodeDataText

    if (textDecoded) {
      const data = {
        type: "barcodePallet",
        palletId: `PLA${textDecoded}`,
      }

      navigate("Ejecutar", { state: data })
    } else {
      throw new Error("")
    }
  }

  const textIsAlreadyDecodedLote = (infoRead) => {
    const textDecoded = infoRead?.barcodeDataText

    if (textDecoded) {
      const data = {
        type: "barcodeLote",
        lote: textDecoded,
        opcionPallet: selectedRadioPallet,
      }

      navigate("Ejecutar", { state: data })
    } else {
      throw new Error("")
    }
  }

  return (
    <>
      <ThemeProvider theme={theme}>
        <Header />
        <div className="main main-app p-3 p-lg-4">
          <BackIcon />
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "0 30px 30px 30px",
              fontSize: "25px",
            }}
          >
            <b>Paletizacion</b>
          </div>

          <Box className={StyledRoot}>
            <Grid container spacing={2} sx={{ justifyContent: "center" }}>
              <Grid item xs={12} md={10}>
                {/* Pallets */}
                <div className="multitag">
                  <MultiTags
                    selectedOptions={selectedPallets}
                    setSelectedOptions={setSelectedPallets}
                    placeholderMsg="Seleccione los pallets"
                  />
                  <Obturador
                    scannerParams={{
                      msg: "Escanea el código de barras",
                      codeType: "bar",
                    }}
                    executeFunction={textIsAlreadyDecodedPallet}
                  />
                </div>

                {/* Lotes */}
                <div className="multitag">
                  <MultiTags
                    selectedOptions={selectedLotes}
                    setSelectedOptions={setSelectedLotes}
                    placeholderMsg="Seleccione los lotes"
                  />
                  <Obturador
                    scannerParams={{
                      msg: "Escanea el código de barras",
                      codeType: "bar",
                    }}
                    executeFunction={textIsAlreadyDecodedLote}
                  />
                </div>

                {/* Bodegas */}
                <MultiSelect
                  className="multiselect"
                  options={allBodegas}
                  value={selectedBodegas}
                  isLoading={isLoadingAllBodegas}
                  onChange={setSelectedBodegas}
                  labelledBy="Select"
                  overrideStrings={{
                    selectAll: "Seleccionar todo",
                    selectSomeItems: "Selecciona las bodegas",
                    allItemsAreSelected: "Todas los items fueron seleccionados",
                  }}
                />

                {/* Productos */}
                <MultiSelect
                  className="multiselect"
                  options={allProductos}
                  value={selectedProductos}
                  isLoading={isLoadingAllProductos}
                  onChange={setSelectedProductos}
                  labelledBy="Select"
                  overrideStrings={{
                    selectAll: "Seleccionar todo",
                    selectSomeItems: "Selecciona los productos",
                    allItemsAreSelected: "Todas los items fueron seleccionados",
                  }}
                />

                {/* Radio group pallets */}

                <FormControl component="fieldset">
                  <RadioGroup
                    aria-label="gender"
                    name="gender1"
                    value={selectedRadioPallet}
                    onChange={handleChangeRadioPallet}
                  >
                    <FormControlLabel value="pallet" control={<Radio />} label="Con pallet" />
                    <FormControlLabel value="noPallet" control={<Radio />} label="Sin pallet" />
                    <FormControlLabel value="all" control={<Radio />} label="Todos" />
                  </RadioGroup>
                </FormControl>

                <div>
                  <Button variant="outlined" color="primary" onClick={ejecutarBusqueda}>
                    Ejecutar Búsqueda
                    <span style={{ marginLeft: "8px" }}>
                      <img src={EjecutarAccionIcon} alt="ejecutar" style={{ width: "40px" }} />
                    </span>
                  </Button>
                </div>
              </Grid>
            </Grid>
          </Box>
        </div>
      </ThemeProvider>
    </>
  )
}

export default CrearPaletizacion
